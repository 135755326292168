import { useContext, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import goToTop from "../../hooks/useTop";
import Center from "../../components/shared/Center";
import { object_diputacio } from "./const";
import { Container, Grid } from "@mui/material";
import { LanguageContext } from "../../context/LanguageContext";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import Star from "../../components/shared/Star";

const Diputacio = () => {
  const { language } = useContext(LanguageContext);
  const { setMenuActive, setSubmenuActive } = useContext(UserContext);

  useEffect(() => {
    setMenuActive(3);
    setSubmenuActive(0);
    goToTop();
  }, [setMenuActive, setSubmenuActive]);
  return (
    <>
      <Center object={object_diputacio} />
      <section className="basic__padding background__sky__blue">
        <Container>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            direction="row"
          >
            <p
              className="description__question"
              style={{ width: "100%" }}
            >
              <b className="center-title">
                {language === "es"
                  ? "En este centro se atiende"
                  : language === "en"
                  ? "In this center is served"
                  : "En aquest centre s'atén"}
              </b>
            </p>
          </Grid>
          <Grid
            className="reduce__contentx1"
            container
            justifyContent="left"
            alignItems="center"
            direction="row"
            xs={12}
          >
            <Grid container xs={12} md={6}>
              {language === "es" ? (
                <p className="description__item__photo">
                  <span className="item__enumeration">•</span> Terapia láser
                  <br />
                  <span className="item__enumeration">•</span> Bebés y niños
                  <br />
                  <span className="item__enumeration">•</span> Adolescentes
                  <br />
                  <span className="item__enumeration">•</span> Adultos
                  <br />
                  <span className="item__enumeration">•</span> Personas mayores
                </p>
              ) : language === "en" ? (
                <p className="description__item__photo">
                  <span className="item__enumeration">•</span> Laser therapy
                  <br />
                  <span className="item__enumeration">•</span> Babies and
                  children
                  <br />
                  <span className="item__enumeration">•</span> Teenagers
                  <br />
                  <span className="item__enumeration">•</span> Adults
                  <br />
                  <span className="item__enumeration">•</span> Seniors
                </p>
              ) : (
                <p className="description__item__photo">
                  <span className="item__enumeration">•</span> Teràpia làser
                  <br />
                  <span className="item__enumeration">•</span> Nadons i nens
                  <br />
                  <span className="item__enumeration">•</span> Adolescents
                  <br />
                  <span className="item__enumeration">•</span> Adults
                  <br />
                  <span className="item__enumeration">•</span> Gent gran
                </p>
              )}
            </Grid>
            <Grid container xs={12} md={6}>
              {language === "es" ? (
                <p className="description__item__photo">
                  <span className="item__enumeration">•</span> Deportistas
                  <br />
                  <span className="item__enumeration">•</span> Personas con
                  osteoporosis
                  <br />
                  <span className="item__enumeration">•</span> Embarazadas
                  <br />
                  <span className="item__enumeration">•</span> Personas con
                  hernia discal
                  <br />
                  <span className="item__enumeration">•</span> Acceso a personas
                  en silla de ruedas
                </p>
              ) : language === "en" ? (
                <p className="description__item__photo">
                  <span className="item__enumeration">•</span> Athletes
                  <br />
                  <span className="item__enumeration">•</span> People with
                  osteoporosis
                  <br />
                  <span className="item__enumeration">•</span> Pregnant
                  <br />
                  <span className="item__enumeration">•</span> People with
                  herniated disc
                  <br />
                  <span className="item__enumeration">•</span> People Access
                  wheelchair bound
                </p>
              ) : (
                <p className="description__item__photo">
                  <span className="item__enumeration">•</span> Esportistes
                  <br />
                  <span className="item__enumeration">•</span> Persones amb
                  osteoporosi
                  <br />
                  <span className="item__enumeration">•</span> Embarassades
                  <br />
                  <span className="item__enumeration">•</span> Persones amb
                  hèrnia discal
                  <br />
                  <span className="item__enumeration">•</span> Accés a persones
                  amb cadira de rodes
                </p>
              )}
            </Grid>
          </Grid>
        </Container>
      </section>
      {/* SECTION CARROUSEL */}
      <section className="basic__padding background__blue__carrousel">
        <Container>
          <Swiper
            modules={[Navigation]}
            spaceBetween={30}
            navigation
            loop={true}
            centeredSlides={true}
            autoHeight={true}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 1.2,
              },
            }}
            style={{ overflow: "initial", marginBottom: "15px" }}
          >
            <SwiperSlide className="slide__person">
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                className="container__person"
              >
                <br />
                <Star />
                <br />
                {language === "es" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “La solución fue la quiropráctica, gracias al equipo, pude
                      recuperarme y ya no he vuelto a tener dolor en las
                      lumbares.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Angels G.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Acudí al centro a raíz de una fuerte lumbalgia que me dejó
                      una semana en la cama sin poderme mover. Fui a
                      rehabilitación, pero seguía doliéndome. La solución fue la
                      quiropráctica, gracias al equipo, pude recuperarme y ya no
                      he vuelto a tener dolor en las lumbares. Ahora sigo
                      ajustándome para mantenerme en forma. Lo recomiendo sin
                      lugar a dudas.
                    </p>
                  </>
                ) : language === "en" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “The solution was chiropractic, thanks to the team, I was
                      able to recover and I have no more pain in my lower back.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Angels G.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      I went to the center due to severe low back pain that left
                      me in bed for a week without being able to move. I went to
                      rehab, but it still hurt. The solution was chiropractic,
                      thanks to the team, I was able to recover and I have no
                      more pain in my lower back. Now I continue to adjust to
                      stay in shape. I recommend it without a doubt.
                    </p>
                  </>
                ) : (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “La solució va ser la quiropràctica, gràcies a l'equip, em
                      vaig poder recuperar i ja no he tornat a tenir dolor a les
                      lumbars.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Angels G.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Vaig anar al centre arran d'una forta lumbàlgia que em va
                      deixar una setmana al llit sense poder-me moure. Vaig anar
                      a rehabilitació, però seguia fent-me mal. La solució va
                      ser la quiropràctica, gràcies a l'equip, em vaig poder
                      recuperar i ja no he tornat a tenir dolor a les lumbars.
                      Ara segueixo ajustant-me per mantenir-me en forma. Ho
                      recomano sense cap dubte.
                    </p>
                  </>
                )}
              </Grid>
            </SwiperSlide>
            <SwiperSlide className="slide__person">
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                className="container__person"
              >
                <br />
                <Star />
                <br />
                {language === "es" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “Son muy profesionales y desde entonces he recomendado a
                      varios amigos.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Marina
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Debido a mi profesión tenía muchos problemas en mi hombro
                      derecho. Después de la primera visita me sentí tan bien
                      que volví porque quería corregir otros problemas que
                      arrastraba desde hace tiempo. Son muy profesionales y
                      desde entonces he recomendado a varios amigos, que están
                      muy satisfechos con los resultados! Actualmente voy para
                      mantenimiento, y estoy muy contento de cómo me ayudan!
                    </p>
                  </>
                ) : language === "en" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “They are very professional and since then I have
                      recommended several friends.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Marina
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Due to my profession, I had many problems with my right
                      shoulder. After the first visit I felt so good that I came
                      back because I wanted to correct other problems that had
                      been dragging on for a long time. They are very
                      professional and I have since referred several friends,
                      who are very satisfied with the results! I am currently
                      going for maintenance, and I am very happy with how they
                      help me!
                    </p>
                  </>
                ) : (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “Són molt professionals i des de llavors he recomanat
                      diversos amics.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Marina
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      A causa de la meva professió tenia molts problemes a la
                      meva espatlla dreta. Després de la primera visita em vaig
                      sentir tan bé que vaig tornar perquè volia corregir altres
                      problemes que arrossegava des de feia temps. Són molt
                      professionals i des de llavors he recomanat diversos
                      amics, que estan molt satisfets amb els resultats!
                      Actualment vaig per a manteniment, i estic molt content de
                      com m'ajuden!
                    </p>
                  </>
                )}
              </Grid>
            </SwiperSlide>
            <SwiperSlide className="slide__person">
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                className="container__person"
              >
                <br />
                <Star />
                <br />
                {language === "es" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “Mi vida dio un cambio radical desde casi el primer
                      momento de empezar con la quiropráctica.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Mercè C.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Tenía un latigazo cervical a causa de un accidente hace
                      más de 15 años. Con 40 años estaba desesperada y agotada
                      física y psíquicamente. Había probado tantas cosas… Mi
                      vida dio un cambio radical desde casi el primer momento de
                      empezar con la quiropráctica. Vuelvo a tener mi semblante
                      sonriente, vuelvo a disfrutar de las pequeñas cosas que
                      tiene la vida y que ya tenía olvidadas: pasear, salir a
                      cenar, estirarme en el sofá a ver una película o a leer,
                      disfrutar de mi familia, de mis amigos… No me he vuelto a
                      tomar ni una sola pastilla.
                    </p>
                  </>
                ) : language === "en" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “My life underwent a radical change from almost the first
                      moment of starting chiropractic.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Mercè C.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      I had whiplash from an accident over 15 years ago. At the
                      age of 40, she was desperate and physically and mentally
                      exhausted. She had tried so many things... My life
                      underwent a radical change from almost the first moment of
                      starting chiropractic. I have my smiling face again, I
                      once again enjoy the little things that life has and that
                      I had already forgotten: taking a walk, going out to
                      dinner, stretching out on the sofa to watch a movie or
                      read, enjoying my family, my friends... I haven't taken a
                      single pill again.
                    </p>
                  </>
                ) : (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “La meva vida va donar un canvi radical des de gairebé el
                      primer moment de començar amb la quiropràctica.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Mercè C.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Tenia una fuetada cervical a causa d'un accident fa més de
                      15 anys. Amb 40 anys estava desesperada i esgotada
                      físicament i psíquicament. Havia provat tantes coses… La
                      meva vida va donar un canvi radical des de gairebé el
                      primer moment de començar amb la quiropràctica. Torno a
                      tenir el meu aspecte somrient, torno a gaudir de les
                      petites coses que té la vida i que ja tenia oblidades:
                      passejar, sortir a sopar, estirar-me al sofà a veure una
                      pel·lícula oa llegir, gaudir de la meva família, dels meus
                      amics… No m'he tornat a prendre ni una sola pastilla.
                    </p>
                  </>
                )}
              </Grid>
            </SwiperSlide>
          </Swiper>
        </Container>
      </section>
    </>
  );
};

export default Diputacio;
