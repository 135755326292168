const title = `Blog`;
const url_img = `banner_blog.jpg`;

const noticias = [
  {
    id: 1,
    img: "blog1.webp",
    date: "22 julio 2021",
    title: "¿Existe una solución a la hernia lumbar?",
    resumen:
      "Los problemas lumbares pueden afectarnos de muchas maneras, pero con el tiempo si no encontramos la causa del problema…",
  },
  {
    id: 2,
    img: "blog2.webp",
    date: "6 julio 2021",
    title: "Mitos de «crujir» los huesos",
    resumen:
      "¿Alguna vez has escuchado un chasquido como de “palomitas” en tus articulaciones al hacer algún movimiento?...",
  },
  {
    id: 3,
    img: "blog3.webp",
    date: "29 junio 2021",
    title: "Terapia láser como complemento de la salud",
    resumen:
      "Poco se conoce de los grandes beneficios que tiene el láser frío, y es que en España no existen muchos centros donde…",
  },
  {
    id: 4,
    img: "blog4.webp",
    date: "22 junio 2021",
    title: "Encuentra el quiropráctico a tu medida",
    resumen:
      "Encontrar el quiropráctico que nos guste es como encontrar nuestro dentista o médico ideal. Y es que no se trata de la...",
  },
  {
    id: 5,
    img: "blog5.jpg",
    date: "15 junio 2021",
    title: "Los hábitos de vida se convierten en salud",
    resumen:
      "Tener “hábitos saludables” en la sociedad en que vivimos hoy en día se está convirtiendo en un reto cada vez…",
  },
  {
    id: 6,
    img: "blog6.jpg",
    date: "8 junio 2021",
    title: "Cómo prevenir y recuperarse de un latigazo cervical",
    resumen:
      "Muchas personas tras un accidente o gesto brusco tienen problemas que no se llegan a resolver  aún habiendo...",
  },
  {
    id: 7,
    img: "blog7.jpg",
    date: "31 mayo 2021",
    title: "Conoce la quiropráctica durante el embarazo",
    resumen:
      "La quiropráctica durante el embarazo es todavía una gran desconocida para muchas personas, pero la gran...",
  },
  {
    id: 8,
    img: "blog8.webp",
    date: "26 mayo 2021",
    title: "La relación entre la espalda y los problemas de salud",
    resumen:
      "El dolor de espalda puede estar causada por un problema de columna, pero ¿sabías que hay otros problemas de…",
  },
  {
    id: 9,
    img: "blog9.webp",
    date: "18 mayo 2021",
    title: "Los famosos también eligen quiropráctica",
    resumen:
      "Normalmente cuando pensamos en celebridades en el ámbito del deporte, el espectáculo o la música...",
  },
  {
    id: 10,
    img: "blog10.webp",
    date: "11 mayo 2021",
    title: "Cuidados de la espalda en el trabajo",
    resumen:
      "¿Tienes que pedir la baja en tu trabajo por problemas de espalda? ¿Pasas muchas horas de pie o sentado/a...",
  },
  {
    id: 11,
    img: "blog11.jpeg",
    date: "4 mayo 2021",
    title: "Cómo afecta el estrés a tu cuerpo y mente",
    resumen:
      "En nuestra sociedad actual, donde el ritmo de vida es cada vez más acelerado, las fuentes de estrés se multiplican…",
  },
  {
    id: 12,
    img: "blog12.jpg",
    date: "26 abril 2021",
    title: "Cómo las mochilas afectan a la espalda de los más peques",
    resumen:
      "Quienes tienen hijos saben que estos cada día tienen que llevar mochilas con mucho peso llevando a muchos...",
  },
  {
    id: 13,
    img: "blog13.jpg",
    date: "14 abril 2021",
    title: "La salud es un proceso dinámico que juega a nuestro favor",
    resumen:
      "Si observamos a nuestro alrededor nos daremos cuenta que todo cuanto sucede en la naturaleza está regido por...",
  },
  {
    id: 14,
    img: "blog14.jpg",
    date: "7 abril 2021",
    title: "Organiza los pilares de tu salud fácilmente",
    resumen:
      "Existen 5 áreas en la salud que desde ahora puedes empezar a cuidar, pero antes necesitamos saber que aunque…",
  },
  {
    id: 15,
    img: "blog15.jpeg",
    date: "30 marzo 2021",
    title: "La buena postura: el alimento corporal",
    resumen:
      "Todos sabemos las “malas posturas” que adoptamos durante el día pero ¿sabías que estas pueden ser la causa...",
  },
  {
    id: 16,
    img: "blog16.jpg",
    date: "23 marzo 2021",
    title: "Cuando has hecho de todo para encontrarte bien, pero nada funciona",
    resumen:
      "Es cierto que la esperanza de vida ha aumentado en los últimos años, pero eso no quiere decir que la calidad...",
  },
  {
    id: 17,
    img: "blog17.jpeg",
    date: "15 junio 2021",
    title: "Haz ejercicio sin dañarte el cuerpo",
    resumen:
      "Cada vez son más las personas que practican algún deporte y saben que es bueno para su salud, pero sienten que…",
  },
  {
    id: 18,
    img: "blog18.jpg",
    date: "9 marzo 2021",
    title: "Una mirada más allá “de la edad”",
    resumen:
      "Muchas son las personas que piensan que por cumplir años su problema ya no tiene solución y están destinadas...",
  },
  {
    id: 19,
    img: "blog19.jpg",
    date: "5 febrero 2021",
    title: "Actúa lo antes posible después de un accidente",
    resumen:
      "La vida está llena de imprevistos que no podemos controlar. A nuestras consultas a menudo acuden pacientes...",
  },
  {
    id: 20,
    img: "blog20.jpg",
    date: "5 febrero 2021",
    title: "Cómo convivir mejor con la tecnología",
    resumen:
      "La nuevas tecnologías han transformado nuestras vidas y se han vuelto imprescindibles para llevar a cabo...",
  },
];

export { title, url_img, noticias };
