import React, { useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import {
  Collapse,
  Menu,
  MenuItem,
  Select,
} from "@mui/material";
import { UserContext } from "../../context/UserContext";
import { LanguageContext } from "../../context/LanguageContext";

const drawerWidth = 280;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const Navbar = () => {
  const { menuActive } = useContext(UserContext);
  const { language, setLanguage } = useContext(LanguageContext);
  const [open, setOpen] = useState(false);
  const [openSubluxacion, setOpenSubluxacion] = useState(false);
  const [openCenter, setOpenCenter] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const openClassic = Boolean(anchorEl);
  const openClassic2 = Boolean(anchorEl2);
  const handleClickClassic = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickClassic2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleClick = () => {
    setOpenCenter(false);
    setOpenSubluxacion(!openSubluxacion);
  };

  const handleClickQuiropractica = () => {
    setOpenSubluxacion(false);
    setOpenCenter(!openCenter);
  };

  const handleDrawerOpen = () => {
    setOpenSubluxacion(false);
    setOpenCenter(false);
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const changeLanguage = (e) => {
    localStorage.setItem("language", e);
    setLanguage(e.target.value);
  };

  const year = new Date().getFullYear();

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar className="navbar__main" position="fixed" open={open}>
        <Toolbar className="navbar__content">
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">
            <Link to="/">
              <img
                src={`${
                  process.env.REACT_APP_FRONTEND + "assets/image/logo.png"
                }`}
                alt="Logo LinQ"
                className="navbar__logo"
              />
            </Link>
          </Typography>
          <Box
            noWrap
            component="div"
            sx={{ flexGrow: 15 }}
            className="items-navbar"
          >
            <a
              className="menu__navbar"
              href
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClickClassic}
            >
              <ListItemText
                primary={
                  language === "es"
                    ? "Quiropráctica"
                    : language === "en"
                    ? "The Chiropractic"
                    : "Quiropráctica"
                }
                className={menuActive === 1 ? "navbar__menu__active" : ""}
              />
            </a>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openClassic}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Link className="item__menu__navbar" to="/the-chiropractic">
                <MenuItem onClick={handleClose}>
                  <ListItemText
                    primary={
                      language === "es"
                        ? "Quiropráctica"
                        : language === "en"
                        ? "The Chiropractic"
                        : "Quiropráctica"
                    }
                    className={menuActive === 1 ? "navbar__menu__active" : ""}
                  />
                </MenuItem>
              </Link>
              <Link className="item__menu__navbar" to="/subluxation">
                <MenuItem onClick={handleClose}>
                  <ListItemText
                    primary={
                      language === "es"
                        ? "Subluxacion"
                        : language === "en"
                        ? "Subluxation"
                        : "Subluxació"
                    }
                    className={menuActive === 1 ? "navbar__menu__active" : ""}
                  />
                </MenuItem>
              </Link>
            </Menu>
            <Link to="/solutions">
              <ListItemText
                primary={
                  language === "es"
                    ? "Soluciones"
                    : language === "en"
                    ? "Solutions"
                    : "Solucions"
                }
                className={menuActive === 2 ? "navbar__menu__active" : ""}
              />
            </Link>
            <a
              className="menu__navbar"
              href
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClickClassic2}
            >
              <ListItemText
                primary={
                  language === "es"
                    ? "Centros"
                    : language === "en"
                    ? "Centers"
                    : "Centres"
                }
                className={menuActive === 3 ? "navbar__menu__active" : ""}
              />
            </a>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl2}
              open={openClassic2}
              onClose={handleClose2}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Link to="/diputacio" className="item__menu__navbar">
                <MenuItem onClick={handleClose2}>
                  <ListItemText
                    primary="Diputació"
                    className={menuActive === 3 ? "navbar__menu__active" : ""}
                  />
                </MenuItem>
              </Link>
              <Link to="/valencia" className="item__menu__navbar">
                <MenuItem onClick={handleClose2}>
                  <ListItemText
                    primary="Valencia"
                    className={menuActive === 3 ? "navbar__menu__active" : ""}
                  />
                </MenuItem>
              </Link>
              <Link to="/vendrell" className="item__menu__navbar">
                <MenuItem onClick={handleClose2}>
                  <ListItemText
                    primary="El Vendrell"
                    className={menuActive === 3 ? "navbar__menu__active" : ""}
                  />
                </MenuItem>
              </Link>
              {/* <Link to="/masnou" className="item__menu__navbar">
                <MenuItem onClick={handleClose2}>
                  <ListItemText
                    primary="El Masnou"
                    className={menuActive === 3 ? "navbar__menu__active" : ""}
                  />
                </MenuItem>
              </Link> */}
            </Menu>
            <Link to="/blog">
              <ListItemText
                primary="Blog"
                className={menuActive === 4 ? "navbar__menu__active" : ""}
              />
            </Link>
            <Link to="/contact">
              <ListItemText
                primary={
                  language === "es"
                    ? "Contacto"
                    : language === "en"
                    ? "Contact"
                    : "Contacte"
                }
                className={menuActive === 5 ? "navbar__menu__active" : ""}
              />
            </Link>
          </Box>
          <Select
            className="items-navbar"
            value={language}
            onChange={(e) => changeLanguage(e)}
          >
            <MenuItem value="es" selected={language === "es" ? true : false}>
              ES
            </MenuItem>
            <MenuItem value="en" selected={language === "en" ? true : false}>
              EN
            </MenuItem>
            <MenuItem value="cat" selected={language === "cat" ? true : false}>
              CAT
            </MenuItem>
          </Select>
          <IconButton
            className="menu-mobile"
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
        className="navbar__menu"
      >
        <DrawerHeader className="navbar__menu__header">
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Select
          className="language-mobile"
          value={language}
          onChange={(e) => changeLanguage(e)}
          style={{ width: "150px" }}
        >
          <MenuItem value="es" selected={language === "es" ? true : false}>
            ES
          </MenuItem>
          <MenuItem value="en" selected={language === "en" ? true : false}>
            EN
          </MenuItem>
          <MenuItem value="cat" selected={language === "cat" ? true : false}>
            CAT
          </MenuItem>
        </Select>
        <List>
          <ListItemButton
            className="navbar__menu__option__collapse"
            onClick={handleClick}
          >
            <ListItemText
              primary={
                language === "es"
                  ? "Quiropráctica"
                  : language === "en"
                  ? "The Chiropractic"
                  : "Quiropráctica"
              }
              className={menuActive === 1 ? "navbar__menu__active" : ""}
            />
            {openSubluxacion ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse
            className="navbar__menu__collapse"
            in={openSubluxacion}
            timeout="auto"
            unmountOnExit
          >
            <ListItem key="Quiropráctica" disablePadding>
              <ListItemButton>
                <Link to="/the-chiropractic" onClick={handleDrawerClose}>
                  <ListItemText
                    primary={
                      language === "es"
                        ? "Quiropráctica"
                        : language === "en"
                        ? "The Chiropractic"
                        : "Quiropráctica"
                    }
                    className={menuActive === 1 ? "navbar__menu__active" : ""}
                  />
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem key="Subluxacion" disablePadding>
              <ListItemButton>
                <Link to="/subluxation" onClick={handleDrawerClose}>
                  <ListItemText
                    primary={
                      language === "es"
                        ? "Subluxacion"
                        : language === "en"
                        ? "Subluxation"
                        : "Subluxació"
                    }
                    className={menuActive === 1 ? "navbar__menu__active" : ""}
                  />
                </Link>
              </ListItemButton>
            </ListItem>
          </Collapse>
          <ListItem key="solutions" disablePadding>
            <ListItemButton>
              <Link to="/solutions" onClick={handleDrawerClose}>
                <ListItemText
                  primary={
                    language === "es"
                      ? "Soluciones"
                      : language === "en"
                      ? "Solutions"
                      : "Solucions"
                  }
                  className={menuActive === 2 ? "navbar__menu__active" : ""}
                />
              </Link>
            </ListItemButton>
          </ListItem>
          <ListItemButton
            className="navbar__menu__option__collapse"
            onClick={handleClickQuiropractica}
          >
            <ListItemText
              primary={
                language === "es"
                  ? "Centros"
                  : language === "en"
                  ? "Centers"
                  : "Centres"
              }
              className={menuActive === 3 ? "navbar__menu__active" : ""}
            />
            {openCenter ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse
            className="navbar__menu__collapse"
            in={openCenter}
            timeout="auto"
            unmountOnExit
          >
            <ListItem key="Diputacio" disablePadding>
              <ListItemButton>
                <Link to="/diputacio" onClick={handleDrawerClose}>
                  <ListItemText
                    primary="Diputació"
                    className={menuActive === 3 ? "navbar__menu__active" : ""}
                  />
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem key="Valencia" disablePadding>
              <ListItemButton>
                <Link to="/valencia" onClick={handleDrawerClose}>
                  <ListItemText
                    primary="Valencia"
                    className={menuActive === 3 ? "navbar__menu__active" : ""}
                  />
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem key="Vendrell" disablePadding>
              <ListItemButton>
                <Link to="/vendrell" onClick={handleDrawerClose}>
                  <ListItemText
                    primary="El Vendrell"
                    className={menuActive === 3 ? "navbar__menu__active" : ""}
                  />
                </Link>
              </ListItemButton>
            </ListItem>
            {/* <ListItem key="El Masnou" disablePadding>
              <ListItemButton>
                <Link to="/masnou" onClick={handleDrawerClose}>
                  <ListItemText
                    primary="El Masnou"
                    className={menuActive === 3 ? "navbar__menu__active" : ""}
                  />
                </Link>
              </ListItemButton>
            </ListItem> */}
          </Collapse>
          <ListItem key="blog" disablePadding>
            <ListItemButton>
              <Link to="/blog" onClick={handleDrawerClose}>
                <ListItemText
                  primary="Blog"
                  className={menuActive === 4 ? "navbar__menu__active" : ""}
                />
              </Link>
            </ListItemButton>
          </ListItem>
          <ListItem key="contact" disablePadding>
            <ListItemButton>
              <Link to="/contact" onClick={handleDrawerClose}>
                <ListItemText
                  primary={
                    language === "es"
                      ? "Contacto"
                      : language === "en"
                      ? "Contact"
                      : "Contacte"
                  }
                  className={menuActive === 5 ? "navbar__menu__active" : ""}
                />
              </Link>
            </ListItemButton>
          </ListItem>
        </List>
        <div className="navbar__menu__footer">
          <div>
            <IconButton href="https://www.facebook.com/quirolinq">
              <FacebookIcon />
            </IconButton>
            <IconButton href="https://www.instagram.com/quirolinq?igsh=MXBtdnFoNTl6bmxldw==">
              <InstagramIcon />
            </IconButton>
          </div>
          <div>
            <p>© {year} LinQ Quiroprácticos</p>
          </div>
        </div>
      </Drawer>
    </Box>
  );
};

export default Navbar;
