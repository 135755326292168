const title = `The Chiropractic`;
const title_español = `La Quiropráctica`;
const title_catalan = `La Quiropràctica`;
const url_img = `banner_chiropractic.jpg`;
const video_quiro = `help-quiro.mp4`;
const video_marcelo = `marcelo.mp4`;

export {
  title,
  title_español,
  title_catalan,
  url_img,
  video_quiro,
  video_marcelo
};
