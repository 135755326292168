import { useContext, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import goToTop from "../../hooks/useTop";
import Center from "../../components/shared/Center";
import { object_valencia } from "./const";
import { LanguageContext } from "../../context/LanguageContext";
import { Container, Grid } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import Star from "../../components/shared/Star";

const Valencia = () => {
  const { language } = useContext(LanguageContext);
  const { setMenuActive, setSubmenuActive } = useContext(UserContext);

  useEffect(() => {
    setMenuActive(3);
    setSubmenuActive(0);
    goToTop();
  }, [setMenuActive, setSubmenuActive]);
  return (
    <>
      <Center object={object_valencia} />
      <section className="basic__padding background__sky__blue">
        <Container>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            direction="row"
          >
            <p className="description__question" style={{ width: "100%" }}>
              <b className="center-title">
                {language === "es"
                  ? "En este centro se atiende"
                  : language === "en"
                  ? "In this center is served"
                  : "En aquest centre s'atén"}
              </b>
            </p>
          </Grid>
          <Grid
            className="reduce__contentx1"
            container
            justifyContent="left"
            alignItems="center"
            direction="row"
            xs={12}
          >
            <Grid container xs={12} md={6}>
              {language === "es" ? (
                <p className="description__item__photo">
                  <span className="item__enumeration">•</span> Bebés y niños
                  <br />
                  <span className="item__enumeration">•</span> Adolescentes
                  <br />
                  <span className="item__enumeration">•</span> Adultos
                  <br />
                  <span className="item__enumeration">•</span> Personas mayores
                  <br />
                  <span className="item__enumeration">•</span> Deportistas
                </p>
              ) : language === "en" ? (
                <p className="description__item__photo">
                  <span className="item__enumeration">•</span> Babies and
                  children
                  <br />
                  <span className="item__enumeration">•</span> Teenagers
                  <br />
                  <span className="item__enumeration">•</span> Adults
                  <br />
                  <span className="item__enumeration">•</span> Seniors
                  <br />
                  <span className="item__enumeration">•</span> Athletes
                </p>
              ) : (
                <p className="description__item__photo">
                  <span className="item__enumeration">•</span> Nadons i nens
                  <br />
                  <span className="item__enumeration">•</span> Adolescents
                  <br />
                  <span className="item__enumeration">•</span> Adults
                  <br />
                  <span className="item__enumeration">•</span> Gent gran
                  <br />
                  <span className="item__enumeration">•</span> Esportistes
                </p>
              )}
            </Grid>
            <Grid container xs={12} md={6}>
              {language === "es" ? (
                <p className="description__item__photo">
                  <span className="item__enumeration">•</span> Personas con
                  osteoporosis
                  <br />
                  <span className="item__enumeration">•</span> Embarazadas
                  <br />
                  <span className="item__enumeration">•</span> Personas con
                  hernia discal
                  <br />
                  <span className="item__enumeration">•</span> Acceso a personas
                  en silla de ruedas
                  <br />
                  <span className="item__enumeration">•</span> Problemas de
                  bruxismo
                </p>
              ) : language === "en" ? (
                <p className="description__item__photo">
                  <span className="item__enumeration">•</span> People with
                  osteoporosis
                  <br />
                  <span className="item__enumeration">•</span> Pregnant
                  <br />
                  <span className="item__enumeration">•</span> People with
                  herniated disc
                  <br />
                  <span className="item__enumeration">•</span> People Access
                  wheelchair bound
                  <br />
                  <span className="item__enumeration">•</span> Problems of
                  bruxism
                </p>
              ) : (
                <p className="description__item__photo">
                  <span className="item__enumeration">•</span> Persones amb
                  osteoporosi
                  <br />
                  <span className="item__enumeration">•</span> Embarassades
                  <br />
                  <span className="item__enumeration">•</span> Persones amb
                  hèrnia discal
                  <br />
                  <span className="item__enumeration">•</span> Accés a persones
                  amb cadira de rodes
                  <br />
                  <span className="item__enumeration">•</span> Problemes de
                  bruxisme
                </p>
              )}
            </Grid>
          </Grid>
        </Container>
      </section>

      {/* SECTION CARROUSEL */}
      <section className="basic__padding background__blue__carrousel">
        <Container>
          <Swiper
            modules={[Navigation]}
            spaceBetween={30}
            navigation
            loop={true}
            centeredSlides={true}
            autoHeight={true}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 1.2,
              },
            }}
            style={{ overflow: "initial" }}
          >
            <SwiperSlide className="slide__person">
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                className="container__person"
              >
                <br />
                <Star />
                <br />
                {language === "es" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “Toda una mejora en calidad de vida, por suerte existe
                      LinQ de la calle Valencia.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Pilar M.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Un gran descubrimiento, que ha supuesto dejar atrás
                      cervicales, migrañas y tos nerviosa. Gracias a la
                      quiropráctica y la profesionalidad de su equipo; siempre
                      aplicando prácticas personalizadas. Y cómo no, buscando
                      ayudar en todo momento. Toda una mejora en calidad de
                      vida, por suerte existe LinQ de la calle Valencia.
                    </p>
                  </>
                ) : language === "en" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “A whole improvement in quality of life, luckily there is
                      LinQ on Valencia street.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Pilar M.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      A great discovery, which has meant leaving behind cervical
                      pain, migraines and nervous cough. Thanks to chiropractic
                      and the professionalism of his team; always applying
                      personalized practices. And of course, looking to help at
                      all times. A whole improvement in quality of life, luckily
                      there is LinQ on Valencia street.
                    </p>
                  </>
                ) : (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “Tota una millora en qualitat de vida, per sort hi ha LinQ
                      del carrer València.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Pilar M.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Un gran descobriment, que ha suposat deixar enrere
                      cervicals, migranyes i tos nerviosa. Gràcies a la
                      quiropràctica i la professionalitat del vostre equip;
                      sempre aplicant pràctiques personalitzades. I és clar,
                      buscant ajudar en tot moment. Tota una millora en qualitat
                      de vida, per sort hi ha LinQ del carrer València.
                    </p>
                  </>
                )}
              </Grid>
            </SwiperSlide>
            <SwiperSlide className="slide__person">
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                className="container__person"
              >
                <br />
                <Star />
                <br />
                {language === "es" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “Estaba constantemente en tensión y me asustaba con el
                      mínimo ruido.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Marina
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Antes era imposible relajarme. Estaba constantemente en
                      tensión y me asustaba con el mínimo ruido. No era capaz de
                      poner la espalda totalmente recta y, al final del día,
                      sentía mucho cansancio y dolor de espalda. Con la
                      quiropráctica estoy más relajada, con mejor postura y
                      menos cansancio.
                    </p>
                  </>
                ) : language === "en" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “He was constantly in tension and scared me with the
                      slightest noise.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Marina
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Before it was impossible to relax. He was constantly in
                      tension and scared me with the slightest noise. I was not
                      able to keep my back completely straight and, at the end
                      of the day, I felt very tired and had back pain. With
                      chiropractic I am more relaxed, with better posture and
                      less fatigue.
                    </p>
                  </>
                ) : (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “Estava constantment en tensió i m'espantava amb el mínim
                      soroll.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Marina
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Abans era impossible relaxar-me. Estava constantment en
                      tensió i m'espantava amb el mínim soroll. No era capaç de
                      posar l'esquena totalment recta i, al final del dia,
                      sentia molt de cansament i mal d'esquena. Amb la
                      quiropràctica estic més relaxada, amb millor postura i
                      menys cansament.
                    </p>
                  </>
                )}
              </Grid>
            </SwiperSlide>
            <SwiperSlide className="slide__person">
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                className="container__person"
              >
                <br />
                <Star />
                <br />
                {language === "es" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “Algo que recomiendo a todo el mundo.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Victor R. N.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Tras muchos años con dolores de espalda a una edad muy
                      temprana (15-18), empecé a venir a LinQ por recomendación
                      de mi madre (paciente desde hace muchos años), y la verdad
                      es que después de 2 años he notado muchísima mejoría. Algo
                      que recomiendo a todo el mundo, no para esperar resultados
                      de forma inmediata, pero sí para buscar bienestar con tu
                      cuerpo a largo plazo.
                    </p>
                  </>
                ) : language === "en" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “Something that I recommend to everyone.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Victor R. N.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      After many years with back pain at a very young age
                      (15-18), I started coming to LinQ on the recommendation of
                      my mother (a patient for many years), and the truth is
                      that after 2 years I have noticed a lot of improvement.
                      Something that I recommend to everyone, not to expect
                      immediate results, but to seek well-being with your body
                      in the long term.
                    </p>
                  </>
                ) : (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “Cosa que recomano a tothom.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Victor R. N.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Després de molts anys amb mals d'esquena a una edat molt
                      primerenca (15-18), vaig començar a venir a LinQ per
                      recomanació de la meva mare (pacient des de fa molts
                      anys), i la veritat és que després de 2 anys he notat
                      moltíssima millora. Cosa que recomano a tothom, no per
                      esperar resultats de manera immediata, però sí per buscar
                      benestar amb el teu cos a llarg termini.
                    </p>
                  </>
                )}
              </Grid>
            </SwiperSlide>
          </Swiper>
        </Container>
      </section>
    </>
  );
};

export default Valencia;
