import { React, useContext, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import goToTop from "../../hooks/useTop";
import { Link, useParams } from "react-router-dom";
import { Container, Grid } from "@mui/material";

const BlogDetail = () => {
  const { id } = useParams();
  const { setMenuActive, setSubmenuActive } = useContext(UserContext);

  useEffect(() => {
    setMenuActive(4);
    setSubmenuActive(0);
    goToTop();
  }, [setMenuActive, setSubmenuActive]);
  return (
    <Container fixed className="basic__padding" style={{ paddingTop: "150px" }}>
      {/* BLOG 1 */}
      {id === "1" && (
        <>
          <p className="title__detail__blog">
            ¿Existe una solución a la hernia lumbar?
          </p>
          <p className="date__detail__blog">22 Julio 2021</p>
          <img
            src={`${process.env.REACT_APP_FRONTEND}assets/image/blog1.webp`}
            alt="Teresa"
            className="img__detail__blog"
          />
          <p className="content__detail__blog">
            Los problemas lumbares pueden afectarnos de muchas maneras, pero con
            el tiempo si no encontramos la causa del problema se pueden
            convertir en un determinante que nos termine limitando nuestra
            calidad de vida.
            <br />
            <br />
            La mayor parte de la población en el mundo tiene hernias (cervicales
            o lumbares mayoritariamente), pero de ser así ¿cómo es que algunas
            personas tienen problemas y otras no tienen ni un síntoma? Para
            responder primero tenemos que saber que…
          </p>
          <p className="subtitle__detail__blog">¿Qué es una hernia discal?</p>
          <p className="content__detail__blog">
            En el centro de la espalda está la columna vertebral que entre otras
            cosas, se compone de huesos (vértebras) y nervios. Entre las
            vértebras además se encuentra el disco que es actúa como una
            “almohada” para que los huesos se puedan mover, distribuir el peso y
            nosotros podamos articular nuestra espalda.
            <br />
            <br />
            Dentro de los discos se encuentra un centro (núcleo) que en gran
            parte es agua, y alrededor de este muchas anillas (aro).
            <br />
            <br />
            Una hernia ocurre cuando las anillas se van rompiendo y el núcleo se
            sale de “su centro” llegando a la zona donde están los nervios o
            médula pudiendo comprimir cualquiera de estas estructuras.
          </p>
          <p className="subtitle__detail__blog">
            ¿En qué vértebras lumbares suelen aparecer las hernias?
          </p>
          <p className="content__detail__blog">
            Si bien esto puede ocurrir en cualquier disco de la columna, en la
            zona lumbar suelen aparecer entre L4-L5 y L5-S1 debido a que tiene
            que cargar con todo el peso del tronco siendo a la vez una zona de
            curvaturas.
          </p>
          <p className="subtitle__detail__blog">
            ¿Se puede tener hernia sin dolor?
          </p>
          <p className="content__detail__blog">
            ¡Claro! Aunque parezca sorprendente la mayoría de los dolores y
            problemas como la ciática no son causadas por la hernia.
            <br />
            <br />
            Los problemas lumbares suelen originarse por otros motivos, por eso
            hay tantas persona que se tratan la hernia pero el dolor vuelve a
            salir y seguir su curso.
            <br />
            <br />
            Sigue leyendo para saber entonces porqué podemos tener problemas
            lumbares…
          </p>
          <p className="subtitle__detail__blog">
            ¿Si no es la hernia, cuál podría sería el origen del dolor o
            problemas?
          </p>
          <p className="content__detail__blog">
            Suele pasar que en la zona de la hernia, las vértebras no se mueven
            correctamente y por eso van formando la hernia misma así como
            inflaman la zona de los nervios (es lo que se conoce como
            subluxación vertebral).
            <br />
            <br />
            Si esto pasa, hasta que no se recupere el movimiento correcto de las
            vértebras, los problemas pueden continuar por más que mediquemos u
            operemos la hernia.
          </p>
          <p className="subtitle__detail__blog">
            ¿Cómo sé si el problema viene de las vértebras?
          </p>
          <p className="content__detail__blog">
            Esto se puede saber acudiendo a un profesional de quiropráctica que
            es el especializado en ver si hay vértebras que son las que están
            presionando la zona y creando el problema.
          </p>
          <p className="subtitle__detail__blog">
            Hábitos que empeoran o crean problemas lumbares y hernias:
          </p>
          <p className="content__detail__blog">
            <span className="item__enumeration">•</span>Sentarse muchas horas
            seguidas
            <br />
            <span className="item__enumeration">•</span>Cruzar las piernas
            <br />
            <span className="item__enumeration">•</span>Estirar las piernas en
            la cama o el sofá
            <br />
            <span className="item__enumeration">•</span>Pasar mas horas en el
            coche
            <br />
            <span className="item__enumeration">•</span>Levantar peso repetidas
            veces
            <br />
            <span className="item__enumeration">•</span>Movimientos de rotación
            y flexión lateral <br />
            Rebotes repetitivos
            <br />
            <span className="item__enumeration">•</span>Agacharse sin flexionar
            las rodillas
            <br />
            <span className="item__enumeration">•</span>Dormir boca abajo
            <br />
            <span className="item__enumeration">•</span>Dormir boca arriba con
            las piernas estiradas
            <br />
            <span className="item__enumeration">•</span>Algunos ejercicios{" "}
          </p>
          <p className="subtitle__detail__blog">
            Hábitos que alivian y ayudan en problemas lumbares y las hernias
          </p>
          <p className="content__detail__blog">
            <span className="item__enumeration">•</span>Hacer descansos si se
            está sentado mucho tiempo
            <br />
            <span className="item__enumeration">•</span>Agacharse con las
            piernas flexionadas
            <br />
            <span className="item__enumeration">•</span>Levantar y bajar pesos
            cerca del cuerpo flexionando las piernas
            <br />
            <span className="item__enumeration">•</span>Dormir de lado o boca
            arriba con las piernas semiflexionadas
            <br />
            <span className="item__enumeration">•</span>Estirar la zona después
            de realizar ejercicio físico
            <br />
            <span className="item__enumeration">•</span>Sentarse y no estirar
            las piernas en el sofá
            <br />
            <span className="item__enumeration">•</span>Evitar cruzar las
            piernas
          </p>
          <p className="subtitle__detail__blog">
            Las hernias y problemas lumbares, ¿Tienen solución?
          </p>
          <p className="content__detail__blog">
            Algunas hernias se forman y se reabsorben naturalmente en pocos
            meses, pero otras se cronifican. Lo importante es mantener el buen
            movimiento de los huesos para que los discos (las almohadas) se
            mantengan hidratados y fuertes.
            <br />
            <br />
            Actualmente la quiropráctica es muy reconocida por los grandes
            resultados en problemas lumbares (incluidas las hernias) incluso en
            casos crónicos donde todo se ha probado pero sin resolución.
            <br />
            <br />
            En todos los centros quiroprácticos en Barcelona de LinQ el
            porcentaje de personas que llegan con problemas, hernias o
            abultamientos en la zona lumbar son muy comunes y suelen acudir
            personas que han probado fármacos, operaciones o rehabilitación, y
            no han terminado de resolver el problema encontrando en la
            quiropráctica muy buenos resultados pudiendo volver a retomar su
            vida.
          </p>
          <p className="subtitle__detail__blog">
            ¿Cómo sé si la quiropráctica me puede ayudar?
          </p>
          <p className="content__detail__blog">
            Para saber si la quiropráctica ayudará a resolver el problema, es
            hacer una serie de pruebas que hace el quiropráctico. En los centros
            LinQ estos realizan el CLA-Insight, un sistema computerizado que con
            diferentes parámetros (muscular y vegetativo), y una serie de
            radiografías específicas en movimiento para evaluar el compromiso de
            los huesos, los nervios y su relación con el problema en sí.
          </p>
        </>
      )}
      {id === "2" && (
        <>
          <p className="title__detail__blog">Mitos de «crujir» los huesos</p>
          <p className="date__detail__blog">6 Julio 2021</p>
          <img
            src={`${process.env.REACT_APP_FRONTEND}assets/image/blog2.webp`}
            alt="Teresa"
            className="img__detail__blog"
          />
          <p className="content__detail__blog">
            ¿Alguna vez has escuchado un chasquido como de “palomitas” en tus
            articulaciones al hacer algún movimiento?; ¿Has visto películas o
            vídeos en el que un quiropráctico le “cruje” a un paciente la
            espalda?.
            <br />
            <br />
            Esas situaciones son muy comunes y se suele pensar -erróneamente-
            que “el crujir” hace un bien a la articulación, pero la realidad es
            muy diferente.
          </p>
          <p className="subtitle__detail__blog">
            El “crack” es un fenómeno natural{" "}
          </p>
          <p className="content__detail__blog">
            En ciencia, el sonido que se escucha se lo conoce como “fenómeno de
            cavilación articular” y ocurre cuando el nitrógeno pasa de estado
            líquido a gaseoso.
            <br />
            <br />
            En las articulaciones tenemos este elemento, el nitrógeno, que se
            encuentra en estado líquido. Cuando realizamos algún tipo de
            movimiento o ponemos presión en la articulación, el nitrógeno se
            puede transformar a gas y a causa de esa conversión se desencadena
            el sonido, no teniendo que ver con una intervención terapéutica.
            <br />
            <br />
            Sigue leyendo porque desde aquí muchas dudas se abren paso.
          </p>
          <p className="subtitle__detail__blog">
            El crujido, de ser parte de un tratamiento…
          </p>
          <p className="content__detail__blog">
            1. Cualquiera podría tratarse a sí mismo ya que no es muy difícil
            reproducirlo. ¿Cuántos de nosotros lo hemos hecho alguna vez o nos
            ha pasado de forma natural con algún movimiento o estiramiento,
            incluso sin querer?. 2. Por el mismo motivo, cualquier persona
            podría “tratar” a otra ya que no se necesitaría ninguna formación ni
            conocimiento. 3. Cada vez que nos cruje algo estaríamos tratando,
            algo que no ocurre como tal.
          </p>
          <p className="subtitle__detail__blog">
            Entonces, ¿qué hace el quiropráctico si el “crack” no soluciona el
            problema?
          </p>
          <p className="content__detail__blog">
            Si bien es cierto que hay técnicas que suenan al ajustar, el sonido
            no es lo que busca un Quiropráctico para tratar a alguien.
            <br />
            <br />
            Su objetivo es eliminar las “subluxaciones” (pérdida específica de
            movimiento de columna y articulaciones) para liberar el sistema
            nervioso y disminuir la irritación de la zona, y por eso mismo hay
            técnicas que puede producir un “Crack” y hay otras que no, pero no
            por ello no se consigue el objetivo de ajustar dichas subluxaciones
            y mejorar la salud de la persona.
          </p>
          <p className="subtitle__detail__blog">
            Las personas mayores y delicadas en su salud no podrían ir al
            quiropráctico.
          </p>
          <p className="content__detail__blog">
            Hay que pensar que el quiropráctico se pasa de 5 a 7 años
            universitarios formándose en cómo y dónde realizar el ajuste para
            que sea preciso sobre el segmento que necesita ser movido escogiendo
            la técnica más adecuada según la persona.
            <br />
            <br />
            Así pues, los ancianos y las personas con situaciones delicadas por
            su salud no podrían visitar al quiropráctico si los “cracks” fuesen
            el tratamiento. Sin embargo, este no es el caso, pues encontramos a
            muchas personas mayores que se benefician del tratamiento
            quiropráctico para tercera edad.
            <br />
            <br />
            Hay ajustes que hacen el sonido pero no es para todos los cuerpos.
            Si bien los ajustes son seguros, no todos los cuerpos toleran todas
            las técnicas por igual, por eso existen diferentes técnicas que el
            quiropráctico adapta a la persona.
            <br />
            <br />
            Las técnicas como la de “Activator» o “SOT» no tienen
            contraindicaciones por lo que las personas en situación de salud
            delicada pueden beneficiarse de mejorar su salud con total seguridad
            y sin necesidad de “crujir”.
          </p>
          <p className="subtitle__detail__blog">
            ¿Es malo para la salud sonarse las articulaciones uno mismo?
          </p>
          <p className="content__detail__blog">
            La literatura científica todavía se debate en crear una conclusión
            al respecto, y no ha podido determinar las consecuencias de crujirse
            las articulaciones uno mismo.
            <br />
            <br />
            Una teoría que tiene fuerza dentro de las diferentes opiniones es la
            que aboga por evitar hacerlo debido a las consecuencias que pueda
            tener en los ligamentos y tejidos blandos a largo plazo.
            <br />
            <br />
            Si una persona se hace crack a sí misma lo hará sin ningún tipo de
            guía objetiva, en cualquier dirección y sin ser específico por lo
            que algunas articulaciones pueden sonar, dando una sensación de
            alivio en el momento, pero habrá huesos que seguirán bloqueados por
            lo que no resolverá el problema y esa sensación de alivio o descarga
            le podrá crear una sensación de “adicción a crujirse” pero sin
            resolver el verdadero problema.
          </p>
          <p className="subtitle__detail__blog">
            ¿En qué se diferencia la osteopatía y la quiropráctica si ambos
            “crujen”?
          </p>
          <p className="content__detail__blog">
            Sí, ambos crujen, pero su forma de trabajar y objetivos así como su
            filosofía y formación son totalmente diferentes.
            <br />
            <br />
            Aunque a ambos profesionales les veamos «crujir» (incluso con
            posturas parecidas), la quiropráctica se ha especializado desde hace
            más de 120 años en encontrar y corregir de forma específica
            segmentos bloqueados llamados “Subluxaciones vertebrales”.
            <br />
            <br />
            La especificidad de la quiropráctica sobre esos segmentos es lo que
            la diferencia de cualquier otra profesión o técnica.
          </p>
          <p className="subtitle__detail__blog">
            Los quiroprácticos van al quiropráctico
          </p>
          <p className="content__detail__blog">
            Si con crujirse uno mismo se está tratando, los quiroprácticos
            entonces no necesitarían ir a otro doctor quiropráctico para
            ajustarse y se lo podrían hacer ellos mismos, pero la realidad es
            que para saber dónde ajustar y hacerlo de forma eficaz y segura,
            ellos tienen que acudir a que otro se lo haga.
            <br />
            <br />
            “Hacer fácil lo difícil es un verdadero arte” y es que aunque no lo
            parezca, la quiropráctica está llena de sus tres pilares: CIENCIA,
            ARTE Y FILOSOFÍA al servicio de quienes quieren cuidarse y mejorar
            su presente y su futuro.
          </p>
        </>
      )}
      {id === "3" && (
        <>
          <p className="title__detail__blog">
            Terapia láser como complemento de la salud
          </p>
          <p className="date__detail__blog">29 Junio 2021</p>
          <img
            src={`${process.env.REACT_APP_FRONTEND}assets/image/blog3.webp`}
            alt="Teresa"
            className="img__detail__blog"
          />
          <p className="content__detail__blog">
            Poco se conoce de los grandes beneficios que tiene el láser frío, y
            es que en España no existen muchos centros donde se utilice para
            rehabilitación, por suerte lo podemos encontrar desde hace unos años
            en el centro quiropráctico en Barcelona “LinQ Diputación” siendo
            actualmente el único en Cataluña teniendo grandes resultados para
            sus clientes.
          </p>
          <p className="subtitle__detail__blog">
            ¿En qué consiste esta terapia láser?{" "}
          </p>
          <p className="content__detail__blog">
            Ante un daño o inflamación del tejido, el láser frío promueve la
            regeneración celular a través de la bioestimulación y, de ese modo,
            acelera la recuperación de tejidos y tendones.
            <br />
            <br />
            Es un tratamiento novedoso, ideal para tratar el dolor crónico de
            forma efectiva pudiendo reducir o eliminar la necesidad de recurrir
            a fármacos, infiltraciones y cirugías.
          </p>
          <p className="subtitle__detail__blog">
            ¿Por qué elegir esta terapia?
          </p>
          <p className="content__detail__blog">
            Este láser FX635, es el único con la aprobación de la FDA para el
            tratamiento del dolor crónico del talón (fascitis plantar) y del
            dolor lumbar crónico, no obstante los beneficios abarcan más casos.
            <br />
            <br />
            Se trata de un producto de alta tecnología fabricado en los EEUU que
            ha sido probado científicamente con resultados excelentes.
          </p>
          <p className="subtitle__detail__blog">¿Es una terapia segura?</p>
          <p className="content__detail__blog">
            El láser frío es totalmente seguro y hasta la fecha no se han
            registrado efectos secundarios.
          </p>
          <p className="subtitle__detail__blog">¿Tiene contraindicaciones?</p>
          <p className="content__detail__blog">
            Excepto alguna condición de salud determinada, la gran mayoría de
            personas pueden aplicárselo sin inconveniente. Por seguridad, el
            profesional siempre preguntará antes de la aplicación determinadas
            condiciones de salud para determinar si se puede proceder a su
            aplicación.
          </p>
          <p className="subtitle__detail__blog">
            ¿Qué se nota después de la sesión?
          </p>
          <p className="content__detail__blog">
            Depende del daño tisular (del tejido) y de la inflamación que haya
            las personas suelen notar alivio local en las primeras sesiones.
            <br />
            <br />
            “Mi experiencia con el láser ha sido genial, al cabo de pocas
            sesiones ya noté mejoría en mi lesión del hombro. Sesión a sesión
            pude sentir cómo el láser trabajaba a nivel interno, en los
            tendones. Definitivamente voy a seguir usándolo en el futuro, ya que
            por mi profesión necesito estar al 100%! “ – RÉMI BIZOUARD
          </p>
          <p className="subtitle__detail__blog">
            Beneficios de la terapia láser
          </p>
          <p className="content__detail__blog">
            El poder regenerativo de esta tecnología reduce la inflamación y, en
            consecuencia, el dolor disminuye de forma progresiva (normalmente 4
            – 8 sesiones suelen ser suficientes para obtener cambios
            significativos, que pueden llegar a la total desaparición del
            dolor).
          </p>
          <p className="subtitle__detail__blog">
            ¿Para qué casos se recomienda?
          </p>
          <p className="content__detail__blog">
            Además de la fascitis plantar y del dolor lumbar, el láser frío
            también es altamente efectivo en el tratamiento de:
            <br />
            <br />
            <span className="item__enumeration">•</span>Lesiones por accidente{" "}
            <br />
            <span className="item__enumeration">•</span>Lesiones deportivas
            <br />
            <span className="item__enumeration">•</span>Tendinitis
            <br />
            <span className="item__enumeration">•</span>Latigazo cervical
            <br />
            <span className="item__enumeration">•</span>Codo de tenista y codo
            de golfista <br />
            <span className="item__enumeration">•</span>Molestias en hombro /
            rodilla <br />
            <br />
            <br />
            Estas son las indicaciones más comunes, pero el láser frío también
            se aplica con buenos resultados a otros casos.
          </p>
          <p className="subtitle__detail__blog">
            ¿Qué hay que hacer para empezar?
          </p>
          <p className="content__detail__blog">
            Si quieres beneficiarte de esta terapia láser como complemento o no
            del tratamiento quiropráctico, pide información en nuestro centro
            LinQ Diputación.
          </p>
          <p className="subtitle__detail__blog">
            La experiencia en el Centro LinQ Diputación:
          </p>
          <p className="content__detail__blog">
            “Desde que incorporamos la terapia de láser de baja intensidad (o
            láser frío) en nuestro centro, muchos pacientes se han beneficiado
            de las sesiones láser para tratar principalmente molestias de
            hombro, codo, manos, rodillas y pies (fascitis plantar).
            <br />
            <br />
            El láser de baja intensidad ayuda a regenerar los tejidos y tendones
            que han sido lastimados por algún mal gesto, caída o accidente y, de
            este modo, facilita su recuperación. También contribuye a reducir la
            inflamación y, en consecuencia, a disminuir el dolor.
            <br />
            <br />
            A las pocas sesiones con láser se suele notar una mejoría y, en
            general, con pocas sesiones es suficiente para obtener buenos
            resultados. La terapia láser es un buen complemento de la
            quiropráctica, y la combinación de las dos resultan en un
            tratamiento muy completo y efectivo. “ DR. JAKE DE GRAAF –
            Quiropráctico
            <br />
            <br />
            «Hace unos meses tuve una mala caída jugando con mi hija y me
            fracturé el codo derecho, además de desgarrarme el tendón. Hablé con
            el centro y empecé el tratamiento con el láser inmediatamente. Al
            ser quiropráctico utilizo las manos para trabajar ¡y tenía que
            volver pronto al trabajo!.
            <br />
            <br />
            Me dieron un mes de baja, ¡pero al cabo de dos semanas y media ya
            estaba trabajando de nuevo! La clave de mi pronta recuperación fue
            recibir tratamiento láser en el codo de forma regular en el centro
            LinQ.
            <br />
            <br />
            No hay duda de que las sesiones con láser aceleraron mi recuperación
            y recomiendo encarecidamente a este centro por su profesionalidad y
            por sus técnicas para una curación más rápida. Gracias”
            <br />
            <br />
            Si crees que te puede ayudar a ti o a algún conocido, puedes ponerte
            en contacto con nosotros.
            <br />
            <br />
            El láser solo puede estar aplicado bajo un profesional, consulte al
            suyo.
          </p>
        </>
      )}
      {id === "4" && (
        <>
          <p className="title__detail__blog">
            Encuentra el quiropráctico a tu medida
          </p>
          <p className="date__detail__blog">22 Junio 2021</p>
          <img
            src={`${process.env.REACT_APP_FRONTEND}assets/image/blog4.webp`}
            alt="Teresa"
            className="img__detail__blog"
          />
          <p className="content__detail__blog">
            Encontrar el quiropráctico que nos guste es como encontrar nuestro
            dentista o médico ideal. Y es que no se trata de la profesión sino
            de cómo se ejerce ya que esto nos puede traer resultados diferentes.
            Hay personas que les cuesta mucho encontrar un profesional que
            encaje con ellos y piensan que “no tienen remedio” o que «todos son
            iguales”; hoy te queremos dar unas pautas sobre qué esperar de un
            quiropráctico y cómo buscarlo más fácilmente para poder ahorrar
            tiempo, dinero y quebraderos de cabeza.
          </p>
          <p className="subtitle__detail__blog">
            No existe un quiropráctico “ideal” para todos
          </p>
          <p className="content__detail__blog">
            Gran parte de las personas que llegan a una consulta quiropráctica
            suele acudir por el “boca-boca” gracias a las experiencias que han
            tenido los propios pacientes.
            <br />
            <br />
            Muchas de esas personas pueden incluso saber de la existencia del
            quiropráctico desde hace años pero no han tomado acción hasta que se
            ven en una situación casi de urgencia, y necesitan encontrar a
            alguien que les ayude con su problema lo antes posible.
            <br />
            <br />
            Lo cierto es que cada persona tiene sus propias necesidades y
            objetivos (hay clientes que les gusta hablar, otros son más
            callados, hay otros que les gusta pasar tiempo relajándose y hay los
            que prefieren algo que no les lleve más de 2-3 minutos). Por eso,
            saber lo que uno quiere y le gusta es importante ya que si nos
            encontramos en una consulta que no tiene nuestro “ritmo”, podemos
            llegar a pensar que la quiropráctica no es para nosotros o que no
            sirve para nada.
            <br />
            <br />
            Para saber a quién buscar debemos saber primero qué nos gusta y
            queremos
          </p>
          <p className="subtitle__detail__blog">Tipos de profesionales</p>
          <p className="content__detail__blog">
            En España la quiropraxia siempre está en crecimiento por su gran
            demanda y podemos encontrar más de 300 profesionales en todo el
            territorio español. Cada uno tiene su forma particular de trabajar y
            de ser. Cada uno a lo largo a lo largo de su experiencia ha ido
            seleccionando la forma y las técnicas en las que se ha
            especializado.
            <br />
            <br />
            Especialidad: Muchos de los quiroprácticos incluso, están
            especializados en algún ámbito como el de:
            <br />
            <br />
            <span className="item__enumeration">•</span>Neurología
            <br />
            <span className="item__enumeration">•</span>Personas mayores
            <br />
            <span className="item__enumeration">•</span>Embarazadas
            <br />
            <span className="item__enumeration">•</span>Deportistas
            <br />
            <span className="item__enumeration">•</span>Niños y bebés
            <br />
            <span className="item__enumeration">•</span>Dolor crónico
            <br />
            <br />
            Tiempo: Dependiendo de las técnicas que se apliquen un ajuste puede
            durar de 5 a 10 minutos o más, no obstante queremos resaltar que sin
            importar el tiempo, el resultado será el mismo en cualquier caso ya
            que el objetivo es el mismo: detectar y corregir las subluxaciones
            vertebrales.
          </p>
          <p className="subtitle__detail__blog">
            España y la legalidad: ¡Cuidado con el intrusismo!
          </p>
          <p className="content__detail__blog">
            Aunque muchos no lo saben, la quiropráctica está reconocida por la
            OMS como una “profesión sanitaria de atención primaria”, pudiendo
            encontrar profesionales quiroprácticos en hospitales, salas de
            partos y en centros de rehabilitación.
            <br />
            <br />
            Esta información no se encuentra fácilmente en España debido a que
            es uno de los últimos que queda por legalizarla, por eso se
            encuentra como una profesión “alegal” (no tiene ley).
            <br />
            <br />
            El problema de esto surge cuando cualquier persona puede decir que
            es quiropráctico aún no teniendo la formación adecuada. Este es uno
            de los mayores desafíos con los que los quiroprácticos se encuentran
            y hacen todo lo posible para acabar con este intrusismo ya que
            engañan al público y lo ponen en riesgo estando tanto profesionales
            como clientes sin amparo legal.
          </p>
          <p className="subtitle__detail__blog">
            Formación de un quiropráctico
          </p>
          <p className="content__detail__blog">
            Debemos tener en cuenta que el quiropráctico ha tenido una formación
            universitaria a tiempo completo de 5 a 7 años dependiendo del país.
            Eso se traduce en más de 4000 horas lectivas y 1000 de clínica. Al
            ser una profesión de atención primaria su formación pertenece a las
            ciencias médicas más su especialización en quiropráctica. (En España
            hay dos universidades que extienden este título, una en Madrid y
            otra en Barcelona).
          </p>
          <p className="subtitle__detail__blog">
            Características a tener en cuenta
          </p>
          <p className="content__detail__blog">
            Como hemos dicho antes cada quiropráctico tiene su parte sus
            particularidades no obstante hay algunas cosas de las que podemos
            valernos para saber o intuir si la persona que tenemos enfrente es
            un quiropráctico titulado o no.
            <br />
            <br />
            Por un lado tenemos que tener en cuenta dos aspectos generales su
            formación y su forma de practicar.
            <br />
            <br />
            <b>Con respecto a su formación podemos: </b>
            <br />
            <br />
            <span className="item__enumeration">•</span>Asociación Española de
            Quiropráctica: Si un quiropráctico está dentro de esta asociación es
            la forma más fácil de saber si es o no titulado ya que en dicha
            asociación sólo pertenecen doctores graduados de universidades
            convalidadas con la UE.
            <br />
            <span className="item__enumeration">•</span>Recomendación directa:
            Si en la Asociación vemos que no tenemos a nadie cerca, siempre
            podemos contactar con alguno de sus quiroprácticos o con la central
            de la misma y preguntarles ya que hay quiroprácticos con título que
            no pertenecen a la asociación pero puede que sea conocido dentro del
            ámbito quiropráctico.
            <br />
            <br />
            <b>Con respecto a su forma de ejercer: </b>
            nuestra recomendación es que mires la web, las redes y preguntes si
            hacen charlas para saber cómo son.
            <br />
            <br />
            Tener una experiencia más cercana te dirá si realmente es quien
            estás buscando o no. De no serlo, puedes comunicarle al
            quiropráctico tu inquietud y él o ella te guiará. ¡Atrévete a
            probarlo, seguro que te sorprende!
            <br />
            <br />
            En el centro LinQ quiroprácticos en Barcelona nos preocupamos de
            tener solo profesionales titulados, donde cada uno se ha
            especializado en su propio estilo de ayudar. Es un equipo con
            experiencia y comprometido con cada cliente a conseguir ayudarle a
            mejorar su vida. Conócelos y contacta con ellos AQUí.
          </p>
        </>
      )}
      {id === "5" && (
        <>
          <p className="title__detail__blog">
            Los hábitos de vida se convierten en salud
          </p>
          <p className="date__detail__blog">15 Junio 2021</p>
          <img
            src={`${process.env.REACT_APP_FRONTEND}assets/image/blog5.jpg`}
            alt="Teresa"
            className="img__detail__blog"
          />
          <p className="content__detail__blog">
            Tener “hábitos saludables” en la sociedad en que vivimos hoy en día
            se está convirtiendo en un reto cada vez mayor. El tipo de
            alimentación, el alcohol, la vida sedentaria, el estrés, y todo lo
            que hagamos juegan un papel fundamental que día a día se irá
            acumulando en nuestro cuerpo y por lo tanto en cómo vivamos. Pero,
            ¿es problema de solo de unos pocos? Y lo más importante ¿cómo puedo
            cambiarlos? ¡Hoy te lo contamos todo!
          </p>
          <p className="subtitle__detail__blog">
            Que debes tener en cuenta para mejorar tus hábitos
          </p>
          <p className="subtitle__detail__blog">No sólo te pasa a ti…</p>
          <p className="content__detail__blog">
            Muchas personas están convencidas de que solo a ellos les cuesta
            vivir con hábitos saludables e idealizan la vida de los demás sin
            tantos problemas, sin tanto dolor y sin tantos retos como tienen
            ellos. Creen que los otros tienen una vida equilibrada y llena de
            buenos hábitos sin esfuerzo.
          </p>
          <p className="subtitle__detail__blog">
            No es cuestión de “suerte” y genética…
          </p>
          <p className="content__detail__blog">
            También están los que piensan que es cuestión de “suerte” tener una
            buena calidad de vida, y si bien es cierto que la genética puede
            jugar un papel importante, cada vez hay más estudios que demuestran
            que los hábitos y el entorno en el que vivimos son más decisivos
            para nuestro futuro que la propia genética.
          </p>
          <p className="subtitle__detail__blog">
            Todo lo que se ha hecho, repercute en el presente
          </p>
          <p className="content__detail__blog">
            Si bien es cierto que hay cosas que no podemos controlar que nos
            ocurran, como sufrir un accidente o la forma en que hemos nacido, lo
            cierto es que la mayoría de cosas que nos pasan tanto física como
            mentalmente, son a causa de hábitos acumulados durante años o
            incluso toda nuestra vida.
          </p>
          <p className="subtitle__detail__blog">
            ¿Cómo he llegado a este estado en mi vida?
          </p>
          <p className="content__detail__blog">
            Normalmente comienza con una experiencia o pensamiento que deriva a
            una emoción, de ahí se construye una idea; después empezamos a
            actuar en base a ese pensamiento y con el tiempo lo convertimos en
            una costumbre hasta que lo integramos y es un hábito.
            <br />
            <br />
            Así que todo lo que hemos ido repitiendo desde una experiencia o
            pensamiento se puede terminar convirtiendo en algo habitual en
            nuestra vida si le damos espacio.
            <br />
            <br />
            Por ejemplo cruzar las piernas suele ser un hábito que se ha ido
            acumulando a lo largo de los años y por eso mismo parar cambiarlo
            nos cuesta tanto.
          </p>
          <p className="subtitle__detail__blog">¿Cómo cambio mi presente?</p>
          <p className="content__detail__blog">
            Una forma de cambiar el presente es ir implementando hábitos para
            que en el futuro se vean los frutos.
            <br />
            <br />
            Es importante ser consciente de que la situación en la que nos
            encontramos no es estática (ya que desde el pasado hubo una
            evolución y seguirá evolucionando) y no cambiará de un día para
            otro, pero constancia el cambio se puede conseguir.
            <br />
            <br />
            Las personas cuando van al quiropráctico después de años con
            problemas y sus vidas se ven transformadas con el cuidado
            quiropráctico, llegan a la conclusión que lo único que han hecho en
            el pasado ha sido poner “parches”. Y valoran mucho los buenos
            hábitos y recomendaciones que el quiropráctico les va dando por los
            resultados positivos que están teniendo.
          </p>
          <p className="subtitle__detail__blog">La clave que nadie dice</p>
          <p className="content__detail__blog">
            Para crear un hábito (bueno o malo) necesitamos dos cosas:
            repetición y constancia.
            <br />
            <br />
            El ejemplo más sencillo es que para recordar un número de teléfono
            necesitaremos repetirlo durante días hasta que nos salga de forma
            automática.
            <br />
            <br />
            Esto mismo ocurre con cada cosa que hacemos, sentimos y pensamos.
            Por eso mismo los ajustes quiroprácticos pueden aliviar en pocas
            sesiones un problema, pero será como un parche hasta que no se
            repita el ajuste hasta que el cuerpo (el cerebro) reaprenda la forma
            de estar en equilibrio de forma natural.
          </p>
          <p className="subtitle__detail__blog">
            La importancia de los pilares
          </p>
          <p className="content__detail__blog">
            Una vez que entendemos que necesitamos ser pacientes para ver los
            resultados, y aplicar diariamente la regla de la constancia y la
            repetición, nos llega la pregunta de “¿qué hago?”.
            <br />
            <br />
            Los quiroprácticos enseñan hábitos dentro de los 5 pilares de la
            salud para que sea mucho más claro qué área se está trabajando.
            Estos son:
            <br />
            <br />
            <span className="item__enumeration">•</span>Nutrición
            <br />
            <span className="item__enumeration">•</span>Postura
            <br />
            <span className="item__enumeration">•</span>Ejercicio/respiración
            <br />
            <span className="item__enumeration">•</span>Mente/emociones
            <br />
            <span className="item__enumeration">•</span>Descanso
            <br />
            <br />
            Ahora bien, tenemos que añadir que es imprescindible para ello
            asegurarse que el sistema nervioso no está irritado y por eso
            tenemos falta de energía, problemas digestivos, bajas defensas o
            problemas de espalda, por eso los mejores resultados se obtienen
            cuando se acude al quiropráctico que mirará a ver cómo está esta
            parte del cuerpo y después cada uno podrá tener mejor resultados en
            los 5 pilares de salud.
            <br />
            <br />
            Muchos clientes quiroprácticos son profesores de yoga, pilates,
            instructores de gimnasio o triatletas y aún así notan una gran
            diferencia en su salud y rendimiento cuando acuden al quiropráctico.
            <br />
            <br />
            ¿Por dónde vas a empezar?
            <br />
            <br />
            Si piensas que le puede ayudar a alguien, comparte este artículo.
            Encontrarás más información en nuestro centro quiropráctico en
            Barcelona. ¡Solicita tu primera visita a través de nuestro
            formulario de contacto!
            <br />
            <br />
            ¡Hasta la próxima!
          </p>
        </>
      )}
      {id === "6" && (
        <>
          <p className="title__detail__blog">
            Cómo prevenir y recuperarse de un latigazo cervical
          </p>
          <p className="date__detail__blog">8 Junio 2021</p>
          <img
            src={`${process.env.REACT_APP_FRONTEND}assets/image/blog6.jpg`}
            alt="Teresa"
            className="img__detail__blog"
          />
          <p className="content__detail__blog">
            Muchas personas tras un accidente o gesto brusco tienen problemas
            que no se llegan a resolver aún habiendo hecho rehabilitación, y eso
            puede ser porque no han acudido al quiropráctico para ver si hay
            subluxaciones vertebrales que estén comprimiendo los nervios y
            desequilibrando la musculatura.
          </p>
          <p className="subtitle__detail__blog">
            En accidentes a poca velocidad el impacto es mayor
          </p>
          <p className="content__detail__blog">
            Un interesante estudio llevado a cabo en 2014 demostró que en el
            caso de colisiones de coche a baja velocidad (a partir de 8 km/h) se
            transmite el doble de esa velocidad a los ocupantes. La sacudida
            provocada por el impacto causa daños en los tejidos blandos del
            cuello y puede lastimar también la columna. Además hay que tener en
            cuenta que:
            <br />
            <br />
            <span className="item__enumeration">•</span>El riesgo de latigazo
            cervical es mayor en colisiones a poca velocidad, pues el ocupante
            absorbe una mayor fuerza del impacto y el cuello describe un
            movimiento brusco mayor que en choques a alta velocidad.
            <br />
            <span className="item__enumeration">•</span>Una mala postura o tener
            la cabeza girada de lado en el momento del choque puede empeorar la
            lesión del latigazo cervical. <br />
            <span className="item__enumeration">•</span>El 85% de las lesiones
            de cuello por accidente son por una colisión por detrás.
            <br />
            <span className="item__enumeration">•</span>Los síntomas del
            latigazo cervical normalmente no aparecen hasta al menos 24 horas
            después del accidente y, a veces, pueden tardar varios días en
            manifestarse.
            <br />
            <br />
            Las radiografías en movimiento que hacen los quiroprácticos ayudan a
            ver las consecuencias del latigazo cervical no resuelto. Así pues,
            la quiropráctica puede resultar muy efectivo para recuperarse,
            ayudando a mantener la columna flexible y alineada de modo que sea
            capaz de responder mucho mejor a los imprevistos que puedan suceder.
          </p>
          <p className="subtitle__detail__blog">
            Los 10 síntomas más comunes del latigazo cervical
          </p>
          <p className="content__detail__blog">
            <span className="item__enumeration">•</span>Dolor y rigidez en el
            cuello <br />
            <span className="item__enumeration">•</span>Dolor de cabeza
            <br />
            <span className="item__enumeration">•</span>Mareo/ vértigo
            <br />
            <span className="item__enumeration">•</span>Visión borrosa
            <br />
            <span className="item__enumeration">•</span>Cansancio
            <br />
            <span className="item__enumeration">•</span>Dificultad para
            concentrarse <br />
            <span className="item__enumeration">•</span>Problemas en los brazos
            <br />
            <span className="item__enumeration">•</span>Pitidos en los oídos{" "}
            <br />
            <span className="item__enumeration">•</span>Dificultad para dormir{" "}
            <br />
            <span className="item__enumeration">•</span>Irritabilidad
          </p>
          <p className="subtitle__detail__blog">
            Consejos para una buena postura en el coche
          </p>
          <p className="content__detail__blog">
            <span className="item__enumeration">•</span>ENTRAR AL COCHE: Es
            mejor sentarse primero con los dos pies en el suelo y, a
            continuación, entrar las piernas (una tras otra) dentro del coche.
            Al salir, sería bueno seguir el proceso contrario.
            <br />
            <span className="item__enumeration">•</span>LAS LUMBARES: La espalda
            debería estar apoyada completamente en el respaldo del asiento, sin
            dejar ningún hueco. Puede añadirse un cojín a la altura de las
            lumbares para ayudar a mantener la curvatura natural y como soporte
            de esa zona.
            <br />
            <span className="item__enumeration">•</span>LAS CERVICALES: La parte
            superior del reposacabezas tiene que estar a la altura de los ojos y
            la cabeza apoyada en él o lo más cerca posible (evitar encorvarse
            hacia adelante). Su función es evitar lesiones de cuello,
            principalmente el latigazo cervical.
            <br />
            <span className="item__enumeration">•</span>LOS BRAZOS: Las dos
            manos al volante, en posición a “las 10 y 10”, y los brazos un poco
            doblados par que estén relajados y no cargue los hombros y cuellos.
            <br />
            <span className="item__enumeration">•</span>LAS PIERNAS: Ligeramente
            flexionadas.
          </p>
          <p className="subtitle__detail__blog">
            Durante el viaje ten en cuenta que:
          </p>
          <p className="content__detail__blog">
            <b>*** Si eres el que conduce…</b> las paradas en el camino son
            fundamentales para despejar la mente y el cuerpo, y renovar la
            energía para tener todos los reflejos preparados para la conducción.
            <br />
            <br />
            <b>*** Si no eres el que conduce… </b>
            <br />
            <br />
            <span className="item__enumeration">•</span>Puedes realizar
            movilizaciones del cuello (flexiones laterales, delante y giros a
            los lados) cada hora. <br />
            <span className="item__enumeration">•</span>Agárrate al asiento con
            una mano y con la otra tira suavemente de la cabeza hacia el lado
            opuesto para estirar el trapecio.
            <br />
            <span className="item__enumeration">•</span>Para estirar la zona
            alta de la espalda, agárrate al asiento por delante con las dos
            manos y arquea la columna dibujando una “C” desde la coronilla, y
            empuja atrás para pegarla al respaldo del asiento.
            <br />
            <span className="item__enumeration">•</span>Para estirar los
            antebrazos, alarga un brazo hacia delante, levanta la palma de la
            mano hacia fuera y tira suavemente de los dedos hacia ti, empujando
            con la otra mano. <br />
            <span className="item__enumeration">•</span>Lleva los pies hacia
            atrás apoyando la punta debajo del asiento y empuja suavemente hacia
            delante con los empeines. <br />
            <span className="item__enumeration">•</span>Para activar la
            circulación apoya los pies en el suelo, fuera de los pedales, y
            levanta alternativamente los talones y las puntas de los pies.
            Conseguirás que tus piernas no estén tan “pesadas” a consecuencia de
            estar mucho tiempo sentados.
            <br />
            <br />
            <b>*** Durante un viaje largo,</b> es fundamental parar cada 45
            minutos o una hora y tomarse unos minutos para estirar las zonas que
            más se sobrecargan al conducir: la espalda, los brazos y los pies.
            Unos sencillos estiramientos pueden hacer del viaje mucho más ameno
            y con sensación de ligereza.
            <br />
            <br />
            <b>***Al llegar a tu destino…</b> es recomendable estirar y moverse
            aunque sean 10-15 minutos.
            <br />
            <br />
            Realizar estas cosas hace que se el cuerpo se note mucho mejor
            durante el viaje afectando positivamente nuestra experiencia.
            <br />
            <br />
            Si crees que puedes tener un latigazo cervical no solucionado y
            quieres ver si la quiropráctica te puede ayudar, contacta con
            nuestro centro quiropráctico y te informaremos.
          </p>
        </>
      )}
      {id === "7" && (
        <>
          <p className="title__detail__blog">
            Conoce la quiropráctica durante el embarazo
          </p>
          <p className="date__detail__blog">31 Mayo 2021</p>
          <img
            src={`${process.env.REACT_APP_FRONTEND}assets/image/blog7.jpg`}
            alt="Teresa"
            className="img__detail__blog"
          />
          <p className="content__detail__blog">
            La quiropráctica durante el embarazo es todavía una gran desconocida
            para muchas personas, pero la gran cantidad de beneficios que trae a
            la embarazada incluso para el parto y el post-parto es tan grande
            que cuando las mujeres lo descubren hace que estén mucho más seguras
            de la capacidad que tiene su cuerpo de mejorar cualquier situación
            en la que se encuentren.
          </p>
          <p className="subtitle__detail__blog">
            El embarazo: una etapa de adaptación para los dos
          </p>
          <p className="content__detail__blog">
            Durante la gestación el cuerpo pasa por cambios hormonales, físicos
            y emocionales, y a esto se le junta el estilo de vida que la futura
            mamá tenía previamente y durante el propio embarazo.
            <br />
            <br />
            Pero ¿por qué las experiencias de cada mujer son tan diferentes y
            únicas?. Esto es debido a lo que se llama: capacidad de adaptación.
            Dependiendo de la facilidad de adaptación a los cambios de su
            sistema nervioso, variará la forma de vivir el proceso. Cuanta mejor
            capacidad adaptativa, más disfrute de cada momento. Por esto mismo
            tiene un gran valor dedicar tiempo y consciencia en el proceso que
            se está llevando a cabo y realizar actividades y cuidados que
            favorezcan y expandan el bienestar propio y el del bebé.
            <br />
            <br />
            Además, debemos pensar que el cuerpo entra en un estado constante de
            cambios donde la energía generada es para dar y formar otra vida,
            por lo que se crea una conexión madre-bebé en donde cuanto más
            armónica sea, mejor será para los dos.
          </p>
          <p className="subtitle__detail__blog">
            ¿En qué ayuda la quiropráctica durante el embarazo?
          </p>
          <p className="content__detail__blog">
            La gente se sorprendería de la cantidad de mujeres que acuden al
            quiropráctico con fantásticas experiencias durante el embarazo y el
            post-parto.
            <br />
            <br />
            Es cierto que es un área de la quiropráctica desconocida para
            muchos, pero de gran valor en todo el mundo ya que ha aportado a las
            mujeres una sensación de tranquilidad, conexión con el bebé,
            consciencia de su cuerpo y disminución de las molestias físicas;
            pudiendo llevar una rutina mucho más equilibrada, incluso llegándose
            a recuperar mucho mejor después de dar a luz.
            <br />
            <br />
            ¿Cómo es posible? Si bien es cierto que el ejercicio, el trabajo de
            respiración y la higiene mental ayuda, gracias a los ajustes
            quiroprácticos ellas llegan a trabajar lugares que de otra forma no
            se podría ya que la quiropráctica se especializar en mantener un
            buen movimiento específico de cada articulación para equilibrar y
            liberar el sistema nervioso del estrés.
            <br />
            <br />
            De esta forma, los ajustes quiroprácticos consiguen en la mujer
            embarazada principalmente que:
            <br />
            <br />
            1. La columna en general recupere y mantenga una buena movilidad;
            por lo que se va adaptando a todos los estadios de gestación de
            forma natural.
            <br /> 2. Los huesos de la columna mantienen una buena alineación
            por lo que la postura se conserva mejor
            <br /> 3. Los huesos y ligamentos pélvicos se mueven y alinean de
            tal forma que respetan y favorecen los cambios que el embarazo y el
            parto necesita para dar a luz de forma más fácil y segura.
            <br />
            <br />
            Los beneficios más vistos al recibir un cuidado quiropráctico
            adecuado en las mujeres durante el embarazo son:
            <br />
            <br />
            1. Menos o ningún dolor de espalda, cuello u hombros.
            <br /> 2. Favorece el posicionamiento del bebé durante su formación.
            <br /> 3. Favorece el correcto posicionamiento del feto en el canal
            del parto.
            <br /> 4. Disminuye el dolor en el parto.
            <br /> 5. Las mamás se sienten más tranquilas durante todo el
            proceso.
          </p>
          <p className="subtitle__detail__blog">
            ¿Es segura la quiropráctica para una embarazada?
          </p>
          <p className="content__detail__blog">
            Los ajustes son una opción segura tanto para ella como para su bebé
            ya que el quiropráctico utiliza técnicas adaptadas y específicas
            para ellos en cada momento. Nuestra mejor recomendación es que se lo
            comunique a su quiropráctico en el momento en que sepa que está
            embarazada para que este pueda realizar las adaptaciones oportunas.
            <br />
            <br />
            Los quiroprácticos de LinQ, entre otras técnicas, utilizan la
            Webster. Esta técnica es de origen estadounidense (os dejamos AQUÍ
            el enlace) y sirve para equilibrar los huesos y ligamentos de la
            pelvis de forma eficaz y segura a través de suaves toques mientras
            dichas estructuras se van relajando y colocando de forma que permita
            un mejor movimiento y alineación de las estructuras para favorecer
            el momento del parto.
            <br />
            <br />
            En un estudio publicado en el JMPT mostró cómo el 82% de los casos
            el feto se había posicionado correctamente tras recibir cuidados
            quiroprácticos con la técnica Webster, en un grupo de embarazadas
            diagnosticadas de mal posicionamiento fetal.
          </p>
          <p className="subtitle__detail__blog">
            ¿Hacer o no hacer quiropráctica durante el embarazo?
          </p>
          <p className="content__detail__blog">
            Muchas madres notan una gran mejoría en el embarazo, el parto y el
            post-parto con cuidado quiropráctico en comparación a los hijos que
            han tenido sin haberse cuidado con ella.
            <br />
            <br />
            Lo que más agradecen es que han disfrutado mucho más del embarazo y
            se han recuperado mucho mejor en el post-parto tanto a nivel
            emocional como físico.
            <br />
            <br />
            Esto ha hecho que no sólo sigan con su cuidado después sino que
            también llevan a sus recién nacidos para un chequeo de su columna,
            muchas veces porque presentan síntomas de irritación que no se
            soluciona.
            <br />
            <br />
            Si te ha parecido interesante este artículo y quieres saber más,
            llama o visita LinQ, centro quiropráctico en Barcelona y
            alrededores.
          </p>
        </>
      )}
      {id === "8" && (
        <>
          <p className="title__detail__blog">
            La relación entre la espalda y los problemas de salud
          </p>
          <p className="date__detail__blog">26 Mayo 2021</p>
          <img
            src={`${process.env.REACT_APP_FRONTEND}assets/image/blog8.webp`}
            alt="Teresa"
            className="img__detail__blog"
          />
          <p className="content__detail__blog">
            El dolor de espalda puede estar causada por un problema de columna,
            pero ¿sabías que hay otros problemas de salud que también se pueden
            resolver si se cuida la columna? En este artículo te vamos explicar
            cómo es posible, algo que los clientes de la quiropráctica lo saben
            muy bien.
          </p>
          <p className="subtitle__detail__blog">
            La diferencia entre espalda y columna
          </p>
          <p className="content__detail__blog">
            Muchas personas piensan que “espalda” y “columna” es lo mismo, por
            eso, para entender mejor lo que hoy os queremos contar necesitamos
            aclarar que si bien la columna se encuentra en la espalda, estos son
            términos diferentes.
            <br />
            <br />
            <b>-- La espalda</b> se refiere a la parte posterior del cuerpo
            desde los hombros hasta la cintura donde están los músculos, la
            columna, los nervios, etc.
            <br />
            <br />
            <b>-- La columna vertebral</b> se encuentra en la parte central de
            la espalda y está compuesta por:
            <br />
            <br />
            <span className="item__enumeration">•</span>Médula y nervios <br />
            <span className="item__enumeration">•</span>Huesos (vértebras){" "}
            <br />
            <span className="item__enumeration">•</span>Discos
            <br />
            <br />
            Un dolor que se encuentra lateral de la espalda, se suele asociar
            con un músculo y si el dolor es más “central” se suele asociar con
            la columna, aunque en este post veremos que no siempre tiene que ser
            así.
          </p>
          <p className="subtitle__detail__blog">
            No todos los problemas ocurren por la musculatura
          </p>
          <p className="content__detail__blog">
            Normalmente se habla más de la musculatura cuando tenemos un
            problema de espalda, no obstante la musculatura, a diferencia de la
            columna, sí tiene fibras de dolor (es decir que nos duele si no
            funciona correctamente, algo que no nos pasa con la columna) pero,
            ¿sabías que los nervios que tensan y relajan la musculatura salen de
            la columna?; ¿Y si el exceso de tensión que no se resuelve con
            estiramientos o masajes puede ser porque esos nervios están pinzados
            y no llegan bien a la musculatura?; pues bien, para resolver esas
            dudas tenemos que hablar de la columna vertebral.
          </p>
          <p className="subtitle__detail__blog">
            El secreto de la columna vertebral
          </p>
          <p className="content__detail__blog">
            La columna, tiene tres cualidades fundamentales para nosotros:
            <br />
            <br />
            1. Hace que podamos permanecer erguidos al estar de pie.
            <br />
            2. Amortigua los golpes al caminar.
            <br />
            3. Protege a la médula.
            <br />
            <br />
            Pero para que pueda hacerlo sin darnos problemas tiene que tener un
            equilibrio importante: cada hueso de la columna se tiene que mover.
            Si esto no pasa es muy posible que se vaya creando una inflamación y
            altere a las tres partes:
            <br />
            <br />
            1. Iremos encorvados o no podremos estar de pie sin tener dolor.
            <br />
            2. Sentiremos dolor y/o rigidez al caminar llegando incluso a tener
            problemas de ciática o hernias.
            <br />
            3. Los nervios que salen de la médula se irritarán y podremos tener
            alteración en cualquier parte del cuerpo.
            <br />
            <br />
            Estos problemas los tenemos si hay presencia de subluxaciones
            vertebrales ya que el hueso no se mueve bien y desequilibra el resto
            del cuerpo, y si el nervio que va a la musculatura está pinzado,
            ¿cómo pretendemos que el músculo esté bien?
          </p>
          <p className="subtitle__detail__blog">
            ¿Cuáles son los problemas más comunes dependiendo del área afectada?
          </p>
          <p className="content__detail__blog">
            En la columna tenemos 3 zonas por donde salen los nervios
            (cervicales, dorsales y lumbares). Si a la columna le falta
            movimiento y hay inflamación (subluxación vertebral), puede ser la
            causa de muchos problemas de salud, los más comunes son:
            <br />
            <br />
            <span className="item__enumeration">•</span>Columna cervical (la
            zona más alta, el cuello): Muchas veces está recta y presenta
            subluxaciones; esta situación puede dar problemas de dolor de cabeza
            o migrañas; dolor de cuello; problemas en los brazos (como
            epicondilitis, tendinitis, adormecimiento o dolor); bronquitis,
            otitis y neumonías (muy común en bebés y niños). Esta zona también
            se asocia a estrés y personas con tendencia a “dar mucha vuelta a
            las cosas”.
            <br />
            <br />
            <span className="item__enumeration">•</span>Columna dorsal (la zona
            media, entre las escápulas): Los bloqueos en esta zona trae
            problemas respiratorios, sensación de agobio o incluso de ansiedad,
            postura encorvada (que por más que se intente no se termina de estar
            recto), problemas digestivos, y en muchos casos es una zona que
            colabora en problemas en la zona cervical y lumbar.
            <br />
            <br />
            <span className="item__enumeration">•</span>Columna lumbar (la zona
            más baja): Muchas personas acuden con problemas de hernia o
            desplazamientos vertebrales que afecta a la médula. Hay personas con
            desequilibrio pélvico o exceso/defecto de curvatura lumbar. Suele
            relacionarse con dolor lumbar o sacro, glúteos, adormecimiento de la
            pierna, problemas de rodilla, problemas de control de vejiga,
            cistitis recurrentes, dolores menstruales,…
          </p>
          <p className="subtitle__detail__blog">
            Beneficios del cuidado quiropráctico
          </p>
          <p className="content__detail__blog">
            Así pues, al principio hablábamos de cómo era posible que la columna
            estuviera implicada en problemas de salud, y es que la presencia o
            no de movimiento en ella puede ser clave ya que muchas personas se
            han visto con problemas de salud al tener las subluxaciones
            vertebrales si no se ha visitado el quiropráctico. Algunos problemas
            más comunes que se ven el la consulta quiropráctica son:
            <br />
            <br />
            <span className="item__enumeration">•</span>Tensión muscular <br />
            <span className="item__enumeration">•</span>Problemas de postura
            <br />
            <span className="item__enumeration">•</span>Mala recuperación de
            operaciones y accidentes
            <br />
            <span className="item__enumeration">•</span>Tendencia a lesionarse
            <br />
            <span className="item__enumeration">•</span>Problemas digestivos
            <br />
            <span className="item__enumeration">•</span>Ansiedad y depresión
            <br />
            <span className="item__enumeration">•</span>Otitis, neumonías y
            bronquitis
            <br />
            <span className="item__enumeration">•</span>Problemas del sistema
            inmunitario <br />
            <span className="item__enumeration">•</span>Ciática
            <br />
            <span className="item__enumeration">•</span>Epicondilitis y bursitis{" "}
            <br />
            <span className="item__enumeration">•</span>Migrañas y dolor de
            cabeza
            <br />
            <br />
            Estos son algunos de los problemas que muchas personas han padecido
            y han visto que al realizar un cuidado quiropráctico adecuado se ha
            resuelto o mejorado notablemente recuperando así su calidad de vida.
          </p>
        </>
      )}
      {id === "9" && (
        <>
          <p className="title__detail__blog">
            Los famosos también eligen quiropráctica
          </p>
          <p className="date__detail__blog">18 Mayo 2021</p>
          <img
            src={`${process.env.REACT_APP_FRONTEND}assets/image/blog9.webp`}
            alt="Teresa"
            className="img__detail__blog"
          />
          <p className="content__detail__blog">
            Normalmente cuando pensamos en celebridades en el ámbito del
            deporte, el espectáculo o la música, solemos asociarlo a cuerpos
            atléticos, buen forma física y/o una imagen perfecta, pero ¿sabías
            que muchos de ellos se cuidan periódicamente con quiropráctica? Y lo
            más importante ¿sabes por qué lo hacen?. Hoy te queremos hablar de
            ello y mucho más.
          </p>
          <p className="subtitle__detail__blog">Deportistas famosos</p>
          <p className="content__detail__blog">
            Es lógico pensar que para dedicarse al deporte de forma profesional
            hay que estar en la mejor forma física y mental posible durante todo
            el año. Así pues, es sabido que los deportistas aplican los mejores
            cuidados en su alimentación y su entrenamiento, muchas veces bajo la
            supervisión de un Coach físico y de vida, orientado al máximo
            rendimiento.
            <br />
            <br />
            Pues bien, la quiropráctica aunque no se sepa públicamente, también
            es un pilar fundamental para estos atletas ya que muchos han visto
            que gracias a ella:
            <br />
            <br />
            1. Mejora su rendimiento donde antes no habían podido hacerlo.
            <br />
            2. Sus recuperaciones son más rápidas.
            <br />
            3. Las lesiones diminuyen desde que se ajustan.
            <br />
            <br />
            Algunas de las declaraciones de estos deportistas las podemos sacar
            de personas como Tiger Woods y el ciclista Lance Amstrong:
            <br />
            <br />
            <span className="item__enumeration">•</span>Tiger Woods, uno de los
            mejores golfistas de todos los tiempos declaró hace tiempo que: «Ya
            no me acuerdo de la primera vez que fui al quiropráctico. Para mí es
            tan esencial como practicar mi swing”. Es sabido que la
            quiropráctica le ha ayudado en el cuidado de su hernia lumbar y que
            no solo él sino también su familia acuden al quiropráctico
            periódicamente.
            <br />
            <span className="item__enumeration">•</span>Lance Armstrong,
            ciclista muy conocido dijo: “No hubiera podido ganar sin mi
            quiropráctico. Él es posiblemente la persona más importante del
            equipo”.
            <br />
            <br />Y este tipo de testimonios los podemos encontrar en equipos de
            fútbol, baloncesto y deportistas olímpicos en todo el mundo.
          </p>
          <p className="subtitle__detail__blog">Cantantes famosos</p>
          <p className="content__detail__blog">
            A nuestros centros acuden muchas personas que se dedican al mundo
            del espectáculo, incluidos cantantes de ópera y profesores de canto;
            para estos su voz es la herramienta clave en su vida y cuando
            comienzan con su cuidado quiropráctico notan que pueden llegar con
            su voz “mas lejos” sin tener que forzarla tanto. Esto se debe
            principalmente a que la postura mejora, irguiéndose sin que ellos la
            fuerzas y al mejorar las curvas cervicales y dorsales consiguen
            mejorar la salida y entrada de aire.
            <br />
            <br />
            <span className="item__enumeration">•</span>Madonna es de las
            cantantes y artistas más conocidas en el mundo y desde hace décadas
            su quiropráctico la acompaña a todas sus giras no sólo para cuidarla
            a ella sino también al resto de su elenco ya que es fundamental que
            todos estén en el mejor rendimiento posible físico y mental para
            equilibrar todo el estrés al que están sometidos.
          </p>
          <p className="subtitle__detail__blog">Actores y actrices</p>
          <p className="content__detail__blog">
            Cuando están trabajando, los actores y actrices pasan muchas horas
            viajando, rodando y practicando y todo bajo mucho estrés ya que todo
            tiene que salir bien. Además, muchos de ellos tienen que realizar
            acrobacias y escenas donde su cuerpo adopta malas posturas y su
            columna está sometida a golpes comprometiendo su estado físico.
            <br />
            <br />
            Es muy común encontrar los actores y actrices dentro del mundo
            quiropráctico ya que les ayuda a seguir teniendo esas largas
            jornadas de rodajes, entrevistas y eventos.
            <br />
            <br />
            <span className="item__enumeration">•</span>Mel Gibson es actor y
            director y se sabe que la quiropráctica le ayudó a eliminar el dolor
            y sus problemas de espalda por mucho tiempo. Declaró que acude al
            quiropráctico desde hace más de 30 años y la utiliza como un cuidado
            natural y preventivo de su salud.
            <br />
            <span className="item__enumeration">•</span>Arnold Schwarzenegger es
            un actor conocido, deportista desde joven, y ex-gobernador del
            estado de California (EEUU), grabó un documental llamado Pumping
            Iron junto a su amigo Franco Columbu, quien acabó dedicándose a la
            quiropráctica. Arnold admira tanto esta profesión que en 1993 se
            convirtió en vocal de la Asociación Internacional de Quiropráctica.
            <br />
            <span className="item__enumeration">•</span>Uma Thurman, actriz de
            la famosa película Kill Bill, se lesionó en el rodaje de la segunda
            parte durante la escena de un accidente automovilístico donde Uma
            tuvo que realizar recuperación durante meses incluyendo la atención
            quiropráctica, ajustes de la columna vertebral, entre otras terapias
            en el Barton Chiropractic Clinic.
            <br />
            <br />
            Para todos ellos es imprescindible estar en su mejor forma física y
            equilibrio mental para poder rendir al máximo y realizar su mejor
            trabajo; y esto también se puede traducir en todos nosotros ya que
            encontrarnos bien hace que podamos tener nuestro mejor rendimiento
            cuando estamos con la familia, en nuestro trabajo y en nuestra vida
            en general.
            <br />
            <br />
            “Los Quiroprácticos son los únicos profesionales en salud que
            entienden totalmente lo que estamos hablando, el Dr. Palmer
            (fundador de la quiropráctica) fue un adelantado a su tiempo, y los
            quiroprácticos han estado a la vanguardia en el campo de la salud, y
            tienen una gran capacidad para entender los conceptos como un todo.
            En la siguiente década ellos serán los lideres.” DR. DEEPACK CHOPRA,
            MÉDICO Y AUTOR
          </p>
        </>
      )}
      {id === "10" && (
        <>
          <p className="title__detail__blog">
            Cuidados de la espalda en el trabajo
          </p>
          <p className="date__detail__blog">11 Mayo 2021</p>
          <img
            src={`${process.env.REACT_APP_FRONTEND}assets/image/blog10.webp`}
            alt="Teresa"
            className="img__detail__blog"
          />
          <p className="content__detail__blog">
            ¿Tienes que pedir la baja en tu trabajo por problemas de espalda?
            ¿Pasas muchas horas de pie o sentado/a y terminas la jornada con
            dolor? Y lo más importante, ¿Te gustaría terminar tu día sintiéndote
            bien? Este tipo de situaciones son cada vez más comunes de encontrar
            en una consulta quiropráctica ya que normalmente con las prisas y el
            estrés no atendemos a nuestro cuerpo todo lo que necesitaría y con
            el tiempo descubrimos que nuestra salud está cada vez más afectada.
            <br />
            <br />
            Cuando tenemos energía y nos sentimos bien tenemos más ganas y
            facilidad de cumplir objetivos personales y profesionales que con
            los años vamos teniendo; no obstante, cuando nos sentimos mal hasta
            el punto en que nos vemos limitados a la hora de desarrollar nuestro
            trabajo hace darnos cuenta la importancia que tiene la salud en
            nuestra vida y en nuestro equilibrio mental.
          </p>
          <p className="subtitle__detail__blog">
            ¿Cómo afecta el dolor en nuestra vida laboral?
          </p>
          <p className="content__detail__blog">
            Para responder a esta pregunta primero queremos aportar una
            perspectiva global de cómo afecta el dolor de espalda en el mundo,
            ya que los datos pueden sorprender a más de uno haciendo ver
            claramente que no es algo “de pocos” ni un hecho aislado.
            <br />
            <br />
            Para empezar, la Facultad de Medicina de Harvard y el “Instituto
            Mercer Health and Benefits” de San Francisco realizaron un análisis
            de distintos estudios hechos en Estados Unidos y Europa. Dicha
            revisión trató sobre el coste-efectividad del tratamiento
            quiropráctico en el dolor lumbar. Dicho estudio mostró que:
            <br />
            <br />
            <span className="item__enumeration">•</span>En el 2002 el 36% de los
            estadounidenses sintieron algún tipo de dolor lumbar. <br />
            <span className="item__enumeration">•</span>Este estudio también
            estableció que el 85% de personas con dolor lumbar tienen recaídas
            en algún momento de su vida.
            <br />
            <br />
            Además, el “Global Burden of Disease”, el mayor estudio
            epidemiológico publicado, concluyó que el dolor lumbar es la primera
            causa de discapacidad en el mundo, por lo que todo apunta a que el
            dolor lumbar es mucho muy común en el mundo.
          </p>
          <p className="subtitle__detail__blog">
            ¿Cómo podemos evitar el absentismo laboral?
          </p>
          <p className="content__detail__blog">
            Por suerte hoy en día las empresas son mucho más conscientes a la
            hora de enseñar a sus empleados la importancia de la higiene
            postural en el trabajo además de que muchas de ellas han habilitado
            sillas, mesas, y ordenadores que intentan adaptarse a la ergonimía
            de la persona pero ¿qué más podemos hacer? A continuación te lo
            contamos:
          </p>
          <p className="subtitle__detail__blog">
            1. La impotencia de los descansos
          </p>
          <p className="content__detail__blog">
            Aunque no lo parezca, tomarse 30 segundos de descanso cada hora
            puede ayudarnos a que la jornada no cargue tanto el cuerpo. Por
            “descanso” nos referimos a mover el cuerpo de forma diferente a
            nuestro puesto de trabajo (si estamos mucho tiempo sentados, sería
            una buena idea movernos aunque sea a por un vaso de agua, caminar un
            poco, mover los brazos “en mariposa” hacia delante y hacia atrás,
            flexionar las piernas para estirar las lumbares,…). Normalmente
            solemos concentrarnos tanto que no caemos en hacer este tipo de
            cosas, pero poniendo la alarma del móvil cada hora o 40 minutos nos
            hará un gran favor par nuestra salud.
          </p>
          <p className="subtitle__detail__blog">2. Mesas de altura adaptable</p>
          <p className="content__detail__blog">
            Para los que pasan mucho tiempo en un mismo sitio os recomendamos
            una mesa con patas adaptables en su altura para ir alternando el
            estar sentado a estar de pie durante la jornada.
          </p>
          <p className="subtitle__detail__blog">
            3. La almohadilla para el ratón puede ser mágica
          </p>
          <p className="content__detail__blog">
            Muchos de las oficinas ya lo han incorporado pero si hemos adaptado
            nuestra oficina a casa y estamos desprovistos de material
            ergonómico, la almohadilla del ratón es algo asequible que ayuda
            mucho al descanso de la muñeca y el brazo.
          </p>
          <p className="subtitle__detail__blog">4. Moverse es importante</p>
          <p className="content__detail__blog">
            Hacer algo de ejercicio nos ayuda a despejar la mente, disminuir el
            estrés, aumentar la dinámica del cuerpo y a estimular los músculos
            correctamente. Tomarse un tiempo diario, aunque sean 15 minutos al
            día, con la ropa y zapatillas adecuadas oxigenará nuestra mente y
            nuestra vida.
          </p>
          <p className="subtitle__detail__blog">
            5. Despejar la mente mejora el rendimiento
          </p>
          <p className="content__detail__blog">
            Leer, comer fuera del trabajo, pasar tiempo con la familia y amigos,
            tener contacto con la naturaleza,… cuando el cerebro está
            interactuando con el mundo fuera del ámbito laboral mejora sus
            conexiones nerviosas, se despeja y por lo tanto puede volver a su
            tarea con mejor rendimiento en cuanto a la memoria y resolución de
            problemas.
          </p>
          <p className="subtitle__detail__blog">
            6. Chequea tu columna de subluxaciones
          </p>
          <p className="content__detail__blog">
            Muchas veces aparecen problemas de espalda y postura que incluso se
            pueden extender a dolores de cabeza, epicondilitis, ciáticas,
            hernias, etc. y que por más que adoptamos buenos hábitos el problema
            continúa. De ser así o antes de que aparezcan estos problemas, es
            una buena idea acudir al quiropráctico ya que es quien está
            especializado en localizar de forma específica las vértebras que han
            perdido su alineación y movimiento de forma y corregirlo de forma
            eficaz, natural y segura.
            <br />
            <br />
            Una columna que esté bien alienada y en buen movimiento podrá
            conservar más salud con el paso del tiempo.
          </p>
          <p className="subtitle__detail__blog">
            Los costes y beneficios de la quiropráctica
          </p>
          <p className="content__detail__blog">
            La quiropráctica puede aportar un gran valor a nuestras vidas siendo
            muy conocida por ayudar en problemas de espalda, dolores de cabeza,
            postura y ciática aunque sus beneficios son muchos más. Te invito a
            que visites nuestra página de soluciones que ofrece la quiropráctica
            donde encontrarás testimonios de pacientes que han conseguido
            solucionar sus problemas a través de la quiropráctica
            <br />
            <br />
            En cuanto a los costes, hay que saber que hoy en día España es uno
            de los últimos países en Europa, y en el mundo, donde la
            quiropráctica no se encuentra dentro del sistema sanitario por lo
            que el acceso debe de ser privado.
            <br />
            <br />
            Un estudio en el 2004 financiado por el Gobierno Británico y
            realizado con más de 1000 pacientes, estableció que el tratamiento
            quiropráctico tiene un alto coste – efectividad, mostrando un ahorro
            sustancial en problemas relacionados con las cervicales y las
            lumbares por lo que este ahorro era un reflejo de que las personas
            se recuperaban antes de su problema por lo que podía volver a
            trabajar antes que con solo tratamiento convencional (fármacos).
          </p>
        </>
      )}
      {id === "11" && (
        <>
          <p className="title__detail__blog">
            Cómo afecta el estrés a tu cuerpo y mente
          </p>
          <p className="date__detail__blog">4 Mayo 2021</p>
          <img
            src={`${process.env.REACT_APP_FRONTEND}assets/image/blog11.jpeg`}
            alt="Teresa"
            className="img__detail__blog"
          />
          <p className="content__detail__blog">
            En nuestra sociedad actual, donde el ritmo de vida es cada vez más
            acelerado, las fuentes de estrés se multiplican: las
            responsabilidades profesionales y personales, las preocupaciones
            familiares y financieras, la contaminación, el tráfico, el ruido,
            etc. Si a eso se añade una alimentación inadecuada o insuficiente,
            la falta de ejercicio y el sedentarismo, se puede entender
            fácilmente por qué tanta gente desarrolla síntomas de estrés o bien
            cae en un bucle de agotamiento, ansiedad y/o depresión.
            <br />
            <br />
            Aunque es imposible ralentizar el ritmo del mundo que nos rodea,
            sufrir los efectos del estrés no tiene porque ser una fatalidad ya
            que cuanta más regulada esté nuestra biología interna, mayor será
            nuestra capacidad de adaptación al medio y a las situaciones que se
            nos presentan, y aunque muy poca gente lo sepa, ¡la quiropráctica
            resulta muy beneficiosa para ayudar a gestionar mejor el día a día y
            los retos que se nos presenten, consiguiendo vivir más tranquilos y
            con más energía!.
          </p>
          <p className="subtitle__detail__blog">
            La vida es un equilibrio simpático – parasimpático
          </p>
          <p className="content__detail__blog">
            Toda respuesta ante cualquier situación de la vida está controlada
            por el sistema nervioso autónomo, el cual se compone de dos ramas
            denominadas “simpática” y “parasimpática”. La primera desempeña
            principalmente un papel de “acelerador” del organismo, mientras que
            la segunda lo hace como “freno”.
            <br />
            <br />
            Ante la percepción de peligro, el “acelerador” nos prepara para una
            reacción física inmediata: el corazón y la respiración se aceleran,
            la tensión muscular y la presión sanguínea aumentan. Esta respuesta
            fisiológica, llamada “de lucha o huida» fue esencial para la
            supervivencia de nuestra especie en la prehistoria, permitiéndonos
            cazar presas o huir de nuestros depredadores. Concluida la amenaza,
            el “freno” toma el relevo: la frecuencia cardiaca disminuye y el
            funcionamiento del organismo vuelve a la normalidad.
            <br />
            <br />
            No obstante, hoy en día puede no haber un león en frente, pero sí un
            trabajo estresante, problemas familiares o un vecino ruidoso, por lo
            que la persona si no se adapta, se encontrará constantemente en un
            estado de «aceleración» forzando a su cuerpo a trabajar al límite de
            sus posibilidades con las consecuencias que ello acarrea.
          </p>
          <p className="subtitle__detail__blog">¡OJO con el estrés!</p>
          <p className="content__detail__blog">
            Un estrés prolongado puede acentuar/acelerar trastornos como la
            artrosis y el dolor, así como empeorar el asma, la bronquitis y
            otros problemas respiratorios. Debilita el sistema inmunitario,
            favoreciendo los resfriados, la gripe y otras infecciones. También
            genera tensión muscular, hipertensión, dolores de cabeza, úlceras,
            diarrea, vómitos, estreñimiento, etc. Cuando el estrés pasa a ser
            crónico puede provocar serios problemas de salud, como: insomnio,
            ansiedad, depresión, trastornos alimenticios, etc. La ansiedad
            proviene del estrés y de la forma en que nos hemos ido adaptando
            fisiológicamente a los acontecimientos.
          </p>
          <p className="subtitle__detail__blog">
            Recomendaciones para combatir el estrés y mejorar nuestra adaptación
          </p>
          <p className="content__detail__blog">
            Cuanto más regulada esté nuestra biología interna, mayor será
            nuestra capacidad de adaptación al medio y a las situaciones que se
            nos presentan, a continuación compartimos algunas herramientas que
            te pueden servir de gran ayuda para mejorar nuestra calidad de vida
            de forma sana y dinámica:
            <br />
            <br />
            <span className="item__enumeration">•</span>Crear una rutina diaria
            de hábitos saludables, como por ejemplo: Leer alguna frase, texto
            positivo o afirmaciones cada día; meditar por lo menos 5 minutos al
            día o antes de empezar con la rutina diaria; hacer ejercicio (de 10
            a 20 minutos) ayudará al cuerpo a hacer conexiones de información
            positiva y estimula los neurotransmisores “de la alegría” como son
            las endorfinas; dormir suficiente y mejor, para lo que es importante
            establecer una rutina de sueño, yendo a dormirse a la misma hora;
            intentar comer lo más sano posible. (conoce más sobre los pilares de
            salud pinchando aquí).
            <br />
            <span className="item__enumeration">•</span>Establecer metas,
            empezando con unas que sean pequeñas y más sencillas de conseguir y
            poco a poco ir añadiendo metas más grandes, y fijar un tiempo límite
            para conseguirlas. <br />
            <span className="item__enumeration">•</span>¡Animarse a probar cosas
            nuevas! Está comprobado que los retos estimulan los niveles de
            dopamina que también se relaciona con el aprendizaje y la felicidad.{" "}
            <br />
            <span className="item__enumeration">•</span>Aunque muy poca gente lo
            sepa, ¡la Quiropráctica resulta muy beneficiosa para ayudar al
            cuerpo a gestionar mejor el equilibrio estrés/relajación! La
            Quiropráctica, refuerza la resistencia del organismo frente al
            estrés. Al eliminar las interferencias nerviosas a lo largo de la
            columna, el cuerpo puede restablecer el equilibrio entre el
            “acelerador” y el “freno”, dotando al organismo de una mejor
            capacidad de adaptación a su medio ambiente. ¿No lo sabías?
            ¡Contacta con nosotros e infórmate!
          </p>
        </>
      )}
      {id === "12" && (
        <>
          <p className="title__detail__blog">
            Cómo las mochilas afectan a la espalda de los más peques
          </p>
          <p className="date__detail__blog">26 Abril 2021</p>
          <img
            src={`${process.env.REACT_APP_FRONTEND}assets/image/blog12.jpg`}
            alt="Teresa"
            className="img__detail__blog"
          />
          <p className="content__detail__blog">
            Quienes tienen hijos saben que estos cada día tienen que llevar
            mochilas con mucho peso llevando a muchos niños a sufrir lesiones de
            espalda debido al sobreesfuerzo realizado y la fatiga muscular que
            crea, agregado a que permanecen mucho tiempo sentados, estudiando o
            delante del ordenador o móvil normalmente adoptando una mala
            postura.
            <br />
            <br />
            Todo esto terminan siendo focos de estrés sobre sus columnas en
            plena etapa de crecimiento. Este estrés a corto plazo puede llegar a
            causar desequilibrios en la columna acelerando cambios estructurales
            como escoliosis, posturas desalineadas, contracturas y espasmos
            musculares, dolor de cabeza, dolor de cuello y lumbalgias entre
            otras. Además, a largo plazo puede favorecer a la degeneración en
            los discos, vértebras pudiendo desarrollar protrusiones o hernias
            discales.
            <br />
            <br />
            Por desgracia estas alteraciones a menudo suelen pasar inadvertidas
            hasta que el problema se encuentra en un estado de desarrollo más
            avanzado.
            <br />
            <br />
            Por ello es muy importante realizar revisiones quiroprácticas
            periódicas de la columna en los niños en período de desarrollo, del
            mismo modo que revisamos su vista y sus dientes.
            <br />
            <br />
            ¿Cómo afecta el peso en la columna y salud de los más pequeños? El
            peso de las mochilas, pasar tantas horas sentados, los golpes, las
            caídas, las malas posturas o el propio nacimiento hace que su
            columna pueda perder su correcto movimiento (algo que no se suele
            percibir) creando las “Subluxaciones vertebrales” pudiendo favorecer
            problemas de:
            <br />
            <br />
            <span className="item__enumeration">•</span>Cólicos
            <br />
            <span className="item__enumeration">•</span>Bronquitis y neumonías
            <br />
            <span className="item__enumeration">•</span>Irritabilidad
            <br />
            <span className="item__enumeration">•</span>Falta de sueño
            <br />
            <span className="item__enumeration">•</span>Llanto
            <br />
            <span className="item__enumeration">•</span>Rotación de la cabeza
            hacia un lado en concreto
            <br />
            <span className="item__enumeration">•</span>Mala postura
            <br />
            <span className="item__enumeration">•</span>Dolor de cabeza
            <br />
            <span className="item__enumeration">•</span>Sistema Inmunitario
            débil
            <br />
            <span className="item__enumeration">•</span>Falta de concentración
            <br />
            <span className="item__enumeration">•</span>Dolor de espalda
          </p>
          <p className="subtitle__detail__blog">
            ¿Por qué es importante la quiropráctica en los más peques?
          </p>
          <p className="content__detail__blog">
            Muchas personas piensan que solo se puede beneficiar de la
            quiropráctica los adultos o personas con problemas de espalda, pero
            así como les llevamos al dentista para prevenir y controlar la salud
            de su boca, los pequeños que acuden al quiropráctico para niños con
            regularidad tienden a tener un equilibrio en su desarrollo.
            <br />
            <br />
            El/la quiropráctico/a mirará en su columna si hay o no subluxaciones
            que puedan estar interfiriendo en su desarrollo y las corregirá de
            forma suave y segura para poder tener los beneficios de la misma.
          </p>
          <p className="subtitle__detail__blog">
            10 razones por las que es beneficioso llevar a los niños al
            quiropráctico:
          </p>
          <p className="content__detail__blog">
            1. Para favorecer su elasticidad neurológica (desarrollo del cerebro
            y nervios): Se sabe que el cuidado quiropráctico favorece las
            conexiones neurológicas sin sobreestimularlas.
            <br />
            2. Para aumentar su bienestar general: Suelen notar que respiran
            mejor y están más tranquilos en general.
            <br />
            3. Para reforzar el sistema inmunológico: En niños con defensas
            bajas esto suele ser uno de los cambios más evidentes que notan los
            padres.
            <br />
            4. Para ayudar en los casos de cólicos y en el síndrome del bebé
            irritable.
            <br />
            5. Para ayudar a aliviar el asma, las dificultades respiratorias y
            alergias.
            <br />
            6. Para mejorar la postura de la columna vertebral: Tienden a
            erguirse y sentarse mejor sin estar tan encorvados. <br />
            7. Para mejorar su capacidad de concentración: Muchos padres notan
            que sus hijos mejoran el rendimiento de las clases una vez inician
            el cuidado quiropráctico.
            <br />
            8. Para obtener un mejor bienestar emocional y mental: Los bebés
            suelen ser más alegres, menos irritables y los niños notan más
            tranquilidad.
            <br />
            9. Para ayudar a aliviar la incontinencia urinaria y los problemas
            digestivos <br />
            10. Para dormir mejor: La tranquilidad que les trae los ajustes hace
            que después tiendan a dormir más y/o más horas seguidas.
          </p>
          <p className="subtitle__detail__blog">
            La mejor forma de usar una mochila en 9 pasos:
          </p>
          <p className="content__detail__blog">
            1. El peso que se lleve en la mochila no debe superar el equivalente
            al 15% del peso corporal del niño (Ej: si un niño pesa 30 kg, la
            mochila debe pesar un máximo de 4,5kg.), ya que una carga mayor lo
            hace arquear la columna hacia adelante e inclinar el tronco y la
            cabeza en una misma dirección. ¡Si la mochila obliga al niño a
            inclinarse hacia adelante es que pesa demasiado!
            <br />
            2. Elegir la mochila adecuada al tamaño del niño.
            <br />
            3. Usar mochilas con correas anchas y acolchadas, ajustadas
            firmemente pero sin que lleguen a apretar. <br />
            4. Repartir el peso. Llevar siempre la mochila por las dos correas y
            no por una sólo, para no sobrecargar uno de los hombros. <br />
            5. Limitar el peso del contenido y evitar transportar cargas
            inútiles. 7. Utilizar las dos manos para coger la mochila de frente.{" "}
            <br />
            6. Doblar las rodillas e inclinarse para levantarla, cargando el
            peso en las piernas y no en la propia espalda. <br />
            7. ¡Cuidado! Las mochilas con ruedas no lo solucionan todo, porque
            en algún momento tendrá que levantarla y existe la tendencia a
            sobrecargar este tipo de mochilas.
            <br />
            8. Si se utiliza la mochila de arrastre hay que mantener la columna
            recta para llevarla, evitando tirar de ellas al bajar o subir
            escalones. <br />
            9. Hacer ejercicio. Practicar algún deporte o ejercicios para
            fortalecer los músculos de la espalda ayudando a soportar mejor la
            mochila.
            <br />
            <br />
            Esperamos que este artículo te haya sido de utilidad. Si buscas
            mejorar tu calidad de vida, no dudes en contactar con nosotros y
            descubrir cómo la quiropráctica te puede ayudar.
          </p>
        </>
      )}
      {id === "13" && (
        <>
          <p className="title__detail__blog">
            La salud es un proceso dinámico que juega a nuestro favor
          </p>
          <p className="date__detail__blog">14 Abril 2021</p>
          <img
            src={`${process.env.REACT_APP_FRONTEND}assets/image/blog13.jpg`}
            alt="Teresa"
            className="img__detail__blog"
          />
          <p className="content__detail__blog">
            Si observamos a nuestro alrededor nos daremos cuenta que todo cuanto
            sucede en la naturaleza está regido por ciclos dinámicos y que se
            suceden de manera regular.
            <br />
            <br />
            Al día le sigue la noche, las estaciones se alternan durante el
            año…. Como seres vivos que somos, nuestra salud no es un estado
            inalterable, sino que también está sometida a un constante proceso
            de cambio.
            <br />
            <br />
            Se suele decir que “por la edad” ese proceso tiende a la enfermedad
            como algo inevitable o un acto de azar o suerte. Es cierto que “con
            la edad” nuestras células cambian y vamos acumulando experiencias y
            cargas, pero si invertimos energía y dedicamos tiempo a nuestra
            salud, el cuerpo responderá positivamente al envejecimiento pudiendo
            llegar a mejorar aspectos que pensábamos que nunca los
            recuperaríamos (es por ello que la quiropráctica para mayores juega
            un papel fundamental en la mejora de calidad de vida).
            <br />
            <br />
            El ganador del premio Nobel, Hans Seile, escribió: “El secreto de la
            salud y la felicidad está en saber ajustarse bien al constante
            cambio de las condiciones de este planeta… Muchas enfermedades
            comunes son grandes errores relacionados con nuestra respuesta de
            adaptación
          </p>
          <p className="subtitle__detail__blog">
            La adaptación como clave de nuestra salud
          </p>
          <p className="content__detail__blog">
            Desde hace muchos años se ha observado que las personas que mejor se
            adaptan al medio y a las cosas que les van pasando en su vida, son
            las que mejor salud tienen (tanto física como mental). Y es que la
            flexibilidad hace que no entremos en estrés, situación que desgasta
            y avejenta el cuerpo.
            <br />
            <br />
            Dicha adaptación depende de la salud del Sistema Nervioso. Si este
            está alterado, su capacidad de adaptación es rígida y/o limitada,
            motivo por el cual nuestro organismo, sometido continuamente a
            distintos factores de estrés (físico, químico y emocional), pierde
            salud de forma paulatina, hasta llegar en ocasiones a un nivel de
            salud bajo.
          </p>
          <p className="subtitle__detail__blog">
            ¿Cómo podemos mejorar nuestra la adaptación a la vida y así ser más
            flexibles?
          </p>
          <p className="content__detail__blog">
            “La curación va de dentro hacia fuera, y de arriba hacia abajo.
            Nuestra salud vuelve en ciclos ”. B. J. Palmer
            <br />
            <br />
            Las subluxaciones vertebrales son bloqueos de las vértebras que
            afectan a la función de los nervios. Si esto ocurre, puede crear en
            el cuerpo una situación de irritación que lo afecte disminuyendo la
            capacidad adaptativa (más estrés, peor sueño, taquicardias,
            problemas gástricos, dolor,…).
            <br />
            <br />
            Si bien es cierto que la quiropráctica es muy conocida por la mejora
            en los dolores y problemas de espalda y nervios, la gran mayoría de
            quienes se cuidan con ella notan una mejoría en su estado general y
            eso se debe a que esta se especializa en localizar dichas
            subluxaciones y corregirlas para liberar las irritaciones.
          </p>
          <p className="subtitle__detail__blog">La salud vuelve en ciclos</p>
          <p className="content__detail__blog">
            Con la quiropráctica, al conseguir tener paulatinamente un sistema
            nervioso menos irritado, el cuerpo está más despierto y por lo tanto
            las personas son cada vez más conscientes cuando algo no les ayuda
            en su salud.
            <br />
            <br />
            En la primera etapa de cuidado quiropráctico, se pasa un primer
            momento en el que el sistema nervioso está cambiando desde su
            rigidez a una forma más flexible y adaptable.
            <br />
            <br />
            Después de este proceso, y cuando el cuerpo está reaprendiendo a
            moverse mejor y a funcionar de otra forma, se es mucho más
            consciente de los hábitos que se tienen y no hacen bien al cuerpo o
            crean estrés a la mente. Hábitos que anteriormente parecían
            “normales”, ahora se dejan de hacer (tumbarse mal en el sofá,
            tensarse mucho, caminar encorvado,…).
            <br />
            <br />
            A medida que se van rompiendo los patrones fijos que constituyen en
            las personas el eje de su problemática, bien sean patrones físicos,
            desórdenes químicos o patrones emocionales rígidos, llega el próximo
            estadio: el cambio.
            <br />
            <br />
            Este se produce cuando la persona se encuentra mejor físicamente y
            los “buenos hábitos” se están instaurando de forma más estable en su
            vida.
            <br />
            <br />
            Gracias a la acción de la persona y los cuidados quiroprácticos
            adecuados que reactivan y equilibran el sistema nervioso, se
            potencia la adaptación poco a poco y sin importar la edad ni la
            condición desde la que se ha partido.
            <br />
            <br />
            Cuanto mejor nos adaptamos a nuestro entorno, más vamos a poder
            disfrutar de la vida haciendo las actividades que nos hacen felices,
            pero para ello es importante estar activo en la salud y cuidar el
            sistema nervioso de las sublevaciones vertebrales ya que normalmente
            no nos hablan de ellas hasta que no acudimos a un centro
            quiropráctico. Realizar estos ciclos: flexibilidad, consciencia y
            cambio, configurarán un proceso continuo de adaptación, que es la
            clave de un organismo sano.
            <br />
            <br />
            Cuando entendemos que nuestra salud es un proceso y no un estado,
            que lo importante no es el destino sino los aprendizajes que vamos
            adquiriendo durante el camino, es entonces cuando podemos disfrutar
            del viaje y entender que existen valles y montañas en el camino de
            mejora de nuestra salud.
          </p>
        </>
      )}
      {id === "14" && (
        <>
          <p className="title__detail__blog">
            Organiza los pilares de tu salud fácilmente
          </p>
          <p className="date__detail__blog">7 Abril 2021</p>
          <img
            src={`${process.env.REACT_APP_FRONTEND}assets/image/blog14.jpg`}
            alt="Teresa"
            className="img__detail__blog"
          />
          <p className="content__detail__blog">
            Existen 5 áreas en la salud que desde ahora puedes empezar a cuidar,
            pero antes necesitamos saber que aunque no hayamos escuchado de él,
            el sistema nervioso es fundamental para nuestra salud porque sino no
            está equilibrado, la calidad de vida puede verse afectada (nos puede
            doler el cuerpo al hacer ejercicio, nos sientan mal las comidas
            aunque comamos “bien”, no tenemos ánimo de movernos, nos falta
            energía, etc.). Si por más que te cuidas, sientes que algo no
            termina de “estar bien”, te recomendamos que primero leas el post
            sobre “La base” del cuidado personal… ¡te sorprenderá si aún no te
            has chequeado tu sistema nervioso y subluxaciones vertebrales!.
            <br />
            <br />
            Ahora bien, ¿Cómo podemos organizar nuestra salud?
            <br />
            <br />
            Una vez chequeada nuestra Base, podemos empezar a cuidar otros
            aspectos en nuestra vida; una «técnica” que aplicamos con los
            clientes que acuden a los centros y que ayuda evitar abrumarse por
            querer cambiar muchas cosas a la vez, es agrupar las acciones en 5
            pilares básicos. Aquí te explicamos cuáles son y qué se puede hacer
            con ellos. ¿Preparados? ¡Empezamos!
          </p>
          <p className="subtitle__detail__blog">
            LOS 5 PILARES DE NUESTRA SALUD:
          </p>
          <p className="content__detail__blog">
            Estos pilares son en los que podemos trabajar activamente para dos
            cosas:
            <br />
            <br />
            1. Ayudarán al sistema nervioso y al trabajo que estemos haciendo
            con el/la quiropráctico/a.
            <br />
            2. Ayudará a las células de nuestro cuerpo.
          </p>
          <p className="subtitle__detail__blog">PILAR 1º: Postura</p>
          <p className="content__detail__blog">
            Hoy en día la postura tanto en peques como en adultos es uno de los
            mayores problemas de salud que pueden irritar al sistema nervioso
            (pudiendo causar subluxaciones vertebrales). Ya que es un pilar que
            se puede extender mucho, pincha aquí para saber más sobre posturas.
          </p>
          <p className="subtitle__detail__blog">PILAR 2º: Mente</p>
          <p className="content__detail__blog">
            Ya sabemos que la mente trae estrés mediante pensamientos
            repetitivos, o enfocarla en exceso hacia los demás y en defecto a
            uno mismo. Por eso, en este punto nos puede ayudar:
            <br />
            <br />
            <span className="item__enumeration">•</span>Leer: Algunos autores
            que te recomendamos: Louise Hay, Byron Katie, Bruce Lipton, Joe
            Dispenza, John Demartini.
            <br />
            <span className="item__enumeration">•</span>Meditar: Aunque sea 10
            minutos al día por las mañanas antes de mirar el móvil y empezar el
            día. ¡Recuerda no quedarte dormido/a!. No es tan importante la
            cantidad de tiempo que le dediquemos sino el hábito de tomarse unos
            minutos para centrarse y calmar la mente. En las redes sociales
            existen diferentes tipos de música y meditaciones guiadas que te
            pueden ayudar si te concentras mejor que estar en silencio.
            <br />
            <span className="item__enumeration">•</span>Respirar: La falta de
            oxígeno en el cerebro hace que se disparen las alarmas del cuerpo.
            Tomarte 15 segundos cada tanto para respirar profundo hará que notes
            tu mente más calmada de forma rápida y fácil. <br />
            <span className="item__enumeration">•</span>Relaciones: Cuidarse de
            las relaciones sociales es importante ya que pueden ser tóxicas para
            la mente si giran alrededor de negatividad, problemas y quejas.
            ¡Atentos a ello!.
            <br />
            <span className="item__enumeration">•</span>Naturaleza: El contacto
            con animales, plantes, el mar, la tierra,… llevan a una tranquilidad
            mental y física. Para los que viven en la ciudad esta práctica se
            puede volver muy necesaria y clave en su salud.
          </p>
          <p className="subtitle__detail__blog">PILAR 3º: Descanso</p>
          <p className="content__detail__blog">
            El descanso es importante ya que favorece la recuperación de
            posibles daños celulares hechos en el día por lo que nos ayuda a no
            ir acumulando más y más estrés. De echo, muchas personas cuando se
            ajustan por primera vez con quiropráctica sienten un cansancio
            generalizado y tranquilidad que les invita a dormir y descansar más
            profundamente. Este tipo de respuestas son sinónimas de que el
            cuerpo necesitaba repararse de daños y estrés..
          </p>
          <p className="subtitle__detail__blog">PILAR 4º: Ejercicio</p>
          <p className="content__detail__blog">
            Moverse un poco diariamente es importante ya que no sólo nos ayuda a
            estimular al cuerpo, nos mejora la oxigenación cerebral y aumenta la
            relajación mental. Además, nos puede despejar de los problemas y nos
            llena de una sensación vital. Practicarlo cada día aunque sean 10-15
            minutos puede hacer que cambie la dinámica del resto de nuestra
            jornada.
          </p>
          <p className="subtitle__detail__blog">PILAR 5º: Nutrición</p>
          <p className="content__detail__blog">
            Sabemos que el té, la cafeína y el azúcar alteran el sistema
            nervioso haciendo que se excite por lo que alimentarlo a base de
            verduras, grasas buenas y proteínas de calidad nos puede ayudar a
            que el cuerpo tenga una energía más calmada, estable y constante
            durante el día ayudando a la mente a sentirse más despejada y menos
            cansada.
          </p>
          <p className="subtitle__detail__blog">Recomendaciones</p>
          <p className="content__detail__blog">
            <span className="item__enumeration">•</span>Empieza por algo
            sencillo y cuando te sientas más cómodo/a continúa hacia el
            siguiente escalón.
            <br />
            <span className="item__enumeration">•</span>No te olvides que las
            interferencias en el sistema nervioso puede ser la causa de sentirse
            irritado y tener estrés que dificulta la gestión del día.
            <br />
            <span className="item__enumeration">•</span>Busca ayuda de
            profesionales si crees que en algún punto necesitas una guía para
            comenzar.
            <br />
            <span className="item__enumeration">•</span>Las personas que se
            ajustan con quiropráctica se notan que tienden a un estado más
            tranquilo de su mente y su cuerpo lo que les ha ayudado a conseguir
            cosas que pensaban que no iban a conseguir. ¡La quiropráctica te
            puede sorprender!.
          </p>
        </>
      )}
      {id === "15" && (
        <>
          <p className="title__detail__blog">
            La buena postura: el alimento corporal
          </p>
          <p className="date__detail__blog">30 Marzo 2021</p>
          <img
            src={`${process.env.REACT_APP_FRONTEND}assets/image/blog15.jpeg`}
            alt="Teresa"
            className="img__detail__blog"
          />
          <p className="content__detail__blog">
            Todos sabemos las “malas posturas” que adoptamos durante el día pero
            ¿sabías que estas pueden ser la causa de problemas en la salud?. En
            este blog te queremos enseñar cuáles son y qué hacer para que cuides
            tu espalda, tu postura y ¡tu salud!
          </p>
          <p className="subtitle__detail__blog">
            ¿Cómo definimos una buena postura?
          </p>
          <p className="content__detail__blog">
            Cuando el cuerpo tiene una buena postura, podemos dibujar una línea
            vertical que va desde el centro de la cabeza, pasando por los
            hombros, las caderas, las rodillas y los tobillos (imagen C). Con
            esta alineación corporal los músculos ejercen su función sin
            tensión, y las articulaciones y las vértebras se mueven libremente,
            sin presión.
            <br />
            <br />
            Si adelantamos la cabeza y/o los hombros, o desplazamos la pelvis
            (imagen A/B), se desplaza el centro de gravedad y se pierde la
            alineación corporal, con lo cual provocamos mayor tensión en los
            músculos y desgaste de las articulaciones, lo que nos puede causar
            molestias en caderas, rodillas y pies, además de dolor de espalda
            por un mal movimiento de las vértebras.
            <br />
            <br />A la larga una mala alineación del cuerpo puede provocar
            hernias en la columna, artrosis, problemas de menisco, migrañas,
            fascitis plantar y problemas circulatorios en piernas, entre otros.
          </p>
          <p className="subtitle__detail__blog">
            Las 5 posturas de las que más abusamos y cómo cambiarlas.
          </p>
          <p className="content__detail__blog">
            1. Pasar mucho tiempo sentado / frente al ordenador: No movernos
            durante horas y cualquier mala postura mientras se está sentado
            suele cargar las cervicales y/o lumbares.
            <br />
            RECOMENDACIÓN: Sentarse con la espalda recta y sin adelantar la
            cabeza, ayudándonos con un pequeño cojín en la zona lumbar. Nuestro
            codo tiene que crear un ángulo de 90º al apoyar la mano en la mesa,
            y los pies tienen que estar bien apoyados en el suelo. Además, es
            importante hacer descansos de unos 30 seg. cada 30-40 minutos para
            moverse un poco… ¡Y evita cruzar las piernas para que las caderas no
            se desequilibren!
            <br />
            <br />
            2. Inclinar la cabeza al mirar el móvil o la tablet: Esta postura
            provoca tensión cervical y puede repercutir en la curvatura del
            cuello, causando problemas de cervicales y/o dolor de cabeza.
            <br />
            RECOMENDACIÓN: Subir el móvil hasta que la cabeza quede recta. Hoy
            en día existen Apps para el móvil que están diseñadas para avisarnos
            si nuestra cabeza está muy inclinada. ¡Mira en la App Store!.
            <br />
            <br />
            3. Cargar peso de un solo lado del cuerpo: Llevar predominantemente
            el bolso colgado o cruzado de un solo lado, puede crear un
            desequilibrio en los hombros y, con ello, en el resto del cuerpo.
            <br />
            RECOMENDACIÓN: Repartir el peso en los dos lados o, por lo menos, ir
            cambiando el peso de un lado al otro y aplicar lo mismo al cruzarse
            el bolso.
            <br />
            <br />
            4. Dormir en una mala postura: ¡Mucho cuidado! Dormir boca abajo con
            el cuerpo medio girado o la cabeza ladeada, girar la cabeza siempre
            al mismo lado o dormir de lado con una rodilla más adelantada que la
            otra, puede causar rotaciones en las cervicales y la cadera y, como
            consecuencia, desequilibrios en estas zonas.
            <br />
            RECOMENDACIÓN: Evitar dormir boca abajo; colocar un cojín debajo de
            la rodillas si dormimos boca arriba o bien entre las piernas si
            dormimos de lado. También son importantes la altura de la almohada
            así que intenta no sean ni muy altas ni muy bajas para tu cuerpo con
            el fin de que la columna se mantenga bien alineada.
            <br />
            <br />
            5. Mucho tiempo de pie: Pasar mucho rato de pie planchando, lavando
            platos o realizando cualquier otro tipo de trabajo puede hacer que
            las lumbares se carguen mucho.
            <br />
            RECOMENDACIÓN: Si vamos a estar mucho tiempo de pie en un solo
            lugar, podemos ayudar a no cargar tanto las lumbares si apoyamos uno
            de los pies en un taburete e ir alternando con la otra pierna
            pasados unos minutos.
          </p>
          <p className="subtitle__detail__blog">
            7 Beneficios que te aporta mejorar tu postura
          </p>
          <p className="content__detail__blog">
            1. Ayudarás al buen funcionamiento de tu musculatura. <br />
            2. Protegerás más tu sistema nervioso.
            <br />
            3. No cargarás la musculatura más de lo necesario.
            <br />
            4. Cuidarás tu columna y discos vertebrales.
            <br />
            5. Mejorarás tu respiración porque ampliarás el espacio pulmonar.
            <br />
            6. Aumentarás el oxígeno en tu cuerpo.
            <br />
            7. Cambiará la actitud mental ante los retos del día.
          </p>
          <p className="subtitle__detail__blog">
            ¿Por qué pensar en ponerse recto no soluciona el problema?
          </p>
          <p className="content__detail__blog">
            Muchas personas cuando se dan cuenta de que están encorvadas
            intentan erguirse pero en cuanto se olvidan de ello vuelven a
            encorvarse, pero ¿por qué pasa esto?
            <br />
            <br />
            Pues bien, existen dos cosas que se pueden producir en estos casos:
            1) alteración muscular y/o 2) falta de movimiento en puntos
            estratégicos de la columna (o subluxaciones vertebrales).
            <br />
            <br />Y es que por más de que intenten “ponerse rectos”, si la
            columna no se mueve correctamente va a tender a “encorvarse hacia
            delante” y como consecuencia puede conllevar tener problemas
            cervicales, de hombros y/o lumbares ya que los músculos se cargan
            más y funcionan peor.
          </p>
          <p className="subtitle__detail__blog">
            ¿Cómo podemos corregir las subluxaciones?
          </p>
          <p className="content__detail__blog">
            Las subluxaciones o falta de movilidad en la columna es detectada y
            corregida por el quiropráctico por eso las personas que realizan un
            buen cuidado con ellos suelen notarse una mejor postura y una
            sensación de mayor movimiento en el cuerpo en general.
            <br />
            <br />
            Consulta nuestro centro quiropráctico en Barcelona y en otros puntos
            de Cataluña para más información sobre cómo la quiropráctica puede
            ayudar.
          </p>
        </>
      )}
      {id === "16" && (
        <>
          <p className="title__detail__blog">
            Cuando has hecho de todo para encontrarte bien, pero nada funciona.
          </p>
          <p className="date__detail__blog">23 Marzo 2021</p>
          <img
            src={`${process.env.REACT_APP_FRONTEND}assets/image/blog16.jpg`}
            alt="Teresa"
            className="img__detail__blog"
          />
          <p className="content__detail__blog">
            Es cierto que la esperanza de vida ha aumentado en los últimos años,
            pero eso no quiere decir que la calidad esté mejorando de la misma
            forma entre el estrés, los malos hábitos y el sedentarismo al que
            muchos estamos expuestos cada día. Es decir, vivimos más, pero en
            muchos casos no vivimos mejor.
            <br />
            <br />
            Todo ello está haciendo que cada vez más personas decidan cuidar su
            dieta, hacer ejercicio o incluso meditar ya que se sabe que puede
            mejorar sustancialmente la calidad de vida pero ¿Cómo es que hay
            personas que aún haciendo “vida sana” no se sienten mejor o siguen
            teniendo problemas de salud?
            <br />
            <br />
            Si eres de los que no quiere vivir tapando problemas, sino que
            quiere resolverlos, en este espacio te contamos qué puede estar
            pasando.
          </p>
          <p className="subtitle__detail__blog">
            Empezar por la base: lo que nadie te cuenta
          </p>
          <p className="content__detail__blog">
            Nutrición, descanso, ejercicio, postura,… muchos son las
            recomendaciones saludables en las que uno puede mejorar, pero hay
            algo más que nadie nos cuenta y consiste en empezar por tener una
            base sólida de nosotros mismos ya que sin ella lo que hagamos puede
            que no nos sirva.
          </p>
          <p className="subtitle__detail__blog">
            Y entonces ¿Cuál es la “base”?
          </p>
          <p className="content__detail__blog">
            Aunque no lo hayamos escuchado antes, el buen rendimiento general
            del cuerpo depende principalmente del sistema nervioso (cerebro,
            médula, nervios).
            <br />
            <br />
            Gracias a este toda nuestra vida es llevada a cabo (la digestión, la
            movilidad, los reflejos, la energía, el descanso, las emociones,…);
            por eso, si se ve alterado puede trastocar toda nuestra vida
            (empeora la postura, salen dolores, altera las digestiones, afecta a
            la gestión del estrés, etc.), así pues, cuidar de nuestro sistema
            nervioso puede ser calve para el rendimiento del día a día.
          </p>
          <p className="subtitle__detail__blog">
            ¿Cómo se puede ver alterado el sistema nervioso?
          </p>
          <p className="content__detail__blog">
            Con el paso del tiempo y nuestros hábitos el sistema nervioso se
            puede ver alterado si una o más vértebras de nuestra columna forman
            lo que en quiropráctica se conoce como “subluxaciones vertebrales”
            (bloqueo en una o más articulaciones) ya que estas pueden causar
            irritación e inflamación de los nervios y alterar la musculatura.
          </p>
          <p className="subtitle__detail__blog">
            ¿Cómo se puede ver alterado el sistema nervioso?
          </p>
          <p className="content__detail__blog">
            Con el paso del tiempo y nuestros hábitos el sistema nervioso se
            puede ver alterado si una o más vértebras de nuestra columna forman
            lo que en quiropráctica se conoce como “subluxaciones vertebrales”
            (bloqueo en una o más articulaciones) ya que estas pueden causar
            irritación e inflamación de los nervios y alterar la musculatura.
          </p>
          <p className="subtitle__detail__blog">
            ¿Qué problemas pueden traer las subluxaciones?
          </p>
          <p className="content__detail__blog">
            A la larga pueden traer problemas de todo tipo si no se corrigen
            (dolor, tensión, dolor de cabeza, problemas de ciática, problemas
            digestivos, ansiedad, depresión,…) ya que intentamos compensarlo de
            otras maneras pero no lo resolvemos desde la raíz (tomando
            medicamentos, reforzando musculatura, estirando, cambiando la
            almohada, etc.).
            <br />
            <br />
            Imagina que una manguera está pisada y lleva menos agua a la planta.
            La subluxación puede hacer lo mismo con los nervios llevando
            información nerviosa alterada al lugar a donde va.
          </p>
          <p className="subtitle__detail__blog">
            ¿Qué problemas pueden traer las subluxaciones?
          </p>
          <p className="content__detail__blog">
            A la larga pueden traer problemas de todo tipo si no se corrigen
            (dolor, tensión, dolor de cabeza, problemas de ciática, problemas
            digestivos, ansiedad, depresión,…) ya que intentamos compensarlo de
            otras maneras pero no lo resolvemos desde la raíz (tomando
            medicamentos, reforzando musculatura, estirando, cambiando la
            almohada, etc.).
          </p>
          <p className="subtitle__detail__blog">
            ¿Cómo corregimos las subluxaciones?
          </p>
          <p className="content__detail__blog">
            La quiropráctica es la profesión que se especializa en detectar y
            corregir dichas subluxaciones vertebrales a través de los “ajustes
            quiroprácticos”, que son movimientos específicos en las
            subluxaciones y como consecuencia se consigue:
            <br />
            <br />
            1. Disminuir la inflamación.
            <br />
            2. Mejorar el movimiento.
            <br />
            3. Descomprimir los nervios.
          </p>
          <p className="subtitle__detail__blog">
            ¿Qué pruebas se necesitan hacer para saber si hay subluxaciones
            vertebrales?
          </p>
          <p className="content__detail__blog">
            La visita al quiropráctico tiene como objetivo saber qué nos ocurre,
            qué queremos solucionar y qué está pasando en nuestro cuerpo.
            <br />
            <br />
            Los quiroprácticos utilizan pruebas como el escáner CLA-Insigth,
            dotado de unos sensores que se colocan en la piel para saber el
            equilibrio muscular y nervioso; la palpación donde este evalúan los
            segmentos articulares de forma individual para saber el nivel de
            movilidad y sensibilidad que tienen; postura; y si los profesionales
            lo ven convenientes utilizarán radiografías que ayudarán a completar
            una visión general en base al historial de la persona.
            <br />
            <br />
            Consulta el centro quiropráctico en Barcelonay en otros puntos de
            Cataluña para más información.
            <br />
            <br />
            María, profesora de educación física y deportista de 46 años. Acude
            a uno de nuestros centros desde el 2018.
            <br />
            <br />
            “Hablando con mi cuñada sobre mis malas digestiones y mi dolor en el
            pie me comentó que desde que iba al quiropráctico ya no tenía
            problemas ni con sus digestiones ni con su columna así que acudí a
            la primera visita para chequearme y ver si podrían ayudarme. Puedo
            decir que el cambio más potente que he sentido ha sido el emocional
            (sin descartar los otros dos).
            <br />
            <br />
            Lo primero que noté fue que era mucho más consciente de mi postura
            cuando estaba de pie. Esto me dio una sensación de poder y
            consciencia corporal. Algo que me paralizaba era que prácticamente
            no podía apoyar el pie (siendo profesora de E.F. era un drama) y
            ahora ya vuelvo a correr. He dejado de tomar la pastilla para el
            dolor de regla (una bendición).
            <br />
            <br />
            La quiropráctica cambió mi vida haciéndome más consciente del
            “porqué” me pasan las cosas, (por ejemplo qué causo mi primera
            subluxación, cual es la causa de mis contracturas, mis dolores) y
            saber solucionarlo de forma eficaz.”
          </p>
        </>
      )}
      {id === "17" && (
        <>
          <p className="title__detail__blog">
            Haz ejercicio sin dañarte el cuerpo
          </p>
          <p className="date__detail__blog">12 Marzo 2021</p>
          <img
            src={`${process.env.REACT_APP_FRONTEND}assets/image/blog17.jpeg`}
            alt="Teresa"
            className="img__detail__blog"
          />
          <p className="content__detail__blog">
            Cada vez son más las personas que practican algún deporte y saben
            que es bueno para su salud, pero sienten que su cuerpo no lo recibe
            de buen agrado y terminan teniendo problemas que les impide
            desarrollar la actividad física con satisfacción. ¿Por qué ocurre
            esto y cómo se puede evitar? Aquí te damos las claves.
          </p>
          <p className="subtitle__detail__blog">
            ¿Cómo se puede dañar la espalda si el deporte es beneficioso?
          </p>
          <p className="content__detail__blog">
            El deporte tiene innumerables beneficios sobre nuestra salud tanto
            físicas como mentales. Pero al mismo tiempo su práctica puede
            conllevar riesgos ya que el cuerpo sufre impactos, sea cual sea el
            deporte que se realice, pudiéndose producir sobrecargas en las
            articulaciones o un desequilibrio en la tensión muscular. Pero, ¿por
            qué ocurre esto?
            <br />
            <br />
            Con el paso del tiempo, el movimiento y la correcta alineación de
            las vértebras de la columna pueden verse afectadas creando lo que en
            quiropráctica se conoce como “Subluxaciones vertebrales” (vértebras
            que se quedan bloqueadas, crean inflamación articular, producen
            desequilibrio muscular y pinzan los nervios).
            <br />
            <br />
            Estas subluxaciones tal vez no repercuten en la salud en los
            primeros años de vida; pero, si no se corrigen, con el paso del
            tiempo pueden crear un proceso inflamatorio y problemas en el
            rendimiento físico normal (dolor de espalda, epicondilitis,
            problemas de postura, tensión muscular recurrente, fatiga, insomnio,
            nerviosismo, etc.).
            <br />
            <br />
            "Voy al quiropráctico desde hace mucho tiempo. Es tan importante
            para mi como practicar mi swing" Tiger Woods.
          </p>
          <p className="subtitle__detail__blog">¿Qué hacer entonces?</p>
          <p className="content__detail__blog">
            El quiropráctico es el profesional especializado en detectar y
            evaluar las subluxaciones en las articulaciones (columna, pelvis y
            extremidades) y corregirlas a través de los “Ajustes articulares
            específicos”.
            <br />
            <br />
            Los ajustes son movimientos precisos con vectores concretos que
            tienen como objetivo mover dichas articulaciones de manera rápida,
            con baja amplitud y poca fuerza para que de esa forma la musculatura
            no se tense.
            <br />
            <br />
            A consecuencia de dichos ajustes, todo el cuerpo tenderá a alinearse
            y centrarse, y se podrá ver una mejora en la calidad del movimiento
            general del cuerpo. Además, con un adecuado cuidado el sistema
            nervioso tendrá más posibilidades de equilibrarse y disminuirán las
            irritaciones.
            <br />
            <br />
            Como consecuencia de todo ello, la persona podrá empezar a notar un
            cuerpo más suelto, con más equilibrio y que funciona mejor en
            diferentes aspectos.
          </p>
          <p className="subtitle__detail__blog">
            BENEFICIOS DE LA QUIROPRÁCTICA MÁS VISTOS EN LOS DEPORTISTAS
          </p>
          <p className="content__detail__blog">
            <span className="item__enumeration">•</span>Aumenta el rendimiento
            físico
            <br />
            <span className="item__enumeration">•</span>Mejora la movilidad y
            flexibilidad
            <br />
            <span className="item__enumeration">•</span>Favorece el equilibrio
            en los movimientos
            <br />
            <span className="item__enumeration">•</span>Previene el desgaste de
            las articulaciones
            <br />
            <span className="item__enumeration">•</span>Equilibra la tensión
            muscular excesiva
            <br />
            <span className="item__enumeration">•</span>Disminuye el riesgo de
            contracturas
            <br />
            <span className="item__enumeration">•</span>Mejora el flujo
            respiratorio al mejorar la posición del diafragma
            <br />
            <span className="item__enumeration">•</span>Ayuda a evitar lesiones
            <br />
            <span className="item__enumeration">•</span>Acelera la recuperación
            (p. ej. tendinitis, desgarros musculares, esquinces y, en general,
            problemas de hombro, muñeca, rodilla o espalda)
          </p>
          <p className="subtitle__detail__blog">
            Los médicos le dijeron que no volvería a correr, y a los nueve meses
            hizo un triatlón:
          </p>
          <p className="content__detail__blog">
            “Hace unos años sufrí un importante accidente de moto y gracias a
            los ajustes quiroprácticos no sólo me recuperé por completo, a pesar
            del pronóstico médico que decía que jamás volvería a hacer deporte,
            sino que en menos de un año desde que ocurrió el accidente ¡conseguí
            terminar un Ironman, un gran reto personal para mí! (Para las
            personas que no saben que es un Ironman, se trata de un triatlón de
            3,86 km de natación, 180 km en bicicleta y una maratón de 42,2 km.).
            <br />
            <br />
            Sin los cuidados quiroprácticos seguro que mis esfuerzos de
            entrenamiento y la recuperación no hubiesen sido tan rápidos y con
            tanto éxito.” DARYN J. WIESE
          </p>
          <p className="subtitle__detail__blog">
            No sólo los deportistas de élite necesitan recibir el mejor cuidado
          </p>
          <p className="content__detail__blog">
            La práctica de cualquier deporte exige estar en las mejores
            condiciones posibles para que el cuerpo pueda funcionar a pleno
            rendimiento. Eso lo saben particularmente los deportistas
            profesionales y, por esa razón, muchos atletas de alto nivel y los
            equipos olímpicos suelen estar bajo cuidado quiropráctico, tanto a
            lo largo del año con mantenimiento regular como durante las
            competiciones, donde suelen ir acompañados por el quiropráctico.
            <br />
            <br />
            No obstante, todo tipo de ejercicio físico (ya sea caminar o hacer
            tenis o baloncesto) puede tener un impacto sobre la columna en la
            presencia o no de subluxaciones vertebrales si el cuerpo ha recibido
            golpes, malas posturas, estrés, accidentes… Por eso, es importante
            saber que revisar primero la columna y corregir dichas subluxaciones
            favorecerá las condiciones del cuerpo a la hora de practicar el
            deporte.
          </p>
          <p className="subtitle__detail__blog">Nuestra experiencia</p>
          <p className="content__detail__blog">
            A los centros LinQ acuden tanto deportistas de élite como personas
            de todas las edades que ven un beneficio en practicar alguna
            actividad física, pero se sienten limitados en su salud para
            desarrollarla (tienen problemas para caminar o correr, les duele la
            espalda, se marean, les falta energía durante el día, se cansan muy
            rápido, etc.)
            <br />
            <br />
            Para poder llegar a tener buenos resultados es clave que el
            quiropráctico evalúe la situación física en la que se encuentra la
            persona y desde ahí realizar un plan quiropráctico adaptado al caso,
            a la edad y a la condición física. En nuestros centros
            quiroprácticos en Barcelona podemos evaluar tu estado físico y
            realizar un ajuste acorde a tu caso personal. ¡Consúltanos!
          </p>
        </>
      )}
      {id === "18" && (
        <>
          <p className="title__detail__blog">
            Una mirada más allá “de la edad”
          </p>
          <p className="date__detail__blog">9 Marzo 2021</p>
          <img
            src={`${process.env.REACT_APP_FRONTEND}assets/image/blog18.jpg`}
            alt="Teresa"
            className="img__detail__blog"
          />
          <p className="content__detail__blog">
            Muchas son las personas que piensan que por cumplir años su problema
            ya no tiene solución y están destinadas a padecer el resto de su
            vida, pero en la práctica esto no tiene porqué ser así.
            <br />
            <br />
            Si bien es cierto que con la edad el cuerpo ha ido acumulando estrés
            y cargas, recibir un cuidado adecuado a cualquier edad puede ayudar
            a mantener las funciones que todavía se conservan y recuperar
            algunas ya perdidas. Este es el objetivo de la quiropráctica.
          </p>
          <p className="subtitle__detail__blog">
            LOS 10 OBJETIVOS MÁS COMUNES POR LOS QUE LOS MAYORES ACUDEN AL
            QUIROPRÁCTICO:
          </p>
          <p className="content__detail__blog">
            1. Mantener la independencia física y mental al cumplir años.
            <br />
            2. Recuperar la movilidad.
            <br />
            3. Mejorar la postura.
            <br />
            4. Mejorar la amplitud del movimiento durante el ejercicio.
            <br />
            5. Recuperarse de los dolores de espalda y extremidades.
            <br />
            6. Dejar de tener mareos o vértigos.
            <br />
            7. Descansar mejor.
            <br />
            8. Sentirse más vitales.
            <br />
            9. Tener más energía
            <br />
            10. Recuperarse de alguna caída u operación.
          </p>
          <p className="subtitle__detail__blog">
            ¿Cómo puede ayudar la quiropráctica?
          </p>
          <p className="content__detail__blog">
            Para saberlo, primero el quiropráctico deberá mirar si los problemas
            que la persona presenta están relacionados con una falta de
            movilidad en la columna que está afectando a las articulaciones,
            musculatura y/o sistema nervioso. Esto lo hará a través de una serie
            de pruebas específicas y, si el quiropráctico observa la existencia
            de dicha relación, aplicará los llamados “Ajustes quiroprácticos”
            con el objetivo de mover dichas articulaciones de forma eficaz,
            segura y sin dolor.
          </p>
          <p className="subtitle__detail__blog">
            ¿Cómo se hace un ajuste quiropráctico a esta edad?
          </p>
          <p className="content__detail__blog">
            Existen más de 200 técnicas diferentes para realizar dichos ajustes,
            por lo que se podrán aplicar las que se adapten mejor teniendo en
            cuenta la edad y condición en la que se encuentre la persona.
            <br />
            <br />
            Una de las técnicas más utilizadas y con mejores resultados es el
            llamado Activador (www.activator.com), que es un tipo de instrumento
            que da un pequeño impulso en el hueso para moverlo sin que se tense
            el músculo. Al ser de gran velocidad y poca amplitud de movimiento,
            requiere de muy poca fuerza, por lo que se convierte en una técnica
            segura y sin efectos negativos sobre el cuerpo.
          </p>
          <p className="subtitle__detail__blog">
            ¿Es segura la quiropráctica para los mayores y los casos de
            osteoporosis, artrosis y artritis?
          </p>
          <p className="content__detail__blog">
            Es normal que cuando hablamos de una persona mayor pensemos en que
            su cuerpo puede ser más frágil que el de un adulto y, por lo tanto,
            necesitará un tipo de atención específica.
            <br />
            <br />
            Afortunadamente, gracias a las diferentes técnicas que existen
            dentro de la quiropráctica, el ajuste se convierte en una forma
            segura de cuidado donde se pueden mejorar las condiciones de salud
            de la persona mayor sin tener riesgo alguno.
            <br />
            <br />
            Resumiendo, el objetivo principal es que el profesional se adapte a
            cada persona, y para ello es esencial conocer bien el caso y
            realizar un cuidado adecuado.
            <br />
            <br />
            “Al final, no cuentan los años en tu vida, cuenta la vida en tus
            años.” Lincoln
          </p>
          <p className="subtitle__detail__blog">
            10 BENEFICIOS MÁS COMUNES QUE SE OBSERVAN EN LAS PERSONAS MAYORES
            CUANDO SE CUIDAN CON QUIROPRÁCTICA
          </p>
          <p className="content__detail__blog">
            1. Sienten mejor sus cervicales y el movimiento de su cabeza.
            <br />
            2. Pueden caminar sin dolor.
            <br />
            3. Son más ágiles en los movimientos corporales.
            <br />
            4. Se sienten más alegres y energéticos y sonríen más.
            <br />
            5. Tienen más ganas de hacer cosas.
            <br />
            6. Se quejan menos de sus lumbares.
            <br />
            7. Pueden hacer sus rutinas diarias (recoger a los nietos, hacer la
            compra, limpiar la casa…)
            <br />
            8. Se ven más rectos y no tan caídos “hacia delante”.
            <br />
            9. Notan que su sistema inmunológico es más fuerte.
            <br />
            10. Sienten que descansan mejor.
          </p>
          <p className="subtitle__detail__blog">HISTORIAS COMPARTIDAS: JOAN</p>
          <p className="content__detail__blog">
            “En 1992 tuve una caída muy fuerte y me tuvieron que operar. Desde
            la operación me empezó una molestia del nervio ciático que fue
            progresado a peor hasta que me impedía caminar y dormir. En el 2006,
            un vecino, al que nunca dejaré de estar suficientemente agradecido,
            me habló de la quiropráctica.
            <br />
            <br />
            Gracias a ella he vuelto a poder caminar y a hacer excursiones. Con
            la práctica de la quiropráctica, se puede tener una mejor calidad de
            vida.
            <br />
            <br />
            Recomiendo la quiropráctica para poder tener una mejor calidad de
            vida. Estoy agradecido.”
          </p>
          <p className="subtitle__detail__blog">Nuestra experiencia</p>
          <p className="content__detail__blog">
            Al contrario de lo que muchos piensan, cada vez hay más personas
            mayores que se cuidan con quiropráctica pudiéndose observar grandes
            beneficios a corto y largo plazo. Y es que recuperar la movilidad de
            la columna de una manera eficaz y segura es posible y estas notan
            que les favorece en su vitalidad, flexibilidad y movilidad, y dicen
            tener una actitud mucho más positiva ante la vida así como una mejor
            resistencia inmunológica (defensas).
            <br />
            <br />
            Para saber si la quiropráctica puede ayudar, es fundamental realizar
            un primer contacto con el quiropráctico para que este pueda valorar
            la situación en la que encuentra el paciente mediante una primera
            visita.
            <br />
            <br />
            Siempre estamos a tiempo para empezar a cuidarnos con quiropráctica
            pudiendo ser una gran oportunidad para mejorar nuestra la calidad de
            vida. Consulta nuestro centro quiropráctico en Barcelona para más
            información sobre cómo te podemos ayudar.
          </p>
        </>
      )}
      {id === "19" && (
        <>
          <p className="title__detail__blog">
            Actúa lo antes posible después de un accidente
          </p>
          <p className="date__detail__blog">5 Febrero 2021</p>
          <img
            src={`${process.env.REACT_APP_FRONTEND}assets/image/blog19.jpg`}
            alt="Teresa"
            className="img__detail__blog"
          />
          <p className="content__detail__blog">
            La vida está llena de imprevistos que no podemos controlar. A
            nuestras consultas a menudo acuden pacientes a los que en su momento
            les pareció que ese choque por detrás que recibieron mientras
            conducían a poca velocidad o estando parados, o bien esa caída menor
            con la moto o bicicleta, no tenía más efectos que algún moratón o
            estar varios días doloridos. Pero se ha demostrado que un movimiento
            brusco del cuello (el llamado latigazo cervical), o la compresión de
            las vértebras de la columna por un fuerte golpe en el suelo, puede
            tener secuelas a largo plazo, incluso al cabo de unos años, y ser el
            origen de desplazamiento de vértebras (lo que en quiropráctica se
            conoce como subluxación vertebral) o incluso de hernias discales,
            causando dolor y falta de movilidad entre otros problemas de salud.
            <br />
            <br />
            En el caso del latigazo cervical, puede que no haya síntomas hasta
            al cabo de unos días después de haber sufrido el accidente, lo que
            en apariencia parece indicar que se trata de una lesión menor. Nada
            más alejado de la realidad. Un interesante estudio llevado a cabo en
            2014 demostró que en el caso de colisiones a poca velocidad (a
            partir de 8 km/h) se transmite el doble de esa velocidad a los
            ocupantes. La sacudida provocada por el impacto causa daños en los
            tejidos blandos del cuello y puede lastimar también la columna.
          </p>
          <p className="subtitle__detail__blog">
            Tratamiento quiropráctico tras latigazo cervical
          </p>
          <p className="content__detail__blog">
            En el caso de sufrir un latigazo cervical, se recomienda hacer una
            revisión quiropráctica cuanto antes para determinar el alcance de
            los daños, y seguir tratamiento quiropráctico para recuperar la
            buena movilidad y alineación de las vértebras en la zona del cuello.
            De hecho, después de cualquier caída o impacto fuertes sería bueno
            revisar que nuestra columna no haya quedado resentida, lo que nos
            daría problemas a largo plazo porque sería más sensible a quedar
            afectada por malas posturas o lesiones y podría derivar en dolor
            crónico y limitación de movilidad.
            <br />
            <br />
            El cuidado quiropráctico, pues, resulta muy efectivo para recuperar
            la salud y bienestar después de un accidente. No solo eso, una buena
            medida para evitar al máximo lesiones por accidentes es tener una
            espalda sana gracias a la quiropráctica, pues nos ayuda a mantener
            la columna flexible y con las vértebras bien alineadas, de modo que
            es capaz de responder mucho mejor a imprevistos que nos puedan
            suceder.
            <br />
            <br />
            En definitiva, el cuidado quiropráctico nos ayuda a evitar lesiones
            mayores en caso de accidentes y a recuperarnos más fácilmente de
            ellos para gozar de nuevo de buena salud y bienestar, con buena
            movilidad de nuestra columna y ausencia de dolor.
            <br />
            <br />
            Visita nuestro centro quiropráctico en Barcelona y descubre cómo
            podemos ayudarte si tienes dolencias similares a una subluxación
            vertebral o un latigazo cervical.
          </p>
        </>
      )}
      {id === "20" && (
        <>
          <p className="title__detail__blog">
            Cómo convivir mejor con la tecnología
          </p>
          <p className="date__detail__blog">5 Febrero 2021</p>
          <img
            src={`${process.env.REACT_APP_FRONTEND}assets/image/blog20.jpg`}
            alt="Teresa"
            className="img__detail__blog"
          />
          <p className="content__detail__blog">
            La nuevas tecnologías han transformado nuestras vidas y se han
            vuelto imprescindibles para llevar a cabo nuestras actividades
            diarias. ¡Ahora nos sería imposible concebir un mundo sin
            ordenadores, tablets o móviles! Pero a pesar de sus innumerables
            ventajas y de ser claves en el funcionamiento de la sociedad actual,
            su uso continuado puede causarnos problemas de salud relacionados
            con la inactividad y las malas posturas.
          </p>
          <p className="subtitle__detail__blog">
            Evitar problemas posturales con la quiropráctica
          </p>
          <p className="content__detail__blog">
            Y es que frente al ordenador tenemos tendencia a inclinar la espalda
            hacia adelante, encorvándola y forzando el cuello para acercarnos a
            la pantalla. Esa tensión en la columna durante horas y día tras otro
            puede generar contracturas musculares y pinzamientos en los nervios,
            causando dolor de espalda además de afectar al buen funcionamiento
            del sistema nervioso por la desalineación de las vértebras. La
            quiropráctica es la solución: gracias a los ajustes, las vértebras
            recuperan su alineación y la columna se endereza, el sistema
            nervioso se libera de interferencias y el cuerpo funciona mucho
            mejor, con mayor movilidad y sensación de bienestar. Si trabajas
            delante de un ordenador o simplemente lo usas a diario varias horas,
            ¡no dudes en acudir al quiropráctico! Además del cuidado
            quiropráctico regular, también recomendamos sentarse frente al
            ordenador manteniendo una buena postura, con las lumbares bien
            apoyadas y sin tensión en las cervicales, y hacer pausas más o menos
            cada hora.
            <br />
            <br />
            No obstante, en la actualidad no son los ordenadores la mayor fuente
            de dolencias de espalda por malas posturas: el uso tan generalizado
            del móvil y de las tablets, sobre todo entre los más jóvenes,
            comporta pasar varias horas al día inclinados sobre la pantalla de
            estos dispositivos, con la espalda totalmente encorvada. Esta
            postura puede provocar rigidez del cuello, dolor de hombros y
            cefaleas, lo que se conoce como “text neck” (o, traducido, “cuello
            de texto”), un trastorno del siglo XXI que va en aumento.
            <br />
            <br />
            Efectivamente, en nuestras consultas hemos notado un aumento de
            pacientes cada vez más jóvenes con problemas de espalda debido a las
            malas posturas al usar las nuevas tecnologías, en las que destacan
            el móvil y la tablet. Gracias a la quiropráctica, consiguen
            recuperar la curvatura natural del cuello y su movilidad, y dejan de
            sentir dolor.
            <br />
            <br />
            Es recomendable también tomar conciencia de las buenas posturas a la
            hora de utilizar el móvil y la tablet, así como de la necesidad de
            racionalizar su uso: de este modo evitaremos que causen adicción,
            ansiedad o problemas de atención, además de los problemas físicos
            mencionados.
            <br />
            <br />
            ¿Pero por qué es tan nociva la postura encorvada? Nuestra cabeza
            pesa unos 5 kilos y un estudio reciente ha determinado cuál es la
            fuerza de la tensión en el cuello a medida que se aumenta su
            inclinación: con un ángulo de 30∞ el peso que tiene que soportar el
            cuello es de 18 kilos, si la inclinación llega a un ángulo de 60∞
            ¡el peso es de 27 kilos! No es de extrañar que esta tensión tan
            elevada en la zona de las cervicales sea una causa frecuente de
            contracturas, pérdida de movilidad, dolor en el cuello o en la base
            del cráneo, dolor de cabeza. Además, el encorvamiento de la espalda
            puede conllevar, a la larga, problemas cardíacos, respiratorios y
            digestivos según se ha demostrado. Es muy importante, pues, recibir
            ajustes quiroprácticos para mantener la columna sana y gozar de una
            buena salud.
            <br />
            <br />
            En conclusión, el cuidado quiropráctico es básico para recuperar la
            movilidad y corregir la mala curvatura de la columna, eliminando así
            el dolor y las interferencias en el sistema nervioso, y mejorando el
            estado de salud en general. Así, la quiropráctica es imprescindible
            para corregir el “text neck” y cualquier problema de espalda
            relacionado con el uso de las nuevas tecnologías.
            <br />
            <br />
            Visita nuestro centro quiropráctico en Barcelona y contacta con
            nosotros para saber sobre cómo te podemos ayudar.
          </p>
        </>
      )}
      {(parseInt(id) < 1 || parseInt(id) > 20) && (
        <Container
          fixed
          className="basic__padding"
          style={{ minHeight: "43.2vh" }}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            xs={12}
          >
            <h4 className="text__content__blue">No existe esta noticia</h4>
            <br />
            <br />
            <Link
              to="/blog"
              className="btn__detail__blog"
              onClick={() => goToTop()}
            >
              Volver al blog
            </Link>
          </Grid>
        </Container>
      )}
      {parseInt(id) > 0 && parseInt(id) < 21 && (
        <Grid container xs={12} justifyContent="center" alignItems="center">
          <Link
            to="/blog"
            className="btn__detail__blog"
            onClick={() => goToTop()}
          >
            Blog
          </Link>
          {parseInt(id) !== 1 && (
            <Link
              to={"/blog/" + (parseInt(id) - 1).toString()}
              className="btn__detail__blog"
              onClick={() => goToTop()}
            >
              Atrás
            </Link>
          )}
          {parseInt(id) !== 20 && (
            <Link
              to={"/blog/" + (parseInt(id) + 1).toString()}
              className="btn__detail__blog"
              onClick={() => goToTop()}
            >
              Siguiente
            </Link>
          )}
        </Grid>
      )}
    </Container>
  );
};

export default BlogDetail;
