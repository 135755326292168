import { Container, Grid } from "@mui/material";
import { React, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import goToTop from "../../hooks/useTop";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";

import { Navigation } from "swiper";
import { LanguageContext } from "../../context/LanguageContext";
import Star from "../../components/shared/Star";

const SolutionView = () => {
  const { language } = useContext(LanguageContext);
  const { setMenuActive, setSubmenuActive } = useContext(UserContext);
  const [solutionSelected, setSolutionSelected] = useState(1);
  const style = {
    backgroundImage:
      "url(" + process.env.REACT_APP_FRONTEND + "assets/image/soluciones.mp4)",
    backgroundPosition: "bottom",
  };

  useEffect(() => {
    setMenuActive(2);
    setSubmenuActive(0);
    goToTop();
  }, [setMenuActive, setSubmenuActive]);
  return (
    <>
      <div className={`banner_container align-items-center`} style={style}>
        <video autoPlay loop muted className="video-banner">
          <source
            src={process.env.REACT_APP_FRONTEND + "assets/image/soluciones.mp4"}
            type="video/mp4"
          />{" "}
        </video>
      </div>

      {/* SECTION BAJADA */}
      <Container fixed className="basic__padding">
        <Grid
          className="reduce__contentx2"
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
        >
          <h4 className="text__content__blue">
            {language === "es"
              ? `En nuestros centros LinQ te ayudamos a optimizar tu calidad de vida.`
              : language === "en"
              ? `In our LinQ centers we help you to optimize your quality of life.`
              : `Als nostres centres LinQ t’ajudem a optimitzar la teva qualitat de vida.`}
            <br />
            {language === "es"
              ? `Las funciones de tu cuerpo -físicas y mentales- pueden mejorar cuando el sistema nervioso está libre de interferencias, este es el trabajo de la quiropráctica.`
              : language === "en"
              ? `The functions of your body – physical and mental – can improve when the
              nervous system is free of interference, this is the work of
              chiropractic.`
              : `Les funcions del teu cos, tant físiques com mentals, poden millorar quan el sistema nerviós està lliure d’interferències: aquesta és la tasca de la quiropràctica.`}
          </h4>
        </Grid>
        <Grid
          className="basic__padding"
          container
          justifyContent="center"
          alignItems="center"
        >
          <Link className="btn__item__contact__chiropractic" to="/contact">
            <button>
              {language === "es"
                ? "Contacta para más información"
                : language === "en"
                ? "Contact us"
                : "Contacta i informa’t"}
            </button>
          </Link>
        </Grid>
      </Container>

      {/* SECTION CARROUSEL */}
      <section className="basic__padding background__blue__carrousel">
        <Container>
          <Swiper
            modules={[Navigation]}
            spaceBetween={30}
            navigation
            loop={true}
            centeredSlides={true}
            autoHeight={true}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 1.2,
              },
            }}
            style={{ overflow: "initial", marginBottom: "50px"}}
          >
            <SwiperSlide className="slide__person">
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                className="container__person"
              >
                <br />
                <Star />
                <br />
                {language === "es" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “Todos los seres humanos deberían probar la quiropráctica”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Teresa
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      “Descubrí la quiropráctica hace más de 8 años gracias a
                      una amiga. Acudí principalmente por el dolor de espalda,
                      que era el resultado de otros problemas físicos, que se
                      veían reflejados en la columna vertebral.
                      <br />
                      Desde que me ajusto regularmente, el dolor de espalda ha
                      desaparecido por completo (incluso en épocas en las que no
                      he podido ajustarme). Además, he tomado más conciencia de
                      mi cuerpo, ya no tomo ningún medicamento y me siento
                      genial.
                      <br />
                      Definitivamente la quiropráctica ha cambiado mi vida ya
                      que gracias a los ajustes he podido solucionar muchos
                      problemas físicos, sufro menos estrés, noto más agilidad
                      mental, he ganado flexibilidad y juventud.
                      <br />
                      Creo que todos los seres humanos deben probar la
                      quiropráctica, aunque no tengan ninguna dolencia:
                      sencillamente para mejorar aún más su calidad de vida.
                      <br />
                      En mi caso, me siento muy agradecida por haber descubierto
                      la quiropráctica, pero sobretodo feliz. Desde hace 8 años
                      siento que voy ganando años de vida.”
                    </p>
                  </>
                ) : language === "en" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “All human beings should try chiropractic.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Teresa
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      I discovered chiropractic more than 6 years ago thanks to
                      a friend. I went there mainly because of back pain, which
                      was the result of other physical problems, which were
                      reflected in the spine.
                      <br />
                      Since I have been adjusting regularly, my back pain has
                      completely disappeared (even at times when I have not been
                      able to adjust). In addition, I have become more aware of
                      my body, I no longer take any medication and I feel great.
                      <br />
                      Chiropractic has definitely changed my life because thanks
                      to the adjustments I have been able to solve many physical
                      problems, I suffer less stress, I notice more mental
                      agility, I have gained flexibility and youth.
                      <br />
                      I believe that all human beings should try chiropractic,
                      even if they do not have any ailments: simply to further
                      improve their quality of life.
                      <br />
                      In my case, I feel very grateful for having discovered
                      chiropractic, but above all, happy. For 6 years now, I
                      feel that I am gaining years of life.”
                    </p>
                  </>
                ) : (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “Tots els éssers humans haurien de provar la
                      quiropràctica”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Teresa
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Vaig descobrir la quiropràctica fa més de 6 anys gràcies a
                      una amiga. Vaig venir principalment pel mal d’esquena, que
                      era el resultat d’altres problemes físics que es veien
                      reflectits en la columna vertebral.
                      <br />
                      Des que m’ajusto regularment, el mal d’esquena ha
                      desaparegut completament (fins I tot en èpoques en les
                      quals no he pogut ajustar-me). A més, he pres més
                      consciència del meu cos, ja no prenc cap medicament I em
                      trobo genial.
                      <br />
                      Definitivament, la quiropràctica ha canviat la meva vida
                      ja que gràcies als ajustos he pogut solucionar molts
                      problemes físics, pateixo menys estrès, noto més agilitat
                      mental, he guanyat flexibilitat i joventut.
                      <br />
                      Crec que tots els éssers humans han de provar la
                      quiropràctica, encara que no tinguin cap malaltia:
                      senzillament per millorar encara més la seva qualitat de
                      vida.
                      <br />
                      En el meu cas, em sento molt agraïda per haver descobert
                      la quiropràctica, però sobretot feliç. Des de fa 6 anys
                      sento que vaig guanyant anys de vida.”
                    </p>
                  </>
                )}
              </Grid>
            </SwiperSlide>
            <SwiperSlide className="slide__person">
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                className="container__person"
              >
                <br />
                <Star />
                <br />
                {language === "es" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “Me ha cambiado La vida”{" "}
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Lola
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      “Descubrí la quiropráctica hace más de 8 años gracias a
                      una amiga. Acudí principalmente por el dolor de espalda,
                      que era el resultado de otros problemas físicos, que se
                      veían reflejados en la columna vertebral.
                      <br />
                      Desde que me ajusto regularmente, el dolor de espalda ha
                      desaparecido por completo (incluso en épocas en las que no
                      he podido ajustarme). Además, he tomado más conciencia de
                      mi cuerpo, ya no tomo ningún medicamento y me siento
                      genial.
                      <br />
                      Definitivamente la quiropráctica ha cambiado mi vida ya
                      que gracias a los ajustes he podido solucionar muchos
                      problemas físicos, sufro menos estrés, noto más agilidad
                      mental, he ganado flexibilidad y juventud.
                    </p>
                  </>
                ) : language === "en" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “It has changed my life”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Lola
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Lola is a pharmacist, has been getting adjusted regularly
                      for more than 10 years and tells us how chiropractic has
                      helped her in her daily life: “When I arrived, I was very
                      ill with fibromyalgia, osteoarthritis and a frozen left
                      arm. In addition, I had depression and could not walk for
                      more than 30 minutes.
                      <br />
                      Chiropractic has changed my life. I am a different person
                      now. I overcame depression and physically I am much
                      better. I have stopped taking painkillers and my immune
                      system is strengthened.
                      <br />
                      Chiropractic is basic to health. I am very grateful.”
                    </p>
                  </>
                ) : (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “M’ha canviat la vida”{" "}
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Lola
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      La Lola és farmacèutica, s’ajusta des de fa més de 10 anys
                      regularment i ens explica com la quiropràctica l’ajuda en
                      el seu dia a dia: “Quan vaig arribar estava molt malament
                      per la fibromiàlgia, l’artrosi i el braç esquerre
                      congelat. A més, tenia depressió i no podia caminar més de
                      30 minuts.
                      <br />
                      La Quiropràctica m’ha canviat la vida. Ara sóc una altra
                      persona. Vaig superar la depressió i físicament estic
                      moltíssim millor. He deixat de prendre analgèsics i el meu
                      sistema immunològic està reforçat.
                      <br />
                      La Quiropràctica és bàsica per a la salut. N’estic molt
                      agraïda.”
                    </p>
                  </>
                )}
              </Grid>
            </SwiperSlide>
            <SwiperSlide className="slide__person">
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                className="container__person"
              >
                <br />
                <Star />
                <br />
                {language === "es" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “Soy otra persona”{" "}
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Jordi
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      “Descubrí la quiropráctica hace más de 8 años gracias a
                      una amiga. Acudí principalmente por el dolor de espalda,
                      que era el resultado de otros problemas físicos, que se
                      veían reflejados en la columna vertebral.
                      <br />
                      Desde que me ajusto regularmente, el dolor de espalda ha
                      desaparecido por completo (incluso en épocas en las que no
                      he podido ajustarme). Además, he tomado más conciencia de
                      mi cuerpo, ya no tomo ningún medicamento y me siento
                      genial.
                      <br />
                      Definitivamente la quiropráctica ha cambiado mi vida ya
                      que gracias a los ajustes he podido solucionar muchos
                      problemas físicos, sufro menos estrés, noto más agilidad
                      mental, he ganado flexibilidad y juventud.
                    </p>
                  </>
                ) : language === "en" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “I am someone else.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Jordi
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Jordi is an image technician and has been in chiropractic
                      care for more than 5 years. This is his story: “When I
                      started coming here I was in a very complicated moment in
                      my life, and I went on the internet to get information and
                      decided to try it.
                      <br />
                      At that time I had been suffering from severe vertigo,
                      dizziness and neck pain for 5 years. In addition, I had a
                      herniated disc that affected my mood a lot. I always had
                      to take sick leave from work, I made continuous visits to
                      the doctor and I had to take medication… and every 2 or 3
                      months I had a relapse.
                      <br />
                      From the first sessions with chiropractic I noticed such a
                      big change that from the first month I started to be
                      another person… it had been years since I had felt so
                      good!”
                    </p>
                  </>
                ) : (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “Sóc una altra persona”{" "}
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Jordi
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      En Jordi és tècnic en imatge i fa més de 5 anys que rep
                      tractament quiropràctic. Aquesta és la seva història:
                      “Quan vaig començar a venir estava en un moment molt
                      complicat de la meva vida; vaig entrar a Internet per
                      informar-me’n i vaig decidir provar-ho.
                      <br />
                      En aquella època patia fortes crisis de vertígens, marejos
                      i dolors cervicals des de feia 5 anys. A més, tenia una
                      hèrnia discal que afectava molt el meu estat d’ànim.
                      Sempre havia d’agafar baixes a la feina, feia visites
                      contínues al metge i m’havia de medicar… i cada 2 o 3
                      mesos tornava a recaure.
                      <br />
                      Des de les primeres sessions de quiropràctica vaig notar
                      un canvi tan gran que a partir del primer mes vaig
                      començar a ser una altra persona… Feia anys que no m’havia
                      trobat tan bé!”
                    </p>
                  </>
                )}
              </Grid>
            </SwiperSlide>
            <SwiperSlide className="slide__person">
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                className="container__person"
              >
                <br />
                <Star />
                <br />
                {language === "es" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “Gracias a la quiropráctica he encontrado el equilibrio
                      físico y emocional.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Oscar P.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Llevo más de 20 años en el centro y me gustaría agradecer
                      a todos los grandísimos profesionales que me han ayudado a
                      mejorar mi salud. Gracias a la quiropráctica he encontrado
                      el equilibrio físico y emocional, tomando conciencia de lo
                      importante que es invertir en salud preventiva para
                      mejorar la calidad de vida presente y futura.
                    </p>
                  </>
                ) : language === "en" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “Thanks to chiropractic I have found physical and
                      emotional balance.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Oscar P.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      I have been at the center for more than 20 years and I
                      would like to thank all the great professionals who have
                      helped me improve my health. Thanks to chiropractic I have
                      found physical and emotional balance, becoming aware of
                      how important it is to invest in preventive health to
                      improve present and future quality of life.
                    </p>
                  </>
                ) : (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “Gràcies a la quiropràctica he trobat l'equilibri físic i
                      emocional.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Oscar P.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Fa més de 20 anys que sóc al centre i m'agradaria agrair a
                      tots els grandíssims professionals que m'han ajudat a
                      millorar la meva salut. Gràcies a la quiropràctica he
                      trobat l'equilibri físic i emocional, prenent consciència
                      de com és d'important invertir en salut preventiva per
                      millorar la qualitat de vida present i futura.
                    </p>
                  </>
                )}
              </Grid>
            </SwiperSlide>
            <SwiperSlide className="slide__person">
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                className="container__person"
              >
                <br />
                <Star />
                <br />
                {language === "es" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “¡Gracias a su ayuda mi calidad de vida y salud han tenido
                      un cambio magnífico!”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Joan C. E.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Llevo viniendo desde el año 2006. ¡Gracias a su ayuda mi
                      calidad de vida y salud han tenido un cambio magnífico!{" "}
                    </p>
                  </>
                ) : language === "en" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “Thanks to your help, my quality of life and health have
                      had a magnificent change!”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Joan C. E.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      I have been coming here since 2006. Thanks to your help,
                      my quality of life and health have had a magnificent
                      change!{" "}
                    </p>
                  </>
                ) : (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “Gràcies a ajudar la meva qualitat de vida i salut han
                      tingut un canvi magnífic!”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Joan C. E.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Porto venint des de l'any 2006. Gràcies a la vostra ajuda
                      la meva qualitat de vida i salut han tingut un canvi
                      magnífic!{" "}
                    </p>
                  </>
                )}
              </Grid>
            </SwiperSlide>
            <SwiperSlide className="slide__person">
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                className="container__person"
              >
                <br />
                <Star />
                <br />
                {language === "es" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “Mejor calidad de vida.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Mayte P. T.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Vine a la consulta con estenosis cervical y muchos
                      problemas al andar. He mejorado muchísimo con los ajustes.
                      Mejor calidad de vida.{" "}
                    </p>
                  </>
                ) : language === "en" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      "Better life quality."
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Mayte P. T.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      I came to the office with cervical stenosis and many
                      problems walking. I have improved a lot with the
                      adjustments. Better life quality.{" "}
                    </p>
                  </>
                ) : (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “Millor qualitat de vida.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Mayte P. T.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Vaig venir a la consulta amb estenosi cervical i molts
                      problemes en caminar. He millorat moltíssim amb els
                      ajustaments. Millor qualitat de vida.{" "}
                    </p>
                  </>
                )}
              </Grid>
            </SwiperSlide>
            <SwiperSlide className="slide__person">
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                className="container__person"
              >
                <br />
                <Star />
                <br />
                {language === "es" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “No tengo dolor ni limitación para realizar actividades
                      que había descartado.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Juana L. J.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Me había acostumbrado a que el dolor fuera parte de mi
                      vida.Mi pareja, ya cliente desde hace muchos años me
                      insistía en que eso no era normal y que probara con este
                      tipo de práctica que a él le iba tan bien. Desde la
                      primera sesión, me dejé ayudar y mi cuerpo notó los
                      cambios. Con un poco de consciencia postural y disciplina,
                      mi vida es completamente distinta. No tengo dolor ni
                      limitación para realizar actividades que había descartado.
                      Mi gracias.{" "}
                    </p>
                  </>
                ) : language === "en" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “I have no pain or limitation to carry out activities that
                      I had ruled out.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Juana L. J.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      I had gotten used to pain being part of my life. My
                      partner, and a client for many years, insisted that this
                      was not normal and that I try this type of practice that
                      worked so well for him. From the first session, I let
                      myself be helped and my body noticed the changes. With a
                      bit of postural awareness and discipline, my life is
                      completely different. I have no pain or limitation to
                      perform activities that I had ruled out. My thanks.{" "}
                    </p>
                  </>
                ) : (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “No tinc dolor ni limitació per fer activitats que havia
                      descartat.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Juana L. J.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      M'havia acostumat que el dolor fos part de la meva vida.
                      La meva parella, ja client des de fa molts anys m'insistia
                      que això no era normal i que provés amb aquest tipus de
                      pràctica que a ell li anava tan bé. Des de la primera
                      sessió, em vaig deixar ajudar i el meu cos va notar els
                      canvis. Amb una mica de consciència postural i disciplina,
                      la meva vida és completament diferent. No tinc dolor ni
                      limitació per fer activitats que havia descartat. Gràcies.{" "}
                    </p>
                  </>
                )}
              </Grid>
            </SwiperSlide>
            <SwiperSlide className="slide__person">
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                className="container__person"
              >
                <br />
                <Star />
                <br />
                {language === "es" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “La mejoraría ha sido a nivel emocional y físico.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Eugenia C. G.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Llevo muchos años ajustándome y es lo mejor que he podido
                      hacer, la mejoraría ha sido a nivel emocional y físico. Es
                      un equipo de profesionales{" "}
                    </p>
                  </>
                ) : language === "en" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “The improvement has been on an emotional and physical
                      level.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Eugenia C. G.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      I've been adjusting for many years and it's the best I've
                      been able to do, the improvement has been on an emotional
                      and physical level. It is a team of professionals{" "}
                    </p>
                  </>
                ) : (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “La milloraria ha estat a nivell emocional i físic.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Eugenia C. G.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Fa molts anys que m'ajusto i és el millor que he pogut
                      fer, la milloraria ha estat a nivell emocional i físic. És
                      un equip de professionals{" "}
                    </p>
                  </>
                )}
              </Grid>
            </SwiperSlide>
            <SwiperSlide className="slide__person">
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                className="container__person"
              >
                <br />
                <Star />
                <br />
                {language === "es" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “Después de un año de tratamiento y ejercicios dejé de
                      sufrir dolores.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Pablo E. P.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Sufría dolores de ciática desde hacia tiempo. Acudí
                      escéptico pero con el aval de mi médico (por probar no
                      pierdes nada, me dijo). Después de un año de tratamiento y
                      ejercicios dejé de sufrir dolores y, ahora, pasados 4 años
                      sigo yendo a ajustarme, casi por superstición, y sigo sin
                      dolor.{" "}
                    </p>
                  </>
                ) : language === "en" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “After a year of treatment and exercises I stopped
                      suffering from pain.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Pablo E. P.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      He had been suffering from sciatica pain for some time. I
                      went skeptical but with the endorsement of my doctor (you
                      don't lose anything by trying, he told me). After a year
                      of treatment and exercises, I stopped suffering from pain,
                      and now, after 4 years, I keep going to adjust, almost out
                      of superstition, and I'm still pain-free.{" "}
                    </p>
                  </>
                ) : (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “Després d'un any de tractament i exercicis vaig deixar de
                      patir dolors.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Pablo E. P.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Patia dolors de ciàtica des de feia temps. Vaig acudir
                      escèptic però amb l'aval del meu metge (per provar no
                      perds res, em va dir). Després d'un any de tractament i
                      exercicis vaig deixar de patir dolors i, ara, passats 4
                      anys segueixo anant a ajustar-me, gairebé per superstició,
                      i segueixo sense dolor.{" "}
                    </p>
                  </>
                )}
              </Grid>
            </SwiperSlide>
          </Swiper>
        </Container>
      </section>

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="row"
      >
        <img
          src={`${process.env.REACT_APP_FRONTEND}assets/image/banner_${solutionSelected}_solutions.jpg`}
          alt="phot item"
          className="image__banner__solutions"
        />
      </Grid>
      <Container fixed className="basic__padding">
        <Grid container>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="row"
            xs={12}
            md={6}
            className="item__padding"
          >
            <div
              className={`btn__item__contact__chiropractic ${
                solutionSelected === 1 ? "btn__active" : ""
              }`}
            >
              <button onClick={() => setSolutionSelected(1)}>
                {language === "es"
                  ? "Deportistas"
                  : language === "en"
                  ? "Athletes"
                  : "Esportistes"}
              </button>
            </div>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="row"
            xs={12}
            md={6}
            className="item__padding"
          >
            <div
              className={`btn__item__contact__chiropractic ${
                solutionSelected === 2 ? "btn__active" : ""
              }`}
            >
              <button onClick={() => setSolutionSelected(2)}>
                {language === "es"
                  ? "Embarazadas"
                  : language === "en"
                  ? "Pregnant women"
                  : "Embarassades"}
              </button>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="row"
            xs={12}
            md={6}
            lg={6}
            className="item__padding"
          >
            <div
              className={`btn__item__contact__chiropractic ${
                solutionSelected === 3 ? "btn__active" : ""
              }`}
            >
              <button onClick={() => setSolutionSelected(3)}>
                {language === "es"
                  ? "Tercera edad"
                  : language === "en"
                  ? "The elderly"
                  : "Tercera edat"}
              </button>
            </div>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="row"
            xs={12}
            md={6}
            lg={6}
            className="item__padding"
          >
            <div
              className={`btn__item__contact__chiropractic ${
                solutionSelected === 4 ? "btn__active" : ""
              }`}
            >
              <button onClick={() => setSolutionSelected(4)}>
                {language === "es"
                  ? "Niños y jóvenes"
                  : language === "en"
                  ? "Children and youth"
                  : "Nens I joves"}
              </button>
            </div>
          </Grid>
        </Grid>
      </Container>

      {/* SOLUTIONS 1 */}
      {solutionSelected === 1 && (
        <>
          {language === "es" ? (
            <>
              {" "}
              <Container fixed className="basic__padding">
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction="row"
                >
                  <h4 className="text__content__blue">
                    Practicar cualquier deporte requiere estar en óptimas
                    condiciones para poder alcanzar un máximo rendimiento
                    corporal, ya que aunque el deporte ofrece numerosos
                    beneficios para la salud, en ocasiones su práctica puede
                    suponer algunos riesgos. Es por eso que la quiropráctica
                    para deportistas se presenta como un gran aliado.
                  </h4>
                </Grid>
                <Grid
                  className="basic__padding"
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Link
                    className="btn__item__contact__chiropractic"
                    to="/contact"
                  >
                    <button>Contacta para más información</button>
                  </Link>
                </Grid>
                <hr className="separator" />
              </Container>
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={5}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/DEPORTE1.jpeg)`,
                      backgroundPosition: "right",
                    }}
                    className="banner_customer"
                  ></Grid>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={12}
                    md={7}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__right__blue">
                      <h1 className="choose__title ">
                        ¿Por qué los deportistas
                        <br />
                        deberían probar la quiropráctica?
                      </h1>
                      <p
                        className="description__item__chiropractic text__align__right reduce__content__left"
                        style={{ marginBottom: "0px!important" }}
                      >
                        La quiropráctica para deportistas se presenta como una
                        ventaja competitiva que utilizan cada vez más atletas
                        principiantes y profesionales, y esto se debe a que la
                        misma destaca en lo siguiente:
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </section>
              <Container fixed className="basic__padding">
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  direction="row"
                >
                  <img
                    src={`${
                      process.env.REACT_APP_FRONTEND + "assets/image/star.png"
                    }`}
                    alt="Pin"
                    className="star__img"
                  />
                  <p className="description__question">Previene lesiones</p>
                  <p
                    className="description__item__chiropractic "
                    style={{ marginTop: "0px" }}
                  >
                    Reducir el riesgo de lesiones para los deportistas supone
                    una tarea un poco ardua, debido a que involucra diversos
                    factores, algunos de ellos difíciles de controlar.
                    <br />
                    <br />
                    No obstante, la atención regular proporcionada por un
                    Quiropráctico ayuda a combatir varios de estos factores,
                    como el incremento de la tensión neural, la restricción del
                    movimiento articular y acortamiento muscular, etc.,
                    disminuyendo así el riesgo de contracturas, desgarros y/o
                    inflamación en los tendones.
                  </p>
                </Grid>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  direction="row"
                >
                  <img
                    src={`${
                      process.env.REACT_APP_FRONTEND + "assets/image/star.png"
                    }`}
                    alt="Pin"
                    className="star__img"
                  />
                  <p className="description__question">
                    Ayuda en las recuperaciones
                  </p>
                  <p
                    className="description__item__chiropractic "
                    style={{ marginTop: "0px" }}
                  >
                    La quiropráctica destaca por ofrecer una ayuda efectiva para
                    problemas de columna (cervical, dorsal y lumbar), pero sus
                    beneficios no terminan ahí.
                    <br />
                    <br />
                    Se conoce que el ajuste quiropráctico resulta apropiado para
                    distintos problemas que afecten tanto extremidades
                    superiores como inferiores; por ejemplo, dolor de rodilla,
                    esguinces de tobillo, dolor de hombro, síndrome de la banda
                    iliotibial, disfunción patelofemoral, etc., al poder ayudar
                    a optimizar tanto la función física como el rango de
                    movimiento.
                  </p>
                </Grid>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  direction="row"
                >
                  <img
                    src={`${
                      process.env.REACT_APP_FRONTEND + "assets/image/star.png"
                    }`}
                    alt="Pin"
                    className="star__img"
                  />
                  <p className="description__question">
                    Optimiza el rendimiento
                  </p>
                  <p
                    className="description__item__chiropractic "
                    style={{ marginTop: "0px" }}
                  >
                    El buen rendimiento en los deportes suele depender de varios
                    factores, incluyendo el entrenamiento, la nutrición, la
                    genética y las variables psicológicas, al igual que la
                    función del sistema nervioso.
                    <br />
                    <br />
                    Y al tener tanto el cuidado de la columna como la función
                    del sistema nervioso como eje principal, la quiropráctica
                    puede ofrecer grandes beneficios para mejorar el rendimiento
                    deportivo. Por ejemplo:
                    <br />
                    • Optimiza la movilidad de las articulaciones.
                    <br />
                    • Optimiza la flexibilidad de los músculos.
                    <br />
                    • Incrementa la fuerza de los músculos.
                    <br />
                    • Incrementa la velocidad de ciertos movimientos: por
                    ejemplo, el pateo dentro del futbol.
                    <br />
                    • Optimiza el balance (mejora el equilibrio).
                    <br />
                    • Optimiza el tiempo de activación de los músculos.
                    <br />
                    <br />
                    Estos elementos ayudan a lograr un mayor desempeño dentro de
                    cualquier deporte, pudiéndose reducir al mismo tiempo el
                    riesgo de lesiones a la vez que se optimiza el
                    funcionamiento del cuerpo en general.
                  </p>
                </Grid>
              </Container>
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={5}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/DEPORTE2.jpeg)`,
                      backgroundPosition: "right",
                    }}
                    className="banner_customer"
                  ></Grid>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={12}
                    md={7}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__right__blue">
                      <h1 className="choose__title ">
                        ¿Qué ofrece el
                        <br />
                        quiropráctico para deportistas?
                      </h1>
                      <p
                        className="description__item__chiropractic text__align__right reduce__content__left"
                        style={{ marginBottom: "0px!important" }}
                      >
                        Los quiroprácticos, al especializarse en localizar y
                        corregir problemas del sistema nervioso, columna
                        vertebral y articulaciones causadas por las
                        subluxaciones vertebrales, pueden:
                        <br />
                        <br />
                        • Aconsejar sobre las consecuencias físicas del deporte
                        a practicar según las condiciones y estado físico de
                        cada atleta.
                        <br />
                        • Ayudar en la prevención y cuidado de lesiones
                        deportivas.
                        <br />
                        • Proporcionar un seguimiento personalizado durante la
                        práctica deportiva.
                        <br />
                        • Favorecer el rendimiento de los deportistas.
                        <br />• Trabajar en la flexibilidad corporal.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </section>
              <section className="basic__padding background__blue">
                <Container>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                  >
                    <p
                      className="description__question"
                      style={{ color: "#fff", width: "100%" }}
                    >
                      <b className="center-title">
                        ¿Qué beneficios tiene la quiropráctica para el deporte?
                      </b>
                      <br />
                      <br />
                      Un apropiado y específico cuidado quiropráctico deportivo
                      permite que las personas que practican un deporte tengan
                      un menor número de lesiones y subluxaciones vertebrales.
                      Por eso, independientemente de si practican deportes de
                      bajo o alto impacto, la quiropráctica puede ofrecerles los
                      siguientes beneficios:
                    </p>
                  </Grid>
                  <Grid container className="basic__padding">
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      direction="column"
                      xs={12}
                      md={6}
                      className="item__padding"
                    >
                      <img
                        src={`${process.env.REACT_APP_FRONTEND}assets/image/track.png`}
                        alt="track"
                      />
                      <p
                        className="description__question"
                        style={{ color: "#fff" }}
                      >
                        <b>En atletismo</b>
                      </p>
                      <p
                        className="description__item__chiropractic text-align-center"
                        style={{ color: "#fff" }}
                      >
                        Cada vez hay más investigaciones que sugieren que los
                        corredores que presentan problemas en las articulaciones
                        suelen ver sus síntomas disminuidos después de recibir
                        quiropráctica, lo cual les permite mantener su
                        kilometraje de entrenamiento e incluso aumentarlo.
                      </p>
                    </Grid>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      direction="column"
                      xs={12}
                      md={6}
                      className="item__padding"
                    >
                      <img
                        src={`${process.env.REACT_APP_FRONTEND}assets/image/soccer.png`}
                        alt="track"
                      />
                      <p
                        className="description__question"
                        style={{ color: "#fff" }}
                      >
                        <b>En fútbol</b>
                      </p>
                      <p
                        className="description__item__chiropractic text-align-center"
                        style={{ color: "#fff" }}
                      >
                        Muchos jugadores de fútbol suelen reportar un alivio del
                        dolor crónico experimentado en la ingle como
                        consecuencia de la práctica de este deporte, después de
                        recibir cuidado quiropráctico durante algunas semanas y
                        realizar ejercicios específicos recomendados por los
                        especialistas.
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      direction="column"
                      xs={12}
                      md={6}
                      lg={6}
                      className="item__padding"
                    >
                      <img
                        src={`${process.env.REACT_APP_FRONTEND}assets/image/basketball.png`}
                        alt="track"
                      />
                      <p
                        className="description__question"
                        style={{ color: "#fff" }}
                      >
                        <b>En baloncesto</b>
                      </p>
                      <p
                        className="description__item__chiropractic text-align-center"
                        style={{ color: "#fff" }}
                      >
                        La atención quiropráctica periódica es de gran ayuda
                        para los jugadores de baloncesto que buscan incrementar
                        su flexibilidad, fuerza, equilibrio, velocidad y rango
                        de movimiento.
                      </p>
                    </Grid>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      direction="column"
                      xs={12}
                      md={6}
                      lg={6}
                      className="item__padding"
                    >
                      <img
                        src={`${process.env.REACT_APP_FRONTEND}assets/image/tennis.png`}
                        alt="track"
                      />
                      <p
                        className="description__question"
                        style={{ color: "#fff" }}
                      >
                        <b>En tenis</b>
                      </p>
                      <p
                        className="description__item__chiropractic text-align-center"
                        style={{ color: "#fff" }}
                      >
                        Es sabido que muchos de los tenistas que apuestan por la
                        quiropráctica logran aliviar considerablemente las
                        molestias generadas por el “codo de tenista”.
                      </p>
                    </Grid>
                  </Grid>
                </Container>
              </section>
            </>
          ) : language === "en" ? (
            <>
              {" "}
              <Container fixed className="basic__padding">
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction="row"
                >
                  <h4 className="text__content__blue">
                    Practicing any sport requires to be in optimal conditions to
                    achieve maximum body performance, because although sport
                    offers many health benefits, sometimes its practice may
                    involve some risks. That is why chiropractic for athletes is
                    presented as a great ally.
                  </h4>
                </Grid>
                <Grid
                  className="basic__padding"
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Link
                    className="btn__item__contact__chiropractic"
                    to="/contact"
                  >
                    <button>Contact us</button>
                  </Link>
                </Grid>
                <hr className="separator" />
              </Container>
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={5}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/DEPORTE1.jpeg)`,
                      backgroundPosition: "right",
                    }}
                    className="banner_customer"
                  ></Grid>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={12}
                    md={7}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__right__blue">
                      <h1 className="choose__title ">
                        Why should
                        <br />
                        athletes try chiropractic?
                      </h1>
                      <p
                        className="description__item__chiropractic text__align__right reduce__content__left"
                        style={{ marginBottom: "0px!important" }}
                      >
                        Chiropractic for athletes is presented as a competitive
                        advantage used by more and more beginner and
                        professional athletes, and this is because it excels in
                        the following:
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </section>
              <Container fixed className="basic__padding">
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  direction="row"
                >
                  <img
                    src={`${
                      process.env.REACT_APP_FRONTEND + "assets/image/star.png"
                    }`}
                    alt="Pin"
                    className="star__img"
                  />
                  <p className="description__question">Prevents injuries</p>
                  <p
                    className="description__item__chiropractic "
                    style={{ marginTop: "0px" }}
                  >
                    Reducing the risk of injury for athletes is a somewhat
                    arduous task because it involves a number of factors, some
                    of which are difficult to control.
                    <br />
                    <br />
                    However, regular care provided by a Chiropractor helps to
                    combat several of these factors, such as increased neural
                    tension, restricted joint movement and muscle shortening,
                    etc., thus decreasing the risk of contractures, tears and/or
                    inflammation in the tendons.
                  </p>
                </Grid>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  direction="row"
                >
                  <img
                    src={`${
                      process.env.REACT_APP_FRONTEND + "assets/image/star.png"
                    }`}
                    alt="Pin"
                    className="star__img"
                  />
                  <p className="description__question">Helps in recoveries</p>
                  <p
                    className="description__item__chiropractic "
                    style={{ marginTop: "0px" }}
                  >
                    Chiropractic stands out for offering effective treatment for
                    spinal problems (cervical, dorsal and lumbar), but its
                    benefits do not end there.
                    <br />
                    <br />
                    The chiropractic adjustment is known to be appropriate for
                    various problems affecting both upper and lower extremities,
                    e.g. knee pain, ankle sprains, shoulder pain, iliotibial
                    band syndrome, patellofemoral dysfunction, etc., as it can
                    help optimize both physical function and range of motion.
                  </p>
                </Grid>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  direction="row"
                >
                  <img
                    src={`${
                      process.env.REACT_APP_FRONTEND + "assets/image/star.png"
                    }`}
                    alt="Pin"
                    className="star__img"
                  />
                  <p className="description__question">Optimizes performance</p>
                  <p
                    className="description__item__chiropractic "
                    style={{ marginTop: "0px" }}
                  >
                    Good performance in sports usually depends on several
                    factors, including training, nutrition, genetics and
                    psychological variables, as well as the function of the
                    nervous system.
                    <br />
                    <br />
                    And by having both spinal care and nervous system function
                    at its core, chiropractic can offer great benefits for
                    improving athletic performance. For example:
                    <br />
                    • Optimizes joint mobility.
                    <br />
                    • Optimizes muscle flexibility.
                    <br />
                    • Increases muscle strength.
                    <br />
                    • Increases the speed of certain movements: for example,
                    kicking in soccer.
                    <br />
                    • Optimizes balance (improves equilibrium).
                    <br />
                    • Optimizes muscle activation time.
                    <br />
                    <br />
                    These elements help to achieve better performance in any
                    sport, while reducing the risk of injury and optimizing
                    overall body function.
                  </p>
                </Grid>
              </Container>
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={5}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/DEPORTE2.jpeg)`,
                      backgroundPosition: "right",
                    }}
                    className="banner_customer"
                  ></Grid>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={12}
                    md={7}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__right__blue">
                      <h1 className="choose__title ">
                        What does the
                        <br />
                        chiropractor offer for athletes?
                      </h1>
                      <p
                        className="description__item__chiropractic text__align__right reduce__content__left"
                        style={{ marginBottom: "0px!important" }}
                      >
                        Chiropractors, specializing in locating and correcting
                        problems of the nervous system, spine and joints caused
                        by vertebral subluxations, can:
                        <br />
                        <br />
                        • Advise on the physical consequences of the sport to be
                        practiced according to the conditions and physical
                        condition of each athlete.
                        <br />
                        • Assist in the prevention and care of sports injuries.
                        <br />
                        • Provide personalized follow-up during sports practice.
                        <br />
                        • To promote the performance of athletes.
                        <br />• Work on body flexibility.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </section>
              <section className="basic__padding background__blue">
                <Container>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                  >
                    <p
                      className="description__question"
                      style={{ color: "#fff", width: "100%" }}
                    >
                      <b className="center-title">
                        What are the benefits of chiropractic for sports?
                      </b>
                      <br />
                      <br />
                      Proper and specific sports chiropractic care allows people
                      who play sports to have fewer injuries and vertebral
                      subluxations. Therefore, regardless of whether they
                      practice low-impact or high-impact sports, chiropractic
                      can offer the following benefits:
                    </p>
                  </Grid>
                  <Grid container className="basic__padding">
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      direction="column"
                      xs={12}
                      md={6}
                      className="item__padding"
                    >
                      <img
                        src={`${process.env.REACT_APP_FRONTEND}assets/image/track.png`}
                        alt="track"
                      />
                      <p
                        className="description__question"
                        style={{ color: "#fff" }}
                      >
                        <b>In track</b>
                      </p>
                      <p
                        className="description__item__chiropractic text-align-center"
                        style={{ color: "#fff" }}
                      >
                        A growing body of research suggests that runners with
                        joint problems often see their symptoms diminish after
                        receiving chiropractic, allowing them to maintain their
                        training mileage and even increase it.
                      </p>
                    </Grid>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      direction="column"
                      xs={12}
                      md={6}
                      className="item__padding"
                    >
                      <img
                        src={`${process.env.REACT_APP_FRONTEND}assets/image/soccer.png`}
                        alt="track"
                      />
                      <p
                        className="description__question"
                        style={{ color: "#fff" }}
                      >
                        <b>In soccer</b>
                      </p>
                      <p
                        className="description__item__chiropractic text-align-center"
                        style={{ color: "#fff" }}
                      >
                        Many soccer players often report relief from chronic
                        groin pain experienced as a result of playing soccer
                        after receiving chiropractic care for a few weeks and
                        performing specific exercises recommended by
                        specialists.
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      direction="column"
                      xs={12}
                      md={6}
                      lg={6}
                      className="item__padding"
                    >
                      <img
                        src={`${process.env.REACT_APP_FRONTEND}assets/image/basketball.png`}
                        alt="track"
                      />
                      <p
                        className="description__question"
                        style={{ color: "#fff" }}
                      >
                        <b>In basketball</b>
                      </p>
                      <p
                        className="description__item__chiropractic text-align-center"
                        style={{ color: "#fff" }}
                      >
                        Periodic chiropractic care is helpful for basketball
                        players looking to increase their flexibility, strength,
                        balance, speed and range of motion.
                      </p>
                    </Grid>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      direction="column"
                      xs={12}
                      md={6}
                      lg={6}
                      className="item__padding"
                    >
                      <img
                        src={`${process.env.REACT_APP_FRONTEND}assets/image/tennis.png`}
                        alt="track"
                      />
                      <p
                        className="description__question"
                        style={{ color: "#fff" }}
                      >
                        <b>In tennis</b>
                      </p>
                      <p
                        className="description__item__chiropractic text-align-center"
                        style={{ color: "#fff" }}
                      >
                        It is known that many of the tennis players who opt for
                        chiropractic achieve considerable relief from the
                        discomfort caused by “tennis elbow“.
                      </p>
                    </Grid>
                  </Grid>
                </Container>
              </section>
            </>
          ) : (
            <>
              {" "}
              <Container fixed className="basic__padding">
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction="row"
                >
                  <h4 className="text__content__blue">
                    Practicar qualsevol esport requereix estar en òptimes
                    condicions per poder aconseguir un màxim rendiment corporal,
                    ja que encara que l’esport ofereix nombrosos beneficis per a
                    la salut, de vegades la pràctica esportiva pot comportar
                    alguns riscos. És per això que la quiropràctica per a
                    esportistes es presenta com un gran aliat.
                  </h4>
                </Grid>
                <Grid
                  className="basic__padding"
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Link
                    className="btn__item__contact__chiropractic"
                    to="/contact"
                  >
                    <button>Contacta i informa’t</button>
                  </Link>
                </Grid>
                <hr className="separator" />
              </Container>
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={5}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/DEPORTE1.jpeg)`,
                      backgroundPosition: "right",
                    }}
                    className="banner_customer"
                  ></Grid>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={12}
                    md={7}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__right__blue">
                      <h1 className="choose__title ">
                        Per què els esportistes
                        <br />
                        haurien de provar la quiropràctica?
                      </h1>
                      <p
                        className="description__item__chiropractic text__align__right reduce__content__left"
                        style={{ marginBottom: "0px!important" }}
                      >
                        La quiropràctica per a esportistes és un avantatge
                        competitiu que utilitzen cada vegada més atletes
                        principiants i professionals, i això es deu al fet que
                        la quiropràctica destaca en el següent:
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </section>
              <Container fixed className="basic__padding">
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  direction="row"
                >
                  <img
                    src={`${
                      process.env.REACT_APP_FRONTEND + "assets/image/star.png"
                    }`}
                    alt="Pin"
                    className="star__img"
                  />
                  <p className="description__question">Prevé lesions</p>
                  <p
                    className="description__item__chiropractic "
                    style={{ marginTop: "0px" }}
                  >
                    Reduir el risc de lesions per als esportistes és una tasca
                    un xic àrdua perquè involucra diversos factors, alguns dels
                    quals són difícils de controlar.
                    <br />
                    <br />
                    No obstant això, l’atenció regular proporcionada per un
                    Quiropràctic ajuda a combatre diversos d’aquests factors,
                    com ara l’increment de la tensió neural, la restricció del
                    moviment articular I l’escurçament muscular, de manera que
                    disminueix el risc de patir contractures, esquinços i/o
                    inflamació en els tendons.
                  </p>
                </Grid>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  direction="row"
                >
                  <img
                    src={`${
                      process.env.REACT_APP_FRONTEND + "assets/image/star.png"
                    }`}
                    alt="Pin"
                    className="star__img"
                  />
                  <p className="description__question">
                    Ajuda en les recuperacions
                  </p>
                  <p
                    className="description__item__chiropractic "
                    style={{ marginTop: "0px" }}
                  >
                    La quiropràctica destaca perquè ofereix un tractament
                    efectiu per a problemes de columna (cervical, dorsal i
                    lumbar), però els seus beneficis no acaben aquí.
                    <br />
                    <br />
                    És sabut que l’ajust quiropràctic resulta adequat per a
                    diferents problemes que afectin tant les extremitats
                    superiors com inferiors; per exemple, mal de genoll,
                    esquinços de turmell, mal d’espatlla, síndrome de la banda
                    iliotibial, disfunció patelofemoral, etc., en poder ajudar a
                    optimitzar tant la funció física com l’amplitud de moviment.
                  </p>
                </Grid>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  direction="row"
                >
                  <img
                    src={`${
                      process.env.REACT_APP_FRONTEND + "assets/image/star.png"
                    }`}
                    alt="Pin"
                    className="star__img"
                  />
                  <p className="description__question">
                    Optimitza el rendiment
                  </p>
                  <p
                    className="description__item__chiropractic "
                    style={{ marginTop: "0px" }}
                  >
                    El bon rendiment en els esports sol dependre de diversos
                    factors, que inclouen l’entrenament, la nutrició, la
                    genètica i les variables psicològiques, igual que la funció
                    del sistema nerviós.
                    <br />
                    <br />
                    I en tenir tant la cura de la columna com la funció del
                    sistema nerviós com a eix principal, la quiropràctica pot
                    oferir grans beneficis per millorar el rendiment esportiu.
                    Per exemple:
                    <br />
                    • Optimitza la mobilitat de les articulacions.
                    <br />
                    • Optimitza la flexibilitat dels músculs.
                    <br />
                    • Incrementa la força dels músculs.
                    <br />
                    • Incrementa la velocitat d’uns certs moviments: per
                    exemple, la puntada de peu en el futbol.
                    <br />
                    • Optimitza el balanceig (millora l’equilibri).
                    <br />
                    • Optimitza el temps d’activació dels músculs.
                    <br />
                    <br />
                    Aquests elements ajuden a aconseguir una millor execució
                    dins de qualsevol esport I, alhora, permeten reduir el risc
                    de lesions i optimitzen el funcionament del cos en general.
                  </p>
                </Grid>
              </Container>
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={5}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/DEPORTE2.jpeg)`,
                      backgroundPosition: "right",
                    }}
                    className="banner_customer"
                  ></Grid>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={12}
                    md={7}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__right__blue">
                      <h1 className="choose__title ">
                        Què ofereix el
                        <br />
                        quiropràctic per a esportistes?
                      </h1>
                      <p
                        className="description__item__chiropractic text__align__right reduce__content__left"
                        style={{ marginBottom: "0px!important" }}
                      >
                        Els quiropràctics, en especialitzar-se a localitzar I
                        corregir problemes del sistema nerviós, columna
                        vertebral i articulacions causades per les subluxacions
                        vertebrals, poden:
                        <br />
                        <br />
                        • Aconsellar sobre les conseqüències físiques de
                        l’esport que es vol practicar segons les condicions i
                        estat físic de cada atleta.
                        <br />
                        • Ajudar en la prevenció i cura de lesions esportives.
                        <br />
                        • Proporcionar un seguiment personalitzat durant la
                        pràctica esportiva.
                        <br />
                        • Afavorir el rendiment dels esportistes.
                        <br />• Treballar en la flexibilitat corporal.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </section>
              <section className="basic__padding background__blue">
                <Container>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                  >
                    <p
                      className="description__question"
                      style={{ color: "#fff", width: "100%" }}
                    >
                      <b className="center-title">
                        Quins beneficis té la quiropràctica per a l’esport?
                      </b>
                      <br />
                      <br />
                      Un tractament quiropràctic esportiu adequat i específic
                      permet que les persones que practiquen un esport tinguin
                      un menor nombre de lesions I de subluxacions vertebrals.
                      Per això, independentment de si practiquen esports de baix
                      o d’alt impacte, la quiropràctica pot oferir-los els
                      beneficis següents:
                    </p>
                  </Grid>
                  <Grid container className="basic__padding">
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      direction="column"
                      xs={12}
                      md={6}
                      className="item__padding"
                    >
                      <img
                        src={`${process.env.REACT_APP_FRONTEND}assets/image/track.png`}
                        alt="track"
                      />
                      <p
                        className="description__question"
                        style={{ color: "#fff" }}
                      >
                        <b>En atletisme</b>
                      </p>
                      <p
                        className="description__item__chiropractic text-align-center"
                        style={{ color: "#fff" }}
                      >
                        Cada vegada hi ha més investigacions que constaten que
                        els corredors amb problemes a les articulacions solen
                        notar que els seus símptomes disminueixen amb la
                        quiropràctica, la qual cosa els permet mantenir el seu
                        quilometratge d’entrenament i, fins i tot, augmentar-lo.
                      </p>
                    </Grid>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      direction="column"
                      xs={12}
                      md={6}
                      className="item__padding"
                    >
                      <img
                        src={`${process.env.REACT_APP_FRONTEND}assets/image/soccer.png`}
                        alt="track"
                      />
                      <p
                        className="description__question"
                        style={{ color: "#fff" }}
                      >
                        <b>En futbol</b>
                      </p>
                      <p
                        className="description__item__chiropractic text-align-center"
                        style={{ color: "#fff" }}
                      >
                        Molts jugadors de futbol solen reportar un alleujament
                        del dolor crònic experimentat a l’engonal a conseqüència
                        de la pràctica d’aquest esport, després de rebre
                        tractament quiropràctic durant algunes setmanes i
                        realitzar exercicis específics recomanats pels
                        especialistes.
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      direction="column"
                      xs={12}
                      md={6}
                      lg={6}
                      className="item__padding"
                    >
                      <img
                        src={`${process.env.REACT_APP_FRONTEND}assets/image/basketball.png`}
                        alt="track"
                      />
                      <p
                        className="description__question"
                        style={{ color: "#fff" }}
                      >
                        <b>En bàsquet</b>
                      </p>
                      <p
                        className="description__item__chiropractic text-align-center"
                        style={{ color: "#fff" }}
                      >
                        L’atenció quiropràctica periòdica és de gran ajuda per
                        als jugadors de bàsquet que busquen incrementar la seva
                        flexibilitat, força, equilibri, velocitat i amplitud de
                        moviment.
                      </p>
                    </Grid>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      direction="column"
                      xs={12}
                      md={6}
                      lg={6}
                      className="item__padding"
                    >
                      <img
                        src={`${process.env.REACT_APP_FRONTEND}assets/image/tennis.png`}
                        alt="track"
                      />
                      <p
                        className="description__question"
                        style={{ color: "#fff" }}
                      >
                        <b>En tennis</b>
                      </p>
                      <p
                        className="description__item__chiropractic text-align-center"
                        style={{ color: "#fff" }}
                      >
                        És sabut que molts dels tennistes que aposten per la
                        quiropràctica aconsegueixen alleujar considerablement
                        les molèsties generades pel “colze de tennista”.
                      </p>
                    </Grid>
                  </Grid>
                </Container>
              </section>
            </>
          )}
        </>
      )}

      {/* SOLUTIONS 2 */}
      {solutionSelected === 2 && (
        <>
          {" "}
          {language === "es" ? (
            <>
              {" "}
              <Container fixed className="basic__padding">
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction="row"
                >
                  <h4 className="text__content__blue">
                    En las embarazadas, la quiropráctica ayuda a preparar el
                    cuerpo para el parto, disminuyendo la tensión muscular y
                    asegurando una mayor movilidad y mejor alineación de la
                    pelvis. Por otra parte, contribuye al buen desarrollo del
                    feto y facilita la buena colocación del bebé en el útero
                    materno antes de nacer.
                  </h4>
                </Grid>
                <Grid
                  className="basic__padding"
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Link
                    className="btn__item__contact__chiropractic"
                    to="/contact"
                  >
                    <button>Contacta para más información</button>
                  </Link>
                </Grid>
                <hr className="separator" />
              </Container>
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={5}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/EMBARAZO1.jpeg)`,
                      backgroundPosition: "right",
                    }}
                    className="banner_customer"
                  ></Grid>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={12}
                    md={7}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__right__blue">
                      <h1 className="choose__title ">
                        Cambios físicos
                        <br />y emocionales constantes
                      </h1>
                      <p
                        className="description__item__chiropractic text__align__right reduce__content__left"
                        style={{ marginBottom: "0px!important" }}
                      >
                        Durante el embarazo, los cambios físicos y emocionales
                        son constantes. Con la quiropráctica tu cuerpo se
                        adaptará a todos estos cambios más fácilmente,
                        ayudándote a tener un embarazo sano y equilibrado. Los
                        ajustes quiroprácticos te ayudarán a liberar la tensión
                        muscular y a aliviar las molestias de espalda a medida
                        que el feto vaya creciendo.
                        <br />
                        <br />
                        Al incidir sobre el sistema nervioso, también
                        contribuirán a relajarte y mejorarán tu descanso. Y, en
                        general, conseguirás un mayor bienestar en todo el
                        proceso, lo que te permitirá seguir con tu vida diaria
                        más fácilmente.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </section>
              <Container fixed className="basic__padding">
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  direction="row"
                >
                  <p className="description__question">
                    <img
                      src={`${
                        process.env.REACT_APP_FRONTEND + "assets/image/star.png"
                      }`}
                      alt="Pin"
                      className="star__img"
                    />
                    <b>
                      El mayor beneficio de la quiropráctica durante el embarazo
                      es que ayuda a tu cuerpo a prepararse para el parto. La
                      función del quiropráctico es restaurar el equilibrio
                      pélvico y la correcta función de la zona sacra, que
                      beneficiará a la hora de dar a luz. Una de las claves para
                      el parto es el posicionamiento de la cintura pélvica
                      (pelvis y sacro) antes y durante el mismo.
                    </b>
                  </p>
                </Grid>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  direction="row"
                >
                  <p className="description__question">
                    También es importante que la pelvis tenga una buena
                    movilidad y esté bien alineada, para lo cual la
                    quiropráctica resulta especialmente efectiva.
                    <br />
                    <br />
                    Además, gracias a la técnica Webster, tu bebé tendrá más
                    espacio para desarrollarse y llegar al parto bien colocado.
                  </p>
                </Grid>
              </Container>
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={5}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/EMBARAZO3.jpeg)`,
                      backgroundPosition: "right",
                    }}
                    className="banner_customer"
                  ></Grid>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={12}
                    md={7}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__right__blue">
                      <h1 className="choose__title ">
                        ¿Qué es la
                        <br />
                        técnica Webster?
                      </h1>
                      <p
                        className="description__item__chiropractic text__align__right reduce__content__left"
                        style={{ marginBottom: "0px!important" }}
                      >
                        Es una técnica quiropráctica eficaz y segura que ayuda a
                        equilibrar los músculos y ligamentos de la pelvis para
                        que el bebé encuentre la posición óptima para su
                        nacimiento.
                        <br /> En un estudio sobre un grupo de embarazadas
                        diagnosticadas de mal posicionamiento fetal, el feto se
                        había posicionado correctamente en el 82% de los casos
                        tras recibir cuidados quiroprácticos con la técnica
                        Webster (JMPT).
                        <br />
                        <br />
                        Los quiroprácticos reciben estudios específicos sobre la
                        técnica Webster. En nuestros centros LinQ tenemos años
                        de experiencia aplicando esta técnica con éxito y total
                        seguridad.
                        <br />
                        <br />
                        Nos encanta ayudar a que nuestras clientas vivan su
                        embarazo con más bienestar y tranquilidad, preparándolas
                        para que tengan un parto más rápido, cómodo y seguro.
                        <br />
                        <br />
                        Si tienes cualquier duda o quieres más información,
                        consúltanos.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </section>
              <section className="basic__padding background__blue">
                <Container>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                  >
                    <p
                      className="description__question"
                      style={{ color: "#fff", width: "100%" }}
                    >
                      <span className="center-title">
                        Beneficios de la quiropráctica en embarazadas:
                      </span>
                      <br />
                      <br />
                      • Favorece la adaptación al estrés físico del embarazo
                      <br />
                      • Alivia y/o elimina el dolor de espalda
                      <br />
                      • Mejora el descanso
                      <br />
                      • Mejora el espacio para el crecimiento del feto
                      <br />
                      • Facilita un buen posicionamiento fetal
                      <br />• Contribuye a un parto más rápido, cómodo y seguro
                    </p>
                  </Grid>
                </Container>
              </section>
            </>
          ) : language === "en" ? (
            <>
              {" "}
              <Container fixed className="basic__padding">
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction="row"
                >
                  <h4 className="text__content__blue">
                    In pregnant women, chiropractic helps prepare the body for
                    childbirth, reducing muscle tension and ensuring greater
                    mobility and better alignment of the pelvis. Moreover, it
                    contributes to the proper development of the fetus and
                    facilitates the proper placement of the baby in the mother’s
                    womb before birth.
                  </h4>
                </Grid>
                <Grid
                  className="basic__padding"
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Link
                    className="btn__item__contact__chiropractic"
                    to="/contact"
                  >
                    <button>Contact us</button>
                  </Link>
                </Grid>
                <hr className="separator" />
              </Container>
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={5}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/EMBARAZO1.jpeg)`,
                      backgroundPosition: "right",
                    }}
                    className="banner_customer"
                  ></Grid>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={12}
                    md={7}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__right__blue">
                      <h1 className="choose__title ">
                        Physical and
                        <br />
                        emotional changes constant
                      </h1>
                      <p
                        className="description__item__chiropractic text__align__right reduce__content__left"
                        style={{ marginBottom: "0px!important" }}
                      >
                        During pregnancy, physical and emotional changes are
                        constant. With chiropractic your body will adapt to all
                        these changes more easily, and you will enjoy a healthy
                        and balanced pregnancy. Chiropractic adjustments will
                        help you release muscle tension and relieve back
                        discomfort as the fetus grows.
                        <br />
                        <br />
                        By affecting the nervous system, they will also help you
                        relax and improve your rest. And, in general, you will
                        achieve greater well-being throughout the process, which
                        will allow you to get on with your daily life more
                        easily.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </section>
              <Container fixed className="basic__padding">
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  direction="row"
                >
                  <p className="description__question">
                    <img
                      src={`${
                        process.env.REACT_APP_FRONTEND + "assets/image/star.png"
                      }`}
                      alt="Pin"
                      className="star__img"
                    />
                    <b>
                      But the greatest benefit of chiropractic during pregnancy
                      is that it helps your body prepare for childbirth. The
                      role of the chiropractor is to restore pelvic balance and
                      proper function of the sacral area, which will benefit
                      when it is time to give birth. One of the keys to
                      childbirth is the positioning of the pelvic girdle (pelvis
                      and sacrum) before and during childbirth.
                    </b>
                  </p>
                </Grid>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  direction="row"
                >
                  <p className="description__question">
                    It is also important that the pelvis has good mobility and
                    is well aligned, for which chiropractic is particularly
                    effective.
                    <br />
                    <br />
                    In addition, thanks to the Webster technique, your baby will
                    have more room to develop and arrive at delivery well
                    positioned.
                  </p>
                </Grid>
              </Container>
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={5}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/EMBARAZO3.jpeg)`,
                      backgroundPosition: "right",
                    }}
                    className="banner_customer"
                  ></Grid>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={12}
                    md={7}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__right__blue">
                      <h1 className="choose__title ">
                        What is
                        <br />
                        the Webster technique?
                      </h1>
                      <p
                        className="description__item__chiropractic text__align__right reduce__content__left"
                        style={{ marginBottom: "0px!important" }}
                      >
                        It is an effective and safe chiropractic technique that
                        helps balance the muscles and ligaments of the pelvis so
                        that the baby finds the optimal position for birth.
                        <br /> In a study of a group of pregnant women diagnosed
                        with fetal malpositioning, the fetus was correctly
                        positioned in 82% of cases after receiving chiropractic
                        care with the Webster technique (JMPT).
                        <br />
                        <br />
                        Chiropractors receive specific studies on the Webster
                        technique.
                        <br />
                        <br />
                        In our LinQ centers we have years of experience applying
                        this technique successfully and safely. We love to help
                        our clients live their pregnancy with more comfort and
                        peace of mind, preparing them to have a faster, more
                        comfortable and safer delivery.
                        <br />
                        <br />
                        If you have any questions or want more information,
                        please contact us.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </section>
              <section className="basic__padding background__blue">
                <Container>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                  >
                    <p
                      className="description__question"
                      style={{ color: "#fff", width: "100%" }}
                    >
                      <span className="center-title">
                        Benefits of chiropractic in pregnant women:
                      </span>
                      <br />
                      <br />
                      • Helps adaptation to the physical stress of pregnancy
                      <br />
                      • Relieves and/or eliminates back pain
                      <br />
                      • Improved rest
                      <br />
                      • Improves space for fetal growth
                      <br />
                      • Facilitates good fetal positioning
                      <br />• Contributes to a faster, more comfortable and
                      safer birth
                    </p>
                  </Grid>
                </Container>
              </section>
            </>
          ) : (
            <>
              {" "}
              <Container fixed className="basic__padding">
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction="row"
                >
                  <h4 className="text__content__blue">
                    En les embarassades, la quiropràctica ajuda a preparar el
                    cos per al part, disminuint la tensió muscular i assegurant
                    una mobilitat més gran i una millor alineació de la pelvis.
                    D’altra banda, contribueix al bon desenvolupament del fetus
                    i facilita la bona col·locació del bebè a l’úter matern
                    abans de néixer.
                  </h4>
                </Grid>
                <Grid
                  className="basic__padding"
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Link
                    className="btn__item__contact__chiropractic"
                    to="/contact"
                  >
                    <button>Contacta i informa’t</button>
                  </Link>
                </Grid>
                <hr className="separator" />
              </Container>
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={5}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/EMBARAZO1.jpeg)`,
                      backgroundPosition: "right",
                    }}
                    className="banner_customer"
                  ></Grid>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={12}
                    md={7}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__right__blue">
                      <h1 className="choose__title ">
                        Els canvis físics I emocionals són constants
                        <br />I emocionals constants
                      </h1>
                      <p
                        className="description__item__chiropractic text__align__right reduce__content__left"
                        style={{ marginBottom: "0px!important" }}
                      >
                        Durant l’embaràs, els canvis físics I emocionals són
                        constants. Amb la quiropràctica el teu cos s’adaptarà a
                        tots aquests canvis més fàcilment, i gaudiràs d’un
                        embaràs sa i equilibrat. Els ajustos quiropràctics
                        t’ajudaran a alliberar la tensió muscular i a alleujar
                        les molèsties d’esquena a mesura que el fetus vagi
                        creixent.
                        <br />
                        <br />
                        En incidir sobre el sistema nerviós, també contribuiran
                        a relaxar-te i milloraran el teu descans. I, en general,
                        aconseguiràs més benestar en tot el procés, la qual cosa
                        et permetrà continuar amb la teva vida diària més
                        fàcilment.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </section>
              <Container fixed className="basic__padding">
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  direction="row"
                  style={{ marginBottom: "25px" }}
                >
                  <p className="description__question">
                    Durant l’embaràs, els canvis físics I emocionals són
                    constants. Amb la quiropràctica el teu cos s’adaptarà a tots
                    aquests canvis més fàcilment, i gaudiràs d’un embaràs sa i
                    equilibrat. Els ajustos quiropràctics t’ajudaran a alliberar
                    la tensió muscular i a alleujar les molèsties d’esquena a
                    mesura que el fetus vagi creixent.
                    <br />
                    <br />
                    En incidir sobre el sistema nerviós, també contribuiran a
                    relaxar-te i milloraran el teu descans. I, en general,
                    aconseguiràs més benestar en tot el procés, la qual cosa et
                    permetrà continuar amb la teva vida diària més fàcilment.
                  </p>
                </Grid>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  direction="row"
                >
                  <p className="description__question">
                    <img
                      src={`${
                        process.env.REACT_APP_FRONTEND + "assets/image/star.png"
                      }`}
                      alt="Pin"
                      className="star__img"
                    />
                    <b>
                      Però el benefici més gran de la quiropràctica durant
                      l’embaràs és que ajuda el teu cos a preparar-se per al
                      part. La funció del quiropràctic és restaurar l’equilibri
                      pelvià i la correcta funció de la zona del sacre, que
                      beneficiarà a l’hora de donar a llum. Una de les claus per
                      al part és el posicionament de la cintura pelviana (pelvis
                      i sacre) abans de parir i durant el part.
                    </b>
                  </p>
                </Grid>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  direction="row"
                >
                  <p className="description__question">
                    També és important que la pelvis tingui una bona mobilitat i
                    estigui ben alineada, per a la qual cosa la quiropràctica
                    resulta especialment efectiva.
                    <br />
                    <br />A més, gràcies a la tècnica Webster, el teu bebè
                    tindrà més espai per desenvolupar-se i arribar al moment del
                    part ben col·locat.
                  </p>
                </Grid>
              </Container>
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={5}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/EMBARAZO3.jpeg)`,
                      backgroundPosition: "right",
                    }}
                    className="banner_customer"
                  ></Grid>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={12}
                    md={7}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__right__blue">
                      <h1 className="choose__title ">
                        Què és, la
                        <br />
                        tècnica Webster?
                      </h1>
                      <p
                        className="description__item__chiropractic text__align__right reduce__content__left"
                        style={{ marginBottom: "0px!important" }}
                      >
                        És una tècnica quiropràctica eficaç i segura que ajuda a
                        equilibrar els músculs I lligaments de la pelvis perquè
                        el bebè trobi la posició òptima per al seu naixement.
                        <br /> En un estudi sobre un grup d’embarassades
                        diagnosticades de mal posicionament fetal, el fetus
                        s’havia posicionat correctament en el 82% dels casos
                        després de rebre ajustos quiropràctics amb la tècnica
                        Webster (JMPT).
                        <br />
                        <br />
                        Els quiropràctics reben estudis específics sobre la
                        tècnica Webster. En els nostres centres LinQ tenim anys
                        d’experiència aplicant aquesta tècnica amb èxit i total
                        seguretat.
                        <br />
                        <br />
                        Ens encanta ajudar les nostres clientes a viure el seu
                        embaràs amb més benestar i tranquil·litat, tot
                        preparant-les perquè tinguin un part més ràpid, còmode i
                        segur.
                        <br />
                        <br />
                        Si tens qualsevol dubte o vols més informació,
                        consulta’ns.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </section>
              <section className="basic__padding background__blue">
                <Container>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                  >
                    <p
                      className="description__question"
                      style={{ color: "#fff", width: "100%" }}
                    >
                      <span className="center-title">
                        Beneficis de la quiropràctica en embarassades:
                      </span>
                      <br />
                      <br />
                      • Afavoreix l’adaptació a l’estrès físic de l’embaràs
                      <br />
                      • Alleuja i/o elimina el mal d’esquena
                      <br />
                      • Millora el descans
                      <br />
                      • Millora l’espai per al creixement del fetus
                      <br />
                      • Facilita un bon posicionament fetal
                      <br />• Contribueix a un part més ràpid, còmode i segur
                    </p>
                  </Grid>
                </Container>
              </section>
            </>
          )}
        </>
      )}

      {/* SOLUTIONS 3 */}
      {solutionSelected === 3 && (
        <>
          {language === "es" ? (
            <>
              {" "}
              <Container fixed className="basic__padding">
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction="row"
                >
                  <h4 className="text__content__blue">
                    Contrario a lo que podría parecer, no existe una edad
                    específica para poder conseguir un sistema nervioso y
                    columna vertebral libre de interferencias. Para quienes
                    desean mantener su calidad de vida con los años, la
                    quiropráctica se presenta como una herramienta perfecta para
                    ello.
                  </h4>
                </Grid>
                <Grid
                  className="basic__padding"
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Link
                    className="btn__item__contact__chiropractic"
                    to="/contact"
                  >
                    <button>Contacta para más información</button>
                  </Link>
                </Grid>
                <hr className="separator" />
              </Container>
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={5}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/TERCERAEDAD1.jpeg)`,
                    }}
                    className="banner_customer"
                  ></Grid>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={12}
                    md={7}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__right__blue">
                      <h1 className="choose__title ">
                        ¿Por qué la quiroprácticas
                        <br />
                        es ideal para la tercera edad?
                      </h1>
                      <p
                        className="description__item__chiropractic text__align__right reduce__content__left"
                        style={{ marginBottom: "0px!important" }}
                      >
                        Con el paso de los años el cuerpo comienza a ser un poco
                        más delicado y puede empezar a tener un metabolismo más
                        lento, además de un esqueleto más frágil, que suele
                        tolerar en menor medida tanto las cirugías como los
                        fármacos.
                        <br />
                        Por eso, es de gran valor poder realizar un cuidado
                        quiropráctico de calidad que se adapte a la situación
                        propia de la edad, sin efectos secundarios y que a la
                        vez se ajuste a las necesidades personales que presenta
                        la persona en este momento de su vida.
                        <br />
                        <br />
                        Al ayudar a liberar el sistema nervioso de cualquier
                        posible interferencia, el cuidado quiropráctico promueve
                        la restauración de las funciones locomotoras y también
                        resulta eficaz para que el organismo pueda recobrar una
                        mayor vitalidad y, en consecuencia, una mejor salud en
                        general.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </section>
              <Container className="basic__padding">
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  direction="row"
                >
                  <p className="description__question">
                    El cuidado quiropráctico en personas mayores consiste en
                    ajustes suaves y correctivos adaptados a las condiciones de
                    la columna de cada persona y tomando en consideración su
                    estado de salud en concreto, prestando una especial atención
                    a la preservación de sus articulaciones, fortalecimiento de
                    sus huesos y mejora del tono muscular.
                    <br />
                    Por eso, es una opción aconsejada en casos de osteoporosis y
                    artrosis, ya que ayuda a proporcionar mayor movimiento a las
                    articulaciones e incluso se ha comprobado que puede aumentar
                    la densidad ósea con la mejora de la movilidad.
                    <br />
                    <br />
                    Así, la quiropráctica para personas mayores destaca por ser
                    completamente efectiva, segura y libre de efectos
                    secundarios.
                  </p>
                </Grid>
              </Container>
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    xs={12}
                    md={7}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__left__blue">
                      <h1 className="choose__title text-align-left">
                        ¿Cuáles son los beneficios
                        <br />
                        de la quiropráctica en las personas mayores?
                      </h1>
                      <p
                        className="description__item__chiropractic text-align-left reduce__content__right"
                        style={{ marginBottom: "0px!important" }}
                      >
                        Muchas personas en la tercera edad suelen desconocer los
                        grandes beneficios que puede ofrecer el cuidado
                        quiropráctico periódico en su vida. Entre ellos podemos
                        destacar los siguientes como los más habituales:
                        <br />
                        <br />
                        • Mejora la flexibilidad y la movilidad.
                        <br />
                        • Optimiza la coordinación y el equilibrio.
                        <br />
                        • Reduce el riesgo de caídas.
                        <br />
                        • Disminuye la degeneración de las articulaciones.
                        <br />
                        • Disminuye la necesidad de consumir medicamentos.
                        <br />
                        • Mejora la calidad de vida y ofrece más vitalidad.
                        <br />
                        • Promueve un mejor funcionamiento de los órganos.
                        <br />
                        • Incrementa la posibilidad de realizar tareas
                        cotidianas y ejercicio.
                        <br />• Facilita una mejora postural.
                      </p>
                    </div>
                  </Grid>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={5}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/TERCERAEDAD2.jpeg)`,
                    }}
                    className="banner_customer"
                  ></Grid>
                </Grid>
              </section>
              <section className="basic__padding background__blue">
                <Container>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                  >
                    <p
                      className="description__question"
                      style={{ color: "#fff", width: "100%" }}
                    >
                      <b className="center-title">
                        ¿Cómo ayuda la quiropráctica a los mayores?
                      </b>
                      <br />
                      <br />
                      La quiropráctica puede ser de gran ayuda para los adultos
                      de la tercera edad, ya que puede actuar de la siguiente
                      manera:
                      <br />
                      <br />
                      <img
                        src={`${
                          process.env.REACT_APP_FRONTEND +
                          "assets/image/star_white.png"
                        }`}
                        alt="Pin"
                        className="star__img"
                      />
                      Mayor flexibilidad y movilidad
                      <br />
                      Al ayudar a aumentar su movilidad y también la
                      flexibilidad, la quiropráctica puede facilitar la
                      realización de actividades diarias como agacharse,
                      vestirse, caminar, etc.
                      <br />
                      <br />
                      <img
                        src={`${
                          process.env.REACT_APP_FRONTEND +
                          "assets/image/star_white.png"
                        }`}
                        alt="Pin"
                        className="star__img"
                      />
                      Mayor coordinación y equilibrio
                      <br />
                      El cuidado quiropráctico es ideal para mejorar tanto el
                      equilibrio como la coordinación en las personas mayores,
                      dado que muchos de los problemas asociados suelen ser
                      consecuencia de cambios degenerativos en la columna
                      vertebral.
                      <br />
                      <br />
                      <img
                        src={`${
                          process.env.REACT_APP_FRONTEND +
                          "assets/image/star_white.png"
                        }`}
                        alt="Pin"
                        className="star__img"
                      />
                      Menor riesgo de caídas
                      <br />
                      Al mejorar la flexibilidad y la movilidad, promoviendo un
                      mejor equilibrio y coordinación, la quiropráctica también
                      puede ayudar a reducir el riesgo de sufrir caídas, ya que
                      permite tener un mejor control del cuerpo.
                      <br />
                      <br />
                      <img
                        src={`${
                          process.env.REACT_APP_FRONTEND +
                          "assets/image/star_white.png"
                        }`}
                        alt="Pin"
                        className="star__img"
                      />
                      Menos degeneración articular
                      <br />
                      La quiropráctica involucra cambios posturales que ayudan a
                      prevenir que las articulaciones puedan sobrecargarse.
                      Asimismo, ayuda a que tanto la columna como el sistema
                      nervioso estén más saludables, favoreciendo el movimiento
                      articular general y previniendo de este modo la
                      degeneración de las articulaciones.
                    </p>
                  </Grid>
                </Container>
              </section>
            </>
          ) : language === "en" ? (
            <>
              {" "}
              <Container fixed className="basic__padding">
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction="row"
                >
                  <h4 className="text__content__blue">
                    Contrary to what it may seem, there is no specific age to
                    achieve a nervous system and spine free of interference. For
                    those who wish to maintain their quality of life over the
                    years, chiropractic presents itself as a perfect tool to do
                    so.
                  </h4>
                </Grid>
                <Grid
                  className="basic__padding"
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Link
                    className="btn__item__contact__chiropractic"
                    to="/contact"
                  >
                    <button>Contact us</button>
                  </Link>
                </Grid>
                <hr className="separator" />
              </Container>
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={5}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/TERCERAEDAD1.jpeg)`,
                    }}
                    className="banner_customer"
                  ></Grid>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={12}
                    md={7}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__right__blue">
                      <h1 className="choose__title ">
                        Why is chiropractic
                        <br />
                        ideal for the elderly?
                      </h1>
                      <p
                        className="description__item__chiropractic text__align__right reduce__content__left"
                        style={{ marginBottom: "0px!important" }}
                      >
                        As the years go by, the body begins to be a little more
                        delicate and may start to have a slower metabolism, as
                        well as a more fragile skeleton, which tends to tolerate
                        less surgery and drugs.
                        <br />
                        Therefore, it is of great value to be able to provide
                        quality chiropractic care that is adapted to the
                        age-specific situation, without side effects, and that
                        at the same time adjusts to the personal needs of the
                        person at this time in his or her life.
                        <br />
                        <br />
                        By helping to free the nervous system from any possible
                        interference, chiropractic care promotes the restoration
                        of locomotor functions and is also effective in helping
                        the body regain greater vitality and, consequently,
                        better overall health.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </section>
              <Container className="basic__padding">
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  direction="row"
                >
                  <p className="description__question">
                    Chiropractic care for the elderly consists of gentle,
                    corrective adjustments adapted to the condition of each
                    person’s spine and taking into consideration his or her
                    particular state of health, paying special attention to
                    preserving joints, strengthening bones and improving muscle
                    tone.
                    <br />
                    Therefore, it is a highly recommended option in cases of
                    osteoporosis and osteoarthritis, as it helps to provide
                    greater movement to the joints and it has even been proven
                    that it can increase bone density with improved mobility.
                    <br />
                    <br />
                    Thus, chiropractic for the elderly stands out for being
                    completely effective, safe and free of side effects.
                  </p>
                </Grid>
              </Container>
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    xs={12}
                    md={7}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__left__blue">
                      <h1 className="choose__title text-align-left">
                        What are the benefits
                        <br />
                        of chiropractic in the elderly?
                      </h1>
                      <p
                        className="description__item__chiropractic text-align-left reduce__content__right"
                        style={{ marginBottom: "0px!important" }}
                      >
                        Many elderly people are often unaware of the great
                        benefits that regular chiropractic care can offer in
                        their lives. Among them we can highlight the following
                        as the most common:
                        <br />
                        <br />
                        • Improves flexibility and mobility.
                        <br />
                        • Optimizes coordination and balance.
                        <br />
                        • Reduces the risk of falls.
                        <br />
                        • Reduces joint degeneration.
                        <br />
                        • Decreases the need to consume medications.
                        <br />
                        • Improves quality of life and offers more vitality.
                        <br />
                        • Promotes better organ function.
                        <br />
                        • Increases the ability to perform daily tasks and
                        exercise.
                        <br />• Facilitates postural improvement.
                      </p>
                    </div>
                  </Grid>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={5}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/TERCERAEDAD2.jpeg)`,
                    }}
                    className="banner_customer"
                  ></Grid>
                </Grid>
              </section>
              <section className="basic__padding background__blue">
                <Container>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                  >
                    <p
                      className="description__question"
                      style={{ color: "#fff", width: "100%" }}
                    >
                      <b className="center-title">
                        How does chiropractic help the elderly?
                      </b>
                      <br />
                      <br />
                      Chiropractic can be of great help to older adults, as it
                      can act as follows:
                      <br />
                      <br />
                      <img
                        src={`${
                          process.env.REACT_APP_FRONTEND +
                          "assets/image/star_white.png"
                        }`}
                        alt="Pin"
                        className="star__img"
                      />
                      Greater flexibility and mobility
                      <br />
                      By helping to increase your mobility and also flexibility,
                      chiropractic can make it easier to perform daily
                      activities such as bending, dressing, walking, etc.
                      <br />
                      <br />
                      <img
                        src={`${
                          process.env.REACT_APP_FRONTEND +
                          "assets/image/star_white.png"
                        }`}
                        alt="Pin"
                        className="star__img"
                      />
                      Improved coordination and balance
                      <br />
                      Chiropractic care is ideal for improving both balance and
                      coordination in the elderly, since many of the associated
                      problems are often the result of degenerative changes in
                      the spine.
                      <br />
                      <br />
                      <img
                        src={`${
                          process.env.REACT_APP_FRONTEND +
                          "assets/image/star_white.png"
                        }`}
                        alt="Pin"
                        className="star__img"
                      />
                      Reduced risk of falls
                      <br />
                      By improving flexibility and mobility, promoting better
                      balance and coordination, chiropractic can also help
                      reduce the risk of falls by allowing better control of the
                      body.
                      <br />
                      <br />
                      <img
                        src={`${
                          process.env.REACT_APP_FRONTEND +
                          "assets/image/star_white.png"
                        }`}
                        alt="Pin"
                        className="star__img"
                      />
                      Less joint degeneration
                      <br />
                      Chiropractic involves postural changes that help prevent
                      joints from becoming overloaded. They also help to make
                      both the spine and nervous system healthier, promoting
                      overall joint movement and thus preventing joint
                      degeneration.
                    </p>
                  </Grid>
                </Container>
              </section>
            </>
          ) : (
            <>
              {" "}
              <Container fixed className="basic__padding">
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction="row"
                >
                  <h4 className="text__content__blue">
                    Contrari al que podria semblar, no existeix una edat
                    específica per poder aconseguir un sistema nerviós i columna
                    vertebral lliure d’interferències. Per als qui desitgen
                    mantenir la seva qualitat de vida amb els anys, la
                    quiropràctica es presenta com una eina perfecta per a
                    aconseguir-ho.
                  </h4>
                </Grid>
                <Grid
                  className="basic__padding"
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Link
                    className="btn__item__contact__chiropractic"
                    to="/contact"
                  >
                    <button>Contacta i informa’t</button>
                  </Link>
                </Grid>
                <hr className="separator" />
              </Container>
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={5}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/TERCERAEDAD1.jpeg)`,
                    }}
                    className="banner_customer"
                  ></Grid>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={12}
                    md={7}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__right__blue">
                      <h1 className="choose__title ">
                        Per què la quiropràctica
                        <br />
                        és ideal per a la tercera edat?
                      </h1>
                      <p
                        className="description__item__chiropractic text__align__right reduce__content__left"
                        style={{ marginBottom: "0px!important" }}
                      >
                        Amb el pas dels anys el cos comença a ser una mica més
                        delicat i pot començar a tenir un metabolisme més lent,
                        a més d’un esquelet més fràgil, que sol tolerar en menor
                        mesura tant les cirurgies com els fàrmacs.
                        <br />
                        Per això, és d’un gran valor poder rebre un tractament
                        quiropràctic de qualitat que s’adapti a la situació
                        pròpia de l’edat, sense efectes secundaris i que alhora
                        s’ajusti a les necessitats personals que presenta la
                        persona en aquest moment de la seva vida.
                        <br />
                        <br />
                        En ajudar a alliberar el sistema nerviós de qualsevol
                        possible interferència, el tractament quiropràctic
                        promou la restauració de les funcions locomotores i
                        també resulta eficaç perquè l’organisme pugui recobrar
                        vitalitat més gran i, en conseqüència, una millor salut
                        en general.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </section>
              <Container className="basic__padding">
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  direction="row"
                >
                  <p className="description__question">
                    El tractament quiropràctic en persones grans consisteix en
                    ajustos suaus i correctius adaptats a les condicions de la
                    columna de cada persona i prenent en consideració el seu
                    estat de salut en concret, amb una especial atenció a la
                    preservació de les articulacions, enfortiment dels ossos i
                    millora del to muscular. Per això, és una opció molt
                    aconsellada en casos d’osteoporosi i artrosi, ja que ajuda a
                    proporcionar més moviment a les articulacions i, fins I tot,
                    s’ha comprovat que pot augmentar la densitat òssia amb la
                    millora de la mobilitat.
                    <br />
                    <br />
                    Així, la quiropràctica per a persones grans destaca perquè
                    és completament efectiva, segura i lliure d’efectes
                    secundaris.
                  </p>
                </Grid>
              </Container>
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    xs={12}
                    md={7}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__left__blue">
                      <h1 className="choose__title text-align-left">
                        Quins són els beneficis
                        <br />
                        de la quiropràctica en les persones grans?
                      </h1>
                      <p
                        className="description__item__chiropractic text-align-left reduce__content__right"
                        style={{ marginBottom: "0px!important" }}
                      >
                        Moltes persones a la tercera edat solen desconèixer els
                        grans beneficis que pot oferir el tractament
                        quiropràctic periòdic en la seva vida. Entre ells podem
                        destacar els següents com els més habituals:
                        <br />
                        <br />
                        • Millora la flexibilitat i la mobilitat.
                        <br />
                        • Optimitza la coordinació i l’equilibri.
                        <br />
                        • Redueix el risc de caigudes.
                        <br />
                        • Disminueix la degeneració de les articulacions.
                        <br />
                        • Disminueix la necessitat de prendre medicaments.
                        <br />
                        • Millora la qualitat de vida i ofereix més vitalitat.
                        <br />
                        • Promou un millor funcionament dels òrgans.
                        <br />
                        • Incrementa la possibilitat de fer tasques quotidianes
                        i exercici.
                        <br />• Facilita una millora postural.
                      </p>
                    </div>
                  </Grid>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={5}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/TERCERAEDAD2.jpeg)`,
                    }}
                    className="banner_customer"
                  ></Grid>
                </Grid>
              </section>
              <section className="basic__padding background__blue">
                <Container>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                  >
                    <p
                      className="description__question"
                      style={{ color: "#fff", width: "100%" }}
                    >
                      <b className="center-title">
                        Com ajuda la quiropràctica a la gent gran?
                      </b>
                      <br />
                      <br />
                      La quiropràctica pot ser de gran ajuda per als adults de
                      la tercera edat, ja que pot actuar de la manera següent:
                      <br />
                      <br />
                      <img
                        src={`${
                          process.env.REACT_APP_FRONTEND +
                          "assets/image/star_white.png"
                        }`}
                        alt="Pin"
                        className="star__img"
                      />
                      Més flexibilitat i mobilitat
                      <br />
                      En ajudar a augmentar la seva mobilitat i també la
                      flexibilitat, la quiropràctica pot facilitar la
                      realització d’activitats diàries com ara ajupir-se,
                      vestir-se, caminar…
                      <br />
                      <br />
                      <img
                        src={`${
                          process.env.REACT_APP_FRONTEND +
                          "assets/image/star_white.png"
                        }`}
                        alt="Pin"
                        className="star__img"
                      />
                      Més coordinació i equilibri
                      <br />
                      La quiropràctica és ideal per millorar tant l’equilibri
                      com la coordinació en les persones grans, atès que molts
                      dels problemes associats solen ser conseqüència de canvis
                      degeneratius en la columna vertebral.
                      <br />
                      <br />
                      <img
                        src={`${
                          process.env.REACT_APP_FRONTEND +
                          "assets/image/star_white.png"
                        }`}
                        alt="Pin"
                        className="star__img"
                      />
                      Menys risc de caigudes
                      <br />
                      En millorar la flexibilitat i la mobilitat, promovent un
                      millor equilibri i coordinació, la quiropràctica també pot
                      ajudar a reduir el risc de patir caigudes, ja que permet
                      tenir un millor control del cos.
                      <br />
                      <br />
                      <img
                        src={`${
                          process.env.REACT_APP_FRONTEND +
                          "assets/image/star_white.png"
                        }`}
                        alt="Pin"
                        className="star__img"
                      />
                      Menys degeneració articular
                      <br />
                      La quiropràctica involucra canvis posturals que ajuden a
                      prevenir que les articulacions puguin sobrecarregar-se.
                      Així mateix, ajuda a tenir una columna i un sistema
                      nerviós més saludables, tot afavorint el moviment
                      articular general i prevenint d’aquesta manera la
                      degeneració de les articulacions.
                    </p>
                  </Grid>
                </Container>
              </section>
            </>
          )}
        </>
      )}

      {/* SOLUTIONS 4 */}
      {solutionSelected === 4 && (
        <>
          {language === "es" ? (
            <>
              {" "}
              <Container fixed className="basic__padding">
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction="row"
                >
                  <h4 className="text__content__blue">
                    La quiropráctica es muy beneficiosa desde los primeros años
                    de vida hasta la adolescencia, pues ayuda a fortalecer el
                    sistema inmunológico y puede llegar a sentar las bases de
                    una buena salud para toda la vida. Los ajustes
                    quiroprácticos en niños y adolescentes aseguran un buen
                    desarrollo tanto a nivel físico como emocional y, en
                    general, consiguen resultados óptimos con mayor rapidez y
                    facilidad que en adultos.
                  </h4>
                </Grid>
                <Grid
                  className="basic__padding"
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Link
                    className="btn__item__contact__chiropractic"
                    to="/contact"
                  >
                    <button>Contacta para más información</button>
                  </Link>
                </Grid>
                <hr className="separator" />
              </Container>
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={6}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/BEBES1.jpeg)`,
                    }}
                    className="banner_customer"
                  ></Grid>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={12}
                    md={6}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__right__blue">
                      <h1 className="choose__title ">Bebés</h1>
                      <p
                        className="description__item__chiropractic text__align__right reduce__content__left"
                        style={{ marginBottom: "0px!important" }}
                      >
                        Ya en el momento del parto, tanto si es natural como por
                        cesárea, el bebé puede sufrir subluxaciones, sobre todo
                        en la zona del cuello, de modo que es muy recomendable
                        una revisión quiropráctica del recién nacido para
                        asegurar que su columna está bien alineada.
                        <br />
                        <br />
                        Si se ha usado fórceps o ha sido un parto difícil es
                        todavía más importante un chequeo por parte del
                        quiropráctico, pues la frágil columna del bebé habrá
                        sido sometida a una mayor tensión. En este examen, el
                        quiropráctico también comprueba que el bebé responda
                        bien a varios estímulos y se asegura de que todo está
                        funcionando correctamente.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </section>
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    xs={12}
                    md={6}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__left__blue">
                      <h1 className="choose__title text-align-left">
                        Bebés que sufren de
                        <br />
                        cólicos o tiene problemas de lactancia
                      </h1>
                      <p
                        className="description__item__chiropractic text-align-left reduce__content__right"
                        style={{ marginBottom: "0px!important" }}
                      >
                        Si tu bebé sufre de cólicos o tiene problemas de
                        lactancia materna, la quiropráctica le puede ser de gran
                        utilidad, pues suelen desaparecer en pocas sesiones
                        (incluso en una sola sesión según el caso) y de una
                        forma muy natural. Si tu bebé llora mucho, o a menudo lo
                        ves intranquilo y nervioso, también encontrará alivio
                        gracias a la quiropráctica, pues los ajustes le ayudarán
                        a relajarse y dormirá mucho mejor.
                        <br />
                        <br />
                        Y, no te preocupes, la quiropráctica no puede resultar
                        ningún peligro para tu bebé ni serle perjudicial porque
                        los ajustes en bebés son específicos y muy suaves:
                        consisten en unas ligeras presiones con las yemas de los
                        dedos en la espalda y en el cráneo. Solo hay que ver la
                        cara relajada de los bebés que nos visitan después de su
                        ajuste y la satisfacción con que los miran sus padres, a
                        los que agradecemos su confianza en nosotros.
                        <br />
                        <br />
                        Para tu tranquilidad, en nuestros centros LinQ muchos
                        bebés se han beneficiado de la quiropráctica y reciben
                        ajustes de quiroprácticos acreditados con la
                        especialidad de quiropráctica pediátrica y con mucha
                        experiencia en este campo.
                      </p>
                    </div>
                  </Grid>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={6}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/BEBES3.jpeg)`,
                    }}
                    className="banner_customer"
                  ></Grid>
                </Grid>
              </section>
              <section className="basic__padding background__blue">
                <Container>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                  >
                    <p
                      className="description__question"
                      style={{ color: "#fff", width: "100%" }}
                    >
                      <div className="center-title">
                        <b>Beneficios de la quiropráctica en bebés</b>
                      </div>
                      <br />
                      <br />
                      • Favorece la alineación en las subluxaciones vertebrales
                      <br />
                      • Favorece su desarrollo y bienestar
                      <br />
                      • Alivia los cólicos pudiendo llegar a desaparecer
                      <br />
                      • Ayuda en los problemas de lactancia materna
                      <br />• Facilita el descanso del bebé
                    </p>
                  </Grid>
                </Container>
              </section>
              {/* ninos */}
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={6}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/NIÑOS1.jpeg)`,
                    }}
                    className="banner_customer"
                  ></Grid>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={12}
                    md={6}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__right__blue">
                      <h1 className="choose__title ">Niños</h1>
                      <p
                        className="description__item__chiropractic text__align__right reduce__content__left"
                        style={{ marginBottom: "0px!important" }}
                      >
                        En etapas posteriores del desarrollo de tu hijo o hija,
                        la quiropráctica también puede resultar muy beneficiosa.
                        Le ayudará en la postura y en los problemas de espalda.
                        Si ya tiene malos hábitos posturales o dolor de espalda
                        por el uso de la mochila o malas posturas en casa o en
                        la escuela, la quiropráctica le ayudará a corregirlos
                        sin casi darse cuenta.
                        <br />
                        <br />
                        Cada vez más los niños pasan horas y horas sentados, a
                        menudo mal colocados, lo que perjudica el buen
                        crecimiento de su columna hasta el punto de provocarle
                        molestias en la espalda. Si tu hijo o hija tiene
                        escoliosis, el cuidado quiropráctico puede ayudarle a
                        evitar que empeore. ¡No dudes en consultarnos cualquier
                        duda!
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </section>
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    xs={12}
                    md={6}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__left__blue">
                      <h1 className="choose__title text-align-left">
                        La quiropráctica no
                        <br />
                        solo tiene beneficios a nivel postural
                      </h1>
                      <p
                        className="description__item__chiropractic text-align-left reduce__content__right"
                        style={{ marginBottom: "0px!important" }}
                      >
                        La quiropráctica no solo tiene beneficios a nivel
                        postural en los niños, sino que también se ha visto que
                        puede mejorar su capacidad de concentración, lo que
                        favorece su rendimiento escolar. Así mismo, les ayuda a
                        relajarse y descansan mejor por la noche. También
                        resulta especialmente útil en casos de enuresis o de
                        hiperactividad.
                        <br />
                        <br />
                        Por otro lado, la quiropráctica resulta beneficiosa en
                        el refuerzo de su sistema inmunológico, observándose una
                        mejora sustancial en caso de resfriados, infecciones o
                        alergias. Si tu hijo o hija se resfría a menudo o tiene
                        muchas infecciones de oído, la visita a un quiropráctico
                        es muy recomendable.
                        <br />
                        <br />
                        Finalmente, a pesar de que los niños parezcan de goma,
                        las frecuentes caídas que sufren a lo largo de su
                        desarrollo pueden causar un desajuste en su columna por
                        el impacto recibido, así que puede ser muy beneficioso
                        comprobar que su columna está alineada y tiene el
                        movimiento correcto mediante una revisión quiropráctica
                        de vez en cuando.
                        <br />
                        <br />
                        En definitiva, el cuidado quiropráctico en niños asegura
                        su óptimo crecimiento y sienta las bases para una buena
                        salud en el futuro. A nuestros centros LinQ vienen
                        muchos niños, que en pocas sesiones suelen corregir el
                        motivo por el que acudieron. ¡Nos encanta verlos crecer
                        sanos y felices!
                      </p>
                    </div>
                  </Grid>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={6}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/NIÑOS3.jpeg)`,
                    }}
                    className="banner_customer"
                  ></Grid>
                </Grid>
              </section>
              <section className="basic__padding background__blue">
                <Container>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                  >
                    <p
                      className="description__question"
                      style={{ color: "#fff", width: "100%" }}
                    >
                      <span className="center-title">
                        Beneficios de la quiropráctica más observados en los
                        niños
                      </span>
                      <br />
                      <br />
                      • Evita y/o corrige las malas posturas
                      <br />
                      • Previene o elimina el dolor de espalda
                      <br />
                      • Aumenta su capacidad de concentración
                      <br />
                      • Favorece su rendimiento en la escuela
                      <br />
                      • Facilita su relajación y descanso nocturno
                      <br />
                      • Evita y/o elimina la enuresis
                      <br />
                      • Ayuda en casos de hiperactividad
                      <br />
                      • Refuerza su sistema inmunológico
                      <br />
                      • Disminuye la posibilidad de resfriados, infecciones o
                      alergias
                      <br />• Corrige los desajustes en la columna debidos a
                      caídas
                    </p>
                  </Grid>
                </Container>
              </section>
              {/* adolescentes */}
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={6}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/ADOLESCENTE1.jpeg)`,
                    }}
                    className="banner_customer"
                  ></Grid>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={12}
                    md={6}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__right__blue">
                      <h1 className="choose__title ">Adolescentes</h1>
                      <p
                        className="description__item__chiropractic text__align__right reduce__content__left"
                        style={{ marginBottom: "0px!important" }}
                      >
                        En los adolescentes, la quiropráctica ayuda con los
                        problemas posturales asociados al uso del móvil, tablet
                        u ordenador, que suelen consistir en un encorvamiento
                        excesivo de la espalda, con adelantamiento de los
                        hombros y, en algunos casos, incluso rectificación de la
                        curva del cuello.
                        <br />
                        <br />
                        Gracias a los ajustes, los jóvenes pueden recuperar la
                        correcta alineación de la columna y tener una postura
                        más erguida, lo que favorece su buena salud en general y
                        previene la aparición de problemas de espalda en la
                        etapa adulta.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </section>
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    xs={12}
                    md={6}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__left__blue">
                      <h1 className="choose__title text-align-left">
                        La quiropráctica
                        <br />
                        también contribuye en:
                      </h1>
                      <p
                        className="description__item__chiropractic text-align-left reduce__content__right"
                        style={{ marginBottom: "0px!important" }}
                      >
                        La quiropráctica también contribuye a gestionar mejor la
                        presión de los estudios y favorece un mayor control de
                        las emociones, haciendo más llevadera esta etapa de
                        cambios.
                        <br />
                        <br />
                        En el deporte, el cuidado quiropráctico permite evitar
                        las lesiones o acelerar su recuperación, y ayuda a tener
                        un cuerpo más armonioso y ágil, con un mayor rendimiento
                        físico.
                        <br />
                        <br />
                        Igual que los niños, los jóvenes suelen responder
                        rápidamente a los ajustes quiroprácticos. Nuestros
                        consejos sobre posturas o ejercicios pueden ser un buen
                        complemento del cuidado quiropráctico.
                      </p>
                    </div>
                  </Grid>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={6}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/ADOLESCENTE2.jpeg)`,
                    }}
                    className="banner_customer"
                  ></Grid>
                </Grid>
              </section>
              <section className="basic__padding background__blue">
                <Container>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                  >
                    <p
                      className="description__question"
                      style={{ color: "#fff", width: "100%" }}
                    >
                      <span className="center-title">
                        Beneficios de la quiropráctica más vistos en
                        adolescentes
                      </span>
                      <br />
                      <br />
                      • Ayuda con los problemas posturales asociados al móvil,
                      tablet u ordenador
                      <br />
                      • Previene y/o elimina el dolor de espalda
                      <br />
                      • Contribuye a gestionar mejor la presión de los estudios
                      <br />
                      • Favorece un mayor control de las emociones
                      <br />
                      • Previene las lesiones deportivas o acelera su
                      recuperación
                      <br />
                      • Permite un mayor rendimiento físico
                      <br />
                      • Refuerza su sistema inmunológico
                      <br />• Sienta las bases para una buena salud en el futuro
                    </p>
                  </Grid>
                </Container>
              </section>
            </>
          ) : language === "en" ? (
            <>
              {" "}
              <Container fixed className="basic__padding">
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction="row"
                >
                  <h4 className="text__content__blue">
                    Chiropractic is very beneficial from early childhood through
                    adolescence, as it helps strengthen the immune system and
                    can lay the foundation for lifelong health. Chiropractic
                    adjustments in children and adolescents ensure good physical
                    and emotional development and, in general, achieve optimal
                    results more quickly and easily than in adults.
                  </h4>
                </Grid>
                <Grid
                  className="basic__padding"
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Link
                    className="btn__item__contact__chiropractic"
                    to="/contact"
                  >
                    <button>Contact us</button>
                  </Link>
                </Grid>
                <hr className="separator" />
              </Container>
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={6}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/BEBES1.jpeg)`,
                    }}
                    className="banner_customer"
                  ></Grid>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={12}
                    md={6}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__right__blue">
                      <h1 className="choose__title ">Babies</h1>
                      <p
                        className="description__item__chiropractic text__align__right reduce__content__left"
                        style={{ marginBottom: "0px!important" }}
                      >
                        Already at the time of delivery, whether it is natural
                        or by cesarean section, the baby may suffer
                        subluxations, especially in the neck area, so it is
                        highly recommended a chiropractic review of the newborn
                        to ensure that your spine is well aligned.
                        <br />
                        <br />
                        If forceps have been used or if the delivery was
                        difficult, a check-up by the chiropractor is even more
                        important, as the baby’s fragile spine will have been
                        subjected to increased stress. In this examination, the
                        chiropractor also checks that the baby responds well to
                        various stimuli and makes sure that everything is
                        working properly.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </section>
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    xs={12}
                    md={6}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__left__blue">
                      <h1 className="choose__title text-align-left">
                        Babies suffering from
                        <br />
                        colic or have lactation problems
                      </h1>
                      <p
                        className="description__item__chiropractic text-align-left reduce__content__right"
                        style={{ marginBottom: "0px!important" }}
                      >
                        If your baby suffers from colic or breastfeeding
                        problems, chiropractic can be very useful, because they
                        usually disappear in a few sessions (even in a single
                        session depending on the case) and in a very natural
                        way. If your baby cries a lot, or you often see him
                        restless and nervous, he will also find relief thanks to
                        chiropractic, as the adjustments will help him relax and
                        sleep much better.
                        <br />
                        <br />
                        And, don’t worry, chiropractic cannot be dangerous or
                        harmful to your baby because the adjustments on babies
                        are specific and very gentle: they consist of light
                        pressure with the fingertips on the back and skull. Just
                        look at the relaxed faces of the babies who visit us
                        after their adjustment and the satisfaction with which
                        their parents look at them, whom we thank for their
                        confidence in us.
                        <br />
                        <br />
                        For your peace of mind, in our LinQ centers many babies
                        have benefited from chiropractic and receive adjustments
                        from chiropractors accredited with the specialty of
                        pediatric chiropractic and with a lot of experience in
                        this field.
                      </p>
                    </div>
                  </Grid>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={6}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/BEBES3.jpeg)`,
                    }}
                    className="banner_customer"
                  ></Grid>
                </Grid>
              </section>
              <section className="basic__padding background__blue">
                <Container>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                  >
                    <p
                      className="description__question"
                      style={{ color: "#fff", width: "100%" }}
                    >
                      <span className="center-title">
                        Benefits of chiropractic in infants
                      </span>
                      <br />
                      <br />
                      • Promotes alignment in vertebral subluxations
                      <br />
                      • Promotes their development and well-being
                      <br />
                      • Relieves colic and may even disappear
                      <br />
                      • Helps with breastfeeding problems
                      <br />• Facilitates baby’s rest
                    </p>
                  </Grid>
                </Container>
              </section>
              {/* ninos */}
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={6}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/NIÑOS1.jpeg)`,
                    }}
                    className="banner_customer"
                  ></Grid>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={12}
                    md={6}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__right__blue">
                      <h1 className="choose__title ">Children</h1>
                      <p
                        className="description__item__chiropractic text__align__right reduce__content__left"
                        style={{ marginBottom: "0px!important" }}
                      >
                        In later stages of your child’s development,
                        chiropractic can also be very beneficial. It will help
                        with posture and back problems. If he or she already has
                        bad postural habits or back pain from backpacking or
                        poor posture at home or at school, chiropractic will
                        help correct them without almost realizing it.
                        <br />
                        <br />
                        More and more children spend hours and hours sitting,
                        often poorly positioned, which impairs the proper growth
                        of their spine to the point of causing back discomfort.
                        If your son or daughter has scoliosis, chiropractic care
                        can help prevent it from getting worse. Do not hesitate
                        to ask us any questions!
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </section>
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    xs={12}
                    md={6}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__left__blue">
                      <h1 className="choose__title text-align-left">
                        Chiropractic does not
                        <br />
                        It only has benefits at the postural level
                      </h1>
                      <p
                        className="description__item__chiropractic text-align-left reduce__content__right"
                        style={{ marginBottom: "0px!important" }}
                      >
                        Chiropractic not only has postural benefits for
                        children, but it has also been shown to improve their
                        ability to concentrate, which improves their school
                        performance.
                        <br />
                        <br />
                        It also helps them relax and rest better at night. It is
                        also especially useful in cases of enuresis or
                        hyperactivity.
                        <br />
                        <br />
                        On the other hand, chiropractic is beneficial in
                        strengthening their immune system, showing a substantial
                        improvement in case of colds, infections or allergies.
                        If your son or daughter often gets colds or has many ear
                        infections, a visit to a chiropractor is highly
                        recommended.
                        <br />
                        <br />
                        Finally, even though children may appear rubbery, the
                        frequent falls they suffer throughout their development
                        can cause a misalignment in their spine from the impact
                        received, so it can be very beneficial to check that
                        their spine is aligned and has the correct motion by
                        having a chiropractic check-up from time to time.
                        <br />
                        <br />
                        Ultimately, chiropractic care for children ensures their
                        optimal growth and lays the foundation for good health
                        in the future.
                        <br />
                        Many children come to our LinQ centers, and in just a
                        few sessions they usually correct the reason why they
                        came to us. We love to see them grow up healthy and
                        happy!
                      </p>
                    </div>
                  </Grid>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={6}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/NIÑOS3.jpeg)`,
                    }}
                    className="banner_customer"
                  ></Grid>
                </Grid>
              </section>
              <section className="basic__padding background__blue">
                <Container>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                  >
                    <p
                      className="description__question"
                      style={{ color: "#fff", width: "100%" }}
                    >
                      <span className="center-title">
                        Benefits of chiropractic most commonly seen in children
                      </span>
                      <br />
                      <br />
                      • Avoid and/or correct bad posture
                      <br />
                      • Prevents or eliminates back pain
                      <br />
                      • Increases your ability to concentrate
                      <br />
                      • Improves their performance in school
                      <br />
                      • Facilitates your relaxation and night’s rest
                      <br />
                      • Prevents and/or eliminates enuresis
                      <br />
                      • Help in cases of hyperactivity
                      <br />
                      • Strengthens your immune system
                      <br />
                      • Decreases the chance of colds, infections or allergies
                      <br />• Corrects spinal misalignments due to falls
                    </p>
                  </Grid>
                </Container>
              </section>
              {/* adolescentes */}
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={6}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/ADOLESCENTE1.jpeg)`,
                    }}
                    className="banner_customer"
                  ></Grid>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={12}
                    md={6}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__right__blue">
                      <h1 className="choose__title ">Teenagers</h1>
                      <p
                        className="description__item__chiropractic text__align__right reduce__content__left"
                        style={{ marginBottom: "0px!important" }}
                      >
                        In adolescents, chiropractic helps with postural
                        problems associated with the use of cell phones, tablets
                        or computers, which usually consist of an excessive
                        curvature of the back, with advancement of the shoulders
                        and, in some cases, even rectification of the curve of
                        the neck.
                        <br />
                        <br />
                        Thanks to the adjustments, young people can recover the
                        correct alignment of the spine and have a more upright
                        posture, which favors their general good health and
                        prevents the appearance of back problems in adulthood.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </section>
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    xs={12}
                    md={6}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__left__blue">
                      <h1 className="choose__title text-align-left">
                        Chiropractic
                        <br />
                        also contributes to:
                      </h1>
                      <p
                        className="description__item__chiropractic text-align-left reduce__content__right"
                        style={{ marginBottom: "0px!important" }}
                      >
                        Chiropractic also helps to better manage the pressure of
                        studies and promotes greater control of emotions, making
                        this stage of change more bearable.
                        <br />
                        <br />
                        In sports, chiropractic care helps prevent injuries or
                        accelerate their recovery, and helps to have a more
                        harmonious and agile body, with greater physical
                        performance.
                        <br />
                        <br />
                        Like children, young people often respond quickly to
                        chiropractic adjustments. Our advice on postures or
                        exercises can be a good complement to chiropractic care.
                      </p>
                    </div>
                  </Grid>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={6}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/ADOLESCENTE2.jpeg)`,
                    }}
                    className="banner_customer"
                  ></Grid>
                </Grid>
              </section>
              <section className="basic__padding background__blue">
                <Container>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                  >
                    <p
                      className="description__question"
                      style={{ color: "#fff", width: "100%" }}
                    >
                      <span className="center-title">
                        Benefits of chiropractic most commonly seen in
                        adolescents
                      </span>
                      <br />
                      <br />
                      • Helps with postural problems associated with cell
                      phones, tablets or computers
                      <br />
                      • Prevents and/or eliminates back pain
                      <br />
                      • Helps to better manage study pressure
                      <br />
                      • Promotes greater control of emotions
                      <br />
                      • Prevents sports injuries or accelerates recovery from
                      them
                      <br />
                      • Enables greater physical performance
                      <br />
                      • Strengthens your immune system
                      <br />• Laying the foundation for future good health
                    </p>
                  </Grid>
                </Container>
              </section>
            </>
          ) : (
            <>
              {" "}
              <Container fixed className="basic__padding">
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction="row"
                >
                  <h4 className="text__content__blue">
                    La quiropràctica és molt beneficiosa des dels primers anys
                    de vida fins a l’adolescència, perquè ajuda a enfortir el
                    sistema immunològic i pot arribar a establir les bases d’una
                    bona salut per a tota la vida. Els ajustos quiropràctics en
                    nens i adolescents asseguren un bon desenvolupament tant a
                    nivell físic com emocional i, en general, aconsegueixen
                    resultats òptims amb més rapidesa i facilitat que en adults.
                  </h4>
                </Grid>
                <Grid
                  className="basic__padding"
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Link
                    className="btn__item__contact__chiropractic"
                    to="/contact"
                  >
                    <button>Contacta i informa’t</button>
                  </Link>
                </Grid>
                <hr className="separator" />
              </Container>
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={6}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/BEBES1.jpeg)`,
                    }}
                    className="banner_customer"
                  ></Grid>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={12}
                    md={6}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__right__blue">
                      <h1 className="choose__title ">Bebès</h1>
                      <p
                        className="description__item__chiropractic text__align__right reduce__content__left"
                        style={{ marginBottom: "0px!important" }}
                      >
                        Ja en el moment del part, tant si és natural com per
                        cesària, el nadó pot patir subluxacions, sobretot en la
                        zona del coll, de manera que és molt recomanable una
                        revisió quiropràctica del nounat per assegurar que té la
                        columna ben alineada.
                        <br />
                        <br />
                        Si s’han fet servir fòrceps o ha estat un part difícil
                        és encara més important una revisió mèdica per part del
                        quiropràctic, perquè la fràgil columna del bebè haurà
                        estat sotmesa a una tensió més gran. En aquest examen,
                        el quiropràctic també comprova que el nadó respongui bé
                        a diversos estímuls i s’assegura que tot està funcionant
                        correctament.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </section>
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    xs={12}
                    md={6}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__left__blue">
                      <h1 className="choose__title text-align-left">
                        Nadons que pateixen de
                        <br />
                        còlics o té problemes de lactància
                      </h1>
                      <p
                        className="description__item__chiropractic text-align-left reduce__content__right"
                        style={{ marginBottom: "0px!important" }}
                      >
                        Si el teu nadó pateix còlics o té problemes de lactància
                        materna, la quiropràctica li pot ser d’una gran
                        utilitat, perquè solen desaparèixer en poques sessions
                        (fins i tot en una sola sessió segons el cas) i d’una
                        forma molt natural. Si el teu nadó plora molt, o sovint
                        el veus intranquil I nerviós, també trobarà alleujament
                        gràcies a la quiropràctica, perquè els ajustos
                        l’ajudaran a relaxar-se i dormirà molt millor.
                        <br />
                        <br />
                        I, no t’amoïnis, la quiropràctica no pot representar cap
                        perill per al teu nadó ni ser-li perjudicial perquè els
                        ajustos en bebès són específics I molt suaus:
                        consisteixen en unes lleugeres pressions amb el tou dels
                        dits a l’esquena i en el crani. Només cal veure la cara
                        relaxada dels bebès que ens visiten després del seu
                        ajust i la satisfacció amb què els miren els seus pares,
                        als quals agraïm la seva confiança en nosaltres.
                        <br />
                        <br />
                        Per a la teva tranquil·litat, en els nostres centres
                        LinQ molts bebès s’han beneficiat de la quiropràctica i
                        reben ajustos de quiropràctics acreditats amb
                        l’especialitat de quiropràctica pediàtrica i amb molta
                        experiència en aquest camp.
                      </p>
                    </div>
                  </Grid>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={6}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/BEBES3.jpeg)`,
                    }}
                    className="banner_customer"
                  ></Grid>
                </Grid>
              </section>
              <section className="basic__padding background__blue">
                <Container>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                  >
                    <p
                      className="description__question"
                      style={{ color: "#fff", width: "100%" }}
                    >
                      <span className="center-title">
                        Beneficis de la quiropràctica en bebès
                      </span>
                      <br />
                      <br />
                      • Afavoreix l’alineació en les subluxacions vertebrals
                      <br />
                      • Afavoreix el seu desenvolupament i benestar
                      <br />
                      • Alleuja els còlics, fins al punt que poden arribar a
                      desaparèixer
                      <br />
                      • Ajuda en els problemes de lactància materna
                      <br />• Facilita el descans del bebè
                    </p>
                  </Grid>
                </Container>
              </section>
              {/* ninos */}
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={6}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/NIÑOS1.jpeg)`,
                    }}
                    className="banner_customer"
                  ></Grid>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={12}
                    md={6}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__right__blue">
                      <h1 className="choose__title ">Nens</h1>
                      <p
                        className="description__item__chiropractic text__align__right reduce__content__left"
                        style={{ marginBottom: "0px!important" }}
                      >
                        En etapes posteriors del desenvolupament del teu fill o
                        filla, la quiropràctica també pot resultar molt
                        beneficiosa: l’ajudarà en la postura i en els problemes
                        d’esquena. Si ja té mals hàbits posturals o mal
                        d’esquena per l’ús de la motxilla o males postures a
                        casa o a l’escola, la quiropràctica l’ajudarà a
                        corregir-ho sense gairebé adonar-se’n.
                        <br />
                        <br />
                        Cada vegada més els nens passen hores i hores asseguts,
                        sovint mal col·locats, la qual cosa perjudica el bon
                        creixement de la seva columna fins al punt de
                        provocar-li molèsties a l’esquena. Si el teu fill o
                        filla té escoliosi, la quiropràctica pot ajudar a evitar
                        que empitjori. No dubtis a consultar-nos qualsevol
                        dubte!
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </section>
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    xs={12}
                    md={6}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__left__blue">
                      <h1 className="choose__title text-align-left">
                        La quiropràctica no
                        <br />
                        només té beneficis a nivell postural
                      </h1>
                      <p
                        className="description__item__chiropractic text-align-left reduce__content__right"
                        style={{ marginBottom: "0px!important" }}
                      >
                        La quiropràctica no tan sols té beneficis a nivell
                        postural en els nens, sinó que també s’ha vist que pot
                        millorar la seva capacitat de concentració, la qual cosa
                        afavoreix el seu rendiment escolar.
                        <br />
                        <br />
                        Així mateix, els ajuda a relaxar-se i descansen millor a
                        la nit. També és especialment útil en casos d’enuresi o
                        d’hiperactivitat.
                        <br />
                        <br />
                        D’altra banda, la quiropràctica resulta beneficiosa en
                        el reforç del seu sistema immunològic i s’observa una
                        millora substancial pel que fa als refredats, infeccions
                        o al·lèrgies. Si el teu fill o filla es constipa sovint
                        o té moltes infeccions d’oïda, la visita a un
                        quiropràctic és molt recomanable.
                        <br />
                        <br />
                        Finalment, tot i que els nens semblin de goma, les
                        freqüents caigudes que pateixen al llarg del seu
                        desenvolupament poden causar un desajust en la seva
                        columna per l’impacte rebut, així que pot ser molt
                        beneficiós comprovar que la seva columna està alineada i
                        té el moviment correcte mitjançant una revisió
                        quiropràctica de tant en tant.
                        <br />
                        <br />
                        En definitiva, el tractament quiropràctic en nens
                        assegura el seu òptim creixement i estableix les bases
                        per a una bona salut en el futur.
                        <br />
                        Als nostres centres LinQ ens visiten molts nens, que en
                        poques sessions solen corregir el motiu pel qual van
                        venir. Ens encanta veure’ls créixer sans I feliços!
                      </p>
                    </div>
                  </Grid>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={6}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/NIÑOS3.jpeg)`,
                    }}
                    className="banner_customer"
                  ></Grid>
                </Grid>
              </section>
              <section className="basic__padding background__blue">
                <Container>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                  >
                    <p
                      className="description__question"
                      style={{ color: "#fff", width: "100%" }}
                    >
                      <span className="center-title">
                        Beneficis de la quiropràctica més observats en els nens
                      </span>
                      <br />
                      <br />
                      • Evita i/o corregeix les males postures
                      <br />
                      • Prevé o elimina el mal d’esquena
                      <br />
                      • Augmenta la seva capacitat de concentració
                      <br />
                      • Afavoreix el seu rendiment a l’escola
                      <br />
                      • Facilita la seva relaxació i descans nocturn
                      <br />
                      • Evita i/o elimina l’enuresi
                      <br />
                      • Ajuda en casos d’hiperactivitat
                      <br />
                      • Reforça el seu sistema immunològic
                      <br />
                      • Disminueix la possibilitat de refredats, infeccions o
                      al·lèrgies
                      <br />• Corregeix els desajustos en la columna deguts a
                      caigudes
                    </p>
                  </Grid>
                </Container>
              </section>
              {/* adolescentes */}
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={6}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/ADOLESCENTE1.jpeg)`,
                    }}
                    className="banner_customer"
                  ></Grid>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={12}
                    md={6}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__right__blue">
                      <h1 className="choose__title ">Adolescents</h1>
                      <p
                        className="description__item__chiropractic text__align__right reduce__content__left"
                        style={{ marginBottom: "0px!important" }}
                      >
                        En els adolescents, la quiropràctica ajuda amb els
                        problemes posturals associats a l’ús del mòbil, tauleta
                        o ordinador, que solen consistir en un encorbament
                        excessiu de l’esquena, amb avançament de les espatlles
                        i, en alguns casos, fins I tot rectificació de la corba
                        del coll.
                        <br />
                        <br />
                        Gràcies als ajustos quiropràctics, els joves poden
                        recuperar la correcta alineació de la columna i tenir
                        una postura més dreta, la qual cosa afavoreix la seva
                        bona salut en general I prevé l’aparició de problemes
                        d’esquena en l’etapa adulta.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </section>
              <section className="background__sky__blue">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="center"
                  direction="row"
                  xs={12}
                >
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    xs={12}
                    md={6}
                    className="basic__padding padding__customer"
                  >
                    <div className="line__left__blue">
                      <h1 className="choose__title text-align-left">
                        La quiropràctica
                        <br />
                        també contribueix a:
                      </h1>
                      <p
                        className="description__item__chiropractic text-align-left reduce__content__right"
                        style={{ marginBottom: "0px!important" }}
                      >
                        La quiropràctica també contribueix a gestionar millor la
                        pressió dels estudis i afavoreix un control més gran de
                        les emocions, fent més suportable aquesta etapa de
                        canvis.
                        <br />
                        <br />
                        En l’esport, el tractament quiropràctic permet evitar
                        les lesions o accelerar-ne la recuperació, i ajuda a
                        tenir un cos més harmoniós i àgil, amb un millor
                        rendiment físic.
                        <br />
                        <br />
                        Igual que els nens, els joves solen respondre ràpidament
                        als ajustos quiropràctics. Els nostres consells sobre
                        postures o exercicis poden ser un bon complement del
                        tractament quiropràctic.
                      </p>
                    </div>
                  </Grid>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                    xs={12}
                    md={6}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/ADOLESCENTE2.jpeg)`,
                    }}
                    className="banner_customer"
                  ></Grid>
                </Grid>
              </section>
              <section className="basic__padding background__blue">
                <Container>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="row"
                  >
                    <p
                      className="description__question"
                      style={{ color: "#fff", width: "100%" }}
                    >
                      <span className="center-title">
                        Beneficis de la quiropràctica més vistos en adolescents
                      </span>
                      <br />
                      <br />
                      • Ajuda amb els problemes posturals associats al mòbil,
                      tauleta o ordinador
                      <br />
                      • Prevé i/o elimina el mal d’esquena
                      <br />
                      • Contribueix a gestionar millor la pressió dels estudis
                      <br />
                      • Afavoreix un control més gran de les emocions
                      <br />
                      • Prevé les lesions esportives o n’accelera la recuperació
                      <br />
                      • Permet un millor rendiment físic
                      <br />
                      • Reforça el seu sistema immunològic
                      <br />• Estableix les bases per a una bona salut en el
                      futur
                    </p>
                  </Grid>
                </Container>
              </section>
            </>
          )}
        </>
      )}
    </>
  );
};

export default SolutionView;
