import { Grid } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import goToTop from "../../hooks/useTop";

const Blog = ({ object }) => {
  const style = {
    backgroundImage:
      "url(" +
      process.env.REACT_APP_FRONTEND +
      "assets/image/" +
      object.img +
      ")",
    backgroundPosition: "bottom",
  };
  return (
    <div to="/oferta">
      <Grid
        className="reduce__contentx1"
        container
        justifyContent="left"
        alignItems="center"
        direction="row"
      >
        <Grid container xs={12} md={6}>
          <Link to={"/blog/" + object.id} style={style} className="img__blog" onClick={() => goToTop()}/>
        </Grid>
        <Grid container xs={12} md={6} className="content__text__blog">
          <p className="fecha__blog">{object.date}</p>
          <Link to={"/blog/" + object.id} onClick={() => goToTop()}>
            <p className="title__blog">{object.title}</p>
          </Link>
          <p className="resumen__blog">{object.resumen}</p>
        </Grid>
      </Grid>
    </div>
  );
};

export default Blog;
