import { Container, Grid } from "@mui/material";
import { React, useContext, useEffect } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import { UserContext } from "../../context/UserContext";
import goToTop from "../../hooks/useTop";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import MapContact from "./MapContact";
import { Link } from "react-router-dom";

const Center = ({ object }) => {
  const { language } = useContext(LanguageContext);
  const { setMenuActive, setSubmenuActive } = useContext(UserContext);

  useEffect(() => {
    setMenuActive(1);
    setSubmenuActive(0);
    goToTop();
  }, [setMenuActive, setSubmenuActive]);
  return (
    <>
      {/* SECTION BAJADA */}
      <div
        fixed
        className="basic__padding"
        style={{ paddingTop: "150px", backgroundColor: "#4c84a7" }}
      >
        <Grid
          className="reduce__contentx2"
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
          xs={12}
        >
          <h4
            className="text__content__subluxacion"
            style={{ margin: "0", padding: "50px 0", color: "#fff" }}
          >
            {object.name}
          </h4>
        </Grid>
      </div>

      <Container fixed className="basic__padding">
        <Grid container xs={12}>
          <Grid container xs={12} className="item__padding">
            {language === "es" ? (
              <p className="description__item__subluxacion reduce__contentx1 text-align-center">
                {object.text_top_es}
              </p>
            ) : language === "en" ? (
              <p className="description__item__subluxacion reduce__contentx1 text-align-center">
                {object.text_top_en}
              </p>
            ) : (
              <p className="description__item__subluxacion reduce__contentx1 text-align-center">
                {object.text_top_cat}
              </p>
            )}
          </Grid>
        </Grid>
      </Container>

      <Container fixed className="basic__padding">
        <Grid
          container
          justifyContent="left"
          alignItems="start"
          direction="row"
          xs={12}
        >
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            xs={12}
            md={6}
            className="basic__padding padding__customer"
          >
            <Container>
              <Swiper
                modules={[Autoplay, Navigation]}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: true,
                }}
                navigation={true}
                loop={true}
                autoHeight={true}
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                className="swiper__center"
              >
                {object.carrusel_fotos.map((foto) => {
                  return (
                    <SwiperSlide>
                      <img
                        src={`${process.env.REACT_APP_FRONTEND}assets/image/${foto}`}
                        alt="phot item"
                        className="img__items__subluxation"
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Container>
          </Grid>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            xs={12}
            md={6}
            className="basic__padding padding__customer reduce__contentx1"
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="row"
              xs={12}
              className="content__description__item"
            >
              <p className="description__item__center text-align-center">
                <span style={{ fontWeight: "400" }}>
                  {language === "es"
                    ? "PEDIR CITA"
                    : language === "en"
                    ? "CONTACT"
                    : "DEMANAR CITA"}
                </span>
                <br />
                <br />
                {object.tel1}
                <br />
                {object.tel2}
                <br />
                {object.mail}
              </p>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="row"
              xs={12}
              className="content__description__item"
            >
              <p className="description__item__center text-align-center">
                <span style={{ fontWeight: "400" }}>
                  {language === "es"
                    ? "HORARIOS"
                    : language === "en"
                    ? "SCHEDULE"
                    : "HORARIS"}
                </span>
                {language === "es" ? (
                  <>
                    <br />
                    <br />
                    {object.horario_es.map((hora) => (
                      <span>
                        {hora}
                        <br />
                      </span>
                    ))}
                  </>
                ) : language === "en" ? (
                  <>
                    <br />
                    <br />
                    {object.horario_en.map((hora) => (
                      <span>
                        {hora}
                        <br />
                      </span>
                    ))}
                  </>
                ) : (
                  <>
                    <br />
                    <br />
                    {object.horario_cat.map((hora) => (
                      <span>
                        {hora}
                        <br />
                      </span>
                    ))}
                  </>
                )}
              </p>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="row"
              xs={12}
              className="content__description__item"
            >
              <p className="description__item__center text-align-center">
                <span style={{ fontWeight: "400" }}>
                  {language === "es"
                    ? "DIRECCIÓN"
                    : language === "en"
                    ? "ADDRESS"
                    : "DIRECCIÓN"}
                </span>
                <br />
                <br />
                {object.direccion}
              </p>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="row"
              xs={12}
              className="content__description__item"
            >
              <Link
                className="btn__item__contact__chiropractic"
                to="/contact"
              >
                <button>
                  {language === "es"
                    ? `Contactar ahora`
                    : language === "en"
                    ? `Contact now`
                    : `Contactar ara`}
                </button>
              </Link>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            xs={12}
            md={6}
            className="basic__padding padding__customer reduce__contentx1"
          >
            <p className="description__item__center">
              <br/>
              <b>{language === "es"
                ? object.title_texto_es
                : language === "en"
                ? object.title_texto_en
                : object.title_texto_cat}</b>
              <br/>
              <br/>
              {language === "es"
                ? object.texto_quiro1_es
                : language === "en"
                ? object.texto_quiro1_en
                : object.texto_quiro1_cat}
              <br />
              <br />
              {language === "es"
                ? object.texto_quiro2_es
                : language === "en"
                ? object.texto_quiro2_en
                : object.texto_quiro2_cat}
            </p>
          </Grid>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            xs={12}
            md={6}
            className="basic__padding padding__customer"
          >
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              direction="row"
              xs={12}
            >
              <MapContact marker={object.marker} info={object.info} />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Center;
