import React, { useContext, useEffect } from "react";
import {
  url_img,
  url_img_2,
  url_img_3,
  icon_chiropractic,
  description_chiropractic,
  description_chiropractic_español,
  description_chiropractic_catalan,
  text_btn_chiropractic,
  text_btn_chiropractic_español,
  text_btn_chiropractic_catalan,
  link_chiropractic,
  icon_solutions,
  description_solutions,
  description_solutions_español,
  description_solutions_catalan,
  text_btn_solutions,
  text_btn_solutions_español,
  text_btn_solutions_catalan,
  link_solutions,
  icon_centers,
  description_centers,
  description_centers_español,
  description_centers_catalan,
  text_btn_centers,
  text_btn_centers_español,
  text_btn_centers_catalan,
  link_centers,
} from "./const";
import goToTop from "../../hooks/useTop";
import { UserContext } from "../../context/UserContext";
import FormContact from "../Contacto/FormContact";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import ItemByIcon from "../../components/shared/ItemByIcon";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../context/LanguageContext";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const HomeView = () => {
  const { language } = useContext(LanguageContext);
  const { setMenuActive, setSubmenuActive } = useContext(UserContext);
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    setMenuActive(0);
    setSubmenuActive(0);
    goToTop();
  }, [setMenuActive, setSubmenuActive]);

  const handleOpen = () => setOpen(!open);

  return (
    <>
      <div className={`banner_container_carrousel`}>
        <Swiper
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="banner_container_carousel"
        >
          <SwiperSlide>
            <img
              className="slide_carousel"
              src={`${process.env.REACT_APP_FRONTEND}assets/image/${url_img}`}
              alt="phot item"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="slide_carousel"
              src={`${process.env.REACT_APP_FRONTEND}assets/image/${url_img_2}`}
              alt="phot item"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="slide_carousel"
              src={`${process.env.REACT_APP_FRONTEND}assets/image/${url_img_3}`}
              alt="phot item"
            />
          </SwiperSlide>
        </Swiper>
        <Container
          fixed
          className="hidden_mobile"
          style={{ position: "absolute", margin: "2% 0 0 4%" }}
        >
          <h1 className="text-banner-home">
            {language === "es"
              ? "Cuidándote desde "
              : language === "en"
              ? "Taking care of you"
              : "Cuidant de tu des"}
            <br />
            {language === "es"
              ? "el 2012"
              : language === "en"
              ? "since 2012"
              : "de 2012"}
          </h1>
          <br />
          <div className="content__btn__banner">
            <a className="content__btn__reserva" href="#section__form">
              <p>
                {language === "es"
                  ? "Solicita tu primera visita"
                  : language === "en"
                  ? "Request your first visit"
                  : "Sol·licita la teva primera visita"}
              </p>
            </a>
          </div>
        </Container>
      </div>
      {/* SECTION BAJADA */}
      <Container fixed className="basic__padding">
        <Grid
          className="reduce__contentx2"
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
          xs={12}
        >
          <h4 className="text__content__blue">
            {language === "es"
              ? `LinQ es un grupo de centros quiroprácticos, situados principalmente en Barcelona, con un equipo de profesionales altamente cualificados y una gran experiencia en el mundo de la salud y la quiropráctica.`
              : language === "en"
              ? `LinQ Chiropractor BarcelonaLinQ is a group of chiropractic centers,
            located mainly in Barcelona, with a team of highly qualified
            professionals and a great experience in the world of health and
            chiropractic.`
              : `LinQ és un grup de centres quiropràctics, situats principalment a Barcelona, amb un equip de professionals altament qualificats i una gran experiència en el món de la salut i la quiropràctica.`}
          </h4>
        </Grid>
      </Container>
      {/* SECTION ITEMS */}
      <Container fixed className="basic__padding">
        <Grid container xs={12}>
          <Grid container xs={12} md={4}>
            <ItemByIcon
              icon={icon_chiropractic}
              description={
                language === "es"
                  ? description_chiropractic_español
                  : language === "en"
                  ? description_chiropractic
                  : description_chiropractic_catalan
              }
              text_btn={
                language === "es"
                  ? text_btn_chiropractic_español
                  : language === "en"
                  ? text_btn_chiropractic
                  : text_btn_chiropractic_catalan
              }
              link={link_chiropractic}
            />
          </Grid>
          <Grid container xs={12} md={4}>
            <ItemByIcon
              icon={icon_solutions}
              description={
                language === "es"
                  ? description_solutions_español
                  : language === "en"
                  ? description_solutions
                  : description_solutions_catalan
              }
              text_btn={
                language === "es"
                  ? text_btn_solutions_español
                  : language === "en"
                  ? text_btn_solutions
                  : text_btn_solutions_catalan
              }
              link={link_solutions}
            />
          </Grid>
          <Grid container xs={12} md={4}>
            <ItemByIcon
              icon={icon_centers}
              description={
                language === "es"
                  ? description_centers_español
                  : language === "en"
                  ? description_centers
                  : description_centers_catalan
              }
              text_btn={
                language === "es"
                  ? text_btn_centers_español
                  : language === "en"
                  ? text_btn_centers
                  : text_btn_centers_catalan
              }
              link={link_centers}
              isCenter={true}
              eventClick={() => handleOpen()}
            />
          </Grid>
        </Grid>
      </Container>
      {/* SECTION CHOOSE */}
      <section className="basic__padding background__sky__blue">
        <Container fixed className="">
          <Grid
            container
            justifyContent="right"
            alignItems="center"
            direction="row"
            xs={12}
            className="line__right__blue"
          >
            {language === "es" ? (
              <h1 className="choose__title">
                ¿Por qué elegir nuestro
                <br />
                centro quiropráctico
                <br />
                en Barcelona y alrededores?
              </h1>
            ) : language === "en" ? (
              <h1 className="choose__title">
                Why choose
                <br />
                our chiropractic
                <br /> centers?
              </h1>
            ) : (
              <h1 className="choose__title">
                Per què triar
                <br />
                els nostres centres
                <br />
                quiropràctics?
              </h1>
            )}
          </Grid>
          <Grid container xs={12}>
            <Grid container xs={12} md={4}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="row"
                xs={12}
                className="item__padding content__icon__item"
              >
                <img
                  src={`${process.env.REACT_APP_FRONTEND}assets/image/choose_1.png`}
                  alt="phot item"
                  className="image__item__choose"
                />
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="row"
                xs={12}
                className="item__padding content__description__item"
              >
                {language === "es" ? (
                  <p className="description__item__photo reduce__contentx2">
                    <b>
                      En nuestros centros ofrecemos una primera visita muy
                      completa para evaluar cada caso a fondo,
                    </b>{" "}
                    lo que nos permite adaptar el tratamiento quiropráctico a
                    las necesidades específicas de cada persona y conseguir un
                    progreso más rápido y efectivo.
                  </p>
                ) : language === "en" ? (
                  <p className="description__item__photo reduce__contentx2">
                    <b>
                      In our centers we offer a very complete first visit to
                      evaluate each case thoroughly,
                    </b>{" "}
                    which allows us to adapt the chiropractic treatment to the
                    specific needs of each person and achieve faster and more
                    effective progress.
                  </p>
                ) : (
                  <p className="description__item__photo reduce__contentx2">
                    <b>
                      Als nostres centres oferim una primera visita molt
                      completa per avaluar cada cas a fons,
                    </b>{" "}
                    la qual cosa ens permet adaptar el tractament quiropràctic a
                    les necessitats específiques de cada persona i aconseguir un
                    progrés més ràpid i efectiu.
                  </p>
                )}
              </Grid>
            </Grid>
            <Grid container xs={12} md={4}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="row"
                xs={12}
                className="item__padding content__icon__item"
              >
                <img
                  src={`${process.env.REACT_APP_FRONTEND}assets/image/choose_2.png`}
                  alt="phot item"
                  className="image__item__choose"
                />
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="row"
                xs={12}
                className="item__padding content__description__item"
              >
                {language === "es" ? (
                  <p className="description__item__photo reduce__contentx2">
                    <b>Acompañamos a los clientes en todo el proceso,</b>{" "}
                    dándoles recomendaciones de fácil aplicación en su vida
                    diaria como consejos sobre higiene postural o ejercicios
                    específicos, entre otras.
                  </p>
                ) : language === "en" ? (
                  <p className="description__item__photo reduce__contentx2">
                    <b>We accompany clients throughout the process,</b> giving
                    them recommendations that are easy to apply in their daily
                    lives, such as advice on postural hygiene or specific
                    exercises, among others.
                  </p>
                ) : (
                  <p className="description__item__photo reduce__contentx2">
                    <b>Acompanyem els clients en tot el procés,</b> donant-los
                    recomanacions de fàcil aplicació en la seva vida diària com
                    ara consells sobre higiene postural o exercicis específics,
                    entre d’altres.
                  </p>
                )}
              </Grid>
            </Grid>
            <Grid container xs={12} md={4}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="row"
                xs={12}
                className="item__padding content__icon__item"
              >
                <img
                  src={`${process.env.REACT_APP_FRONTEND}assets/image/choose_3.png`}
                  alt="phot item"
                  className="image__item__choose"
                />
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="row"
                xs={12}
                className="item__padding content__description__item"
              >
                {language === "es" ? (
                  <p className="description__item__photo reduce__contentx2">
                    A menudo las personas llegan a nuestros centros frustradas
                    porque ningún tratamiento previo les ha ayudado y, con los
                    ajustes quiroprácticos, poco a poco sus vidas van
                    transformándose:{" "}
                    <b>
                      dejan atrás el dolor, y recuperan la energía y vitalidad
                      que habían perdido.
                    </b>{" "}
                  </p>
                ) : language === "en" ? (
                  <p className="description__item__photo reduce__contentx2">
                    Often people come to our centers frustrated because no
                    previous treatment has helped them and, with chiropractic
                    adjustments, little by little their lives are transformed:{" "}
                    <b>
                      they leave the pain behind, and regain the energy and
                      vitality they had lost.
                    </b>{" "}
                  </p>
                ) : (
                  <p className="description__item__photo reduce__contentx2">
                    Sovint les persones arriben als nostres centres frustrades
                    perquè cap tractament previ els ha ajudat I, amb els ajustos
                    quiropràctics, a poc a poc les seves vides es van
                    transformant:{" "}
                    <b>
                      deixen enrere el dolor, i recuperen l’energia i vitalitat
                      que havien perdut.
                    </b>{" "}
                  </p>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </section>

      {/* SECTION PHASES */}
      <Container fixed className="basic__padding">
        <Grid
          container
          justifyContent="left"
          alignItems="center"
          direction="row"
          xs={12}
          className="line__left__blue"
        >
          {language === "es" ? (
            <h1 className="phases__title">
              Fases del cuidado
              <br />
              quiropráctico
            </h1>
          ) : language === "en" ? (
            <h1 className="phases__title">
              Phases
              <br />
              of chiropractic treatment
            </h1>
          ) : (
            <h1 className="phases__title">
              Fases del tractament
              <br />
              quiropràctic
            </h1>
          )}
        </Grid>
        <Grid container xs={12}>
          <Grid container xs={12} md={6}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="row"
              xs={12}
              className="item__padding content__icon__item"
            >
              <img
                src={`${process.env.REACT_APP_FRONTEND}assets/image/phases_1.jpg`}
                alt="phot item"
                className="image__item__phases"
              />
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              direction="row"
              xs={12}
              className="item__padding content__description__item reduce__contentx2"
            >
              {language === "es" ? (
                <>
                  <p className="title__primary">Fase 1: Correctiva</p>
                  <p className="description__item__photo">
                    En esta primera fase se recomienda una mayor frecuencia de
                    visitas para conseguir un efecto más notorio. El objetivo es
                    recuperar el buen funcionamiento de la columna y liberar la
                    presión sobre el sistema nervioso. Poco a poco la persona
                    empieza a notar cambios, como una disminución del dolor,
                    mayor movilidad, menor tensión muscular y más bienestar en
                    general.
                  </p>
                </>
              ) : language === "en" ? (
                <>
                  <p className="title__primary">Phase 1: Corrective</p>
                  <p className="description__item__photo">
                    In this first phase, a higher frequency of visits is
                    recommended to achieve a quick and effective solution. The
                    objective is to recover the proper functioning of the spine
                    and release the pressure on the nervous system. Gradually
                    the person begins to notice changes, such as a decrease in
                    pain, greater mobility, less muscle tension and more general
                    well-being.
                  </p>
                </>
              ) : (
                <>
                  <p className="title__primary">Fase 1: Correctiva</p>
                  <p className="description__item__photo">
                    En aquesta primera fase es recomana una freqüència de
                    visites més gran per aconseguir una solució ràpida i
                    efectiva. L’objectiu és recuperar el bon funcionament de la
                    columna i alliberar la pressió sobre el sistema nerviós. De
                    mica en mica la persona comença a notar canvis, com ara una
                    disminució del dolor, més mobilitat, menys tensió muscular i
                    més benestar en general.
                  </p>
                </>
              )}
            </Grid>
          </Grid>
          <Grid container xs={12} md={6}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="row"
              xs={12}
              className="item__padding content__icon__item"
            >
              <img
                src={`${process.env.REACT_APP_FRONTEND}assets/image/phases_2.jpg`}
                alt="phot item"
                className="image__item__phases"
              />
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              direction="row"
              xs={12}
              className="item__padding content__description__item reduce__contentx2"
            >
              {language === "es" ? (
                <>
                  <p className="title__primary">Fase 2: Mantenimiento</p>
                  <p className="description__item__photo">
                    El objetivo de esta segunda fase es afianzar los resultados
                    obtenidos y mantener la mejoría de la columna y del cuerpo
                    en general, con lo cual se pueden espaciar progresivamente
                    las visitas. ¡Si queremos mantener lo conseguido, será
                    importante aplicar los hábitos aprendidos y realizar una
                    revisión quiropráctica cada cierto tiempo!
                  </p>
                </>
              ) : language === "en" ? (
                <>
                  <p className="title__primary">Phase 2: Maintenance</p>
                  <p className="description__item__photo">
                    The objective of this second phase is to consolidate the
                    results obtained and maintain the improvement in the health
                    of the spine and the body in general, so that visits can be
                    progressively spaced out. It is important to continue with
                    chiropractic care to preserve what has been achieved and
                    incorporate it as a health routine!
                  </p>
                </>
              ) : (
                <>
                  <p className="title__primary">Fase 2: Manteniment</p>
                  <p className="description__item__photo">
                    L’objectiu d’aquesta segona fase és refermar els resultats
                    obtinguts i mantenir la millora en la salut de la columna i
                    del cos en general, amb la qual cosa es poden espaiar
                    progressivament les visites. És important continuar el
                    tractament quiropràctic per conservar el que s’ha aconseguit
                    i incorporar-lo com una rutina de salut!
                  </p>
                </>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="row"
            xs={12}
            style={{ marginTop: "-30px" }}
          >
            <a className="btn__item__contact" href="#section__form">
              <button>
                {language === "es"
                  ? "Contacta para más información"
                  : language === "en"
                  ? "Contact us for more information"
                  : "Contacta per a més informació"}
              </button>
            </a>
          </Grid>
        </Grid>
      </Container>

      {/* SECTION CUSTOMERS */}
      <section className="background__sky__blue">
        <Grid
          container
          justifyContent="left"
          alignItems="center"
          direction="row"
          xs={12}
        >
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            direction="row"
            xs={12}
            md={5}
            style={{
              backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/banner_customer.jpg)`,
            }}
            className="banner_customer"
          ></Grid>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            xs={12}
            md={7}
            className="basic__padding padding__customer"
          >
            <div className="line__right__blue">
              {language === "es" ? (
                <>
                  <h1 className="choose__title ">
                    Testimonios de
                    <br />
                    Nuestros clientes{" "}
                  </h1>
                  <p
                    className="description__item__photo text__align__right reduce__content__left"
                    style={{ marginBottom: "0px!important" }}
                  >
                    A menudo nuestros clientes nos comentan lo agradecidos que
                    están, porque se sienten realmente escuchados, y reciben un
                    tratamiento natural y no invasivo que va a la raíz de su
                    problema y es efectivo.{" "}
                    <b>
                      Suelen decir: “¡Ojalá hubiera venido antes!” o “La
                      quiropráctica ha cambiado mi vida” o “Yo recomiendo la
                      quiropráctica a todo el mundo”.
                    </b>
                    <br />
                    <br />
                    Además, se sorprenden de algunos beneficios de la
                    quiropráctica que no esperaban cuando llegaron al centro
                    pues, aparte de notar menos dolor o más movilidad y
                    flexibilidad, muchos nos explican que duermen mejor o
                    gestionan mejor el estrés de la vida diaria o tienen mejores
                    digestiones o se resfrían menos… Y, en general, nos comentan
                    que toman más conciencia de su cuerpo y su salud, de manera
                    que empiezan a cuidarse más, vigilando sus posturas,
                    haciendo más ejercicio o incluso cambiando su alimentación.
                  </p>
                </>
              ) : language === "en" ? (
                <>
                  <h1 className="choose__title ">
                    What our
                    <br />
                    customers say
                  </h1>
                  <p
                    className="description__item__photo text__align__right reduce__content__left"
                    style={{ marginBottom: "0px!important" }}
                  >
                    Often our clients tell us how grateful they are, because
                    they really feel listened to, and they receive a natural,
                    non-invasive treatment that gets to the root of their
                    problem and is effective. They often say,{" "}
                    <b>
                      “I wish I had come here sooner!” or “Chiropractic has
                      changed my life” or “I recommend chiropractic to
                      everyone.”
                    </b>
                    <br />
                    <br />
                    In addition, they are surprised by some benefits of
                    chiropractic that they did not expect when they came to the
                    center because, apart from noticing less pain or more
                    mobility and flexibility, many explain that they sleep
                    better or better manage the stress of daily life or have
                    better digestion or get colds less …
                  </p>
                </>
              ) : (
                <>
                  <h1 className="choose__title ">
                    Què opinen
                    <br />
                    els nostres clients{" "}
                  </h1>
                  <p
                    className="description__item__photo text__align__right reduce__content__left"
                    style={{ marginBottom: "0px!important" }}
                  >
                    Sovint els nostres clients ens comenten que estan molt
                    agraïts perquè se senten realment escoltats, i reben un
                    tractament natural i no invasiu que va a l’arrel del seu
                    problema i és efectiu. Solen dir:{" "}
                    <b>
                      “Tant de bo hagués vingut abans!” o “La quiropràctica ha
                      canviat la meva vida” o “Jo recomano la quiropràctica a
                      tothom”.
                    </b>
                    <br />
                    <br />A més, se sorprenen d’alguns beneficis de la
                    quiropràctica que no esperaven quan van arribar al centre
                    perquè, a part de notar menys dolor o més mobilitat i
                    flexibilitat, molts ens expliquen que dormen millor o
                    gestionen millor l’estrès de la vida diària o tenen més
                    bones digestions o es constipen menys… I, en general, ens
                    comenten que prenen més consciència del seu cos i de la seva
                    salut, de manera que comencen a cuidar-se més, vigilant les
                    seves postures, fent més exercici o fins I tot canviant la
                    seva alimentació.
                  </p>
                </>
              )}
            </div>
            <br />

            {language === "es" ? (
              <p className="description__item__photo text__align__right content__description__customer reduce__content__left">
                Así, su calidad de vida mejora notablemente, y solo hay que ver
                el cambio en su estado de ánimo y su sonrisa al cabo de varias
                sesiones quiroprácticas.
                <br />
                <br />
                También nos encanta ver que cada vez más clientes deciden seguir
                disfrutando de los beneficios de la quiropráctica con un plan de
                mantenimiento, y algunos llevan años viniendo a nuestros centros
                porque, según nos aseguran, con el cuidado quiropráctico se
                sienten mucho mejor.
              </p>
            ) : language === "en" ? (
              <p className="description__item__photo text__align__right content__description__customer reduce__content__left">
                And, in general, they tell us that they become more aware of
                their body and their health, so they begin to take more care of
                themselves, watching their posture, exercising more or even
                changing their diet. Thus, their quality of life improves
                noticeably after a short time, and you only have to see the
                change in their mood and smile after several chiropractic
                sessions.
                <br />
                <br />
                We are also delighted to see that more and more clients are
                choosing to continue to enjoy the benefits of chiropractic with
                a maintenance plan, and some have been coming to our centers for
                years because, they tell us, they feel so much better with
                chiropractic care.
              </p>
            ) : (
              <p className="description__item__photo text__align__right content__description__customer reduce__content__left">
                Així, la seva qualitat de vida millora notablement al cap de
                poc, i només cal veure el canvi en el seu estat d’ànim i el seu
                somriure després d’unes quantes sessions quiropràctiques.
                <br />
                <br />
                També ens encanta veure que cada vegada més clients decideixen
                continuar gaudint dels beneficis de la quiropràctica amb un pla
                de manteniment, i alguns fa anys que venen als nostres centres
                perquè, segons ens asseguren, amb el tractament quiropràctic se
                senten molt millor.
              </p>
            )}

            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              direction="row"
              xs={12}
              className="item__padding"
            >
              <Link className="btn__item__contact" to="/solutions">
                <button>
                  {language === "es"
                    ? "Conoce más beneficios"
                    : language === "en"
                    ? "Discover how chiropractic helps you"
                    : "Contacta per a més informació"}
                </button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </section>

      {/* SECTION PHILOSOPHY */}
      <Container fixed className="basic__padding">
        <Grid
          container
          justifyContent="left"
          alignItems="center"
          direction="row"
          xs={12}
          className="line__left__blue"
        >
          {language === "es" ? (
            <h1 className="phases__title">
              Filosofía de nuestros
              <br />
              centros quiroprácticos <br />
              en Barcelona y alrededores{" "}
            </h1>
          ) : language === "en" ? (
            <h1 className="phases__title">
              Philosophy
              <br />
              of our chiropractic centers:
              <br />
              in Barcelona & Tarragona
            </h1>
          ) : (
            <h1 className="phases__title">
              Filosofia dels nostres
              <br />
              centres quiropràctics
              <br />a Barcelona i voltants{" "}
            </h1>
          )}
        </Grid>
        <Grid
          container
          justifyContent="left"
          alignItems="center"
          direction="row"
          xs={12}
          className="line__left__blue"
          style={{ marginTop: "0px" }}
        >
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            direction="row"
            xs={12}
            md={6}
            className="item__padding content__description__item"
          >
            {language === "es" ? (
              <p className="description__item__photo reduce__content__right">
                En nuestros centros potenciamos al máximo la filosofía de la
                quiropráctica, según la cual es nuestro propio cuerpo el que
                tiene la capacidad de curarse: la salud viene desde dentro.
                <br /> <br />
                Para ello es importante seguir unos hábitos saludables (buenas
                posturas, actividad física, buena alimentación y descanso) e
                incluir el cuidado quiropráctico en nuestra rutina, ya que éste
                equilibra el sistema nervioso (el responsable de que todo el
                cuerpo funcione en armonía).
              </p>
            ) : language === "en" ? (
              <p className="description__item__photo reduce__content__right">
                In our centers we maximize the philosophy of chiropractic,
                according to which it is our own body that has the ability to
                heal itself: health comes from within.
                <br /> <br />
                For this it is important to follow healthy habits (good posture,
                physical activity, good nutrition and rest) and include
                chiropractic care in our routine, as it balances the nervous
                system (responsible for the whole body to function in harmony).
              </p>
            ) : (
              <p className="description__item__photo reduce__content__right">
                En els nostres centres potenciem al màxim la filosofia de la
                quiropràctica, segons la qual és el nostre propi cos el que té
                la capacitat de curar-se: la salut ve des de dins.
                <br /> <br />
                Per a això és important seguir uns hàbits saludables (bones
                postures, activitat física, bona alimentació i descans) i
                incloure el tractament quiropràctic en la nostra rutina, ja que
                aquest equilibra el sistema nerviós (el responsable que tot el
                cos funcioni en harmonia).
              </p>
            )}
          </Grid>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            direction="row"
            xs={12}
            md={6}
            className="item__padding content__description__item"
          >
            {language === "es" ? (
              <p className="description__item__photo reduce__content__left">
                Nos caracterizamos por poner todos nuestros recursos y
                conocimientos para que la persona no solo se sienta mejor, sino
                que consiga <b>realizar un cambio en su vida</b> que le lleve a
                reforzar su confianza en el gran potencial que tiene el cuerpo
                cuando el cuidado se realiza correctamente.
              </p>
            ) : language === "en" ? (
              <p className="description__item__photo reduce__content__left">
                We are characterized by putting all our resources and knowledge
                to make the person not only feel better, but to achieve a{" "}
                <b>change in your life</b> that leads to strengthen their
                confidence in the great potential of the body when the care is
                done correctly.
              </p>
            ) : (
              <p className="description__item__photo reduce__content__left">
                Ens caracteritzem per posar tots els nostres recursos i
                coneixements perquè la persona no solament es trobi millor, sinó
                que aconsegueixi fer un <b>canvi en la seva vida</b> que la
                porti a reforçar la seva confiança en el gran potencial que té
                el cos quan el tractament es fa de forma correcta.
              </p>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          direction="row"
          xs={12}
          className="item__padding"
        >
          <div className="btn__item__contact" onClick={handleOpen}>
            <button>
              {" "}
              {language === "es"
                ? "Encontrar mi centro"
                : language === "en"
                ? "Find my center"
                : "Trobar el meu centre"}
            </button>
          </div>
        </Grid>
      </Container>

      {/* SECTION QUESTIONS */}
      <Container fixed className="basic__padding">
        <Grid
          className="reduce__contentx1"
          container
          justifyContent="left"
          alignItems="center"
          direction="row"
          xs={12}
        >
          <h1 className="title__question">
            {language === "es"
              ? "Preguntas frecuentes"
              : language === "en"
              ? "Frequently Asked Questions about chiropractic"
              : "Preguntas frecuentes"}
          </h1>
        </Grid>
        {language === "es" ? (
          <Grid className="reduce__contentx1">
            <Accordion className="item__question">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p className="description__question">
                  ¿Para quién está indicada la Quiropráctica?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description__question">
                  Para todos aquellos que tengan columna vertebral y quieran
                  recuperar o mantener su calidad de vida. Es decir, a cualquier
                  persona incluyendo a recién nacidos, mujeres embarazadas,
                  deportistas o abuelos. A todos ellos la Quiropráctica les
                  puede ayudar a vivir mejor.
                  <br /> Si tienes molestias y buscas una solución, o bien no
                  tienes ninguna pero quieres potenciar tu bienestar y prevenir
                  futuros problemas, la Quiropráctica es para ti.
                  <br />
                  <br />
                  Encuentra tu quiropráctico en Barcelona. Llama para pedir tu
                  primera cita en cualquiera de nuestros centros quiroprácticos
                  en Barcelona centro y El Masnou. O bien, si estás por la zona
                  de Tarragona, no dudes en llamar a nuestro centro
                  quiropráctico en Tarragona (situado en la localidad de El
                  Vendrell).
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion className="item__question">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <p className="description__question">
                  ¿Qué preparación tiene un Quiropráctico?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description__question">
                  La carrera de Quiropráctica puede estudiarse en España desde
                  el 2007. Actualmente existen unos 350 quiroprácticos en toda
                  España. Sin embargo, la Quiropráctica es la profesión
                  sanitaria natural más difundida en el mundo. Está establecida
                  en más de 70 países y cuenta con un colectivo de
                  aproximadamente 90.000 profesionales y un gran reconocimiento
                  internacional, especialmente en EEUU y los países
                  anglosajones. Si estás pensando en estudiar Quiropráctica, te
                  recomendamos en Barcelona el Barcelona Chiropractic College
                  (BCC) y, en Madrid el Madrid Chiropractic College (MCC).
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion className="item__question">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <p className="description__question">
                  ¿La Quiropráctica es segura?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description__question">
                  Sí, es segura. Tanto que cada semana pasan por nuestros
                  centros desde recién nacidos hasta personas de 99 años.
                  <br />
                  Si tienes dudas, te recomendamos que visites nuestra página de
                  nuestros{" "}
                  <Link to="/solutions" className="text-link">
                    <b>Clientes</b>
                  </Link>
                  .
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion className="item__question">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <p className="description__question">¿El ajuste duele?</p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description__question">
                  No, todo lo contrario. La corrección es suave y agradable. Da
                  una sensación de relajación y bienestar que puede durar varias
                  horas después de haberlo realizado.
                  <br />
                  <br />
                  La Quiropráctica nos ayuda a funcionar mejor en todos los
                  sentidos. La gran mayoría de sus usuarios se sienten mejor, se
                  recuperan más rápido de sus problemas y minimizan el uso de
                  medicamentos y cirugías. Muchos de los que utilizan la
                  Quiropráctica notan que gestionan mejor el estrés, tienen más
                  vitalidad y disfrutan más de su día a día.
                  <br />
                  <Link to="/the-chiropractic" className="text-link">
                    <b>Conocer más información</b>
                  </Link>
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion className="item__question">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <p className="description__question">
                  ¿Para qué sirve la Quiropráctica?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description__question">
                  La quiropráctica nos ayuda a funcionar mejor en todos los
                  aspectos. La gran mayoría de sus usuarios se sienten mejor, se
                  recuperan más rápido y minimizan el uso de medicamentos y
                  cirugías. Muchos de los que utilizan la quiropráctica notan
                  que manejan mejor el estrés, tienen más vitalidad y disfrutan
                  más de su día a día.
                  <br />
                  <Link to="/the-chiropractic" className="text-link">
                    <b>Más información</b>
                  </Link>
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion className="item__question">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <p className="description__question">
                  ¿Ayuda en la escoliosis?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description__question">
                  La escoliosis se produce cuando la columna vertebral se curva
                  hacia fuera y hacia el lado. La escoliosis puede o no provocar
                  dolor, pero los efectos negativos que este tipo de curvatura
                  puede ocasionar sobre nuestro sistema nervioso y salud en
                  general son muy importantes.
                  <br />
                  Por eso es indispensable acudir a un Quiropráctico cuando
                  alguien tiene escoliosis.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion className="item__question">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <p className="description__question">
                  ¿Qué se hace en la primera visita?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description__question">
                  En la primera visita haremos una serie de pruebas para ver si
                  la Quiropráctica te puede ayudar. Para más información,
                  <b>
                    {" "}
                    solicita tu Primera Visita haciendo{" "}
                    <Link to="/contact" className="text-link">
                      click aquí
                    </Link>
                    .
                  </b>
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion className="item__question">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <p className="description__question">
                  ¿Hay que desvestirse en cada visita?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description__question">
                  No, sólo es necesario quitarse la chaqueta y aquellos objetos
                  de los bolsillos que le puedan incomodar.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion className="item__question">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <p className="description__question">¿Atendéis a niños?</p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description__question">
                  Cada vez un número mayor de familias se da cuenta de las
                  ventajas de la quiropráctica y los exámenes de la columna
                  vertebral de los más pequeños.
                  <br />
                  <br />
                  El modelo quiropráctico para niños ofrece muchos beneficios.
                  <br />- Menor uso de antibióticos a lo largo de los años
                  <br />- Menos alergias y menos problemas con las amígdalas
                  <br />- Menos infecciones de oído
                  <br />- Menos irritabilidad
                  <br />- Dormir mejor
                  <br />
                  <br />
                  Puedes tener más información sobre{" "}
                  <Link to="/solutions" className="text-link">
                    <b>Quiropráctica Infantil aquí.</b>
                  </Link>
                </p>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ) : language === "en" ? (
          <Grid>
            <Accordion className="item__question">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p className="description__question">
                  Who is Chiropractic for?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description__question">
                  For all those who have a spinal column and want to recover or
                  maintain their quality of life. That is, anyone including
                  newborns, pregnant women, athletes or grandparents.
                  Chiropractic can help all of them to live better.
                  <br /> If you have discomfort and are looking for a solution,
                  or if you have none but want to enhance your well-being and
                  prevent future problems, Chiropractic is for you. Find your
                  chiropractor in Barcelona. Call to{" "}
                  <Link to="/contact" className="text-link">
                    <b>make your first appointment</b>
                  </Link>{" "}
                  at any of our LinQ centers. Or if you are in the area of
                  Tarragona, do not hesitate to call our chiropractic center in
                  Tarragona located in the town of El Vendrell).
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion className="item__question">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <p className="description__question">
                  Why is Chiropractic so little known in Spain?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description__question">
                  The chiropractic career can be studied in Spain since 2007.
                  For this reason it is not yet well known, as there are
                  currently only 250 chiropractors in the whole country.
                  However, Chiropractic is the most widespread natural health
                  profession in the world. It is established in more than 70
                  countries and has a collective of approximately 90,000
                  professionals and a great international recognition,
                  especially in the U.S. and Anglo-Saxon countries.
                  <br /> If you are thinking of studying chiropractic, we
                  recommend you to visit, in Barcelona, the Barcelona
                  Chiropractic College (BCC) and, in Madrid, the Madrid
                  Chiropractic College (MCC)
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion className="item__question">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <p className="description__question">
                  Is it safe to go to a chiropractor?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description__question">
                  Yes, very safe. So much so that every week, from newborns to
                  99 year olds pass through our centers. Do you think a mother
                  would bring her children if there was any danger? If you have
                  any doubts, we recommend you to visit our TESTIMONIALS page of
                  people who have visited our centers.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion className="item__question">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <p className="description__question">
                  Is the visit to the chiropractor painful?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description__question">
                  No, quite the contrary. The correction is gentle and pleasant.
                  It gives a feeling of relaxation and well-being that can last
                  for several hours after it has been performed.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion className="item__question">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <p className="description__question">
                  What is Chiropractic for?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description__question">
                  Chiropractic helps us to function better in every way. The
                  vast majority of its users feel better, recover faster and
                  minimize the use of medications and surgeries. Many of those
                  who use Chiropractic notice that they manage stress better,
                  have more vitality and enjoy more of their day to day.
                  <br />
                  <Link to="/the-chiropractic" className="text-link">
                    <b>More information</b>
                  </Link>
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion className="item__question">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <p className="description__question">
                  My daughter has scoliosis, can it be treated with
                  Chiropractic?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description__question">
                  Scoliosis occurs when the spine curves outward and to the
                  side. Scoliosis may or may not cause pain, but the negative
                  effects that this type of curvature can cause on our nervous
                  system and overall health are very important. That is why it
                  is essential to see a chiropractor when someone has scoliosis.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion className="item__question">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <p className="description__question">
                  What does the first visit consist of?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description__question">
                  In the first visit we will do a series of tests to see if
                  Chiropractic can help you. For more information,{" "}
                  <Link to="/contact" className="text-link">
                    <b>request your First Visit.</b>
                  </Link>{" "}
                  If you have any questions about your health, you can call any
                  of our centers and we will attend you personally.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion className="item__question">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <p className="description__question">
                  Do you have to take off your clothes to get an adjustment?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description__question">
                  No, it is only necessary to remove your jacket and those
                  objects from your pockets that may be uncomfortable.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion className="item__question">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <p className="description__question">
                  Why are there so many young children and families in your
                  centers?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description__question">
                  An increasing number of families are realizing the benefits of
                  chiropractic and spinal examinations for their young children.
                  The chiropractic model for children offers many benefits.
                  <br />- Reduced use of antibiotics over the years
                  <br />- Fewer allergies and fewer tonsil problems
                  <br />- Fewer ear infections
                  <br />
                  <Link to="/solutions" className="text-link">
                    <b>More about Chiropractic for Children</b>
                  </Link>
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion className="item__question">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <p className="description__question">
                  What is a chiropractic adjustment? Is it a massage?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description__question">
                  Adjustment is not a massage as it does not work on the muscle.
                  The adjustment is a precise, safe, quick, low-force movement
                  on misaligned vertebrae (or vertebral subluxations) that are
                  affecting the nerves. It is something that only chiropractors
                  do and they have over 150 chiropractic techniques, all of
                  which are aimed at aligning the vertebrae and decompressing
                  the nerves.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion className="item__question">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <p className="description__question">
                  Is it compatible with medications, therapies and treatments?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description__question">
                  Yes, don’t worry. Chiropractors work with safe and natural
                  techniques. We also love to work with other professionals to
                  help you optimize your health in every way.
                </p>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ) : (
          <Grid>
            <Accordion className="item__question">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p className="description__question">
                  Per a qui és la Quiropràctica?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description__question">
                  Per a tots aquells que tinguin columna vertebral i vulguin
                  recuperar o mantenir la seva qualitat de vida. És a dir,
                  qualsevol persona inclosos els nadons, les dones embarassades,
                  els esportistes o els avis. A tots ells la Quiropràctica els
                  pot ajudar a viure millor. Si tens molèsties i busques una
                  solució, o bé no tens cap molèstia però vols potenciar el teu
                  benestar i prevenir futurs problemes, la Quiropràctica és per
                  a tu. Troba el teu quiropràctic a Barcelona. Truca per a
                  demanar la teva primera cita en qualsevol dels nostres centres
                  quiropràctics a Barcelona. O bé, si estàs per la zona de
                  Tarragona, no dubtis a trucar al nostre centre quiropràctic a
                  Tarragona (situat a la localitat del Vendrell).
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion className="item__question">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <p className="description__question">
                  Per què és tan poc coneguda la Quiropràctica a Espanya?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description__question">
                  La carrera de Quiropràctica pot estudiar-se a l’Estat espanyol
                  des del 2007. Per això encara no és del tot coneguda, ja que
                  actualment només hi ha 250 quiropràctics en tot l’Estat. No
                  obstant això, la Quiropràctica és la professió sanitària
                  natural més difosa al món. Està establerta en més de 70 països
                  i compta amb un col·lectiu d’aproximadament 90.000
                  professionals I un gran reconeixement internacional,
                  especialment als EUA i als països anglosaxons.
                  <br />
                  Si estàs pensant estudiar Quiropràctica, et recomanem a
                  Barcelona el Barcelona Chiropractic College (BCC) I, a Madrid,
                  el Madrid Chiropractic College (MCC)
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion className="item__question">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <p className="description__question">
                  És segur, anar al quiropràctic?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description__question">
                  Sí, molt segur. Tant que cada setmana passen pels nostres
                  centres des de nadons fins a persones de 99 anys. Creus que
                  una mare portaria els seus fills si hi hagués cap perill? Si
                  tens dubtes, et recomanem que visitis la nostra pàgina de
                  TESTIMONIS de les persones que han visitat els nostres
                  centres.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion className="item__question">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <p className="description__question">
                  És dolorosa, la visita amb el Quiropràctic?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description__question">
                  No, al contrari. La correcció és suau i agradable. Dóna una
                  sensació de relaxació i benestar que pot durar diverses hores
                  després d’haver-se realitzat.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion className="item__question">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <p className="description__question">
                  Per a què serveix, la Quiropràctica?{" "}
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description__question">
                  La Quiropràctica ens ajuda a funcionar millor en tots els
                  sentits. La gran majoria dels seus usuaris se senten millor,
                  es recuperen més ràpid i minimitzen l’ús de medicaments I
                  cirurgies. Moltes dels qui utilitzen la Quiropràctica noten
                  que gestionen millor l’estrès, tenen més vitalitat i gaudeixen
                  més del seu dia a dia.
                  <br />
                  <Link to="/the-chiropractic" className="text-link">
                    <b>Més informació</b>
                  </Link>
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion className="item__question">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <p className="description__question">
                  La meva filla té escoliosi. Es pot tractar amb la
                  Quiropràctica?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description__question">
                  L’escoliosi es produeix quan la columna vertebral es corba cap
                  enfora i de costat. L’escoliosi pot provocar dolor o no, però
                  els efectes negatius que aquest tipus de curvatura pot
                  ocasionar sobre el nostre sistema nerviós i la salut en
                  general són molt importants. Per això és indispensable visitar
                  un Quiropràctic quan algú té escoliosi.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion className="item__question">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <p className="description__question">
                  En què consisteix, la primera visita?{" "}
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description__question">
                  En la primera visita farem una sèrie de proves per veure si la
                  Quiropràctica et pot ajudar. Per a més informació, sol·licita
                  la teva Primera Visita. Si tens alguna pregunta sobre la teva
                  salut, pots trucar a qualsevol dels nostres centres LinQ i
                  t’atendrem personalment.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion className="item__question">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <p className="description__question">
                  Cal treure’s la roba per fer-se un ajust?{" "}
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description__question">
                  No, només cal treure’s la jaqueta i aquells objectes de les
                  butxaques que et puguin molestar.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion className="item__question">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <p className="description__question">
                  Per què hi ha tants nens petits i famílies en els vostres
                  centres?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description__question">
                  Cada vegada més famílies s’adonen dels avantatges de la
                  quiropràctica i els exàmens de la columna vertebral dels més
                  petits.
                  <br />
                  El model quiropràctic per a nens ofereix molts beneficis.
                  <br />- Menys ús d’antibiòtics al llarg dels anys
                  <br />- Menys al·lèrgies i menys problemes amb les amígdales
                  <br />- Menys infeccions d’oïda
                  <br />
                  <Link to="/solutions" className="text-link">
                    <b>Més informació sobre Quiropràctica Infantil</b>
                  </Link>
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion className="item__question">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <p className="description__question">
                  En què consisteix, l’ajust quiropràctic? És un massatge?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description__question">
                  L’ajust no és un massatge, ja que no treballa sobre el múscul.
                  L’ajust és un moviment precís, segur, ràpid i de poca força
                  sobre les vèrtebres desalineades (o subluxacions vertebrals)
                  que estan afectant els nervis. És quelcom que només fan els
                  quiropràctics I hi ha més de 150 tècniques quiropràctiques,
                  totes amb l’objectiu d’alinear aquestes vèrtebres i que es
                  descomprimeixin els nervis.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion className="item__question">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <p className="description__question">
                  És compatible amb els medicaments, teràpies I tractaments?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description__question">
                  Sí, no t’amoïnis. Els quiropràctics treballem amb tècniques
                  segures i naturals. A més, ens encanta treballar al costat
                  d’altres professionals que t’ajudin a optimitzar la teva salut
                  en tots els sentits.
                </p>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
      </Container>

      <FormContact />

      {open && (
        <Modal
          open={open}
          onClose={handleOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Link to="/diputacio" className="modal-center">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Diputació
              </Typography>
            </Link>
            <br />
            <Link to="/valencia" className="modal-center">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Valencia
              </Typography>
            </Link>
            <br />
            <Link to="/vendrell" className="modal-center">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                El Vendrell
              </Typography>
            </Link>
            {/* <br />
            <Link to="/masnou" className="modal-center">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                El Masnou
              </Typography>
            </Link> */}
          </Box>
        </Modal>
      )}
    </>
  );
};

export default HomeView;
