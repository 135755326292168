import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { React, useContext, useState } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import sendForm from "../../services/form/sendForm";
import "./styles.scss";

const initialValues = {
  name_person: "",
  mail: "",
  center: "DIPUTACIÓ",
  message: "",
};

const FormContact = () => {
  const { language } = useContext(LanguageContext);
  const [values, setValues] = useState(initialValues);
  const [loader, setLoader] = useState(false);
  const [errorNombre, setErrorNombre] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [mjeErrorEmail, setMjeErrorEmail] = useState("");
  const [errorCenter, setErrorCenter] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const submitForm = (event) => {
    event.preventDefault();
    sendForm(
      values,
      setLoader,
      setErrorNombre,
      setErrorEmail,
      setErrorCenter,
      setErrorMessage,
      setMjeErrorEmail,
      setValues,
      initialValues,
    );
  };

  return (
    <div className="section__contact" id="section__form">
      <Container className="reduce__contentx1">
        <Grid
          className="title__contact"
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
          xs={12}
        >
          <h4>
            {language === "es"
              ? "Contáctanos hoy"
              : language === "en"
              ? "Contact us"
              : "Contacta i informa’t"}
          </h4>
        </Grid>

        <Grid container spacing={2}>
          <>
            <Grid item xs={12}>
              <Box
                sx={{
                  maxWidth: "100%",
                }}
              >
                {!errorNombre && (
                  <TextField
                    fullWidth
                    variant="filled"
                    label={
                      language === "es"
                        ? "Nombre*"
                        : language === "en"
                        ? "Name*"
                        : "Nom*"
                    }
                    name="name_person"
                    onChange={handleChange}
                    value={values.name_person}
                    className="input__contact"
                  />
                )}
                {errorNombre && (
                  <TextField
                    variant="filled"
                    fullWidth
                    label={
                      language === "es"
                        ? "Nombre*"
                        : language === "en"
                        ? "Name*"
                        : "Nom*"
                    }
                    name="name_person"
                    onChange={handleChange}
                    error
                    helperText="Enter a name."
                    value={values.name_person}
                    className="input__contact"
                  />
                )}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={{
                  maxWidth: "100%",
                }}
              >
                {!errorEmail && (
                  <TextField
                    variant="filled"
                    fullWidth
                    label={
                      language === "es"
                        ? "Email*"
                        : language === "en"
                        ? "Email*"
                        : "Correu*"
                    }
                    name="mail"
                    onChange={handleChange}
                    value={values.mail}
                    className="input__contact"
                  />
                )}
                {errorEmail && (
                  <TextField
                    variant="filled"
                    fullWidth
                    label={
                      language === "es"
                        ? "Mail*"
                        : language === "en"
                        ? "Mail*"
                        : "Correu*"
                    }
                    name="mail"
                    onChange={handleChange}
                    error
                    helperText={mjeErrorEmail}
                    value={values.mail}
                    className="input__contact"
                  />
                )}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={{
                  maxWidth: "100%",
                }}
              >
                {!errorCenter && (
                  <Select
                    className="input__contact"
                    value={values.center}
                    onChange={handleChange}
                    name="center"
                    style={{ width: "100%" }}
                  >
                    <MenuItem value="DIPUTACIÓ">DIPUTACIÓ</MenuItem>
                    <MenuItem value="VALENCIA">VALENCIA</MenuItem>
                    <MenuItem value="VENDRELL">VENDRELL</MenuItem>
                    {/* <MenuItem value="EL MASNOU">EL MASNOU</MenuItem> */}
                  </Select>
                )}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={{
                  maxWidth: "100%",
                }}
              >
                {!errorMessage && (
                  <TextField
                    variant="filled"
                    fullWidth
                    multiline
                    rows={8}
                    label={
                      language === "es"
                        ? "Motivo de consulta*"
                        : language === "en"
                        ? "Reason for consultation*"
                        : "Motiu de consulta*"
                    }
                    name="message"
                    onChange={handleChange}
                    value={values.message}
                    className="input__contact"
                  />
                )}
                {errorMessage && (
                  <TextField
                    variant="filled"
                    fullWidth
                    label={
                      language === "es"
                        ? "Motivo de consulta*"
                        : language === "en"
                        ? "Reason for consultation*"
                        : "Motiu de consulta*"
                    }
                    name="message"
                    onChange={handleChange}
                    error
                    helperText="Enter a message."
                    value={values.message}
                    className="input__contact"
                  />
                )}
              </Box>
            </Grid>

            <Grid className="container__btn__contact" item xs={12} md={12}>
              <Button variant="outlined" onClick={submitForm}>
                {!loader && language === "es"
                  ? "Enviar"
                  : language === "en"
                  ? "Send"
                  : "Enviar"}
                {loader && <CircularProgress />}
              </Button>
            </Grid>
          </>
        </Grid>
      </Container>
    </div>
  );
};

export default FormContact;
