import React from "react";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";

const ItemByIcon = ({
  icon,
  description,
  text_btn,
  link,
  isCenter = false,
  eventClick = null,
}) => {
  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="row"
        xs={12}
        className="item__padding content__icon__item"
      >
        <img
          src={`${process.env.REACT_APP_FRONTEND}assets/image/${icon}`}
          alt={`Logo ${text_btn}`}
          className="icon__logo"
        />
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="row"
        xs={12}
        className="item__padding content__description__item"
      >
        <p className="description__item reduce__contentx2">{description}</p>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="row"
        xs={12}
        className="item__padding"
      >
        {isCenter ? (
          <div className="btn__item reduce__contentx4" onClick={() => eventClick()}>
            <button>{text_btn}</button>
          </div>
        ) : (
          <Link className="btn__item reduce__contentx4" to={link}>
            <button>{text_btn}</button>
          </Link>
        )}
      </Grid>
    </>
  );
};

export default ItemByIcon;
