import { postWithoutToken } from "../helpers/post";

const sendForm = async (
  values,
  setLoader,
  setErrorNombre,
  setErrorEmail,
  setErrorCenter,
  setErrorMessage,
  setMjeErrorEmail,
  setValues,
  initialValues,
) => {
  let validationName = false;
  let validationCenter = false;
  let validationMessage = false;
  let validationEmail = false;
  let validationEmailCorrect = false;
  setLoader(true);
  if (values.name_person === "") {
    setErrorNombre(true);
    validationName = false;
  } else {
    setErrorNombre(false);
    validationName = true;
  }

  if (values.mail === "") {
    setErrorEmail(true);
    setMjeErrorEmail("Ingrese un email.");
    validationEmail = false;
  } else {
    validationEmail = true;
    var expresion = /^[a-z][\w.-]+@\w[\w.-]+\.[\w.-]*[a-z][a-z]$/i;
    if (!expresion.test(values.mail)) {
      setErrorEmail(true);
      setMjeErrorEmail("Ingrese un email correcto.");
      validationEmailCorrect = false;
    } else {
      setErrorEmail(false);
      setMjeErrorEmail("");
      validationEmailCorrect = true;
    }
  }

  if (values.center === "") {
    setErrorCenter(true);
    validationCenter = false;
  } else {
    setErrorCenter(false);
    validationCenter = true;
  }

  if (values.message === "") {
    setErrorMessage(true);
    validationMessage = false;
  } else {
    setErrorMessage(false);
    validationMessage = true;
  }

  if (
    validationName &&
    validationEmail &&
    validationCenter &&
    validationMessage &&
    validationEmailCorrect
  ) {
    const response = await postWithoutToken("sendForm", values);
    if (response.data.status === 201) {
      setErrorNombre(false);
      setErrorEmail(false);
      setErrorCenter(false);
      setErrorMessage(false);
      setMjeErrorEmail("");
      setValues(initialValues);
      setLoader(false);
      return true;
    }
    // errores
    setLoader(false);
    return false;
  } else {
    setLoader(false);
    return false;
  }
};

export default sendForm;
