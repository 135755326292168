import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import React, { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";

const Footer = () => {
  const { language } = useContext(LanguageContext);
  const year = new Date().getFullYear();
  return (
    <footer>
      <div className="footer__content">
        <Container fixed>
          <Grid container spacing={2}>
            <Grid
              container
              xs={12}
              justifyContent="center"
              alignItems="center"
              direction="row"
            >
              <img
                src={`${
                  process.env.REACT_APP_FRONTEND +
                  "assets/image/logo_footer.png"
                }`}
                alt="Logo LinQ"
                className="footer__logo"
              />
            </Grid>
            <Grid container xs={12} className="container__items__footer">
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="row"
                xs={12}
                md={6}
                lg={3}
              >
                <h4>QuirolinQ</h4>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="row"
                xs={12}
                md={6}
                lg={6}
              >
                <h4>
                  <a
                    href={`${
                      process.env.REACT_APP_FRONTEND +
                      "assets/Politica_de_privacidad.pdf"
                    }`}
                    download="Politica_de_privacidad.pdf"
                    style={{color: "#fff"}}
                  >
                    {language === "es"
                      ? "Política de privacidad"
                      : language === "en"
                      ? "Privacy Policy"
                      : "Política de privacitat"}
                  </a>
                  {language === "es"
                      ? " y "
                      : language === "en"
                      ? " and "
                      : " i "}
                  <a
                    href={`${
                      process.env.REACT_APP_FRONTEND +
                      "assets/Aviso_legal.pdf"
                    }`}
                    download="Aviso_legal.pdf"
                    style={{color: "#fff"}}
                  >
                    {language === "es"
                      ? "aviso legal"
                      : language === "en"
                      ? "legal notice"
                      : "avís legal"}
                  </a>
                </h4>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="row"
                xs={12}
                md={6}
                lg={3}
              >
                <h4>
                  <img
                    src={`${
                      process.env.REACT_APP_FRONTEND + "assets/image/calls.png"
                    }`}
                    alt="Calls"
                    className="calls_logo"
                  />
                  665 602 566
                </h4>
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              justifyContent="center"
              alignItems="center"
              direction="row"
            >
              <p>
                {language === "es"
                  ? `LinQ Quiroprácticos, todos los derechos reservados, ${year}. Los precios de los servicios pueden variar dependiendo del centro al que se consulte.`
                  : language === "en"
                  ? `LinQ Chiropractors, all rights reserved, ${year}. Service prices may vary depending on the center consulted.`
                  : `LinQ Quiropràctics, tots els drets reservats, ${year}. Els preus dels serveis poden variar depenent del centre al que es consultin.`}
              </p>
            </Grid>
          </Grid>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
