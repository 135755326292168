const title = `Taking care of you since 2012`;
const title_español = `Cuidándote desde el 2012`;
const title_catalan = `Cuidant de tu des de 2012`;
const url_img = `CARRUSELHOME1.jpeg`;
const url_img_2 = `CARRUSELHOME2.JPG`;
const url_img_3 = `CARRUSELHOME3.jpeg`;
const icon_chiropractic = "icon_chiropractic.png";
const description_chiropractic =
  "We are characterized by offering a professional and, at the same time, very close and familiar treatment: our goal is to help people, whatever their age and condition, to improve their health and obtain a better quality of life in a pleasant and trustworthy environment.";
const description_chiropractic_español =
  "Nos caracterizamos por ofrecer un trato profesional y a la vez cercano y familiar: nuestro objetivo es ayudar a las personas, sea cual sea su edad y condición, a mejorar su salud y obtener una mayor calidad de vida en un entorno agradable y de confianza.";
const description_chiropractic_catalan =
  "Ens caracteritzem per oferir un tracte professional I, alhora, molt proper I familiar: el nostre objectiu és ajudar les persones, sigui quina sigui la seva edat i condició, a millorar la seva salut I a obtenir més qualitat de vida en un entorn agradable i de confiança.";
const text_btn_chiropractic = "The Chiropractic";
const text_btn_chiropractic_español = "Quiropráctica";
const text_btn_chiropractic_catalan = "Quiropráctica";
const link_chiropractic = "/the-chiropractic";

const icon_solutions = "icon_solutions.png";
const description_solutions =
  "We treat from newborns to seniors, through children, adolescents and adults, prioritizing their particular needs and looking for their well-being. Regular reviews and careful observation of the evolution of each case allow us to adapt the care plan according to the progress shown.";
const description_solutions_español =
  "Tratamos desde recién nacidos a personas mayores, pasando por niños, adolescentes y adultos, priorizando sus necesidades particulares y buscando su bienestar. Las revisiones periódicas y la observación atenta de la evolución de cada caso nos permiten adaptar el plan de cuidado según el progreso mostrado. ";
const description_solutions_catalan =
  "Tractem des de nadons a persones grans, passant per nens, adolescents I adults, tot prioritzant les seves necessitats particulars I buscant el seu benestar. Les revisions periòdiques i l’observació atenta de l’evolució de cada cas ens permeten adaptar el tractament segons el progrés mostrat. ";
const text_btn_solutions = "Solutions";
const text_btn_solutions_español = "Soluciones";
const text_btn_solutions_catalan = "Solucions";
const link_solutions = "/solutions";

const icon_centers = "icon_centers.png";
const description_centers =
  "We have more than 8 years of successful experience with entire families, pregnant women, athletes, elderly people, children… We complement chiropractic care with personalized recommendations to achieve the best results in the shortest possible time, as the active participation of clients in their own recovery is key to better outcomes.";
const description_centers_español =
  "Nos avalan más de 10 años de experiencia con éxito. Complementamos el cuidado quiropráctico con recomendaciones personalizadas para conseguir los mejores resultados en el menor tiempo posible, pues la participación activa de los clientes en su propia recuperación es clave para tener una mejor evolución.";
const description_centers_catalan =
  "Ens avalen més de 8 anys d’experiència amb èxit en famílies senceres, embarassades, esportistes, persones grans, nens… Complementem el tractament quiropràctic amb recomanacions personalitzades per aconseguir els millors resultats en el mínim de temps possible, perquè la participació activa dels clients en la seva pròpia recuperació és clau per tenir una millor evolució.";
const text_btn_centers = "Centers";
const text_btn_centers_español = "Centros";
const text_btn_centers_catalan = "Centres";
const link_centers = "/centers";
export {
  title,
  title_español,
  title_catalan,
  url_img,
  url_img_2,
  url_img_3,
  icon_chiropractic,
  description_chiropractic,
  description_chiropractic_español,
  description_chiropractic_catalan,
  text_btn_chiropractic,
  text_btn_chiropractic_español,
  text_btn_chiropractic_catalan,
  link_chiropractic,
  icon_solutions,
  description_solutions,
  description_solutions_español,
  description_solutions_catalan,
  text_btn_solutions,
  text_btn_solutions_español,
  text_btn_solutions_catalan,
  link_solutions,
  icon_centers,
  description_centers,
  description_centers_español,
  description_centers_catalan,
  text_btn_centers,
  text_btn_centers_español,
  text_btn_centers_catalan,
  link_centers,
};
