import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

const MapContact = ({ marker = null, isContact = false, info = "" }) => {
  const myIcon = L.icon({
    iconUrl: process.env.REACT_APP_FRONTEND + "assets/image/pin_map.png",
  });

  const markerContact = [
    {
      id: 1,
      latitude: 41.38459391073355,
      longitude: 2.1587167115432697,
    },
    {
      id: 2,
      latitude: 41.394885445343704,
      longitude: 2.166266798040443,
    },
    {
      id: 3,
      latitude: 41.22053439935004,
      longitude: 1.5366890845417156,
    },
    // {
    //   id: 4,
    //   latitude: 41.481148259024856,
    //   longitude: 2.315136255713664,
    // },
  ];

  return (
    <MapContainer
      center={
        isContact
          ? { lat: "41.38459391073355", lng: "2.1587167115432697" }
          : { lat: marker.latitude, lng: marker.longitude }
      }
      zoom={10}
      maxZoom={18}
      minZoom={3}
      className="leaflet-container"
    >
      <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png" />
      {isContact &&
        markerContact.map((element) => {
          return (
            <Marker
              icon={myIcon}
              position={[element.latitude, element.longitude]}
              key={element.id}
            >
              <Popup>
                <p>{info}</p>
              </Popup>
            </Marker>
          );
        })}
      {!isContact && (
        <Marker
          icon={myIcon}
          position={[marker.latitude, marker.longitude]}
          key={marker.id}
        >
          <Popup>
            <p>{info}</p>
          </Popup>
        </Marker>
      )}
    </MapContainer>
  );
};

export default MapContact;
