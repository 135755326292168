const title = `Contact`;
const title_español = `Contacto`;
const title_catalan = `Contacte`;
const url_img = `banner_view_contact.jpg`;

export {
  title,
  url_img,
  title_español,
  title_catalan
};
