import { Container, Grid } from "@mui/material";
import { React, useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import { UserContext } from "../../context/UserContext";
import goToTop from "../../hooks/useTop";
import FormContact from "../Contacto/FormContact";
import Viewer from "react-viewer";

const QuiropracticaView = () => {
  const { language } = useContext(LanguageContext);
  const { setMenuActive, setSubmenuActive } = useContext(UserContext);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setViewerIsOpen(true);
  };

  useEffect(() => {
    setMenuActive(1);
    setSubmenuActive(0);
    goToTop();
  }, [setMenuActive, setSubmenuActive]);
  return (
    <>
      {/* SECTION BAJADA */}
      <Container
        fixed
        className="basic__padding"
        style={{ paddingTop: "150px" }}
      >
        <Grid
          className="reduce__contentx2"
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
          xs={12}
        >
          <h4 className="text__content__subluxacion">
            {language === "es"
              ? `Subluxación vertebral y sistema nervioso`
              : language === "en"
              ? `Vertebral subluxation and nervous system`
              : `Subluxació vertebral i sistema nerviós`}
          </h4>
        </Grid>
      </Container>

      <Container fixed className="basic__padding">
        <Grid container xs={12}>
          <Grid container xs={12} className="item__padding">
            {language === "es" ? (
              <p className="description__item__subluxacion reduce__contentx1 text-align-center">
                ¿Usted sabe qué es lo que hace que nuestro cuerpo funcione? Es
                lo que hace latir el corazón, lo que nos permite respirar,
                digerir comida, y mover nuestras manos y pies… nuestro generador
                de vida: el sistema nervioso.
              </p>
            ) : language === "en" ? (
              <p className="description__item__subluxacion reduce__contentx1 text-align-center">
                Do you know what makes our body work? It’s what makes our heart
                beat, what allows us to breathe, digest food, and move our hands
                and feet… our life generator: the nervous system.
              </p>
            ) : (
              <p className="description__item__subluxacion reduce__contentx1 text-align-center">
                Vostè sap què és el que fa que el nostre cos funcioni? És el que
                fa bategar el cor, allò que ens permet respirar, pair menjar, i
                moure les nostres mans i peus… el nostre generador de vida: el
                sistema nerviós.
              </p>
            )}
          </Grid>
        </Grid>
      </Container>
      <Container className="basic__padding">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
          xs={12}
          className="item__padding"
        >
          <a className="btn__item__contact" href="#section__form">
            <button>
              {language === "es"
                ? "Solicita tu primera visita"
                : language === "en"
                ? "Request your first visit"
                : "Sol·licita la teva primera visita"}
            </button>
          </a>
        </Grid>
      </Container>

      {/* SECTION CHIROPRACTIC */}
      <section className="background__sky__blue">
        <Grid
          container
          justifyContent="left"
          alignItems="center"
          direction="row"
          xs={12}
        >
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            xs={12}
            md={7}
            className="basic__padding padding__customer"
          >
            <div className="line__left__blue">
              {language === "es" ? (
                <>
                  <h1 className="choose__title text-align-left">
                    Qué es el
                    <br />
                    sistema nervioso?
                  </h1>
                  <p
                    className="description__item__chiropractic text-align-left reduce__content__right"
                    style={{ marginBottom: "0px!important" }}
                  >
                    El sistema nervioso es la compleja maquinaria que da vida a
                    nuestro cuerpo. Está formado por el cerebro, la médula
                    espinal y los miles de millones de nervios que actúan como
                    una red eléctrica del cuerpo. Su tarea es transmitir la
                    información vital desde el cerebro a todas nuestras células,
                    músculos y órganos.
                    <br />
                    <br />
                    Si queremos vivir en salud, es esencial que nuestro sistema
                    nervioso funciona al máximo. Eso significa que no hayan
                    interferencias en la comunicación entre el cerebro y el
                    cuerpo, y que los impulsos nerviosos lleguen con el 100% de
                    eficacia.
                  </p>
                </>
              ) : language === "en" ? (
                <>
                  <h1 className="choose__title text-align-left">
                    What is the
                    <br />
                    nervous system?
                  </h1>
                  <p
                    className="description__item__chiropractic text-align-left reduce__content__right"
                    style={{ marginBottom: "0px!important" }}
                  >
                    The nervous system is the complex machinery that gives life
                    to our body. It is made up of the brain, the spinal cord and
                    the billions of nerves that act like an electrical network
                    in the body. Their task is to transmit vital information
                    from the brain to all our cells, muscles and organs.
                    <br />
                    <br />
                    If we want to live in health, it is essential that our
                    nervous system functions at its best. That means that there
                    is no interference in the communication between the brain
                    and the body, and that the nerve impulses arrive with 100%
                    efficiency.
                  </p>
                </>
              ) : (
                <>
                  <h1 className="choose__title text-align-left">
                    Què és el
                    <br />
                    sistema nerviós?
                  </h1>
                  <p
                    className="description__item__chiropractic text-align-left reduce__content__right"
                    style={{ marginBottom: "0px!important" }}
                  >
                    El sistema nerviós és la complexa maquinària que dóna vida
                    al nostre cos. Està format pel cervell, la medul·la espinal
                    i els milers de milions de nervis que actuen com una xarxa
                    elèctrica del cos. La seva tasca és transmetre la informació
                    vital des del cervell a totes les nostres cèl·lules, músculs
                    i òrgans.
                    <br />
                    <br />
                    Si volem viure en salut, és essencial que el nostre sistema
                    nerviós funcioni al màxim. Això vol dir que no hi hagi
                    interferències en la comunicació entre el cervell i el cos,
                    i que els impulsos nerviosos arribin amb el 100% d'eficàcia.
                  </p>
                </>
              )}
            </div>
            <Grid
              className="reduce__content__right"
              style={{ marginTop: "50px" }}
            >
              {language === "es" ? (
                <p className="description__item__chiropractic text-align-left content__description__customer">
                  Así las células se regenerarán sanas, los órganos trabajarán
                  correctamente y nuestro sistema inmunológico estará
                  fortalecido.{" "}
                  <b>El resultado es una persona vital, sana y equilibrada.</b>
                  <br />
                  <br />
                  El quiropráctico es especialista en corregir subluxaciones
                  vertebrales (interferencias del sistema nervioso) para que
                  podamos vivir en salud.
                </p>
              ) : language === "en" ? (
                <p className="description__item__chiropractic text-align-left content__description__customer">
                  Thus, the cells will regenerate healthy, the organs will work
                  properly and our immune system will be strengthened.{" "}
                  <b>The result is a vital, healthy and balanced person.</b>
                  <br />
                  <br />
                  The chiropractor is a specialist in correcting vertebral
                  subluxations (interference of the nervous system) so that we
                  can live in health.
                </p>
              ) : (
                <p className="description__item__chiropractic text-align-left content__description__customer">
                  Així les cèl·lules es regeneraran sanes, els òrgans
                  treballaran correctament i el nostre sistema immunològic
                  estarà enfortit.{" "}
                  <b>El resultat és una persona vital, sana i equilibrada.</b>
                  <br />
                  <br />
                  El quiropràctic és especialista a corregir subluxacions
                  vertebrals (interferències del sistema nerviós) perquè puguem
                  viure en salut.
                </p>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            direction="row"
            xs={12}
            md={5}
            style={{
              backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/PESTANASUBLUX.JPG)`,
            }}
            className="banner_customer"
          ></Grid>
        </Grid>
      </section>

      <Container fixed className="basic__padding">
        <Grid
          container
          justifyContent="right"
          alignItems="center"
          direction="row"
          xs={12}
          className="line__right__blue"
        >
          {language === "es" ? (
            <h1 className="phases__title text-align-right">
              La Subluxación
              <br />
              Vertebral
            </h1>
          ) : language === "en" ? (
            <h1 className="phases__title text-align-right">
              Vertebral
              <br />
              Subluxation
            </h1>
          ) : (
            <h1 className="phases__title text-align-right">
              La Subluxació
              <br />
              Vertebral
            </h1>
          )}
          <Grid container xs={12}>
            <Grid container xs={12} md={6}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                direction="row"
                xs={12}
                className="item__padding content__description__item reduce__contentx2"
              >
                {language === "es" ? (
                  <>
                    <p className="description__item__photo">
                      Las causas principales de subluxaciones son:
                      <br />
                      <br />
                      <span className="item__enumeration">•</span> Malas
                      posturas (permanecer sentado o de pie durante mucho
                      tiempo, trabajar en el ordenador, dormir boca abajo, etc.)
                      <br />
                      <span className="item__enumeration">•</span> Caídas y
                      accidentes
                      <br />
                      <span className="item__enumeration">•</span> Nacimiento
                      difícil para el bebé
                      <br />
                      <span className="item__enumeration">•</span> Embarazo
                      complicado o parto difícil para la madre
                      <br />
                      <span className="item__enumeration">•</span> Tensiones y
                      estrés del día a día
                      <br />
                      <span className="item__enumeration">•</span>{" "}
                      Preocupaciones
                      <br />
                      <span className="item__enumeration">•</span> Toxinas
                      (tabaco, polución, mala alimentación, etc.)
                      <br />
                      <br />
                      La especialidad del Quiropráctico es detectar y corregir
                      la subluxación vertebral, devolviéndole al cuerpo su
                      capacidad innata de recuperación.
                    </p>
                  </>
                ) : language === "en" ? (
                  <>
                    <p className="description__item__photo">
                      The main causes of subluxations are:
                      <br />
                      <br />
                      <span className="item__enumeration">•</span> Bad posture
                      (sitting or standing for long periods of time, working at
                      the computer, sleeping on the stomach, etc.).
                      <br />
                      <span className="item__enumeration">•</span> Falls and
                      accidents
                      <br />
                      <span className="item__enumeration">•</span> Difficult
                      birth for the baby
                      <br />
                      <span className="item__enumeration">•</span> Complicated
                      pregnancy or difficult delivery for the mother
                      <br />
                      <span className="item__enumeration">•</span> Day-to-day
                      stresses and strains
                      <br />
                      <span className="item__enumeration">•</span> Concerns
                      <br />
                      <span className="item__enumeration">•</span> Toxins
                      (tobacco, pollution, poor diet, etc.)
                      <br />
                      <br />
                      The specialty of the chiropractor is to detect and correct
                      vertebral subluxation, restoring the body’s innate ability
                      to recover.
                    </p>
                  </>
                ) : (
                  <>
                    <p className="description__item__photo">
                      Les causes principals de subluxacions són:
                      <br />
                      <br />
                      <span className="item__enumeration">•</span> Males
                      postures (romandre assegut o dret durant molt de temps,
                      treballar a l'ordinador, dormir de cap per avall, etc.)
                      <br />
                      <span className="item__enumeration">•</span> Caigudes i
                      accidents
                      <br />
                      <span className="item__enumeration">•</span> Naixement
                      difícil per al nadó
                      <br />
                      <span className="item__enumeration">•</span> Embaràs
                      complicat o part difícil per a la mare
                      <br />
                      <span className="item__enumeration">•</span> Tensions i
                      estrès del dia a dia
                      <br />
                      <span className="item__enumeration">•</span> Preocupacions
                      <br />
                      <span className="item__enumeration">•</span> Toxines
                      (tabac, pol·lució, mala alimentació, etc.)
                      <br />
                      <br />
                      L'especialitat del Quiropràctic és detectar i corregir la
                      subluxació vertebral, i tornar-li al cos la seva capacitat
                      innata de recuperació.{" "}
                    </p>
                  </>
                )}
                <div className="container__img__items__subluxation">
                  <img
                    src={`${process.env.REACT_APP_FRONTEND}assets/image/PESTANA SUBLUXACION 2.jpg`}
                    alt="phot item"
                    className="img__items__subluxation"
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container xs={12} md={6}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                direction="row"
                xs={12}
                className="item__padding content__description__item reduce__contentx2"
              >
                {language === "es" ? (
                  <>
                    <p className="description__item__photo text-align-right">
                      Una subluxación vertebral es lo que se conoce como
                      “pinzamiento.” Sucede cuando una vértebra se desplaza de
                      su posición normal, “estrangula” el nervio que pasa a
                      través de ella e interfiere con la transmisión de impulsos
                      nerviosos entre el cerebro y el cuerpo.
                      <br />
                      <br />
                      La gran mayoría de las subluxaciones no producen dolor
                      físico ya que sólo un 10% de los nervios están asociados a
                      fibras de dolor.{" "}
                      <b>
                        Sin embargo su efecto dañino es inmediato, debilitando
                        el cuerpo y su rendimiento a todos los niveles.
                      </b>
                    </p>
                  </>
                ) : language === "en" ? (
                  <>
                    <p className="description__item__photo text-align-right">
                      A vertebral subluxation is what is known as “impingement.”
                      It happens when a vertebra shifts from its normal
                      position, “strangles” the nerve that passes through it and
                      interferes with the transmission of nerve impulses between
                      the brain and the body.
                      <br />
                      <br />
                      The vast majority of subluxations do not produce physical
                      pain as only 10% of the nerves are associated with pain
                      fibers.
                      <b>
                        However, their damaging effect is immediate, weakening
                        the body and its performance at all levels.
                      </b>
                    </p>
                  </>
                ) : (
                  <>
                    <p className="description__item__photo text-align-right">
                      Una subluxació vertebral és el que es coneix com a
                      “pinzament.” Passa quan una vèrtebra es desplaça de la
                      seva posició normal, “estrangula” el nervi que hi passa i
                      interfereix amb la transmissió d'impulsos nerviosos entre
                      el cervell i el cos.
                      <br />
                      <br />
                      La gran majoria de les subluxacions no produeixen dolor
                      físic, ja que només un 10% dels nervis estan associats a
                      fibres de dolor.
                      <b>
                        No obstant això, el seu efecte nociu és immediat,
                        debilitant el cos i el seu rendiment a tots els nivells.
                      </b>
                    </p>
                  </>
                )}
                <div className="container__img__items__subluxation">
                  <img
                    src={`${process.env.REACT_APP_FRONTEND}assets/image/PESTANASUBLUXACION.jpg`}
                    alt="phot item"
                    className="img__items__subluxation"
                    onClick={() =>
                      handleImageClick(
                        `${process.env.REACT_APP_FRONTEND}assets/image/PESTANASUBLUXACION.jpg`
                      )
                    }
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="right"
          alignItems="center"
          direction="row"
          xs={12}
          className="item__padding"
        >
          <a className="btn__item__contact" href="#section__form">
            <button>
              {language === "es"
                ? "Contacta para más información"
                : language === "en"
                ? "Contact us for more information"
                : "Contacta per a més informació"}
            </button>
          </a>
        </Grid>
      </Container>
      {viewerIsOpen && (
        <Viewer
          visible={viewerIsOpen}
          onClose={() => setViewerIsOpen(false)}
          images={[{ src: selectedImage, alt: "Descripción de la imagen" }]}
          zIndex={9999}
        />
      )}
      <FormContact />
    </>
  );
};

export default QuiropracticaView;
