import React from "react";

const Star = () => {
  return (
    <div className="container__star">
      <img
        src={`${process.env.REACT_APP_FRONTEND}assets/image/estrella.png`}
        alt="Teresa"
        className="img__slide"
      />
      <img
        src={`${process.env.REACT_APP_FRONTEND}assets/image/estrella.png`}
        alt="Teresa"
        className="img__slide"
      />
      <img
        src={`${process.env.REACT_APP_FRONTEND}assets/image/estrella.png`}
        alt="Teresa"
        className="img__slide"
      />
      <img
        src={`${process.env.REACT_APP_FRONTEND}assets/image/estrella.png`}
        alt="Teresa"
        className="img__slide"
      />
      <img
        src={`${process.env.REACT_APP_FRONTEND}assets/image/estrella.png`}
        alt="Teresa"
        className="img__slide"
      />
    </div>
  );
};

export default Star;
