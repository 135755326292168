import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import { React, useContext, useEffect } from "react";
import { Banner } from "../../components/shared";
import MapContact from "../../components/shared/MapContact";
import { LanguageContext } from "../../context/LanguageContext";
import { UserContext } from "../../context/UserContext";
import goToTop from "../../hooks/useTop";
import { title, title_español, title_catalan, url_img } from "./const";
import FormContact from "./FormContact";

const ContactoView = () => {
  const { language } = useContext(LanguageContext);
  const { setMenuActive, setSubmenuActive } = useContext(UserContext);

  useEffect(() => {
    setMenuActive(5);
    setSubmenuActive(0);
    goToTop();
  }, [setMenuActive, setSubmenuActive]);
  return (
    <>
      <Banner
        title={
          language === "es"
            ? title_español
            : language === "en"
            ? title
            : title_catalan
        }
        url_img={url_img}
        btn_form={false}
        center={true}
      />
      {/* SECTION BAJADA */}
      <Container fixed className="basic__padding">
        <Grid container xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="row"
            xs={12}
            md={4}
          >
            <p className="description__item__photo text-align-center">
              <h4 className="text__content__blue">DIPUTACIÓ</h4>
              <br />
              C/ Diputació, 168
              <br /> 08011 Barcelona
              <br />
              <br />
              93 451 53 01
              <br />
              665 602 566
              <br />
              diputacio@quirolinq.com
            </p>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="row"
            xs={12}
            md={4}
          >
            <p className="description__item__photo text-align-center">
              <h4 className="text__content__blue">VALENCIA</h4>
              <br />
              C/ Valencia 308,
              <br />
              08009 Barcelona
              <br />
              <br />
              932 15 89 07
              <br />
              695 19 78 02
              <br />
              valencia@quirolinq.com
            </p>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="row"
            xs={12}
            md={4}
          >
            <p className="description__item__photo text-align-center">
              <h4 className="text__content__blue">VENDRELL</h4>
              <br />
              La Rambla, 10, Escalera 2 Entresuelo B 43700 El Vendrell
              (Tarragona)
              <br />
              <br />
              977 66 37 19
              <br />
              606 909 702
              <br />
              elvendrell@quirolinq.com
            </p>
          </Grid>
          {/* <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="row"
            xs={12}
            md={3}
          >
            <p className="description__item__photo text-align-center">
              <h4 className="text__content__blue">EL MASNOU</h4>
              <br />
              C/ Navarra, 66
              <br />
              08320 El Masnou
              <br />
              <br />
              93 555 10 67
              <br />
              646 471 754
              <br />
              elmasnou@quirolinq.com
            </p>
          </Grid> */}
        </Grid>

        <Grid
          className="basic__padding"
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
        >
          <a className="btn__item__contact__chiropractic" href="#section__form">
            <button>
              {language === "es"
                ? "Más información"
                : language === "en"
                ? "More information"
                : "Més informació"}
            </button>
          </a>
        </Grid>
      </Container>

      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        direction="row"
        xs={12}
      >
        <MapContact isContact={true} />
      </Grid>

      <FormContact />
    </>
  );
};

export default ContactoView;
