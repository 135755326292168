const title = "404";
const title_bajada = "Page Not Found";
const description =
  "The resource requested could not be found on this server.";
const title_bajada_español = "Página no encontrada";
const description_español =
  "El recurso solicitado no se pudo encontrar en este servidor.";
const title_bajada_catalan = "Pàgina no trobada.";
const description_catalan =
  "El recurs sol·licitat no s'ha pogut trobar en aquest servidor.";

export {
  title,
  title_bajada,
  description,
  title_bajada_español,
  description_español,
  title_bajada_catalan,
  description_catalan,
};
