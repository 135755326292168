import { Container, Grid } from "@mui/material";
import { React, useContext, useEffect } from "react";
import { Banner } from "../../components/shared";
import { UserContext } from "../../context/UserContext";
import goToTop from "../../hooks/useTop";
import FormContact from "../Contacto/FormContact";
import { title, url_img, noticias } from "./const";
import Blog from "../../components/shared/Blog";

const BlogView = () => {
  const { setMenuActive, setSubmenuActive } = useContext(UserContext);

  useEffect(() => {
    setMenuActive(4);
    setSubmenuActive(0);
    goToTop();
  }, [setMenuActive, setSubmenuActive]);
  return (
    <>
      <Banner title={title} url_img={url_img} btn_form={false} center={true} />
      {/* SECTION BAJADA */}
      <Container fixed className="basic__padding">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
          xs={12}
        >
          <Container>
            <Grid
              container
              justifyContent="left"
              alignItems="center"
              direction="row"
              xs={12}
            >
              {noticias.map((noticia) => {
                return (
                  <Grid container xs={12} md={6} className="content__blog">
                    <Blog object={noticia} />
                  </Grid>
                );
              })}
            </Grid>
          </Container>
        </Grid>
      </Container>

      <FormContact />
    </>
  );
};

export default BlogView;
