const title = `Centers`;
const title_español = `Centros`;
const title_catalan = `Centres`;
const url_img = `banner_center.jpg`;

const object_diputacio = {
  name: "Diputació",
  text_top_es:
    "En LinQ Diputació atendemos cada día a más personas y les ayudamos a entender que la salud viene desde dentro. Enseñamos conceptos básicos utilizando el sentido común para que puedan recuperar su salud de forma natural, para mantenerla así a largo plazo. Creamos un ambiente vital, agradable y educativo para que puedas gozar al máximo de los beneficios de la Quiropráctica.",
  text_top_en:
    "At LinQ Diputació we serve more people every day and help them understand that health comes from within. We teach basic concepts using common sense so that they can recover their health naturally, to keep it that way in the long term. We create a vital, pleasant and educational environment so that you can fully enjoy the benefits of Chiropractic.",
  text_top_cat:
    "A LinQ Diputació atenem cada dia més persones i els ajudem a entendre que la salut ve des de dins. Ensenyem conceptes bàsics utilitzant el sentit comú perquè puguin recuperar la seva salut de manera natural, per mantenir-la així a llarg termini. Creem un ambient vital, agradable i educatiu perquè pugueu gaudir al màxim dels beneficis de la Quiropràctica.",
  tel1: "93 451 53 01",
  tel2: "665 602 566",
  mail: "diputacio@quirolinq.com",
  horario_es: [
    "Lunes 8:45 a 12:00 h. / 17:00 a 20:00 h.",
    "Martes 16:00 a 19:00 h.",
    "Miércoles 8:45 a 12:00 h. / 17:00 a 20:00 h.",
    "Jueves 17:00 a 20:00 h.",
    "Viernes 10:00 a 13:00 h.",
  ],
  horario_en: [
    "Monday 8:45 am to 12:00 pm / 5:00 pm to 8:00 pm",
    "Tuesday 4:00 pm to 7:00 pm",
    "Wednesday 8:45 am to 12:00 pm / 5:00 pm to 8:00 pm",
    "Thursdays 5:00 pm to 8:00 pm",
    "Fridays 10:00 am to 1:00 pm",
  ],
  horario_cat: [
    "Dilluns 8:45 a 12:00 h. / 17:00 a 20:00 h.",
    "Dimarts 16:00 a 19:00 h.",
    "Dimecres 8:45 a 12:00 h. / 17:00 a 20:00 h.",
    "Dijous 17:00 a 20:00 h.",
    "Divendres 10:00 a 13:00 h.",
  ],
  direccion: "C/ Diputació, 168, 08011 Barcelona",
  carrusel_fotos: [
    "CARRUSEL1.jpeg",
    "CARRUSEL2.jpg",
    "CARRUSEL3.jpg",
    "CARRUSEL4.jpg",
    "CARRUSEL5.jpeg",
  ],
  title_texto_es: "El quiropráctico",
  title_texto_en: "The chiropractor",
  title_texto_cat: "El quiropràctic",
  texto_quiro1_es: "",
  texto_quiro2_es:
    "Juho Ohtonen (a la izquierda de la foto) se graduó en el 2021 en el Barcelona Chiropractic College (BCC). Al ser padre de dos niñas, desde el principio Juho ha destacado por el cuidado de niños, bebés y embarazadas; así como adolescentes y deportistas. Cada mes abre el espacio en el centro a familias para compartir experiencias y apoyo en todos los campos del crecimiento infantil y familiar.",
  texto_quiro1_en: "",
  texto_quiro2_en:
    "Juho Ohtonen (on the left of the photo) graduated in 2021 from the Barcelona Chiropractic College (BCC). Being the father of two girls, from the beginning Juho has stood out for the care of children, babies and pregnant women; as well as adolescents and athletes. Every month the space in the center is opened to families to share experiences and support in all fields of child and family growth.",
  texto_quiro1_cat: "",
  texto_quiro2_cat:
    "Juho Ohtonen (a l'esquerra de la foto) es va graduar el 2021 al Barcelona Chiropractic College (BCC). Com que és pare de dues nenes, des del principi Juho ha destacat per la cura de nens, nadons i embarassades; així com adolescents i esportistes. Cada mes obre l'espai al centre a famílies per compartir experiències i suport a tots els camps del creixement infantil i familiar.",
  marker: {
    id: 1,
    latitude: 41.38459391073355,
    longitude: 2.1587167115432697,
  },
  info: "C/ Diputació, 168, 08011 Barcelona",
};

const object_valencia = {
  name: "Valencia",
  text_top_es:
    "En LinQ Valencia nos apasiona tener algo tan importante entre manos: tu bienestar. Nos dedicamos con cariño y profesionalidad a cada persona que entra dispuesta a aumentar su bienestar y calidad de vida. LinQ Valencia es un centro donde las personas se sienten como en casa donde la quiropráctica no solo les ha cambiado la vida sino que además forma parte de su cuidado de salud habitual.",
  text_top_en:
    "At LinQ Valencia we are passionate about having something so important on our hands: your well-being. We dedicate ourselves with affection and professionalism to each person who enters willing to increase their well-being and quality of life. LinQ Valencia is a center where people feel at home where chiropractic has not only changed their lives but is also part of their regular health care.",
  text_top_cat:
    "A LinQ València ens apassiona tenir una cosa tan important entre mans: el teu benestar. Ens dediquem amb afecte i professionalitat a cada persona que entra disposada a augmentar-ne el benestar i la qualitat de vida. LinQ València és un centre on les persones se senten com a casa on la quiropràctica no només els ha canviat la vida sinó que a més forma part de la cura de salut habitual.",
  tel1: "932 15 89 07",
  tel2: "695 19 78 02",
  mail: "valencia@quirolinq.com",
  horario_es: [
    "Lunes 9:00 a 13:00 h. / 15:00 a 20:00 h.",
    "Miércoles 9:00 a 13:00 h. / 15:00 a 20:00 h.",
    "Jueves 9:00 a 13:00 h. / 15:00 a 20:00 h.",
    "Viernes 9:00 a 11:00 (At. Telefónica)",
  ],
  horario_en: [
    "Monday 9:00 am to 1:00 pm / 3:00 pm to 8:00 pm",
    "Wednesday 9:00 am to 1:00 pm / 3:00 pm to 8:00 pm",
    "Thursdays 9:00 am to 1:00 pm / 3:00 pm to 8:00 pm",
    "Fridays 9:00 am to 11:00 am (Telephone attention)",
  ],
  horario_cat: [
    "Dilluns 9:00 a 13:00 h. / 15:00 a 20:00 h.",
    "Dimecres 9:00 a 13:00 h. / 15:00 a 20:00 h.",
    "Dijous 9:00 a 13:00 h. / 15:00 a 20:00 h.",
    "Divendres 9:00 a 11:00 (At. Telefónica)",
  ],
  direccion: "C/ Valencia 308, 08009 Barcelona",
  carrusel_fotos: [
    "CARRUSELVALENCIA2.jpeg",
    "CARRUSELVALENCIA4.JPG",
    "CARRUSELVALENCIA5.JPG",
  ],
  title_texto_es: "Los quiroprácticos",
  title_texto_en: "Chiropractors",
  title_texto_cat: "Els quiropràctics",
  texto_quiro1_es: "",
  texto_quiro2_es:
    "David Aranda nació en California, y se graduó en la facultad de Palmer College of Chiropractic West, California. Vivió y trabajó durante 12 años en Londres, y en el 2009 decidió mudarse a España en busca de una mejor calidad de vida para él y su familia. Ha ajustado a importantes personas y actualmente ayuda a las personas a mejorar sus vidas como quiropráctico.",
  texto_quiro1_en: "",
  texto_quiro2_en:
    "David Aranda was born in California, and graduated from the faculty of Palmer College of Chiropractic West, California. He lived and worked for 12 years in London, and in 2009 decided to move to Spain in search of a better quality of life for himself and his family. He has adjusted important people and is currently helping people improve their lives as a chiropractor.",
  texto_quiro1_cat: "",
  texto_quiro2_cat:
    "David Aranda va néixer a Califòrnia, i es va graduar a la facultat de Palmer College of Chiropractic West, Califòrnia. Va viure i treballar durant 12 anys a Londres, i el 2009 va decidir mudar-se a Espanya buscant una millor qualitat de vida per a ell i la seva família. Ha ajustat a importants persones i actualment ajuda les persones a millorar les seves vides com a quiropràctic.",
  marker: {
    id: 2,
    latitude: 41.394885445343704,
    longitude: 2.166266798040443,
  },
  info: "C/ Valencia 308, 08009 Barcelona",
};

const object_masnou = {
  name: "El Masnou",
  text_top_es:
    "El centro LinQ situado en El Masnou lleva muchos años funcionando en la zona. Es un lugar ideal para las personas que viven en la costa y alrededores que necesitan cuidado durante todo el año. La atención es personalizada y a él acuden personas con toda clase de problemas, edades y condiciones.",
  text_top_en:
    "The LinQ center located in El Masnou has been operating in the area for many years. It is an ideal place for people who live on the coast and its surroundings who need care throughout the year. The attention is personalized and people with all kinds of needs come to it. problems, ages and conditions.",
  text_top_cat:
    "El centre LinQ situat al Masnou fa molts anys que funciona a la zona. És un lloc ideal per a les persones que viuen a la costa i rodalies que necessiten cura durant tot l'any. L'atenció és personalitzada ia ell acudeixen persones amb tota mena de problemes, edats i condicions.",
  tel1: "93 555 10 67",
  tel2: "646 471 754",
  mail: "elmasnou@quirolinq.com",
  horario_es: [
    "Lunes 11:00 a 13:00 h. / 14:00 a 16:00 h.",
    "Martes 10:00 a 13:00 h. / 15:00 a 19:00 h.",
    "Jueves 10:00 a 13:00 h. / 15:00 a 19:00 h.",
  ],
  horario_en: [
    "Monday 11:00 am to 1:00 pm / 2:00 pm to 4:00 pm",
    "Wednesday 10:00 am to 1:00 pm / 3:00 pm to 7:00 pm",
    "Thursdays 10:00 am to 1:00 pm / 3:00 pm to 7:00 pm",
  ],
  horario_cat: [
    "Dilluns 11:00 a 13:00 h. / 14:00 a 16:00 h.",
    "Dimecres 10:00 a 13:00 h. / 15:00 a 19:00 h.",
    "Dijous 10:00 a 13:00 h. / 15:00 a 19:00 h.",
  ],
  direccion: "C/ Navarra, 66, 08320 El Masnou",
  carrusel_fotos: [
    "CARRUSELMASNOU1.jpeg",
    "CARRUSELMASNOU2.jpeg",
    "CARRUSELMASNOU3.jpeg",
    "CARRUSELMASNOU4.jpeg",
  ],
  title_texto_es: "El quiropráctico",
  title_texto_en: "The chiropractor",
  title_texto_cat: "El quiropràctic",
  texto_quiro1_es:
    "Mariano Feretti es director y quiropráctico de Linq El Masnou. Descubrió la quiropráctica durante su formación como fisioterapeuta en Argentina, gracias a un profesor de cátedra. Así pues, comenzó a estudiar quiropráctica en paralelo a la fisioterapia y, una vez terminada su formación, se dedicó por completo a mejorar y trabajar como tal. Desde entonces, su enfoque se centra en la quiropráctica y su relación con todos los aspectos de la vida, tanto deportivo como de la vida diaria.",
  texto_quiro2_en:
    '"Since 2017 my focus is to improve the health and quality of life of people through chiropractic, while providing education on comprehensive health care"',
  texto_quiro2_cat:
    '"Des del 2017 el meu focus és millorar la salut i qualitat de vida de les persones a través de la quiropràctica, alhora que brindar educació sobre la cura integral de la salut"',
  texto_quiro2_es:
    '"Desde 2017 mi foco es mejorar la salud y calidad de vida de las personas a través de la quiropráctica, al mismo tiempo que brindar educación sobre el cuidado integral de la salud"',
  texto_quiro1_en:
    "Mariano Feretti is director and chiropractor at Linq El Masnou. He discovered chiropractic during his training as a physiotherapist in Argentina, thanks to a professor. So, he began to study chiropractic in parallel to physiotherapy and, once he finished his training, he completely dedicated himself to improving and working as such. Since then, his focus has been on chiropractic and its relationship to all aspects of life, both sports and daily life.",
  texto_quiro1_cat:
    "Mariano Feretti és director i quiropràctic de Linq El Masnou. Va descobrir la quiropràctica durant la seva formació com a fisioterapeuta a Argentina, gràcies a un professor de càtedra. Així doncs, va començar a estudiar quiropràctica en paral·lel a la fisioteràpia i, un cop acabada la seva formació, es va dedicar completament a millorar i treballar com a tal. Des de llavors, el seu enfocament se centra en la quiropràctica i la relació amb tots els aspectes de la vida, tant esportiu com de la vida diària.",
  marker: {
    id: 3,
    latitude: 41.481148259024856,
    longitude: 2.315136255713664,
  },
  info: "C/ Navarra, 66, 08320 El Masnou",
};

const object_vendrell = {
  name: "El Vendrell",
  text_top_es:
    "LinQ El Vendrell es un centro quiropráctico en Tarragona que abrió en el 2010 y desde entonces da servicio a todo tipo de personas. Es un centro familiar ideal para todo tipo de casos y edades. Gracias a su localización, muchas personas de alrededor de El Vendrell se puede beneficiar del cuidado quiropráctico.",
  text_top_en:
    "LinQ El Vendrell is a chiropractic center in Tarragona that opened in 2010 and since then has served all kinds of people. It is an ideal family center for all types of cases and ages. Thanks to its location, many people from around El Vendrell can benefit from chiropractic care.",
  text_top_cat:
    "LinQ El Vendrell és un centre quiropràctic a Tarragona que va obrir el 2010 i des de llavors dóna servei a tota mena de persones. És un centre familiar ideal per a tot tipus de casos i edats. Gràcies a la seva localització, moltes persones del voltant d'El Vendrell es pot beneficiar de la cura quiropràctica.",
  tel1: "977 66 37 19",
  tel2: "606 909 702",
  mail: "elvendrell@quirolinq.com",
  horario_es: [
    "Martes 9:00 a 13:00 h.",
    "Jueves 9:00 a 13:00 h./ 16:00 a 20:00 h.",
    "Sábado 10:00 a 13:00 h.",
  ],
  horario_en: [
    "Tuesday 9:00 am to 1:00 pm",
    "Thursday 9:00 am to 1:00 pm / 4:00 pm to 8:00 pm",
    "Saturday 10:00 am to 1:00 pm",
  ],
  horario_cat: [
    "Dimarts 9:00 a 13:00 h.",
    "Dijous 9:00 a 13:00 h. / 16:00 a 20:00 h.",
    "Dissabte 10:00 a 13:00 h.",
  ],
  direccion:
    "La Rambla, 10, Escalera 2 Entresuelo B, 43700 El Vendrell (Tarragona)",
  carrusel_fotos: [
    "CARRUSELVENDRELL1.jpeg",
    "CARRUSELVENDRELL2.jpeg",
    "CARRUSELVENDRELL3.jpeg",
  ],
  title_texto_es: "El quiropráctico",
  title_texto_en: "The chiropractor",
  title_texto_cat: "El quiropràctic",
  texto_quiro1_es:
    "Nacido en California, David Aranda se graduó en la facultad de Palmer College of Chiropractic West, California. Vivió y trabajó durante 12 años en Londres, y en el 2009 decidió mudarse a España en busca de una mejor calidad de vida para él y su familia. Ha ajustado a importantes personas y actualmente ayuda a las personas a mejorar sus vidas como quiropráctico.",
  texto_quiro1_en:
    "Born in California, David Aranda graduated from the faculty of Palmer College of Chiropractic West, California. He lived and worked for 12 years in London, and in 2009 decided to move to Spain in search of a better quality of life for himself and his family. He has adjusted important people and is currently helping people improve their lives as a chiropractor.",
  texto_quiro1_cat:
    "Nascut a Califòrnia, David Aranda es va graduar a la facultat de Palmer College of Chiropractic West, Califòrnia. Va viure i treballar durant 12 anys a Londres, i el 2009 va decidir mudar-se a Espanya buscant una millor qualitat de vida per a ell i la seva família. Ha ajustat a importants persones i actualment ajuda les persones a millorar les seves vides com a quiropràctic.",
  marker: {
    id: 4,
    latitude: 41.22053439935004,
    longitude: 1.5366890845417156,
  },
  info: "La Rambla, 10, Escalera 2 Entresuelo B, 43700 El Vendrell (Tarragona)",
};

export {
  title,
  title_español,
  title_catalan,
  url_img,
  object_diputacio,
  object_masnou,
  object_valencia,
  object_vendrell,
};
