import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { IconButton } from "@mui/material";

const BtnWhatsApp = () => {
  return (
    <div className="container__btn__whatsapp">
      <IconButton href="https://api.whatsapp.com/send/?phone=34651836080&text=Hola%21+quisiera+agendar+una+cita&type=phone_number&app_absent=0">
        <WhatsAppIcon />
      </IconButton>
    </div>
  );
};

export default BtnWhatsApp;
