import { Container, Grid } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import { UserContext } from "../../context/UserContext";
import goToTop from "../../hooks/useTop";
import {
  title,
  title_bajada,
  description,
  title_bajada_español,
  description_español,
  title_bajada_catalan,
  description_catalan,
} from "./const";

const ErrorView = () => {
  const { language } = useContext(LanguageContext);
  const { setMenuActive, setSubmenuActive } = useContext(UserContext);
  useEffect(() => {
    setMenuActive(0);
    setSubmenuActive(0);
    goToTop();
  }, [setMenuActive, setSubmenuActive]);

  return (
    <Container>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        className="container__person text-align-center"
        style={{ minHeight: "calc(100vh - 150px)" }}
      >
        <h4 className="text__content__blue">
          {title}
          <br />
          {language === "es"
            ? title_bajada_español
            : language === "en"
            ? title_bajada
            : title_bajada_catalan}
        </h4>
        <p className="description__question">
          {language === "es"
            ? description_español
            : language === "en"
            ? description
            : description_catalan}
        </p>
      </Grid>
    </Container>
  );
};

export default ErrorView;
