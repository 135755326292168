import { useContext, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import goToTop from "../../hooks/useTop";
import Center from "../../components/shared/Center";
import { object_vendrell } from "./const";
import { Container, Grid } from "@mui/material";
import { LanguageContext } from "../../context/LanguageContext";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import Star from "../../components/shared/Star";

const Vendrell = () => {
  const { language } = useContext(LanguageContext);
  const { setMenuActive, setSubmenuActive } = useContext(UserContext);

  useEffect(() => {
    setMenuActive(3);
    setSubmenuActive(0);
    goToTop();
  }, [setMenuActive, setSubmenuActive]);
  return (
    <>
      <Center object={object_vendrell} />
      <section className="basic__padding background__sky__blue">
        <Container>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            direction="row"
          >
            <p className="description__question" style={{ width: "100%" }}>
              <b className="center-title">
                {language === "es"
                  ? "En este centro se atiende"
                  : language === "en"
                  ? "In this center is served"
                  : "En aquest centre s'atén"}
              </b>
            </p>
          </Grid>
          <Grid
            className="reduce__contentx1"
            container
            justifyContent="left"
            alignItems="center"
            direction="row"
            xs={12}
          >
            <Grid container xs={12} md={6}>
              {language === "es" ? (
                <p className="description__item__photo">
                  <span className="item__enumeration">•</span> Adolescentes
                  <br />
                  <span className="item__enumeration">•</span> Adultos
                  <br />
                  <span className="item__enumeration">•</span> Personas mayores
                </p>
              ) : language === "en" ? (
                <p className="description__item__photo">
                  <span className="item__enumeration">•</span> Teenagers
                  <br />
                  <span className="item__enumeration">•</span> Adults
                  <br />
                  <span className="item__enumeration">•</span> Seniors
                </p>
              ) : (
                <p className="description__item__photo">
                  <span className="item__enumeration">•</span> Adolescents
                  <br />
                  <span className="item__enumeration">•</span> Adults
                  <br />
                  <span className="item__enumeration">•</span> Gent gran
                </p>
              )}
            </Grid>
            <Grid container xs={12} md={6}>
              {language === "es" ? (
                <p className="description__item__photo">
                  <span className="item__enumeration">•</span> Personas con
                  osteoporosis
                  <br />
                  <span className="item__enumeration">•</span> Personas con
                  hernia discal
                </p>
              ) : language === "en" ? (
                <p className="description__item__photo">
                  <span className="item__enumeration">•</span> People with
                  osteoporosis
                  <br />
                  <span className="item__enumeration">•</span> People with
                  herniated disc
                </p>
              ) : (
                <p className="description__item__photo">
                  <span className="item__enumeration">•</span> Persones amb
                  osteoporosi
                  <br />
                  <span className="item__enumeration">•</span> Persones amb
                  hèrnia discal
                </p>
              )}
            </Grid>
          </Grid>
        </Container>
      </section>

      {/* SECTION CARROUSEL */}
      <section className="basic__padding background__blue__carrousel">
        <Container>
          <Swiper
            modules={[Navigation]}
            spaceBetween={30}
            navigation
            loop={true}
            centeredSlides={true}
            autoHeight={true}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 1.2,
              },
            }}
            style={{ overflow: "initial" }}
          >
            <SwiperSlide className="slide__person">
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                className="container__person"
              >
                <br />
                <Star />
                <br />
                {language === "es" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “Me ha ayudado a detectar un problema en la parte superior
                      de la columna vertebral y lo está corrigiendo.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Ana N.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Muy buena experiencia con David. Me ha ayudado a detectar
                      un problema en la parte superior de la columna vertebral y
                      lo está corrigiendo. Buenos resultados desde el primer
                      momento, se nota la dedicación y la experiencia
                    </p>
                  </>
                ) : language === "en" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “He has helped me detect a problem in the upper part of
                      the spine and is correcting it.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Ana N.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Very good experience with David. He has helped me detect a
                      problem in the upper part of the spine and is correcting
                      it. Good results from the first moment, dedication and
                      experience can be seen
                    </p>
                  </>
                ) : (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “M'ha ajudat a detectar un problema a la part superior de
                      la columna vertebral i ho està corregint.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Ana N.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Molt bona experiència amb David. M'ha ajudat a detectar un
                      problema a la part superior de la columna vertebral i ho
                      està corregint. Bons resultats des del primer moment, es
                      nota la dedicació i l'experiència
                    </p>
                  </>
                )}
              </Grid>
            </SwiperSlide>
            <SwiperSlide className="slide__person">
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                className="container__person"
              >
                <br />
                <Star />
                <br />
                {language === "es" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “Recomendado sin duda y con plena confianza además del
                      buen trato por su parte.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Francisco T.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Hace unos quince años que me estoy tratando en este centro
                      por David, estaba a base de pastillas sin remediar los
                      dolores de espalda. Deje de hacer el deporte que más me
                      motivaba además de dificultad para trabajar, me hablaron
                      de la quiropráctica y probé ya como último recurso. En
                      estos momentos estoy sin dolores y he podido seguir
                      haciendo lo que me gusta cuando seguro fuera pasado por
                      operación sin garantías. Recomendado sin duda y con plena
                      confianza además del buen trato por su parte.
                    </p>
                  </>
                ) : language === "en" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “Recommended without a doubt and with full confidence in
                      addition to the good treatment on your part.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Francisco T.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      For about fifteen years I have been treated in this center
                      for David, I was based on pills without remedying back
                      pain. I stopped doing the sport that motivated me the
                      most, in addition to having difficulty working, they told
                      me about chiropractic and I tried it as a last resort.
                      Right now I am pain-free and I have been able to continue
                      doing what I like when I was sure to undergo an operation
                      without guarantees. Recommended without a doubt and with
                      full confidence in addition to the good treatment on your
                      part.
                    </p>
                  </>
                ) : (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “Recomanat sens dubte i amb plena confiança a més del bon
                      tracte per part seva.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Francisco T.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Fa uns quinze anys que m'estic tractant en aquest centre
                      pel David, estava a base de pastilles sense posar remei
                      als mals d'esquena. Deixeu de fer l'esport que més em
                      motivava a més de dificultat per treballar, em van parlar
                      de la quiropràctica i vaig provar ja com a últim recurs.
                      En aquests moments estic sense dolors i he pogut continuar
                      fent el que m'agrada quan segur fos passat per operació
                      sense garanties. Recomanat sens dubte i amb plena
                      confiança a més del bon tracte per part seva.
                    </p>
                  </>
                )}
              </Grid>
            </SwiperSlide>
            <SwiperSlide className="slide__person">
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                className="container__person"
              >
                <br />
                <Star />
                <br />
                {language === "es" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “Gracias a las sesiones del doctor Aranda la tensión de
                      cuello y espalda a disminuido y también los dolores.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Javi J.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Llegue a la consulta con problemas de cervicales y
                      lumbares que me impedían llevar una vida normal y sin
                      dolor. Gracias a las sesiones del doctor Aranda la tensión
                      de cuello y espalda a disminuido y también los dolores.
                    </p>
                  </>
                ) : language === "en" ? (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “Thanks to Dr. Aranda's sessions, neck and back tension
                      has decreased as well as pain.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Javi J.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      I arrived at the consultation with cervical and lumbar
                      problems that prevented me from leading a normal life
                      without pain. Thanks to Dr. Aranda's sessions, neck and
                      back tension has decreased as well as pain.
                    </p>
                  </>
                ) : (
                  <>
                    <p className="description__question text-align-center stand-text">
                      “Gràcies a les sessions del doctor Aranda, la tensió de
                      coll i esquena a disminuït i també els dolors.”
                    </p>
                    <p className="title__primary" style={{ color: "#1F3934" }}>
                      Javi J.
                    </p>
                    <p
                      className="description__item__chiropractic"
                      style={{ marginTop: "25px", marginBottom: "50px" }}
                    >
                      Arribeu a la consulta amb problemes de cervicals i lumbars
                      que m'impedien portar una vida normal i sense dolor.
                      Gràcies a les sessions del doctor Aranda, la tensió de
                      coll i esquena a disminuït i també els dolors.
                    </p>
                  </>
                )}
              </Grid>
            </SwiperSlide>
          </Swiper>
        </Container>
      </section>
    </>
  );
};

export default Vendrell;
