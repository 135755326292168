import { Container } from "@mui/system";
import React from "react";

const Banner = ({ title, url_img, btn_form = false, center = true }) => {
  const style = {
    backgroundImage:
      "url(" + process.env.REACT_APP_FRONTEND + "assets/image/" + url_img + ")",
    backgroundPosition: "bottom",
  };
  return (
    <>
      <div
        className={`banner_container ${center ? "align-items-center" : ""}`}
        style={style}
      >
        <Container fixed>
          {center ? (
            <h1
              className="d-flex justify-content-center text-banner-other"
              align="center"
            >
              {title}
            </h1>
          ) : (
            <h1 className="text-banner-home">
              {title === "es"
                ? "Cuidándote desde "
                : title === "en"
                ? "Taking care of you"
                : "Cuidant de tu des"}
              <br />
              {title === "es"
                ? "el 2012"
                : title === "en"
                ? "since 2012"
                : "de 2012"}
            </h1>
          )}

          {btn_form && (
            <>
              <br />
              <div className="content__btn__banner">
                <a className="content__btn__reserva" href="#section__form">
                  <p>
                    {title === "es"
                      ? "Solicita tu primera visita"
                      : title === "en"
                      ? "Request your first visit"
                      : "Sol·licita la teva primera visita"}
                  </p>
                </a>
              </div>
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default Banner;
