import { Container, Grid } from "@mui/material";
import { React, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Banner } from "../../components/shared";
import { LanguageContext } from "../../context/LanguageContext";
import { UserContext } from "../../context/UserContext";
import goToTop from "../../hooks/useTop";
import {
  title,
  title_español,
  title_catalan,
  url_img,
  video_quiro,
  video_marcelo,
} from "./constQuiropractica";

const QuiropracticaView = () => {
  const { language } = useContext(LanguageContext);
  const { setMenuActive, setSubmenuActive } = useContext(UserContext);

  useEffect(() => {
    setMenuActive(1);
    setSubmenuActive(0);
    goToTop();
  }, [setMenuActive, setSubmenuActive]);
  return (
    <>
      <Banner
        title={
          language === "es"
            ? title_español
            : language === "en"
            ? title
            : title_catalan
        }
        url_img={url_img}
        btn_form={false}
        center={true}
      />
      {/* SECTION BAJADA */}
      <Container fixed className="basic__padding">
        <Grid
          className="reduce__contentx2"
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
          xs={12}
        >
          <h4 className="text__content__blue">
            {language === "es"
              ? `Si buscas soluciones, estás en el sitio correcto.`
              : language === "en"
              ? `If you are looking for solutions, you are in the right place.`
              : `Si buscas soluciones, estás en el sitio correcto.`}
            <br />
            {language === "es"
              ? `Queremos conocerte, saber tus objetivos y trabajar juntos para que tu vida sea mejor.`
              : language === "en"
              ? `We
              want to get to know you, your goals and work together to make your
              life better.`
              : `Queremos conocerte, saber tus objetivos y trabajar juntos para que tu vida sea mejor.`}
          </h4>
        </Grid>
      </Container>

      <Container fixed className="basic__padding">
        <Grid container xs={12}>
          <Grid container xs={12} md={6} className="item__padding">
            {language === "es" ? (
              <p className="description__item__photo reduce__contentx1">
                La visita incluye una serie de pruebas de la columna vertebral,
                y sistema nervioso, y saber si la Quiropráctica te puede ayudar.
                <br />
                <br />
                La visita incluye una serie de pruebas físicas, un escáner
                termográfico (CORE Score) de la columna vertebral, un historial
                de salud y un reconocimiento físico. Todas estas pruebas se
                realizan en las instalaciones de nuestros centros quiroprácticos
                en Barcelona y en nuestro centro quiropráctico en Tarragona.
              </p>
            ) : language === "en" ? (
              <p className="description__item__photo reduce__contentx1">
                In the first visit a series of tests are performed to check the
                condition of your spine and nervous system, and see if
                Chiropractic can help you.
                <br />
                <br />
                The visit includes a series of physical tests, a thermographic
                scan (CORE Score) of the spine, a health history and a physical
                examination. All of these tests are performed at our
                chiropractic centers in Barcelona as well as in our chiropractic
                center in Tarragona.
              </p>
            ) : (
              <p className="description__item__photo reduce__contentx1">
                En la primera visita es duen a terme una sèrie de proves per
                comprovar l’estat de la teva columna vertebral i sistema
                nerviós, i per saber si la Quiropràctica et pot ajudar.
                <br />
                <br />
                La visita inclou una sèrie de proves físiques, un escàner
                termogràfic (CORE Score) de la columna vertebral, un historial
                de salut I un reconeixement físic. Totes aquestes proves es
                realitzen a les instal·lacions dels nostres centres
                quiropràctics a Barcelona i al nostre centre quiropràctic a
                Tarragona.
              </p>
            )}
          </Grid>
          <Grid container xs={12} md={6} className="item__padding">
            {language === "es" ? (
              <p className="description__item__photo reduce__contentx1">
                Es a través de esta primera visita que podemos saber cuál tu
                calidad de vida y si te podemos ayudar con tu caso en
                particular.
                <br />
                <br />
                Una vez recopilada toda la información necesaria, elaboramos un
                informe que recogerás en tu segunda visita.
                <br />
                Si confirmamos que podemos llevar tu caso, se puede comenzar
                inmediatamente con el cuidado quiropráctico.
                <br />
                Si tienes alguna pregunta o duda, puedes llamarnos y te
                atenderemos personalmente.
              </p>
            ) : language === "en" ? (
              <p className="description__item__photo reduce__contentx1">
                It is only through this first visit that we will be able to know
                well what is your level of health, and if we can help you with
                your particular case.
                <br />
                <br />
                Once all the necessary health information is gathered, we
                prepare a report that you will pick up on your second visit. If
                we confirm that we can take your case, chiropractic care can
                begin immediately.
                <br />
                If you have any questions or doubts about your health, you can
                call any of our LinQ centers and we will attend you personally.
              </p>
            ) : (
              <p className="description__item__photo reduce__contentx1">
                És únicament a través d’aquesta primera visita que podrem
                conèixer bé quin és el teu nivell de salut I si et podem ajudar
                en el teu cas en concret.
                <br />
                <br />
                Un cop recollida tota la informació de salut necessària,
                elaborem un informe que rebràs en la teva segona visita. Si
                confirmem que podem portar el teu cas, es pot començar
                immediatament el tractament quiropràctic.
                <br />
                Si tens cap pregunta o dubte sobre la teva salut, pots
                trucar-nos a qualsevol dels nostres centres LinQ I t’atendrem
                personalment.
              </p>
            )}
          </Grid>
        </Grid>
      </Container>

      {/* SECTION CHIROPRACTIC */}
      <section className="background__sky__blue">
        <Grid
          container
          justifyContent="left"
          alignItems="center"
          direction="row"
          xs={12}
        >
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            xs={12}
            md={7}
            className="basic__padding padding__customer"
          >
            <div className="line__left__blue">
              {language === "es" ? (
                <>
                  <h1 className="choose__title text-align-left">
                    La Quiropráctica
                    <br />y tu bienestar
                  </h1>
                  <p
                    className="description__item__chiropractic text-align-left reduce__content__right"
                    style={{ marginBottom: "0px!important" }}
                  >
                    La Quiropráctica nos ayuda a funcionar mejor en todos los
                    sentidos. La gran mayoría de sus usuarios se sienten mejor,
                    se recuperan más rápido y minimizan el uso de medicamentos y
                    cirugías.
                    <br />
                    <br />
                    Muchos de los que utilizan la Quiropráctica notan que
                    gestionan mejor el estrés, tienen más vitalidad y disfrutan
                    más de su día a día.
                  </p>
                </>
              ) : language === "en" ? (
                <>
                  <h1 className="choose__title text-align-left">
                    Chiropractic
                    <br />& your well-being
                  </h1>
                  <p
                    className="description__item__chiropractic text-align-left reduce__content__right"
                    style={{ marginBottom: "0px!important" }}
                  >
                    Chiropractic helps us to function better in every way. The
                    vast majority of its users feel better, recover faster and
                    minimize the use of medications and surgeries.
                    <br />
                    <br />
                    Many of those who use Chiropractic notice that they manage
                    stress better, have more vitality and enjoy more of their
                    day to day.
                  </p>
                </>
              ) : (
                <>
                  <h1 className="choose__title text-align-left">
                    La Quiropràctica
                    <br />i el teu benestar
                  </h1>
                  <p
                    className="description__item__chiropractic text-align-left reduce__content__right"
                    style={{ marginBottom: "0px!important" }}
                  >
                    La Quiropràctica ens ajuda a funcionar millor en tots els
                    sentits. La gran majoria dels seus usuaris es troben millor,
                    es recuperen més ràpid i minimitzen l’ús de medicaments I
                    cirurgies.
                    <br />
                    <br />
                    Molts dels qui utilitzen la Quiropràctica noten que
                    gestionen millor l’estrès, tenen més vitalitat i gaudeixen
                    més del seu dia a dia.
                  </p>
                </>
              )}

              <Link
                className="btn__item__contact__chiropractic reduce__content__right"
                to="/solutions"
              >
                <button>
                  {language === "es"
                    ? `Más información`
                    : language === "en"
                    ? `More information`
                    : `Més informació`}
                </button>
              </Link>
            </div>
            <Grid
              className="reduce__content__right"
              style={{ marginTop: "50px" }}
            >
              {language === "es" ? (
                <p className="description__item__chiropractic text-align-left content__description__customer">
                  Cada año millones de personas disfrutan de los beneficios de
                  la Quiropráctica, la profesión sanitaria natural más difundida
                  en el mundo.
                  <br />
                  <br />
                  Su formación es universitaria y dura un mínimo de cinco años.
                  Si bien en España todavía no está reconocida como profesión
                  sanitaria, la Quiropráctica está establecida en más de 70
                  países y cuenta con un colectivo de aproximadamente 90.000
                  profesionales y un gran reconocimiento internacional,
                  especialmente en EEUU y los países anglosajones.
                </p>
              ) : language === "en" ? (
                <p className="description__item__chiropractic text-align-left content__description__customer">
                  Every year millions of people enjoy the benefits of
                  Chiropractic, the most widespread natural health profession in
                  the world.
                  <br />
                  <br />
                  Its training is university and lasts a minimum of five years.
                  Although in Spain it is not yet recognized as a health
                  profession, Chiropractic is established in more than 70
                  countries and has a group of approximately 90,000
                  professionals and a great international recognition,
                  especially in the U.S. and Anglo-Saxon countries.
                </p>
              ) : (
                <p className="description__item__chiropractic text-align-left content__description__customer">
                  Cada any milions de persones gaudeixen dels beneficis de la
                  Quiropràctica, la professió sanitària natural més difosa al
                  món.
                  <br />
                  <br />
                  La seva formació és universitària i dura un mínim de cinc
                  anys. Si bé a Espanya encara no està reconeguda com a
                  professió sanitària, la Quiropràctica està establerta en més
                  de 70 països i compta amb un col·lectiu d’aproximadament
                  90.000 professionals I un gran reconeixement internacional,
                  especialment als EUA i als països anglosaxons.
                </p>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            direction="row"
            xs={12}
            md={5}
            style={{
              backgroundImage: `url(${process.env.REACT_APP_FRONTEND}assets/image/banner_the_chiropractic.jpg)`,
            }}
            className="banner_customer"
          ></Grid>
        </Grid>
      </section>

      {/* SECTION VIDEO */}
      <Container fixed className="basic__padding">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          xs={12}
        >
          <video
            className="video__config"
            controls
            src={process.env.REACT_APP_FRONTEND + "assets/video/" + video_quiro}
          />
          <p className="description__item__video">
            {language === "es"
              ? `Este video nos explica cómo la Quiropráctica nos ayuda a conseguir ese equilibrio y bienestar.`
              : language === "en"
              ? `This video explains how Chiropractic helps us to achieve balance and
              wellness.`
              : `Aquest vídeo ens explica com la Quiropràctica ens ajuda a aconseguir aquest equilibri i benestar.`}
          </p>
        </Grid>
        <Grid container xs={12}>
          <Grid container xs={12} md={6} className="item__padding">
            {language === "es" ? (
              <p className="description__item__photo reduce__contentx2">
                Detección, corrección y prevención de las alteraciones del
                sistema Neuro-músculo-esquelético, especialmente la columna
                vertebral y de los efectos que producen estos desórdenes.
                <br />
                La Quiropráctica se basa en el sentido común y en unos
                principios básicos. Partimos del hecho de que el cuerpo humano
                está hecho para curarse a sí mismo siempre y cuando esté en
                equilibrio.
              </p>
            ) : language === "en" ? (
              <p className="description__item__photo reduce__contentx2">
                Detection, correction and prevention of alterations of the
                neuro-musculoskeletal system, especially the spine and the
                effects produced by these disorders.
                <br />
                Chiropractic is based on common sense and basic principles. We
                start from the fact that the human body is made to heal itself
                as long as it is in BALANCE.
              </p>
            ) : (
              <p className="description__item__photo reduce__contentx2">
                Detecció, correcció i prevenció de les alteracions del sistema
                neuromusculoesquelètic, especialment la columna vertebral, i
                dels efectes que produeixen aquests desordres.
                <br />
                La Quiropràctica es basa en el sentit comú i en uns principis
                bàsics. Partim del fet que el cos humà està fet per curar-se a
                si mateix sempre que estigui en EQUILIBRI.
              </p>
            )}
          </Grid>
          <Grid container xs={12} md={6} className="item__padding">
            <p className="description__item__photo reduce__contentx2">
              {language === "es"
                ? `El Quiropráctico trabaja con sus manos, alineando aquellas vértebras de la columna que se encuentran desplazadas. Su objetivo es optimizar nuestra calidad de vida, devolviendo al sistema nervioso su equilibrio y su normal funcionamiento.
              `
                : language === "en"
                ? `The chiropractor works with his hands, aligning those vertebrae of
              the spine that are displaced. His goal is to optimize our quality
              of life, restoring balance and normal functioning of the nervous
              system.`
                : `El Quiropràctic treballa amb les seves mans, alineant aquelles vèrtebres de la columna que es troben desplaçades. El seu objectiu és optimitzar la nostra qualitat de vida, retornant al sistema nerviós el seu equilibri i el seu funcionament normal.`}
            </p>
          </Grid>
        </Grid>
      </Container>

      {/* SECTION PILLARS */}
      <section className="basic__padding section__pillars">
        <Container>
          <Grid
            container
            justifyContent="right"
            alignItems="center"
            direction="row"
            xs={12}
            className="line__right__white"
          >
            {language === "es" ? (
              <h1 className="pillars__title">
                Los 5 pilares
                <br />
                de la quiropráctica
              </h1>
            ) : language === "en" ? (
              <h1 className="pillars__title">
                The 5 Pillars
                <br />
                of Chiropractic
              </h1>
            ) : (
              <h1 className="pillars__title">
                Els 5 pilars
                <br />
                de la Quiropràctica
              </h1>
            )}
          </Grid>
          <Grid container xs={12}>
            <Grid container xs={12} md={6}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                xs={12}
                className="item__pading content__item__pillars"
              >
                <h1 className="pillars__title" style={{ margin: "0px" }}>
                  1
                </h1>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                xs={12}
                className="item__padding content__item__pillars"
              >
                <div className="circle__pillars">
                  {language === "es"
                    ? `Un cuerpo en equilibrio puede curarse a sí mismo ya que la salud viene desde dentro.`
                    : language === "en"
                    ? `A body in balance can heal itself as health comes from within.`
                    : `Un cos en equilibri pot curar-se a si mateix, ja que la salut ve des de dins.`}
                </div>
              </Grid>
            </Grid>
            <Grid container xs={12} md={6}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                xs={12}
                className="item__pading content__item__pillars"
              >
                <h1 className="pillars__title" style={{ margin: "0px" }}>
                  2
                </h1>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                xs={12}
                className="item__padding content__item__pillars"
              >
                <div className="circle__pillars">
                  {language === "es"
                    ? `El sistema nervioso (cerebro, médula espinal y nervios) controla y coordina todas las funciones del cuerpo. Cada aspecto de nuestras vidas, tanto físico como emocional, depende siempre de la perfecta conexión entre el cerebro y el cuerpo. La médula espinal y nervios (sistema nervioso) son el principal conducto de impulsos entre el cerebro y el cuerpo.`
                    : language === "en"
                    ? `The nervous system (brain, spinal cord and nerves) controls
                    and coordinates all functions of the body. Every aspect of our
                    lives, both physical and emotional, always depends on the
                    perfect connection between the brain and the body. The spinal
                    cord and nerves (nervous system) are the main conduit of
                    impulses between the brain and the body.`
                    : `El sistema nerviós (cervell, medul·la espinal i nervis) controla i coordina totes les funcions del cos. Cada aspecte de les nostres vides, tant físic com emocional, depèn sempre de la perfecta connexió entre el cervell i el cos. La medul·la espinal i nervis (sistema nerviós) són el principal conducte d’impulsos entre el cervell i el cos.`}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid container xs={12} style={{ marginBottom: "50px" }}>
            <Grid container xs={12} md={4}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                xs={12}
                className="item__pading content__item__pillars"
              >
                <h1 className="pillars__title" style={{ margin: "0px" }}>
                  3
                </h1>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                xs={12}
                className="item__padding content__item__pillars"
              >
                <div className="circle__pillars">
                  {language === "es"
                    ? `La médula espinal pasa por el interior de la columna vertebral, que actúa como su casco protector.`
                    : language === "en"
                    ? `The spinal cord passes through the interior of the spinal
                    column, which acts as its protective helmet.`
                    : `La medul·la espinal passa per l’interior de la columna vertebral, que actua com el seu casc protector.`}
                </div>
              </Grid>
            </Grid>
            <Grid container xs={12} md={4}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                xs={12}
                className="item__pading content__item__pillars"
              >
                <h1 className="pillars__title" style={{ margin: "0px" }}>
                  4
                </h1>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                xs={12}
                className="item__padding content__item__pillars"
              >
                <div className="circle__pillars">
                  {language === "es"
                    ? `Cuando la columna pierde su alineación normal, llamada “subluxación vertebral”, interfiere en el funcionamiento del sistema nervioso, perjudicando la salud del cuerpo.`
                    : language === "en"
                    ? `When the spine loses its normal alignment, called “vertebral
                    subluxation”, it interferes with the functioning of the
                    nervous system, impairing the body’s health.`
                    : `Quan la columna perd la seva alineació normal, l’anomenada “subluxació vertebral” interfereix en el funcionament del sistema nerviós i perjudica la salut del cos.`}
                </div>
              </Grid>
            </Grid>
            <Grid container xs={12} md={4}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                xs={12}
                className="item__pading content__item__pillars"
              >
                <h1 className="pillars__title" style={{ margin: "0px" }}>
                  5
                </h1>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                xs={12}
                className="item__padding content__item__pillars"
              >
                <div className="circle__pillars">
                  {language === "es"
                    ? `Cuando el Quiropráctico corrige la subluxación vertebral, elimina las interferencias del sistema nervioso y permite que la persona, hecha para vivir en salud, funcione con todo su potencial.`
                    : language === "en"
                    ? `When the chiropractor corrects the vertebral subluxation, he
                    eliminates the interference of the nervous system and allows
                    the person, made to live in health, to function to his full
                    potential.`
                    : `Quan el Quiropràctic corregeix la subluxació vertebral, elimina les interferències del sistema nerviós i permet que la persona, feta per viure en salut, funcioni amb tot el seu potencial.`}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </section>

      {/* SECTION VIDEO */}
      <Container fixed className="basic__padding">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          xs={12}
        >
          <video
            className="video__config"
            controls
            src={
              process.env.REACT_APP_FRONTEND + "assets/video/" + video_marcelo
            }
          />
          <p className="description__item__video">
            {language === "es"
              ? `En este vídeo el Doctor Marcelo Ruíz realiza un ajuste quiropráctico.`
              : language === "en"
              ? `In this video Dr. Marcelo Ruiz performs a chiropractic adjustment.`
              : `En aquest vídeo el Doctor Marcelo Ruíz fa un ajust quiropràctic.`}
          </p>
        </Grid>
      </Container>
    </>
  );
};

export default QuiropracticaView;
